import React, { useState, useEffect, useContext } from 'react';
import ITotalCountReport from '../../model/ITotalCountReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

interface GridData {
    total: string,
    stars: number,
    manual: number,
    totalCourses: number,
    sharedInstructionCourses? : number
}


interface ILocation {
    changeExcelRef: any;
    selectedReport: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialSort: Array<SortDescriptor> = [
    { field: "totalCourses", dir: "desc" },
];

export default function TotalCountsReport(props: ILocation) {
    const { orginalSchoolYear } = useContext(Year);
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<GridData[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);

    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const GetTotalCountsReport = () => {
        ReportsService.GetTotalCountsReport(+orginalSchoolYear.id).then(response => {
            let allData: ITotalCountReport[] = response.data
            let gridData: GridData[] = [
                { total: "Total Courses", stars: allData[0].starsCourses, manual: allData[0].manualCourses,sharedInstructionCourses : allData[0].sharedInstructionCourses ,totalCourses: allData[0].totalCourses },
                { total: "Total Teachers", stars: allData[0].totalStarsTeachers, manual: allData[0].totalManualTeachers ,sharedInstructionCourses : allData[0].totalSharedInstructionTeachers, totalCourses: allData[0].totalTeachers },
                { total: "Total Students", stars: allData[0].totalStarsStudents, manual: allData[0].totalManualStudents ,sharedInstructionCourses : allData[0].totalSharedInstructionStudents, totalCourses: allData[0].totalStudents },
                { total: "Unique Teachers", stars: allData[0].totalStarsUniqueTeachers, manual: allData[0].totalManualUniqueTeachers ,sharedInstructionCourses : allData[0].totalSharedInstructionUniqueTeachers, totalCourses: allData[0].totalUniqueTeachers },
                { total: "Unique Students", stars: allData[0].totalStarsUniqueStudents, manual: allData[0].totalManualUniqueStudents ,sharedInstructionCourses : allData[0].totalSharedInstructionUniqueStudents, totalCourses: allData[0].totalUniqueStudents },
                { total: "School Locations", stars: 0, manual: 0, sharedInstructionCourses : 0 , totalCourses: allData[0].locationCode },
            ]
            setRowData(gridData)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetTotalCountsReport();
    }, [props.selectedReport, orginalSchoolYear.id]);


    return (
        <ExcelExport data={rowData} ref={_export} fileName={"Total_Counts_Report" + ' ' + new Date().toDateString()}>
            <Grid
                data={orderBy(rowData.slice(page.skip, page.take + page.skip), sort)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                scrollable={"none"}>
                <GridColumn field="total" title=""></GridColumn>
                <GridColumn field="stars" title="Stars"></GridColumn>
                <GridColumn field="manual" title="Manual"></GridColumn>
                <GridColumn field="sharedInstructionCourses" title="Shared Instruction"></GridColumn>
                <GridColumn field="totalCourses" title="Stars + Manual + Shared Instruction"></GridColumn>
            </Grid>
        </ExcelExport>
    )
}