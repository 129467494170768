import { Redirect, Router } from "react-router";
import React, { useRef } from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import AuthService from "../services/openid/AuthService";
import { render } from "react-dom";
import appsettings from '../assets/appsettings.json'

// export default function ProtectedRoute({ children, ...rest})
// {
//  return (
//      <Router {...rest} render={() => {
//          return true 
//          ? component
//          : <Redirect to ='/Login' />
//      }} ></Router>
//  )
// }
interface Props extends RouteProps {
  component: React.ComponentType;
}

const PrivateRoute: React.SFC<RouteProps> =
  ({ component: Component, ...rest }) => {
    const authService = useRef(new AuthService())

    let isAuth =  sessionStorage.getItem(`oidc.user:${appsettings.OIDC.REACT_APP_OIDC_AUTHORITY}:${appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID}`)
    if (!Component) {
      return null;
    }
    if (!isAuth) {
      authService.current.userManager.signinRedirect()
      return <span>loading</span>;
    }
    else {
      return (

        <Route
          {...rest}
          render={(props: RouteComponentProps<{}>) => <Component {...props} />} />

      );
    }
  };

export default PrivateRoute;
// export const ProtectedRoute = ({ component : Component, ...rest }: Props) => {
//     const authService = useRef(new AuthService())
//     const isAuth : boolean = true
//     return (
//         <Route
//             {...rest}
//             render={(props) => <Component {...props} />}
//         />
//     );
//   };



//   const renderFn = Component => props => (
//     <>
//     {({ isAuthenticated, signinRedirect }) => {
//       if (!!Component && isAuth) {
//         return <Component {...props} />;
//       } else {
//         signinRedirect();
//         return <span>loading</span>;
//       }
//     }}
// </>
// );

// return <Route {...rest} render={renderFn(component)} />;