import { AxiosInstances as AxiosInstance} from './BaseService'
import ICourseContent from '../model/VendorContent';
import IVendor from '../model/VendorContent';

export default {
    getData: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/VendorContent',
        }),
        getByLocationCode: (locationCode:string, vendorId : number) =>
            AxiosInstance({
            'method': 'GET',
            'url': `/VendorContent/${locationCode}/${vendorId}`,                      
        }),
        getVendorContentByLocationCode: (schoolDBN:string) =>
            AxiosInstance({
            'method': 'GET',
            'url': `/VendorContentBySchool/${schoolDBN}`,                      
        }),
        getByVendorId: (vendorId:number) =>
            AxiosInstance({
            'method': 'GET',
            'url': '/vendorcontentbyvendorId/' + vendorId,
        }),
        getById: (id:number) =>
            AxiosInstance({
            'method': 'GET',
            'url': '/VendorContent/' + id,
        }),
    post: (requestdata: ICourseContent) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/VendorContent',
            'data': requestdata
        }),
    edit: (requestdata: ICourseContent) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/VendorContent?id=' + requestdata.id,
            'data': requestdata
        }),
    delete: (id: number) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': '/VendorContent?id=' + id,
        })
}
