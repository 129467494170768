import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import ILearnLayout from './shared/ILearnLayout'

import { AuthContext, AuthContextDefault, ErrorContext, ErrorContextDefault, LocationContext, UserProfileType } from './services/AppContext'
import { Route, Switch } from 'react-router-dom';
import Login from './shared/Login';
import Home from './shared/Home';
import SigninCallback from './services/openid/SigninCallback';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import ProtectedRoute from "./shared/ProtectedRoute"
import { refreshToken, isTokenExpired } from './services/BaseService'
import moment from 'moment';
import LocalStorageService from './services/LocalStorage';
import { SilentRenew } from './services/oidc/silentRenew';


export default function App() {
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile)
    const [errorMessages, setErrorMessages] = useState<Array<string>>(ErrorContextDefault.errorMessages)
    const [locationCode, setLocationCode] = useState('');
     
    const getIntervalTime = () => {
        let now = moment();
        let expirationDate = moment(LocalStorageService.getExpirationTime());
        let time = expirationDate.diff(now, 'seconds') + 3
        return time * 1000
    }
     
    useEffect(() => {
        setInterval(() => {
            if (isTokenExpired()) {
                refreshToken()
            }
        }, getIntervalTime())
    })

    return (

        <AuthContext.Provider value={{ userProfile, setUserProfile }}>
            <div className="main-body">
                <ErrorContext.Provider value={{ errorMessages, setErrorMessages }}>
                    <LocationContext.Provider value={{ locationCode, setLocationCode }}>
                        <Switch>

                            <Route exact path="/" component={Login} />
                            <ProtectedRoute exact path="/Home" component={Home} />
                            <ProtectedRoute path="/ILearn" component={ILearnLayout}
                                render={({ match: { url } }) => (
                                    <>
                                        <ProtectedRoute path={`${url}/:tabName`} render={(props) => <ILearnLayout />} />
                                        <ProtectedRoute path={`${url}/manage/:tabName`} render={(props) => <ILearnLayout {...props} isManageActivated={true} />} />
                                    </>
                                )} />
                            <Route exact path='/silent-renew' component={SilentRenew} />
                            <Route exact path='/signin-callback' component={SigninCallback} />
                            <Route render={() =>
                                <div>
                                    <Header />
                                    <div className="navbar" style={{ "height": "73px" }}></div>

                                    <div className="container text-center">
                                        <i className="fas fa-robot fa-4x text-rust m-5"></i>
                                        <h1>Resource Not Found</h1>
                                        <div>That's all we know. Please check the URL.</div>
                                        <a href="/Home" className="btn btn-primary mt-5">Back to Home</a>
                                    </div>

                                </div>
                            } />

                        </Switch>
                    </LocationContext.Provider>
                </ErrorContext.Provider>
            </div>
            <Footer />
        </AuthContext.Provider>


    )
}