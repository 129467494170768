import { useState, useEffect, useContext } from 'react';
import { LocationContext, Year } from '../../services/AppContext'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Row, Col } from 'react-bootstrap';
import LicenseUsageReport from './LicenseUsageReport';
import LicenseAllocationReport from './LicenseAllocationReport';
import PublishedCoursesReport from './PublishedCoursesReport';
import TotalCountsReport from './TotalCountsReport'
import CourseStatusReport from './StatusWiseReport';
import LicenseTransferHistoryReport from './LicenseTransferHistoryReport'
import DailyReport from './DailyReport';
import MissingTORReport from './MissingTORReport';
import ReportsService from '../../services/ReportsService'
import TeacherCreatedCourseReport from './TeacherCreatedCourseReport';
import { KendoOoxml } from '@progress/kendo-react-excel-export';
import SharedInstructionCourseReport from './SharedInstructionCourseReport';
import { Workbook } from '@progress/kendo-ooxml';
import CourseEnrollmentSummaryReport from './CourseEnrollmentSummary';



interface ILocation {
  id: string
}

interface Reports {
  reportName: string;
  reportValue: string;
  isDefaultSelected: number;
}
const dropDownData = ['Transferrable-Unlimited', 'NonTransferrable-Unlimited', 'NonTransferrable-Per Enrollment']

export default function Report(location: ILocation) {
  const { locationCode } = useContext(LocationContext);
  const { orginalSchoolYear } = useContext(Year);
  const [licenseType, setLicenseType] = useState('Transferrable-Unlimited');
  const [allReports, setAllReports] = useState<Reports[]>([{ reportName: '', reportValue: '', isDefaultSelected: 0 }]);
  const [selectedReport, setSelectedReports] = useState<Reports>({ reportName: '', reportValue: '', isDefaultSelected: 0 });
  const [excelRef, setExcelRef] = useState<any>(null);
  const [rowData, setRowData] = useState<any[]>([]);


  const excelExport = () => {
    if (excelRef.current !== null) {
      if (excelRef.current.props.fileName.indexOf('Teacher_Created_Course_Report') == 0) {
        let workbook = excelRef.current.workbookOptions()
        let rows = workbook.sheets[0].rows;
        let headerOptions = rows[0].cells[0];
        let data = excelRef.current.props.data
        for (let idx = data.length - 1; idx >= 0; idx--) {
          let teachers = data[idx].teachers
          for (let teacherIdx = teachers.length - 1; teacherIdx >= 0; teacherIdx--) {
            const teacher = teachers[teacherIdx];
            rows.splice(idx + 2, 0, { cells: [{}, { value: teacher.ein }, { value: teacher.firstName }, { value: teacher.lastName }, { value: teacher.teacherEmail }] });
          }
          rows.splice(idx + 2, 0, {
            cells: [
              {},
              Object.assign({}, headerOptions, { value: 'EIN' }),
              Object.assign({}, headerOptions, { value: 'First Name' }),
              Object.assign({}, headerOptions, { value: 'Last Name' }),
              Object.assign({}, headerOptions, { value: 'Email' })
            ]
          });

        }
        new KendoOoxml.Workbook(workbook).toDataURL().then((dataUrl) => {
          excelRef.current.save(workbook)
        })
      } else if (excelRef.current.props.fileName.indexOf('Shared_Instruction_Courses_Report') == 0) {
        let workbook = excelRef.current.workbookOptions();

        let rows = workbook.sheets[0].rows;
        let headerOptions = rows[0];
        let data = excelRef.current.props.data;

        rows = [headerOptions]; // Initialize rows with headerOptions
        data.forEach((course: any) => {
          course.assignedStarsCourses.forEach((ele: any) => {
            rows.push({
              cells: [
                { value: course.sharedCourseName },
                { value: course.sharedCourseLocationCode },
                { value: course.sharedCourseStatus },
                { value: course.teacherofRecordEIN },
                { value: course.teacherofRecordName },
                { value: course.sharedCourseCreationDate },
                { value: ele.starsCourseName },
                { value: ele.starsCourseLocationCode },
                { value: ele.starsCourseCode },
                { value: ele.starsMappingId },
                { value: ele.termId },
                {
                  value: ele.assignedStarsCoursesTeachers && ele.assignedStarsCoursesTeachers.length && ele.assignedStarsCoursesTeachers.map((teacher: any) => {
                    if (teacher.teacherEIN && teacher.teacherEIN != "") {
                      return `${teacher.teacherName} - ${teacher.teacherEIN} (${teacher.teacherDBN})`;
                    }

                  }).join(', ')
                },
                { value: ele.studentsCount }
              ],
            });
          });
        });

        workbook.sheets[0].rows = rows;

        new KendoOoxml.Workbook(workbook).toDataURL().then((dataUrl) => {
          excelRef.current.save(workbook);
        });

      } else {
        excelRef.current.save();
      }
    }





  };



  const changeExcelRef = (ref: any) => {
    setExcelRef(ref)
  }

  const getReportData = (data: any) => {
    setRowData(data)
  }

  const onReportSelectionChange = (e: any) => {
    setSelectedReports(e.target.value)
  }

  const onSelectionChange = (e: any) => {
    setSelectedReports(selectedReport)
    setLicenseType(e.target.value)

  }

  useEffect(() => {
    if (location.id == "AllSchools") {
      ReportsService.GetAllReports().then(response => {
        setAllReports(response.data)
        let defaultSelectedReport = response.data.find((d: Reports) => d.isDefaultSelected == 1)
        setSelectedReports(defaultSelectedReport)
      })
    } else {
      let reports: Reports[] = [{ reportName: 'License Allocation Report', reportValue: 'License Allocation Report', isDefaultSelected: 1 }
        , { reportName: 'Course Status Report', reportValue: 'Course Status Report', isDefaultSelected: 0 }]
      setAllReports(reports)
      let defaultSelectedReport = reports.find((d: Reports) => d.isDefaultSelected == 1) ?? { reportName: 'License Allocation Report', reportValue: 'License Allocation Report', isDefaultSelected: 1 }
      setSelectedReports(defaultSelectedReport)
    }
  }, [locationCode, orginalSchoolYear.id, location.id]);


  return (
    <div>
      <Row className="mb-3">
        <Col xs={9} sm={8} md={6} lg={4}>
          <DropDownList
            data={allReports}
            defaultValue={selectedReport}
            popupSettings={{ width: 350 }}
            style={{ width: "100%" }}
            value={selectedReport}
            onChange={onReportSelectionChange}
            textField="reportName"
          />
        </Col>
        <Col xs={3} sm={4} md={6} lg={8} className="text-right">
          <button type="button" className="btn btn-primary" onClick={excelExport}>
            <i className="fas fa-download"></i>
          </button>
        </Col>
      </Row>
      {selectedReport.reportValue == "License Usage Report" &&
        <Row className="mb-3">
          <Col sm="4" className="text-right">
            <DropDownList
              data={dropDownData}
              defaultValue={dropDownData[0]}
              onChange={onSelectionChange}
              style={{ maxWidth: '350px', width: "100%" }}
            />
          </Col>
        </Row>
      }
      {selectedReport.reportValue == "License Allocation Report" && <LicenseAllocationReport id={location.id} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Course Status Report" && <CourseStatusReport selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Published Courses Report" && <PublishedCoursesReport selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Total Counts Report" && <TotalCountsReport selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "License Transfer History Report" && <LicenseTransferHistoryReport selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Daily Report" && <DailyReport selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "License Usage Report" && licenseType == 'Transferrable-Unlimited'
        && <LicenseUsageReport licenseType={licenseType} selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "License Usage Report" && licenseType == 'NonTransferrable-Unlimited'
        && <LicenseUsageReport licenseType={licenseType} selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "License Usage Report" && licenseType == 'NonTransferrable-Per Enrollment'
        && <LicenseUsageReport licenseType={licenseType} selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Teacher Created Course Report" && <TeacherCreatedCourseReport changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Course Enrollment Summary" && <CourseEnrollmentSummaryReport changeExcelRef={changeExcelRef} />}
      {selectedReport.reportValue == "Shared Instruction Courses Report" && <SharedInstructionCourseReport changeExcelRef={changeExcelRef} getReportData={getReportData} />}
      {selectedReport.reportValue == "Missing TOR Report"   && <MissingTORReport selectedReport={selectedReport} changeExcelRef={changeExcelRef} />}
    </div>

  )
}


