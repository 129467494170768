import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Button, Modal, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FormMode, IDropDownValue } from '../../services/Utility'
import schoolService from '../../services/SchoolService';
import { Year, UserProfileType, AuthContextDefault, AuthContextType, AuthContext } from '../../services/AppContext';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ICourses from '../../model/ICourses';
import IconCourseEnvelop from '../../assets/icons/icon-envelop-course';
import CoursesService from '../../services/CoursesService';
import IIndividualCourses from '../../model/IIndividualCourses';
import SplashScreen from '../../shared/SplashScreen';
import LocalStorageService from '../../services/LocalStorage';
import SharedCoursesList from '../../shared/SharedCoursesList';

interface Props {
    courseId: number
    courseStatus: string
    locationCode: string
    courseUniqueId: string
    updateCourseInfo: (courseId: number, courseTeachers: any, studentCount: number, action: string) => void
}
const initialDataState = {
    skip: 0,
    take: 10,
};


export default function IndividualCourses(courseProps: Props) {

    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [isLoading, setIsLoading] = useState(false);
    const { orginalSchoolYear } = useContext(Year);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [assignedCourses, setAssignedCourses] = useState<IIndividualCourses[]>([]);
    const [checkedCourses, setCheckedCourses] = useState<ICourses[]>([]);
    const [starsCourses, setStarsCourses] = useState<ICourses[]>([]);
    const [showAddIndividualCoursesDialog, setShowAddIndividualCoursesDialog] = useState<boolean>(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [orginalSchoolList, setOrginalSchoolList] = useState<IDropDownValue[]>([]);
    const [schoolList, setSchoolList] = useState<IDropDownValue[]>([]);
    const [selectedSchool, setSelectedSchool] = useState<IDropDownValue>();
    const [deletedCourse, setDeletedCourse] = useState<string>();
    const [page, setPage] = React.useState(initialDataState);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);



    const pageChange = (event: any) => {
        setPage(event.page);
        let currentPage = (event.page.skip / event.page.take) + 1
        getStarsCourses(currentPage, event.page.take, selectedSchool?.id ?? courseProps.locationCode)
    };

    const GetStatusBadgeCssClass = (status: string) => {

        if (status.toLowerCase() === "new") {
            return "text-gray"
        }
        else if (status.toLowerCase() === "edited") {
            return "text-secondary"
        }
        else if (status.toLowerCase() === "published") {
            return "text-success"
        }
        else if (status.toLowerCase() === "closed") {
            return "text-danger"
        }
        
    }

    const getStarsCourses = (page: number, limit: number, locationCode: string) => {
        setIsLoading(true)
        CoursesService.getStarsCourses(courseProps.courseUniqueId, locationCode, +orginalSchoolYear.id, page, limit).then(response => {
            setIsLoading(false)
            setStarsCourses(response.data)
            if (checkedCourses && checkedCourses.length > 0) {
                if (response.data.length > 0) {
                    checkedCourses.forEach((c: any) => {

                        let selectedCourse = response.data.find((s: any) => s.courseId == c.courseId)

                        if (selectedCourse) {
                            selectedCourse.isSelected = true
                            forceUpdate()
                        }
                    })
                }
            }
        })

    }


    const removeIndividualCourse = (sectionUniqueId: string) => {
        setDeletedCourse(sectionUniqueId)
        setFormMode(FormMode.Delete)
    }

    const getSchoolLocations = () => {

        if (userProfile?.role?.roleName == 'System Administrator' || userProfile.role.roleName == "Central Staff") {
            schoolService.getData().then(response => {
                let dropDownData = response.data.map((sl: any) => ({
                    id: sl.locationCode,
                    text: sl.locationCode + ' -- ' + sl.schoolName
                }));
                setSchoolList(dropDownData)
                setOrginalSchoolList(dropDownData)
            });
        } else {

            let data = userProfile?.role?.locations?.map(s => ({ id: s.LocationCode, text: s.LocationCode + ' -- ' + s.LocationName }))
            if (data) {
                setSchoolList(data)
                setOrginalSchoolList(data)
            }

        }
    }
    const filterData = (filter: any) => {
        let data = orginalSchoolList.slice();
        return filterBy(data, filter);
    }

    const filterChange = (event: any) => {
        let data = filterData(event.filter)
        setSchoolList(data)
    }

    const handleChange = (event: any) => {
        let selectedSchool = event.target.value;
        setSelectedSchool(selectedSchool)
        setPage(initialDataState)
        let currentPage = (initialDataState.skip / initialDataState.take) + 1
        getStarsCourses(currentPage, initialDataState.take, selectedSchool.id)
    }

    const getDefaultValue = (schoolList: any) => {
        let school = schoolList.find((s: any) => s.id == courseProps.locationCode)
        if (school) {
            return school
        }
    }

    const getAssignedCourses = (courseId: number) => {
        CoursesService.getIndividualCoursesBySharedCourseId(courseId).then(response => {
            setAssignedCourses(response.data)
        });
    }

    useEffect(() => {
        setCheckedCourses([])
        getAssignedCourses(courseProps.courseId);
        setUserProfile(LocalStorageService.getUserProfile())
    }, [courseProps.courseId]);


    const toggleDialog = () => {
        setCheckedCourses([])
        setShowAddIndividualCoursesDialog(!showAddIndividualCoursesDialog)
    }

    const addIndividualCourses = () => {
        setShowAddIndividualCoursesDialog(true)
        getSchoolLocations()
        setFormMode(FormMode.New)
        setPage(initialDataState)
        let currentPage = (initialDataState.skip / initialDataState.take) + 1
        getStarsCourses(currentPage, initialDataState.take, courseProps.locationCode)
    }

    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        let selectedCourses = checkedCourses
        if (dataItem.isSelected) {
            selectedCourses.push(dataItem)

            setCheckedCourses(selectedCourses)
        } else {
            selectedCourses = checkedCourses.filter(item => item.courseId !== dataItem.courseId);

            setCheckedCourses(selectedCourses)
        }

        forceUpdate();
    }


    const TeacherBadge = (props: any) => {

        return (
            <td>
                <div>Assigned Teachers</div>
                {props.dataItem.courseTeachers.map((item: any) =>

                (

                    <OverlayTrigger
                        key={item.teacherId}
                        placement='top'
                        trigger={['hover', 'focus']}
                        overlay={
                            <Tooltip id={`tooltip-${item.teacherId}`} style={{zIndex:1000000}}>
                                {item.firstName + item.lastName}
                            </Tooltip>
                        }
                    >
                        <Button key={item.teacherId} className={"btn badge badge-warning border-0 mr-1"}>{item.teacherInitial ? item.teacherInitial : item.firstName?.charAt(0) + item.lastName?.charAt(0)}</Button>
                    </OverlayTrigger>
                ))}
            </td>



        );
    }

    const [searchCourseName, setSearchCourseName] = useState<string>();
    const searchByCourseName = (event: any) => {
        const target = event.target
        if (!target.value || target.value == 0) {
            setErrorMsg('')
            setPage(initialDataState);
            let currentPage = (initialDataState.skip / initialDataState.take) + 1
            getStarsCourses(currentPage, initialDataState.take, selectedSchool?.id ?? courseProps.locationCode)
        }
        setSearchCourseName(target.value)
    };
    const getCourseByCourseName = () => {
        setErrorMsg('')
        if (!searchCourseName) {
            setErrorMsg("Please enter course name ")
            return
        }
        setStarsCourses([])
        setIsLoading(true)
        setPage(initialDataState);
        let currentPage = (initialDataState.skip / initialDataState.take) + 1
        CoursesService.searchStarsCourseByName(courseProps.courseUniqueId, selectedSchool?.id ?? courseProps.locationCode, +orginalSchoolYear.id, currentPage, initialDataState.take, searchCourseName).then(response => {
            setIsLoading(false)
            setStarsCourses(response.data)
        })
    }



    const onSave = (event: any, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.New:
                let addIndividualCourse: any = {
                    sharedCourseUniqueId: courseProps.courseUniqueId,
                    individualCoursesUniqueId: checkedCourses.map(i => i.courseUniqueId)
                }


                if (addIndividualCourse.individualCoursesUniqueId?.length == 0) {
                    setErrorMsg('Please select courses to add.');
                    return;
                }
                let courseTeachers = checkedCourses.map(i => i.courseTeachers)
                let studentCount = checkedCourses.map(i => i.totalStudentEnrolled)
                let result = studentCount.reduce((a, b) => {
                    return a + b;
                });

                CoursesService.addIndividualCourses(addIndividualCourse).then(res => {
                    setFormMode(FormMode.None)
                    toggleDialog()
                    courseProps.updateCourseInfo(courseProps.courseId, courseTeachers.flat(), result, "add")
                })

                break;
            case FormMode.Delete:

                let deleteIndividualCourse: any = {
                    sharedCourseUniqueId: courseProps.courseUniqueId,
                    individualCourseUniqueId: deletedCourse
                }
                CoursesService.deleteIndividualCourses(deleteIndividualCourse).then(res => {
                    setFormMode(FormMode.None)
                    courseProps.updateCourseInfo(courseProps.courseId, res.data.result.newCourseTeachers, res.data.result.studentCount, "delete")

                })
                break;
            default: {
                setFormMode(FormMode.None)
                break;

            }
        }
    }


    return (
        <div>
            {showAddIndividualCoursesDialog && <Dialog title={"Add Shared Instruction Course"} width={1000} onClose={toggleDialog} style={{ width: "80%", position: "fixed", left: "10%", top: "20%", height: "350px" }} >
                <div id="dropDownParent">
                    <div hidden={errorMsg.length == 0}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMsg}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" >
                        <div className="text-sm-left text-left">
                            <InputGroup >
                                <FormControl
                                    placeholder="Course Name"
                                    onChange={searchByCourseName}
                                />
                                <Button variant="outline-secondary" id="button-addon1" onClick={getCourseByCourseName}
                                >
                                    Search
                                </Button>
                            </InputGroup>
                        </div>
                        <div className="text-sm-right text-left"  >
                            <DropDownList
                                data={schoolList}
                                defaultValue={getDefaultValue(schoolList)}
                                textField="text"
                                filterable={true}
                                onChange={handleChange}
                                onFilterChange={filterChange}
                                popupSettings={{ appendTo: document.getElementById("dropDownParent") as HTMLElement }}
                                style={{ maxWidth: '300px', width: '100%' }}
                            />
                        </div>
                    </div>
                    {isLoading && <div className="d-flex justify-content-center"><SplashScreen /></div>}
                    <div className="mt-3" style={{ height: "400px" }}>
                        <Grid
                            data={starsCourses.slice(0, page.take)}

                            skip={page.skip}
                            take={page.take}
                            total={starsCourses && starsCourses.length ? starsCourses[0].totalPages : 0}
                            className="k-grid-no-header"
                            pageable={true}
                            scrollable={"none"}
                            onPageChange={pageChange}
                        >
                            <GridColumn field="isSelected" width="40px" filterable={false} cell={props => (
                                <td>
                                    <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                                </td>
                            )} />

                            <GridColumn field="UserIcon" title=" " filterable={false} width="50px" cell={
                                props => (
                                    <td className="pr-0">
                                        <div className="d-flex">
                                            <div className={`svg-app-icon ${props.dataItem.courseStatus.toLowerCase() === "published" ? " text-success" : " text-blue-gray"} mr-3"`} style={{ height: "50px" }}>
                                                <IconCourseEnvelop courseType={props.dataItem.courseType} courseOrginType="stars" merge={0}></IconCourseEnvelop>
                                            </div>
                                        </div>
                                    </td>
                                )} />
                            <GridColumn field="Name" cell={
                                props => (
                                    <td>
                                        <div>{props.dataItem.courseName}</div>
                                        <div className="small">Course Code:{props.dataItem.courseCode}</div>
                                        
                                            <div ><span className={GetStatusBadgeCssClass(props.dataItem.courseStatus)}> {props.dataItem.courseStatus} </span>
                                             {props.dataItem.starsSharedCourses.length != 0 &&
                                                <SharedCoursesList
                                                sharedCourses={props.dataItem.starsSharedCourses}
                                                width='80%'
                                                />
                                            }</div>

                                        
                                    </td>
                                )} />
                            <GridColumn field="Teachers" cell={TeacherBadge} />

                            <GridColumn field="Students" cell={
                                props => (
                                    <td>
                                        <div>Enrolled Students</div>
                                        <div >{props.dataItem.totalStudentEnrolled}</div>
                                    </td>
                                )} />
                            <GridColumn field="Location" cell={
                                props => (
                                    <td>
                                        <div>Associated Location</div>
                                        <div >{props.dataItem.locationCode}</div>
                                    </td>
                                )} />

                        </Grid>

                    </div>

                </div>
                <DialogActionsBar>
                    <Button className="m-3" variant="secondary" onClick={toggleDialog}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Add Courses</Button>
                </DialogActionsBar>
            </Dialog>}
            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>

                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Remove Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove course ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <div className="card mt-4">
                <div className="card-header font-weight-bold bg-secondary-light">
                    <div className="row align-items-center">
                        <div className="col-10">Shared Instruction Courses ({assignedCourses.length})</div>
                        {courseProps.courseStatus.toLowerCase() == 'closed' ? '' :
                            <div className="col-2  text-right">
                                {orginalSchoolYear.isCurrent != 0 &&
                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => { addIndividualCourses() }}
                                    >
                                        <i className="fas fa-plus"></i>
                                    </button>}
                            </div>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <Grid
                        data={assignedCourses}
                        scrollable={"none"}
                        className="k-grid-no-header"
                    >
                        {/* <GridColumn field="expanded" width="80px" title=' ' /> */}
                        <GridNoRecords>
                            No Enrolled Courses
                        </GridNoRecords>
                        <GridColumn field="CourseIcon" title=" " width="80px" cell={
                            props => (
                                <td>
                                    <div className="d-flex">
                                        <div className={`svg-app-icon text-blue-gray mr-3"`} style={{ height: "60px" }}>
                                            <IconCourseEnvelop courseType={props.dataItem.courseType} courseOrginType="stars" merge={0}></IconCourseEnvelop>
                                        </div>
                                    </div>
                                </td>
                            )} />
                        <GridColumn field="Name" title="" cell={
                            props => (
                                <td>
                                    <div> {props.dataItem.courseName}</div>
                                    <div className="small">Course Code:{props.dataItem.courseCode}</div>
                                </td>
                            )} />

                        <GridColumn field="schoolDBN" title="Associated Location" cell={
                            props => (
                                <td>
                                    <div className="small">Associated Location:</div>
                                    <div>{props.dataItem.locationCode}</div>
                                </td>
                            )} />
                        <GridColumn field="" title=" " cell={
                            props => (
                                <td className="text-right menu-btn">
                                    {
                                        courseProps.courseStatus?.toLowerCase() === 'closed'
                                            ? '' :
                                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { removeIndividualCourse(props.dataItem.sectionUniqueId) }}
                                            >
                                                <i className="fas fa-minus"></i>
                                            </button>
                                    }
                                </td>
                            )} />
                    </Grid>
                </div>
            </div>
        </div>
    )
}