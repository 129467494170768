import React, { useState, useEffect } from 'react';
import LicenseAllocationService from '../../services/LicenseAllocation';
import ICourseContent from '../../model/VendorContent';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
interface IContentDisplay {
    locationCode: string,
    vendorId: number
}
export default function VendorContentDisplay(props: IContentDisplay) {
    const [rowData, setCourseContentData] = useState<ICourseContent[]>([]);
    useEffect(() => {
        LicenseAllocationService.getAllocatedVendorDetailById(props.locationCode, props.vendorId).then(response => {
            setCourseContentData(response.data)
        })
    }, []);

    return (
        <div className="card">
            <div className="card-header font-weight-bold bg-secondary-light">
                Vendor Content
            </div>
            <div className="card-body">
                <Grid data={rowData} filterable={false} pageable={true} sortable={true} scrollable="none" >
                    <GridColumn headerClassName="bg-light text-dark" field="vendorCourseContentName" title="Vendor Course Content Name" />
                </Grid>
            </div>
        </div>
    )
}

