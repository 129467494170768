import  { AxiosInstances as AxiosInstance  }  from './BaseService'

//const raback = setupInterceptorsForRBAC(AxiosInstances)
export default {
    getUserRole: () =>
    AxiosInstance({
        'method': 'GET',
        'url': `/RBAC`
    })
        // AxiosInstance({
        //     'method': 'GET',
        //     'url': `/RBAC`
        // }),    
}