import React, { useState, useEffect, useReducer, useRef, useContext } from 'react'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid'
import IVendorLicenseContent from '../../model/IVendorLicenseContent'
import VendorDetail from '../../shared/VendorDetail'
import VendorContentDisplay from './VendorContentDisplay'
import VendorManageContent from './VendorManageContent'
import VendorEditContent from './VendorEditContent'
import GridHierarchyExpand from '../../shared/GridHierarchyExpand'
import { FormMode, IDropDownValue } from '../../services/Utility'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import licenseAllocationService from '../../services/LicenseAllocation'
import ILicenseAllocation from '../../model/ILicenseAllocation'
import IconBrowserGear from '../../assets/icons/icon-window-gear'
import axios, { AxiosResponse } from 'axios'
import { AuthContextDefault, UserProfileType, Year } from '../../services/AppContext';
import LocalStorageService from '../../services/LocalStorage'
import { CheckIfNYCDOELicense } from '../../helpers/helper'
interface ILocationCode {
    id: string,
}
export default function VendorContent(locationCode: ILocationCode) {
    const mountedRef = useRef(true);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    const { orginalSchoolYear } = useContext(Year);
    //Page Load event - Get data from Service
    const [data, setRowData] = useState<IVendorLicenseContent[]>([]);
    const [editData, setEditData] = useState<IVendorLicenseContent>({
        id: 0,
        vendorId: 0,
        vendorName: '',
        vendorDescription: '',
        licenseModel: '',
        licenseType: '',
        availableLicenses: 0,
        totalLicenses: 0
    });
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    //Force re-render the child component on Expand
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [vendorList, setVendorList] = useState<IDropDownValue[]>([]);
    const [vendorAllocatedList, setVendorAllocatedList] = useState<IDropDownValue[]>([]);

    const [formValue, setFormValue] = useState<ILicenseAllocation>({
        id: 0,
        locationCode: '',
        fromLocationCode: '',
        toLocationCode: '',
        vendorId: 0,
        vendorName: '',
        quantityAllocated: 0,
        quantityAssigned: 0,
        quantityCap: 0
    });

    const getVendorDetail = () => {
        licenseAllocationService.getAllocatedVendorsDetail(locationCode.id, +orginalSchoolYear.id).then(response => {
            setRowData(response.data)
            data.map((item: any) => {
                item.expanded = false;
                item.isSelected = false
                return item;
            })
            let nonAllocatedVendor = vendorList
            nonAllocatedVendor = nonAllocatedVendor.filter(x => !response.data.filter((y: IVendorLicenseContent) => y.vendorId === +x.id).length)
            setVendorList(nonAllocatedVendor)
        })
    }
    useEffect(() => {
        if (locationCode.id && +orginalSchoolYear.id != 0) {
            axios.all([
                licenseAllocationService.getAllocatedVendorsDetail(locationCode.id, +orginalSchoolYear.id),
                licenseAllocationService.getNonAllocatedVendors(locationCode.id, +orginalSchoolYear.id),
                
                licenseAllocationService.getAllocatedVendors(locationCode.id, +orginalSchoolYear.id)
            ])
                .then(responseArray => {
                    setRowData(responseArray[0].data)
                    responseArray[0].data.map((item: any) => {
                        item.expanded = false;
                        item.isSelected = false
                        return item;
                    })

                    let nonAllocatedVendorDropDownData = responseArray[1].data.map((vl: any) => ({
                        id: vl.id,
                        text: vl.vendorName
                    }));
                    setVendorList(nonAllocatedVendorDropDownData)
                    let allocatedVendorDropDownData = responseArray[2].data.map((vl: any) => ({
                        id: vl.id,
                        text: vl.vendorName
                    }));
                    setVendorAllocatedList(allocatedVendorDropDownData)
                })
            setUserProfile(LocalStorageService.getUserProfile())
        }
        return () => { mountedRef.current = false }
    }, [locationCode.id, orginalSchoolYear.id]);

    //On click of expand this function will call
    const SubGridHost = (props: any) => {
        // feed data to below components, Call the service Based on the prop.Id
        return (
            <div>
                <VendorDetail data={props.dataItem} />
                <VendorContentDisplay locationCode={locationCode.id} vendorId={props.dataItem?.vendorId} />
            </div>
        )
    }

    const GridColumnHierarchyExpand = (props: any) => <GridHierarchyExpand {...props} expandChange={onGridExpand} />
    const onGridExpand = (dataItem: any) => {
        dataItem.expanded = !dataItem.expanded;
        forceUpdate();
    }
    const onFormAction = (vendor: any, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.New:
                setFormMode(FormMode.New)

                break;
            case FormMode.Edit:
                setFormMode(FormMode.Edit)
                setEditData(vendor)

                break;
            case FormMode.Transfer:
                setFormMode(FormMode.Transfer)
                setEditData(vendor)

                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }
    const vendorFormClose = (data: FormMode) => {
        setFormMode(data)
    }

    const onVendorSave = (event: any, actionType: FormMode, data: ILicenseAllocation) => {
        switch (actionType) {
            case FormMode.New:
                event.preventDefault();
                licenseAllocationService.post(data).then(response => {
                    // Display Success message
                    getVendorDetail();
                    setFormMode(FormMode.None)
                }
                );
                //Clear Existing vlues
                break;
            case FormMode.Edit:
                event.preventDefault();
                licenseAllocationService.put(data).then(response => {
                    // Display Success message
                    getVendorDetail();
                    setFormMode(FormMode.None)
                }

                );
                //Clear Existing vlues
                break;
            case FormMode.Transfer:
                event.preventDefault();
                licenseAllocationService.postTransfer(data).then(response => {
                    // Display Success message
                    getVendorDetail();
                    setFormMode(FormMode.None)
                }

                );
                //Clear Existing vlues
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }

    const MyCellComponent = (props : any) => {
        // Extract the necessary data from props
        const { dataItem } = props;
      
        // Use the extracted data to render the cell content
        if(!CheckIfNYCDOELicense(dataItem.licenseModel)){
            return (
                <td className="text-right" style={{ overflow: 'visible' }}>
                  <DropdownButton
                    menuAlign="right"
                    title={<i className='fas fa-bars'></i>}
                    id="dropdown-menu-align-right"
                    size="sm"
                    variant="outline-primary"
                    className="menu-bars"
                  >
                    <Dropdown.Item onClick={() => { onFormAction(dataItem, FormMode.Edit) }}>Edit Licenses</Dropdown.Item>
                  </DropdownButton>
                </td>
              );
        }

        else {
            return <td></td>
        }
        
      };
   
    return (
        <div>
            {
                (userProfile?.role?.roleName == 'System Administrator' && orginalSchoolYear.isCurrent != 0) ?
                    <div className="text-right mb-2">
                        <button type="button" className="btn btn-primary" onClick={(e) => { onFormAction(0, FormMode.New) }}>
                            <i className="fas fa-plus"></i>
                        </button>
                    </div> : <div> </div>
            }
            {(formMode == FormMode.Transfer || formMode == FormMode.New) && <VendorManageContent location={locationCode.id}  vendorlist={vendorList} vendorAllocatedlist={vendorAllocatedList} formmode={formMode} datasource={data} isEditable={false}  onSave={onVendorSave} />}
            <Grid
                data={data}
                detail={SubGridHost}
                expandField={'expanded'}
                scrollable={"none"}
            >
                <GridColumn field="expanded" cell={GridColumnHierarchyExpand} title=' ' width={"50px"} />

                <GridColumn field="VendorInfo" title="Vendor" cell={
                    props => (
                        <td>
                            <div className="d-flex">
                                <div className="svg-app-icon text-blue-gray mr-3" style={{ "height": "30px" }}>
                                    <IconBrowserGear />
                                </div>
                                <div className="mb-0 h3">{props.dataItem.vendorName}</div>
                            </div>
                        </td>
                    )} />
                <GridColumn field="AvailableLicense" title="Available Licenses"
                    // Grid Header - center text
                    headerCell={
                        props => (
                            <div className="text-center">
                                {props.title}
                            </div>
                        )}
                    // Grid Cell - center text
                    cell={
                        props => (
                            <td className="text-center">
                                <div>{!CheckIfNYCDOELicense(props.dataItem.licenseModel) ? props.dataItem.availableLicenses : 'unlimited'} </div>
                            </td>
                        )}
                />
                <GridColumn field="AvailableLicense" title="Total Licenses"
                    // Grid Header - center text
                    headerCell={
                        props => (
                            <div className="text-center">
                                {props.title}
                            </div>
                        )}
                    // Grid Cell - center text
                    cell={
                        props => (
                            <td className="text-center">
                                <div>{ props.dataItem.licenseModel != "Unlimited use of catalog for unlimited users" ? props.dataItem.totalLicenses : 'unlimited'}</div>
                            </td>
                        )}
                />
                {
                    (userProfile?.role?.roleName == 'System Administrator' && orginalSchoolYear.isCurrent != 0  ) ?
                        <GridColumn field="id" title=" " cell={MyCellComponent
                            } /> : <div></div>
                }
            </Grid>
            {formMode == FormMode.Edit && <VendorEditContent location={locationCode.id} vendorlist={vendorList} formmode={formMode} datasource={editData} isEditable={true} onVendorEditClose={vendorFormClose} onSave={onVendorSave} />}
        </div>
    )
}