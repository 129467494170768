import React, { useState, useEffect, Component } from 'react';
import schoolInfoService from '../services/SchoolInfoService';
import ISchoolInfo from '../model/ISchoolInfo'
import PurchaseOrder from './PurchaseOrder';

interface ILocationCode
{
  id: string,
}

export default function SchoolBanner(locationCode: ILocationCode) {
  const [schoolData, getSchoolData] = useState<ISchoolInfo>({
    locationName: 'string',
    locationCode: 'string',
    address: 'string',
    principalFullName: 'string'
  });
  useEffect(() => {
    schoolInfoService.getBySchoolDBN(locationCode.id).then(response => {
      getSchoolData(response.data)
      
    })
  }, [locationCode]);

  return (
    <section>
            <div className="ml-0 ml-md-4 mt-2 mt-md-0 align-middle text-center text-md-left">
              <h1 className="h2 mb-0 d-inline-block">{schoolData.locationName} ({schoolData.locationCode})</h1>
              <div className="h6 mt-1 mb-0 text-muted font-weight-normal">
                <div className="d-md-inline">DBN: {schoolData.locationCode}</div>
                <div className="pl-1 pr-1 d-none d-md-inline">&bull;</div>
                <div className="d-md-inline">{schoolData.address}</div>
                {schoolData.principalFullName != "" ? (<div className="d-md-inline"><div className="pl-1 pr-1 d-none d-md-inline">&bull;</div>Principal: {schoolData.principalFullName}</div>) : ""}
              </div>
            </div>         
      {/* <PurchaseOrder>{schoolData.locationCode}</PurchaseOrder> */}
    </section>
  )

}