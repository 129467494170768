import { useEffect, useState, useContext } from "react";
import studentAndStaffService from '../../services/StudentAndStaffService'
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Year } from '../../services/AppContext';
import IconCourseEnvelop from '../../assets/icons/icon-envelop-course';
interface StudentAndStaffCourses {
    courseName: string,
    courseCode: string,
    courseOrginType: string,
    courseStatus: string,
    isMerged: boolean
}

interface StudentAndStaffProps {
    id: string,
    locationCode: string
}

export default function StaffCoursesDetails(props: StudentAndStaffProps) {
    const { orginalSchoolYear } = useContext(Year);
    const [data, setRowData] = useState<StudentAndStaffCourses[]>([]);
    const getAllStudentAndStaffCourses = () => {
        studentAndStaffService.getStaffCoursesById(props.locationCode, +orginalSchoolYear.id, props.id).then(response => {
            setRowData(response.data)
        })
    }

    useEffect(() => {
        getAllStudentAndStaffCourses();
    }, [props]);

    return (
        <div>
            <div className="card mt-4">
                <div className="card-header font-weight-bold bg-secondary-light">
                    <div className="row align-items-center">
                        <div className="col-10">
                            {"Enrolled in " + data.length + " Courses"}
                        </div>

                    </div>
                </div>
                <div className="card-body">
                    <Grid
                        data={data}
                        className="k-grid-no-header"
                    >
                        <GridColumn filterable={false} width="80px" field="expanded" title=' ' cell={props => (
                            <td className="pr-0">
                                <div className={`svg-app-icon ${props.dataItem.courseStatus.toLowerCase() === "published" ? " text-success" : " text-blue-gray"} mr-3"`} style={{ height: "40px" }}>
                                    <IconCourseEnvelop courseType= {props.dataItem.courseType}  courseOrginType={props.dataItem.courseOrginType} merge={props.dataItem.isMerged}></IconCourseEnvelop>
                                </div>
                            </td>

                        )} />
                        <GridColumn field="" title=""
                            cell={
                                props => (
                                    <td>
                                        <div>{props.dataItem.courseName}</div>
                                        {props.dataItem.courseCode && <div>{props.dataItem.courseCode}</div>}
                                    </td>
                                )} />
                    </Grid>
                </div>
            </div>
        </div>
    )
}