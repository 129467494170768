import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormMode,IDropDownValue } from '../../services/Utility'
import ILicenseAllocation from '../../model/ILicenseAllocation';
import IVendorLicenseContent from '../../model/IVendorLicenseContent';

interface IVendorFormContent {
  id?: number
  location:string
  vendorlist:IDropDownValue[]
  formmode:FormMode
  datasource: IVendorLicenseContent
  isEditable?: boolean | false
  onVendorEditClose:(data:FormMode)=>void,
  onSave: (event: React.MouseEvent<HTMLElement,MouseEvent>,actionType: FormMode,data:ILicenseAllocation) => void
}
export default function VendorEditContent(props:IVendorFormContent) {
  const mountedRef = useRef(true);
  const [vendorList, setVendorList] = useState<IDropDownValue>({
    id: props.datasource.vendorId.toString(),
    text:props.datasource.vendorName
  });
  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
  const [formValue, setFormValue] = useState<ILicenseAllocation>({
    id:0,
    locationCode:'',
    fromLocationCode: '',
    toLocationCode:'',
    vendorId: 0,
    vendorName: '',
    quantityAllocated: 0,
    quantityAssigned: 0,
    quantityCap: 0
  });

  useEffect(() => {
    setFormMode(props.formmode)
      setFormValue({ ...formValue,
        id:props.datasource.id,
        locationCode:props.location,
        vendorId: props.datasource.vendorId,
        vendorName: props.datasource.vendorName,
        quantityAllocated: props.datasource.totalLicenses,
        quantityAssigned: 0,
        quantityCap: props.datasource.totalLicenses
       });
    return () => { mountedRef.current = false }
  }, []);

  const handleChange = (event: any) => {
    const target = event.target //as HTMLSelectElement

    setFormValue({ ...formValue, [target.name]: [target.value] });
  };
  const vendorChange = (event: DropDownListChangeEvent) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, vendorName: target.value.text, vendorId: target.value.id });

  };

  const [errors, setErrors] = useState({
    vendorNameError: '',
    licenseQuantityError: '',
  })
  const validate = () => {
    let error = {
      vendorNameError: '',
      licenseQuantityError: '',
    };

    if (formMode == FormMode.New||formMode == FormMode.Edit) {
      if (!formValue.vendorName) { error.vendorNameError = "Vendor Name is required"; }

      if (!formValue.quantityAllocated||formValue.quantityAllocated==null) { error.licenseQuantityError = "Total Licenses is required"; }

      let assignedLicense = props.datasource.totalLicenses - props.datasource.availableLicenses

      if (formValue.quantityAllocated - assignedLicense < 0) { error.licenseQuantityError = `Total License can't be less than ${assignedLicense}`; }
      setErrors(error);
      if (error.vendorNameError || error.licenseQuantityError) {
        return false;
      }
    }
    return true;
  };

  const onSave = (event: React.MouseEvent<HTMLElement>, actionType: FormMode) => {
    
    let formData: ILicenseAllocation = {
      id:Number(formValue.id),
      locationCode:formValue.locationCode,
      fromLocationCode: '',
      toLocationCode:'',
      vendorId: Number(formValue.vendorId),
      vendorName: formValue.vendorName,
      quantityAllocated: Number(formValue.quantityAllocated),
      quantityAssigned: props.datasource.totalLicenses - props.datasource.availableLicenses,
      quantityCap: Number(formValue.quantityCap)
    };

    switch (actionType) {
      case FormMode.Edit:
        setFormMode(FormMode.Edit)
        // messageService.edit(message).then(response => {
        //   // Display Success message
        // })
        event.preventDefault();
        if (validate()) {
          props.onSave(event, FormMode.Edit,formData);
         }
        break;
      default: {
        props.onSave(event, FormMode.None,formData);
        break;
      }
    }
  }

  return (
    <div>
      <Modal backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Edit} onHide={() => props.onVendorEditClose(FormMode.None) }>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Edit Licenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="vendorName">
            <Form.Label>Vendor</Form.Label>
            <DropDownList
              disabled={true}
              style={{ width: '100%' }}
              defaultValue={vendorList}              
              textField="text"
              onChange={vendorChange}
            />
            <Form.Text className="text-danger">{errors.vendorNameError}</Form.Text>
          </Form.Group>
          <Form.Group controlId="quantityAllocated">
              <Form.Label>Number of Licenses</Form.Label>
              <Form.Control type="number" name="quantityAllocated" value={formValue.quantityAllocated} onChange={handleChange} />
              <Form.Text className="text-danger">{errors.licenseQuantityError}</Form.Text>
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

