import React, { useCallback, useEffect, useState } from 'react';
import { Navbar, Nav, Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import AnnouncementManage from '../components/adminManagement/announcementManagement/AnnouncementManage'
import Dashboard from '../components/Dashboard'
import SchoolDropDown from '../components/SchoolDropDown';
import SchoolYearDropDown from '../components/SchoolYearDropDown';
import GlobalMenu from './GlobalMenu'
import { LocationContext, AuthContext, ErrorContext, IErrorContext, ErrorContextDefault, Year } from '../services/AppContext'
import SchoolBanner from '../components/SchoolBanner'
import VendorContent from '../components/vendorLicenseAllocation/VendorContent';
import Courses from '../components/courses/Courses';
import PurchaseOrder from '../components/PurchaseOrder';
import Report from '../components/Reports/Reports';
import ProfileMenu from './ProfileMenu';
import VendorManage from '../components/adminManagement/VendorManagement/VendorManage';
import SchoolManage from '../components/adminManagement/schoolManagement/SchoolManage';
import StudentAndStaff from '../components/studentAndStaff/studentAndStaff'
import ErrorDisplay from '../shared/ErrorDisplay';
import Header from './Header';
import { useParams, withRouter } from 'react-router-dom';
import IconSchool from '../assets/icons/icon-school'
import IconHome from '../assets/icons/icon-home'
import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    useLocation,
    useHistory
} from "react-router-dom";
import LocalStorageService from '../services/LocalStorage';
import { AuthContextDefault, UserProfileType } from '../services/AppContext';
import SchoolYearManage from '../components/adminManagement/schoolYearManage/SchoolYearManage';



export default function ILearnLayout(props?: any, isManageActivated?: boolean) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const [isManageRouteEnabled, setIsManageRouteEnabled] = useState(isManageActivated)
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    //    const errorContext1 = useContext<AuthContextType>(AuthContext);
    const [locationCode, setLocationCode] = useState('');
    const value = { locationCode, setLocationCode };

    //const [location,setLocation] = useState(window.location.pathname)


    const [currentPath, setCurrentPath] = useState('');
    interface SchoolYear {
        id: string,
        isCurrent: number,
        schoolYearEndDate: Date
    }

    let [orginalSchoolYear, setOrginalSchoolYear] = useState<SchoolYear>({ id: '', isCurrent: 0, schoolYearEndDate: new Date() });
    const v = { orginalSchoolYear, setOrginalSchoolYear };
    useEffect(() => {
        setIsManageRouteEnabled(window.location.pathname.startsWith("/ILearn/manage/"))
        setUserProfile(LocalStorageService.getUserProfile())
        if ((LocalStorageService.getUserProfile().role.roleName != "System Administrator" && LocalStorageService.getUserProfile().role.roleName != "Central Staff") && LocalStorageService.getUserProfile().role.locations.length > 0) {
            setLocationCode(LocalStorageService.getUserProfile().role?.locations[0].LocationCode)
        }
        if (window.location.pathname.slice(15) == "years") {
            setCurrentPath('School Year')
        } else {
            setCurrentPath(window.location.pathname.slice(15))
        }

    }, [isManageRouteEnabled,])

    const onBack = () => {
        setIsManageRouteEnabled(false)
    }





    const onMenuClick = (e: any, menuName: string) => {
        
        if (window.location.pathname.slice(15) == "years") {
            setCurrentPath('School Year')
        } else {
            setCurrentPath(window.location.pathname.slice(15))
        }

    }

    const onNavaigationProfile = () => {
        if (window.location.pathname.slice(15) == "years") {
            setCurrentPath('School Year')
        } else {
            setCurrentPath(window.location.pathname.slice(15))
        }
        setIsManageRouteEnabled(true)
    }

    const PublicHeader = useCallback(() => {
        return (
            <div className="pt-5 welcome-heading">
                <div className="container">
                    <div className="d-md-flex align-items-center">
                        <div className="text-center">
                            <div className="svg-app-icon text-secondary d-inline" style={{ width: "50px" }}>
                                <IconSchool />
                            </div>
                        </div>
                        {locationCode != "" && <SchoolBanner id={locationCode} />}
                    </div>
                    <div>
                        <GlobalMenu />
                    </div>
                </div>
            </div>
        )
    }, [isManageRouteEnabled, locationCode])

    const ManageHeader = () => {
        return (
            <div className="pt-5 welcome-heading bg-blue-gray pb-5">
                <Container className="container">
                    <Row>
                        <Col sm="8">
                            <div className="d-flex">
                                <Link to='/ILearn' onClick={onBack} className="text-secondary pt-2 pb-2 pl-4 pr-4 border-secondary border-right" ><i className="fas fa-arrow-circle-left fa-lg"></i></Link>
                                <h1 className="p-1 mb-0 ml-3 text-capitalize" >Manage {currentPath}</h1>
                            </div>
                        </Col>
                        <Col sm="4" className="text-center text-sm-right">
                            <DropdownButton
                                menuAlign="right"
                                title={currentPath}
                                id="manage_pages_menu"
                                variant="outline-dark"
                                className="text-capitalize"
                            >
                                <Dropdown.Item href="/ILearn/manage/announcements" onClick={(e) => onMenuClick(e, '/ILearn/manage/announcements')}>Announcements</Dropdown.Item>
                                <Dropdown.Item href='/ILearn/manage/vendors' onClick={(e) => onMenuClick(e, '/ILearn/manage/vendors')}> Vendors </Dropdown.Item>
                                <Dropdown.Item href="/ILearn/manage/schools" onClick={(e) => onMenuClick(e, '/ILearn/manage/schools')}> Schools </Dropdown.Item>
                                <Dropdown.Item href="/ILearn/manage/years" onClick={(e) => onMenuClick(e, '/ILearn/manage/years')}> School Year </Dropdown.Item>
                                <Dropdown.Item href="/ILearn/manage/reports" onClick={(e) => onMenuClick(e, '/ILearn/manage/reports')}> Reports </Dropdown.Item>
                            </DropdownButton>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    return (
        <BrowserRouter   >
            <LocationContext.Provider value={value}>
                <Year.Provider value={v}>
                    <Header />
                    <Navbar variant="dark" expand="lg" className="navbar-mobile-links">
                        <Container className="justify-content-end">
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav" className="position-relative navbar-mobile-visible">
                                <Nav className="mr-auto position-relative">
                                    <Nav.Link href="/Home" className="link-mobile-visible link-mobile-home">
                                        <div className="svg-app-icon text-white" style={{ height: "30px" }}>
                                            <IconHome />
                                        </div>
                                    </Nav.Link>
                                    <li className="nav-item kendo-dropdown circle-dropdown k-school-dropdown">
                                        <div className="nav-link main-and-sub">
                                            <label className="list-sub-title">Schools</label>
                                            <SchoolDropDown />
                                        </div>
                                    </li>
                                    <li className="nav-item kendo-dropdown circle-dropdown">
                                        <div className="nav-link main-and-sub">
                                            <label className="list-sub-title">Year</label>
                                            <SchoolYearDropDown />
                                        </div>
                                    </li>
                                </Nav>
                                <ProfileMenu onNavigation={onNavaigationProfile} userProfile={userProfile} />
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <React.Fragment>
                        <ErrorDisplay />
                        {
                            isManageRouteEnabled ? <ManageHeader /> : <PublicHeader />
                        }
                    </React.Fragment>
                    <div className="main-wrap">
                        <main className="container mt-5">
                            <Switch>
                                <Route path="/ILearn/Courses" render={(props) => <Courses {...props} id={locationCode} />} />
                                <Route path="/ILearn/VendorContent" render={(props) => <VendorContent {...props} id={locationCode} />} />
                                <Route path="/ILearn/StudentStaffs" render={(props) => <StudentAndStaff {...props} id={locationCode} />} />
                                <Route path="/ILearn/PurchaseOrders" render={(props) => <PurchaseOrder {...props} id={locationCode} />} />
                                {/* <Route exact path="/StudentStaffs">
                                    <AnnouncementManage />
                                </Route> */}
                                <Route exact path="/ILearn/Reports"  >
                                    {(locationCode != "" && orginalSchoolYear.id != "") &&
                                        <Report id={locationCode} />}
                                </Route>
                                <Route exact path="/ILearn/manage/reports"  >
                                    {(locationCode != "" && orginalSchoolYear.id != "") &&
                                        <Report id="AllSchools" />}
                                </Route>
                                <Route exact path="/ILearn/manage/announcements" component={AnnouncementManage} />
                                <Route exact path="/ILearn/manage/vendors" component={VendorManage} />
                                <Route exact path="/ILearn/manage/schools" component={SchoolManage} />
                                <Route exact path="/ILearn/manage/years" component={SchoolYearManage} />
                                <Route exact path="/ILearn">
                                    {(locationCode != "" && orginalSchoolYear.id != "") &&
                                        <Dashboard id={locationCode} />}
                                </Route>
                            </Switch>
                        </main>
                    </div>
                </Year.Provider>
            </LocationContext.Provider>
        </BrowserRouter>
    )

}




