import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormMode } from '../../services/Utility'
import IMergeCourseSpec from "../../model/IMergeCourseSpec";

interface IMergeErrorMessage {

  mergeMessage:string,
  formmode: FormMode,
  onMergeClose:(data:FormMode)=>void,
  onMerge: (event: React.MouseEvent<HTMLElement, MouseEvent>, actionType: FormMode, data: IMergeCourseSpec[]) => void

}

export default function MergeError(props: IMergeErrorMessage) {
  const mountedRef = useRef(true);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);

  useEffect(() => {
    setFormMode(props.formmode)
    return () => { mountedRef.current = false }
  }, []);




  const onMerge = (event: React.MouseEvent<HTMLElement>, actionType: FormMode) => {


    switch (actionType) {
      case FormMode.Merge:
        setFormMode(FormMode.Merge)
        // messageService.edit(message).then(response => {
        //   // Display Success message
        // })
        event.preventDefault();

        break;
      default:
        props.onMerge(event, FormMode.None, []);
        break;

    }
  }

  const [errors, setErrors] = useState({
    mergeError: ''
  })
  return (
    <div>
      <Modal backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Error} onHide={() => props.onMergeClose(FormMode.None)}>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Merge Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <label>{props.mergeMessage}</label>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => onMerge(e, FormMode.None)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}