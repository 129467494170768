import React, { useState, useEffect, useContext } from 'react';
import IPublishedCoursesReport from '../../model/IPublishedCoursesReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';


interface ILocation {
    changeExcelRef: any;
    selectedReport: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};



const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

const initialSort: Array<SortDescriptor> = [
    { field: "totalCourses", dir: "desc" },
];

export default function PublishedCoursesReport(props: ILocation) {
    const { orginalSchoolYear } = useContext(Year);
    const [page, setPage] = React.useState(initialDataState);
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<IPublishedCoursesReport[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);

    const pageChange = (event: any) => {
        setPage(event.page);
    };


    const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
    }

    const GetPublishedCoursesReport = () => {
        ReportsService.GetPublishedCoursesReport(+orginalSchoolYear.id).then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetPublishedCoursesReport();
    }, [props.selectedReport, orginalSchoolYear.id]);


    return (
        <ExcelExport data={rowData} ref={_export} fileName={"Published_Courses_Report" + ' ' + new Date().toDateString()}>
            <Grid
                data={filterBy(orderBy(rowData, sort), filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50, 100], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    if (e.sort.length === 0) {
                        if (sort.length != 0 && sort[0].dir == "asc") {
                            setSort([{ field: "totalCourses", dir: "desc" }])
                        } else {
                            setSort([{ field: "totalCourses", dir: "asc" }])
                        }
                    } else {
                        setSort(e.sort);
                    }
                }}
                filterable={true}
                filter={filter}
                onFilterChange={onFilterChange}
                scrollable={"none"}>
                <GridColumn field="locationCode" filterable={true} title="Location Code"></GridColumn>
                <GridColumn field="totalCourses" filterable={false} title="Total"></GridColumn>
                <GridColumn field="publishedCourses" filterable={false} title="Published"></GridColumn>
                <GridColumn field="starsPublishedCourses" filterable={false} title="Stars Published"></GridColumn>
                <GridColumn field="manualPublishedCourses" filterable={false} title="Manual Published"></GridColumn>
                <GridColumn field="sharedInstructionPublishedCourses" filterable={false} title="Shared Instruction Published"></GridColumn>

            </Grid>
        </ExcelExport>
    )
}