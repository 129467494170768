import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Grid, GridColumn, GridFilterChangeEvent, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import IAssignedAdditionalStaff from '../../model/IAssignedAdditionalStaff';
import AdditionalStaffService from '../../services/AdditionalStaffService';
import { FormMode, IDropDownValue } from '../../services/Utility'
import schoolService from '../../services/SchoolService';
import IAddCourseAdditionalStaff from '../../model/IAddCourseAdditionalStaff';
import IconPersonCircle from '../../assets/icons/icon-person-circle';
import LocalStorageService from '../../services/LocalStorage';
import { AuthContextDefault, UserProfileType, Year } from '../../services/AppContext';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import TeacherService from '../../services/TeacherService';


interface IAssignedAdditionalStaffProps {
    courseId: number
    courseStatus: string
    courseSource: string
    isEditable?: boolean | false
    locationCode: string
    updateCourseStatus: (courseId : number) => void
    courseUniqueId : string

}
const initialDataState = {
    skip: 0,
    take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

export default function AssingedAdditionalStaff(props: IAssignedAdditionalStaffProps) {
    const mountedRef = useRef(true);
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [courseAdditionalStaff, setCourseAdditionalStaff] = useState<IAssignedAdditionalStaff[]>([]);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    const [data, setRowData] = useState<IAssignedAdditionalStaff[]>([]);
    const [dataCopy, setRowCopyData] = useState<IAssignedAdditionalStaff[]>([]);
    const [deleteAdditionalStaffId, setDeleteTecherId] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [orginalSchoolList, setOrginalSchoolList] = useState<IDropDownValue[]>([]);
    const { orginalSchoolYear } = useContext(Year);
    const [schoolList, setSchoolList] = useState<IDropDownValue[]>([]);
    const [filter, setFilter] = useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);


    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const getAdditionalStaffBySchoolDBN = (locationCode: string) => {
      AdditionalStaffService.GetUnassignedStaffBySchoolDBN(locationCode , props.courseUniqueId).then(response => {
            setRowData(response.data)
            setRowCopyData(response.data)
        })
    }

    const getAssignedAdditionalStaff = (CourseId: number) => {
        AdditionalStaffService.getByCourseId(CourseId).then(response => {
            const additionalStaffData = response.data.map((x:IAssignedAdditionalStaff) => ({
                ...x,
                courseSource: props.courseSource,
                courseStatus: props.courseStatus
              })) as IAssignedAdditionalStaff[];
            setCourseAdditionalStaff(additionalStaffData)
        })
        return () => { mountedRef.current = false }
    }
    const getSchoolLocations = () => {
        schoolService.getData().then(response => {
            let dropDownData = response.data.map((sl: any) => ({
                id: sl.locationCode,
                text: sl.locationCode + ' -- ' + sl.schoolName
            }));
            setSchoolList(dropDownData)
            setOrginalSchoolList(dropDownData)
        });
    }
    const filterData = (filter: any) => {
        let data = orginalSchoolList.slice();
        return filterBy(data, filter);
    }

    const filterChange = (event: any) => {
        let data = filterData(event.filter)
        setSchoolList(data)
    }

    const handleChange = (event: any) => {
        let selectedSchool = event.target.value;
        getAdditionalStaffBySchoolDBN(selectedSchool.id);
    }


    const getDefaultValue = (schoolList: any) => {
        let school = schoolList.find((s: any) => s.id == props.locationCode)
        if (school) {
            return school
        }
    }

    useEffect(() => {
        getAssignedAdditionalStaff(props.courseId);
        setUserProfile(LocalStorageService.getUserProfile())
        return () => { mountedRef.current = false }
    }, [props.locationCode]);



    const [additionalStaffVisible, setAdditionalStaffVisible] = useState<boolean>(false);
    const toggleAssignedTeachersDialog = () => {
        setAdditionalStaffVisible(!additionalStaffVisible)
        let data = orginalSchoolList.slice();
        setSchoolList(data)
        setHeaderCheckBox(false);
    }

    const addAdditionalStaff = () => {
        setErrorMsg('');
        getSchoolLocations();
        getAdditionalStaffBySchoolDBN(props.locationCode);
        setFormMode(FormMode.New)
        toggleAssignedTeachersDialog()
    }

    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        forceUpdate();
    }

    const [headerCheckBoxValue, setHeaderCheckBox] = useState(false);
    const onHeaderCheckBoxClick = (event: any) => {
        let checked = event.target.checked;
        if (checked == true) {
            data.map((t: any) => t.isSelected = true)
        } else {
            data.map((t: any) => t.isSelected = false)
        }
        setHeaderCheckBox(checked)
    }
    const removeAdditionalStaff = (additionalStaffId: string) => {
        setDeleteTecherId(additionalStaffId)
        setFormMode(FormMode.Delete)
    }

    const [searchTeacherEin, setSearchTeacherEin] = useState("");
    const searchTeacherChange = (event: any) => {
        const target = event.target
        if (!target.value || target.value == "") {
            setErrorMsg('')
            setRowData(dataCopy)
        }
        setSearchTeacherEin(target.value)
    };
    const getTeacherById = () => {
        setErrorMsg('')
        if (searchTeacherEin == "") {
            setErrorMsg("Please enter teacher EIN")
            return
        }
        setRowData([])
        TeacherService.GetUnassignedTeacherById(searchTeacherEin  , props.courseUniqueId).then(response => {
            if (response.data.isValidResponse) {
                let teacher = response.data.result
                teacher.additionalStaffId = response.data.result.teacherId
                let teachers: IAssignedAdditionalStaff[] = []
                teachers.push(teacher)
                setRowData(teachers)
                setPage(initialDataState)
            } 
        }).catch((ex)=>{
           setErrorMsg(ex.response.data.errors.join())
        })
    }

    const onSave = (event: any, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.New:

                let addCourseAdditionalStaff: IAddCourseAdditionalStaff = {
                    courseId: props.courseId,
                    schoolYear :+orginalSchoolYear.id,
                    additionalStaff: []
                }
                addCourseAdditionalStaff.additionalStaff = data.filter(x => x.isSelected);
                if (addCourseAdditionalStaff.additionalStaff.length == 0) {
                    setErrorMsg('Please select atleast one additional Staff to add.');
                    return;
                }
                let additionalStaffIds = courseAdditionalStaff.map(a => a.additionalStaffId);
                let addedAdditionalStaff = addCourseAdditionalStaff.additionalStaff.filter((x) => !additionalStaffIds.includes(x.additionalStaffId));;
                addCourseAdditionalStaff.additionalStaff = addedAdditionalStaff;

                if (addCourseAdditionalStaff.additionalStaff.length == 0) {
                    setErrorMsg('Selected Additional Staff are already added.');
                    return;
                }
                var currentAdditionalStaffList = courseAdditionalStaff;
                currentAdditionalStaffList = currentAdditionalStaffList.concat(addedAdditionalStaff);

                AdditionalStaffService.post(addCourseAdditionalStaff).then(response => {
                    setCourseAdditionalStaff(currentAdditionalStaffList);
                    setAdditionalStaffVisible(!additionalStaffVisible)
                    props.updateCourseStatus(props.courseId)
                    setFormMode(FormMode.None)
                    setHeaderCheckBox(false);
                });
                break;
            case FormMode.Delete:
                AdditionalStaffService.delete(props.courseId, deleteAdditionalStaffId).then(response => {
                    let courseAdditionalStaffList = courseAdditionalStaff.filter(x => x.additionalStaffId != deleteAdditionalStaffId);
                    setCourseAdditionalStaff(courseAdditionalStaffList);
                    props.updateCourseStatus(props.courseId)
                    setFormMode(FormMode.None)
                });
                break;
            default: {
                setFormMode(FormMode.None)
                break;

            }
        }
    }


    return (
        <div >
            {additionalStaffVisible && <Dialog title={"Add AdditionalStaff"} onClose={toggleAssignedTeachersDialog} style={{ width: "75%", position: "fixed", left: "15%", top: "20%", height: "350px" }} >
                <div id="dropDownParent"  >
                    <div hidden={errorMsg.length == 0}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMsg}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" >
                        <div className="text-sm-left text-left">

                            <InputGroup >
                                <FormControl
                                    placeholder="Teacher EIN"
                                    onChange={searchTeacherChange}
                                />
                                <Button variant="outline-secondary" id="button-addon1" onClick={getTeacherById} >
                                    Search
                                </Button>
                            </InputGroup>
                        </div>
                        <div className="text-sm-right text-left">
                            <DropDownList
                                data={schoolList}
                                defaultValue={getDefaultValue(schoolList)}
                                textField="text"
                                filterable={true}
                                onChange={handleChange}
                                onFilterChange={filterChange}
                                popupSettings={{ appendTo: document.getElementById("dropDownParent") as HTMLElement }}
                                style={{ maxWidth: '350px', width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <Grid
                            data={filterBy(data, filter).slice(page.skip, page.take + page.skip)}
                            filter={filter}
                            filterable={true}
                            skip={page.skip}
                            take={page.take}
                            total={data.length}
                            pageable={true}
                            onPageChange={pageChange}
                            scrollable={"none"}
                            style={{ height: '400px' }}
                            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}>
                            <GridColumn field="isSelected" width="40px" filterable={false} headerCell={props => (<input type="checkbox" checked={headerCheckBoxValue} onChange={(e) => onHeaderCheckBoxClick(e)} />)} cell={props => (
                                <td>
                                    <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                                </td>
                            )} />

                            <GridColumn field="UserIcon" title=" " filterable={false} width="80px" cell={
                                props => (
                                    <td>
                                        <div className="d-flex">
                                            <div className="svg-app-icon text-secondary mr-3" style={{ height: "30px" }}>
                                                <IconPersonCircle />
                                            </div>
                                        </div>
                                    </td>
                                )} />
                            <GridColumn field="fullName" title="Name" cell={
                                props => (
                                    <td>
                                        <div>{props.dataItem.fullName}</div>
                                    </td>
                                )} />
                            <GridColumn field="additionalStaffId" title="EIN" />
                            <GridColumn field="userName" title="Username" />
                            <GridColumn field="schoolDBN" title="Associated Location" filterable={false} />
                        </Grid>
                    </div>
                </div>
                <DialogActionsBar>
                    <Button className="m-3" variant="secondary" onClick={toggleAssignedTeachersDialog}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Add Additional Staff</Button>
                </DialogActionsBar>
            </Dialog>}
            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>

                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Remove Additional Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove additional staff ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <div className="card mt-4">
                <div className="card-header font-weight-bold bg-secondary-light">
                    <div className="row align-items-center">
                        <div className="col-8 font-weight-bold">Additional Staff</div>
                        {props.courseStatus.toLowerCase() == 'closed' ? '' :
                            <div className="col-4 text-right">
                                {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 &&
                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => { addAdditionalStaff() }}>
                                        <i className="fas fa-plus"></i>
                                    </button>}
                            </div>}
                    </div>
                </div>
                <div className="card-body">
                    <Grid
                        data={courseAdditionalStaff}
                        scrollable={"none"}
                        className="k-grid-no-header"
                    >
                        {/* <GridColumn field="expanded" width="80px" title=' ' /> */}
                        <GridNoRecords>
                            No Additional Staff
                        </GridNoRecords>
                        <GridColumn field="UserIcon" title=" " width="80" cell={
                            props => (
                                <td>
                                    <div className="d-flex">
                                        <div className="svg-app-icon text-secondary mr-3" style={{ height: "40px" }}>
                                            <IconPersonCircle />
                                        </div>
                                    </div>
                                </td>
                            )} />
                        <GridColumn field="Name" title="" cell={
                            props => (
                                <td>
                                    <div> {props.dataItem.lastName} {props.dataItem.firstName}</div>
                                    <div className="small">EIN:{props.dataItem.additionalStaffId}</div>
                                </td>
                            )} />

                        <GridColumn field="" title="Username" cell={
                            props => (
                                <td>
                                    <div className="small">Username:</div>
                                    <div>{props.dataItem.userName}</div>
                                </td>
                            )} />
                        <GridColumn field="schoolDBN" title="Associated Location" cell={
                            props => (
                                <td>
                                    <div className="small">Associated Location:</div>
                                    <div>{props.dataItem.schoolDBN}</div>
                                </td>
                            )} />
                        <GridColumn field="" title=" " cell={
                            props => (
                                <td className="text-right menu-btn">
                                    {(userProfile?.role?.roleName == 'Central Staff'
                                        || props.dataItem?.courseStatus?.toLowerCase() === 'closed') || orginalSchoolYear.isCurrent == 0
                                        ? '' :
                                        <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { removeAdditionalStaff(props.dataItem.additionalStaffId) }}>
                                            <i className="fas fa-minus"></i>
                                        </button>
                                    }
                                </td>
                            )} />
                    </Grid>
                </div>
            </div>
        </div>
    )
}