import { AxiosInstances as AxiosInstance} from './BaseService'

export default {
    getBySchoolDBN: (locationCode: string , schoolYear : number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/PurchaseOrders/${schoolYear}`
        }),
        getByProductId: (id:number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/PurchaseOrder/Product/${id}`
        }),
        post: (requestdata: any) =>
        AxiosInstance({
            'method': 'POST',
            'url': `PurchaseOrder/create`,
            'data': requestdata
        })
}