import { AxiosInstances as AxiosInstance } from './BaseService'
export default {
    getStudentByLocationCode: (locationCode: string, schoolYear: number, pageNumber: number, pageSize: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Student/${locationCode}/${schoolYear}/${pageNumber}/${pageSize}`
        }),


    getStaffByLocationCode: (locationCode: string, schoolYear: number, pageNumber: number, pageSize: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Staff/${locationCode}/${schoolYear}/${pageNumber}/${pageSize}`
        }),

    SearchStudentByLocationCode: (locationCode: string, schoolYear: number, pageNumber: number, pageSize: number, search: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Student/${locationCode}/${schoolYear}/${pageNumber}/${pageSize}/${search}`
        }),

    SearchStaffByLocationCode: (locationCode: string, schoolYear: number, pageNumber: number, pageSize: number, search: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Staff/${locationCode}/${schoolYear}/${pageNumber}/${pageSize}/${search}`
        }),

    getStudentCoursesById: (locationCode: string, schoolYear: number, id: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/StudentCourses/${locationCode}/${schoolYear}/${id}`
        }),

    getStaffCoursesById: (locationCode: string, schoolYear: number, id: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/StaffCourses/${locationCode}/${schoolYear}/${id}`
        }),
}