import React, { useState, useEffect, useRef, useContext } from 'react';
import purchaseOrderService from '../services/PurchaseOrderService';
import IPurchaseOrder from '../model/IPurchaseOrder'
import IPurchaseOrderProduct from '../model/IPurchaseOrderProduct'
import { Modal, Button, Row, Form, Col } from 'react-bootstrap';
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import schoolListService from '../services/SchoolListService';
import vendorService from '../services/VendorService'
import productService from '../services/ProductService'
import licenseAllocationService from '../services/LicenseAllocation'
import { filterBy } from '@progress/kendo-data-query';
import { FormMode, IDropDownValue } from '../services/Utility'
import Moment from 'moment';
import { AuthContextDefault, UserProfileType, Year } from '../services/AppContext';
import LocalStorageService from '../services/LocalStorage'

interface ILocation {
  id: string
}
const initialDataState = {
  skip: 0,
  take: 25,
};

export default function PurchaseOrder(location: ILocation) {

  const mountedRef = useRef(true);
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event: any) => {
    setPage(event.page);
  };
  const { orginalSchoolYear } = useContext(Year);
  const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
  const [orginalVendorList, setOrginalVendorList] = useState<IDropDownValue[]>([]);
  const [vendorList, setVendorList] = useState<IDropDownValue[]>([]);
  const [vendorListCopy, setVendorListCopy] = useState<IDropDownValue[]>([]);
  const [productList, setProductList] = useState<IDropDownValue[]>([]);
  const [product, setProduct] = useState<IPurchaseOrderProduct>({
    locationCode: '',
    productSKU: '',
    price: 0,
    note: '',
    priceType: '',
    productName: '',
    vendorName: '',
    id: 0,
    vendorId: 0
  });
  const [formCascade, setFormCascade] = useState({
    vendors: vendorList,
    products: productList,
    vendor: null,
    product: null
  })

  useEffect(() => {
    vendorService.getData().then(response => {
      let vendorDropDownData = response.data.map((vl: any) => ({
        id: vl.id,
        text: vl.vendorName
      }));
      setVendorList(vendorDropDownData)
      setVendorListCopy(vendorDropDownData)
    })
    setUserProfile(LocalStorageService.getUserProfile())
    return () => { mountedRef.current = false }

  }, []);

  const vendorChange = (event: any) => {
    setFormCascade({
      vendors: [],
      products: [],
      vendor: null,
      product: null
    });
    const vendor = event.target.value;

    const target = event.target //as HTMLSelectElement

    setFormValue({ ...formValue, [target.name]: target.value.text });
    const vendorId = Number(vendor.id)

    productService.getByVendorId(vendorId).then(response => {
      let productDropDownData = response.data.map((pl: any) => ({
        id: pl.id,
        text: pl.productName
      }));
      setProductList(productDropDownData)
    })

    setProduct({
      locationCode: '',
      productSKU: '',
      price: 0,
      note: '',
      priceType: '',
      productName: '',
      vendorName: '',
      id: 0,
      vendorId: 0
    });
    setFormCascade({
      vendors: vendorList,
      products: productList,
      vendor: vendor,
      product: null
    });
  }

  const productChange = (event: any) => {
    setProduct({
      locationCode: '',
      productSKU: '',
      price: 0,
      note: '',
      priceType: '',
      productName: '',
      vendorName: '',
      id: 0,
      vendorId: 0
    });
    const product = event.target.value;
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, [target.name]: target.value.text });

    const productId = Number(product.id)
    purchaseOrderService.getByProductId(productId).then(response => {
      setProduct(response.data)
    })

    setFormCascade({
      vendors: vendorList,
      products: productList,
      vendor: formCascade.vendor,
      product: product
    })
  }


  const [rowData, setRowData] = useState<IPurchaseOrder[]>([]);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
  const [formValue, setFormValue] = useState({
    locationCode: '',
    productSKU: '',
    quantityPurchased: 0,
    unitPrice: 0,
    purchaseOrderNumber: '',
    productName: '',
    vendorId: 0,
    vendorName: ''
  });

  const [errors, setErrors] = useState({
    vendorNameError: '',
    productNameError: '',
    quantityPurchasedError: '',
    purchaseOrderNumberError: '',
    serverSideError: ''
  })
  const validate = () => {
    let error = {
      vendorNameError: '',
      productNameError: '',
      quantityPurchasedError: '',
      purchaseOrderNumberError: '',
      serverSideError: ''
    };

    if (formMode == FormMode.New) {
      if (!formCascade.vendor) { error.vendorNameError = "Vendor Name is required"; }

      if (!formCascade.product) { error.productNameError = "Product is required"; }

      if (!formValue.quantityPurchased || formValue.quantityPurchased <= 0) { error.quantityPurchasedError = "Enter Quantity Purchased"; }
      if (!formValue.purchaseOrderNumber) { error.purchaseOrderNumberError = "Purchase Order is required"; }
      setErrors(error);
      if (error.vendorNameError || error.productNameError || error.quantityPurchasedError || error.purchaseOrderNumberError) {
        return false;
      }
    }
    return true;

  };

  const handleChange = (event: any) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, [target.name]: target.value });
  };


  const onFormAction = (purchaseOrderId: number, actionType: FormMode) => {
    setErrors({
      vendorNameError: '',
      productNameError: '',
      quantityPurchasedError: '',
      purchaseOrderNumberError: '',
      serverSideError: ''
    });
    setFormValue({
      locationCode: '',
      productSKU: '',
      quantityPurchased: 0,
      unitPrice: 0,
      purchaseOrderNumber: '',
      productName: '',
      vendorId: 0,
      vendorName: ''
    });
    setFormCascade({
      vendors: vendorList,
      products: productList,
      vendor: null,
      product: null
    });

    setProduct({
      locationCode: '',
      productSKU: '',
      price: 0,
      note: '',
      priceType: '',
      productName: '',
      vendorName: '',
      id: 0,
      vendorId: 0
    });
    switch (actionType) {

      case FormMode.New:
        setFormMode(FormMode.New)

        vendorService.getData().then(response => {
          let vendorDropDownData = response.data.map((vl: any) => ({
            id: vl.id,
            text: vl.vendorName
          }));
          setVendorList(vendorDropDownData)
          setVendorListCopy(vendorDropDownData)
          return () => { mountedRef.current = false }
        })

        //Clear Existing vlues
        break;
      default: {
        setFormMode(FormMode.None)
        break;
      }
    }
  }

  const onSave = (event: any, actionType: FormMode) => {
    let purchaseOrder = {
      productSKU: product.productSKU,
      vendorId: product.vendorId,
      vendorName: formValue.vendorName,
      locationCode: location.id,
      productName: formValue.productName,
      quantityPurchased: Number(formValue.quantityPurchased),
      unitPrice: Number(product.price),
      purchaseOrderNumber: formValue.purchaseOrderNumber,
      schoolYear: +orginalSchoolYear.id
    };
    switch (actionType) {
      case FormMode.New:
        event.preventDefault();

        if (validate()) {

          purchaseOrderService.post(purchaseOrder).then(response => {
            if (response.data.isValidResponse) {
              getPurchaseOrder()
              // Display Success purchaseOrder
              setFormMode(FormMode.None)
            } else {
              let error = {
                vendorNameError: '',
                productNameError: '',
                quantityPurchasedError: '',
                purchaseOrderNumberError: '',
                serverSideError: response.data.errors[0]
              };
              setErrors(error)
            }


          })


        }
        //Clear Existing vlues
        break;
      default: {
        setFormMode(FormMode.None)
        break;
      }
    }
  }

  const filterData = (filter: any) => {
    let data = vendorListCopy.slice();
    return filterBy(data, filter);
  }

  const filterChange = (event: any) => {
    let data = filterData(event.filter)
    setVendorList(data)
  }

  useEffect(() => {
    purchaseOrderService.getBySchoolDBN(location.id, +orginalSchoolYear.id).then(response => {
      setRowData(response.data)
    })
  }, [location.id, orginalSchoolYear.id]);

  const getPurchaseOrder = () => {
    purchaseOrderService.getBySchoolDBN(location.id, +orginalSchoolYear.id).then(response => {
      setRowData(response.data)
    })
  }

  const format_purchaseOrder = (props: any) => {
    return (
      <td>
        <div className="d-flex align-items-center">
          <i className="fas fa-shopping-cart mr-2 text-blue-gray" style={{ "fontSize": "30px" }}></i>
          <div>
            <div className="text-dark mb-0 h4 p-0 font-weight-bold">{props.dataItem.orderID}</div>
          </div>
        </div>
      </td>
    )
  }

  const format_Price = (props: any) => {
    return (
      <td>
        ${props.dataItem.unitPrice.toLocaleString()}
      </td>
    )
  }

  const format_Date = (props: any) => {
    { Moment.locale('en') }
    return (
      <td>
        {Moment(props.dataItem.purchaseDate).format('MMM D, YYYY')}
      </td>
    )
  }

  return (
    <div>
      {formMode != FormMode.None && <Dialog title={"Add Purchase Order"} onClose={() => { setFormMode(FormMode.None) }} style={{ width: "75%", position: "fixed", left: "15%", top: "20%", height: "350px" }} >
        <div id="dropDownParent">
        {errors.serverSideError && <div className="alert alert-danger">{errors.serverSideError}</div>}
          <Form>
            
            <Form.Group controlId="vendorName">
              <Form.Label>Vendor</Form.Label>
              <DropDownList
                style={{ width: '100%' }}
                defaultValue={null}
                data={vendorList}
                name='vendorName'
                textField="text"
                value={formCascade.vendor ?? ''}
                onChange={vendorChange}
                filterable={true}
                onFilterChange={filterChange}
                popupSettings={{ appendTo: document.getElementById("dropDownParent") as HTMLElement }}
              />
              <Form.Text className="text-danger">{errors.vendorNameError}</Form.Text>
            </Form.Group>
            <Form.Group controlId="productName">
              <Form.Label>Product</Form.Label>
              <DropDownList
                style={{ width: '100%' }}
                defaultValue={null}
                data={productList}
                name='productName'
                textField="text"
                value={formCascade.product ?? ''}
                onChange={productChange}
              />
              <Form.Text className="text-danger">{errors.productNameError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="purchaseOrderNumber">
              <Form.Label>PO #</Form.Label>
              <Form.Control type="text" name="purchaseOrderNumber" value={formValue.purchaseOrderNumber?.toString() ?? ''} onChange={handleChange} />
              <Form.Text className="text-danger">{errors.purchaseOrderNumberError}</Form.Text>
            </Form.Group>
            <Row>
              <Col sm="6">
                <Form.Group controlId="quantityPurchased">
                  <Form.Label>Quantity Purchased</Form.Label>
                  <Form.Control type="number" name="quantityPurchased" value={formValue.quantityPurchased ?? 0} onChange={handleChange} />
                  <Form.Text className="text-danger">{errors.quantityPurchasedError}</Form.Text>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group controlId="unitPrice">
                  <Form.Label>Unit Price</Form.Label>
                  <Form.Text>${product?.price}  {product?.priceType}</Form.Text>
                </Form.Group>
              </Col>
            </Row>

          </Form>

        </div>
        <DialogActionsBar>
          <Button className="m-3" variant="secondary" onClick={() => { setFormMode(FormMode.None) }}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </DialogActionsBar>
      </Dialog>}
      {
        (userProfile?.role?.roleName == 'System Administrator' && orginalSchoolYear.isCurrent != 0) ?
          <div className="text-right mb-2">
            <button type="button" className="btn btn-primary" onClick={(e) => { onFormAction(0, FormMode.New) }}>
              <i className="fas fa-plus"></i>
            </button>
          </div> : <div></div>
      }
      <Grid
        data={rowData.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={rowData.length}
        pageable={true}
        scrollable={"none"}
        onPageChange={pageChange}>
        <GridColumn field="orderID" title="Purchase Order #" cell={format_purchaseOrder}></GridColumn>
        <GridColumn field="recordSource" title="Type"></GridColumn>
        <GridColumn field="vendorName" title="Vendor"></GridColumn>
        <GridColumn field="quantityPurchased" title="Quantity"></GridColumn>
        <GridColumn field="unitPrice" title="Price" cell={format_Price}></GridColumn>
        <GridColumn field="purchaseDate" title="Date" cell={format_Date}></GridColumn>
      </Grid>

    </div>
  )
}