import { useContext, useEffect, useRef, useState } from "react";
import { Button, FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Year } from '../../services/AppContext';
import Student from './Student'
import Staff from './Staff'

interface ILocationCode {
    id: string,
}

const dropDownData = ['Student', 'Staff']

export default function StudentAndStaff(locationCode: ILocationCode) {

    const { orginalSchoolYear } = useContext(Year);
    const [selectedValue, setSelectedValue] = useState("Student");
    const [searchNameOrId, setSearchNameOrIdValue] = useState("");
    const [searchForData, setSearchForData] = useState(false);
    const mountedRef = useRef(true)


    const onSelectionChange = (e: any) => {
        setSelectedValue(e.target.value)
        setSearchNameOrIdValue("")
    }

    const onChangeSearch = (e: any) => {
        if (e.target.value) {
            setSearchNameOrIdValue(e.target.value)
        }
        else {
            clearSearch()
        }
    }

    const clearSearch = () => {
        setSearchNameOrIdValue("")
        searchData()
    }

    const searchData = () => {
        setSearchForData(!searchForData)
    }

    const searchByClickEnter = (e: any) => {
        if (e.charCode == 13) {
            searchData()
        }
    }

    useEffect(() => {
        setSearchNameOrIdValue("")
        return () => { mountedRef.current = false }
    }, [locationCode, orginalSchoolYear]);

    return (
        <div>
            <Row className="mb-3">
                <Col sm="6">
                    <InputGroup className="input-group">
                        <FormControl
                            placeholder="LastName FirstName or OSIS ID or EIN"
                            className="form form-control form-control-md text-ellipse"
                            maxLength={128}
                            onChange={onChangeSearch}
                            value={searchNameOrId}
                            onKeyPress={searchByClickEnter}
                            width={500}
                        />
                        {searchNameOrId && searchNameOrId != "" &&
                            <button type="button" className="close mr-3" onClick={clearSearch} aria-label="Close">
                                <span aria-hidden="false">&times;</span>
                            </button>
                        }
                        <div className="input-group-append">
                            <Button className="btn btn-primary btn-md " onClick={searchData} >
                                Search
                            </Button>
                        </div>
                    </InputGroup>
                </Col>
                <Col sm="6" className="text-right">
                    <DropDownList
                        data={dropDownData}
                        defaultValue={dropDownData[0]}
                        onChange={onSelectionChange}
                        style={{ maxWidth: '350px', width: "100%" }}
                    />

                </Col>
            </Row>

            {selectedValue == "Student" && <Student locationCode={locationCode.id} schoolYear={+orginalSchoolYear.id} searchNameOrId={searchNameOrId} searchForData={searchForData} />}

            {selectedValue == "Staff" && <Staff locationCode={locationCode.id} schoolYear={+orginalSchoolYear.id} searchNameOrId={searchNameOrId} searchForData={searchForData} />}
        </div >
    )
}