import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Dropdown, } from 'react-bootstrap';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { FormMode, IDropDownValue } from '../../services/Utility'
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import ICourses from '../../model/ICourses';
import IMergeCourses from '../../model/IMergeCourses';
import {IAssignedTeachers} from '../../model/IAssignedTeachers';
import IMergeCourseSpec from "../../model/IMergeCourseSpec";

interface ICourseMergeContent {
  mergeData: IMergeCourseSpec[]
  courses: ICourses[],
  formmode: FormMode,
  courseCount: Number,
  onMergeClose: (data: FormMode) => void,
  onMerge: (event: React.MouseEvent<HTMLElement, MouseEvent>, actionType: FormMode, data: IMergeCourseSpec[]) => void

}

export default function MergeCourses(props: ICourseMergeContent) {
  const mountedRef = useRef(true);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
  const [mergeData, setMergeData] = useState<ICourses[]>([]);
  const [mergeCourseData, setMergeCourseData] = useState<IMergeCourseSpec[]>([]);
  const [selectedValue, setSelectedValue] = useState(props.courses[0].courseId);

  const handleRadioChange = React.useCallback(
    (e: any) => {
      setSelectedValue(e.value)
    },
    [setSelectedValue]
  );
  useEffect(() => {
    setFormMode(props.formmode)
    setMergeData(props.courses)
    setMergeCourseData(props.mergeData)
    return () => { mountedRef.current = false }
  }, []);


  const GridColumnCourses = (props: any) => {
    return (
      <td>
        <h5 className="mb-0">{props.dataItem.courseName}</h5>
        <div className="text-secondary">
          <div>Course Code: {props.dataItem.courseCode}</div>
          <div>Assigned Teachers: {props.dataItem.courseTeachers.map((item: IAssignedTeachers) => { return item.teacherInitial }).join(',')}</div>
          <div>Students: {props.dataItem.totalStudentEnrolled}</div>
        </div>
      </td>
    )
  }

  const GridColumnButton = (props: any) => {
    return (
      <td className="text-right" style={{ overflow: 'visible' }}>

        <RadioButton type="radio" label="Merge into this course" key={props.dataItem.courseId} value={props.dataItem.courseId} checked={selectedValue === props.dataItem.courseId} onChange={handleRadioChange}></RadioButton>
      </td>
    )
  }

  const onMerge = (event: React.MouseEvent<HTMLElement>, actionType: FormMode) => {
    mergeCourseData.forEach(element => {
      if (element.courseId != selectedValue) {
        element.isPrimary = false
      }
      else {
        element.isPrimary = true
      }
    });

    switch (actionType) {
      case FormMode.Merge:
        setFormMode(FormMode.Merge)
        event.preventDefault();
        if (validate()) {
          props.onMerge(event, FormMode.Merge, mergeCourseData);
        }
        break;
      default:
        props.onMerge(event, FormMode.None, []);
        break;

    }
  }

  const [errors, setErrors] = useState({
    mergeError: ''
  })
  const validate = () => {
    let error = {
      mergeError: '',
    };
    if (props.courseCount < 2) { error.mergeError = "Choose 2 or more courses"; }
    if (props.courseCount > 10) { error.mergeError = "Choose  less than 10 courses"; }
    setErrors(error);
    if (error.mergeError) {
      return false;
    }

    return true;
  };
  return (
    <div>
      <Modal size="lg" backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Merge} onHide={() => props.onMergeClose(FormMode.None)}>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Merge Courses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <label>Merge Following {props.courseCount} Courses</label>
            <Grid
              data={mergeData}
              scrollable={"none"}
            >
              <GridColumn field="courseName" title="Courses" cell={GridColumnCourses} />
              <GridColumn field="" title="" className="text-right" cell={GridColumnButton} />
            </Grid>
            <Form.Text className="text-danger">{errors.mergeError}</Form.Text>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => onMerge(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onMerge(e, formMode)}>Merge Courses</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}