import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import AuthService from '../services/openid/AuthService';
import { AuthContext, AuthContextType, AuthContextDefault } from '../services/AppContext';
import Header from "./Header";


export default function Login() {
    const authService = useRef(new AuthService())
    const [user, setUser] = useState<any>({});
    let history = useHistory();
    const { userProfile, setUserProfile } = useContext<AuthContextType>(AuthContext);
    const params = new URLSearchParams(window.location.search)
    const [loggedOut, setLoggedOut] = useState(false)

    useEffect(() => {
        if (window.location.search == "?logout") {
            authService.current.logout()
            setLoggedOut(true)
        }
    }, [window.location.search])

    const onSignIn = (e: any) => {
        const user1 = authService.current.getUser().then(user => {
            if (!user) {
                authService.current.login()
            }
            else {
                setUser(user);
                setUserProfile(AuthContextDefault.userProfile)
                history.push('/Home')
            }
        })
    }

    if (!loggedOut) {
        return (
            <div className='sign-in-page'>
                <Header />
                <div className="hero position-relative">
                    <div className="hero-gradient"></div>
                    <div className="hero-image" style={{ "background": "url(https://www.nycenet.edu/ui/apps/resources/graphics/patterns/oriental-tiles.png)" }}></div>
                    <div className='hero-signin-form'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 pl-3 pr-3">
                                    <div className="app-txt-main h1 text-success">LMS Admin</div>
                                    <div className="app-txt-sub h4 text-success">Learning Management System</div>
                                    <div className="pt-2 pb-2 font-weight-normal">
                                        Synchronize grades, rosters, courses and content between STARS systems and LMSs like iLearnNYC and Google Classroom.
                            </div>

                                    <a asp-controller="Home" asp-action="Login" className="btn btn-warning btn-lg btn-block mt-4 col-6 offset-3 col-sm-4 offset-sm-4"
                                        onClick={(e) => onSignIn(e)}>Sign In</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<div>
            <h1>Redirecting...</h1>
        </div>)
    }
}
