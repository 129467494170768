import IEnrolledStudent from '../model/IEnrolledStudent'
import IAddCourseStudent from '../model/IAddCourseStudent'
import { AxiosInstances as AxiosInstance } from './BaseService'

export default {
    getBySchoolDBN: (locationCode: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/Student`
        }),
    getByCourseId: (courseId: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/${courseId}/Student`
        }),

    getStudentById: (studentId: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Students/${studentId}`
        }),
    post: (requestdata: IAddCourseStudent) =>
        AxiosInstance({
            'method': 'POST',
            'url': `/Courses/Student`,
            'data': requestdata
        }),
    delete: (courseId: number, StudentId: number) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': `/Courses/${courseId}/Student/${StudentId}`
        }),
}