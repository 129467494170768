import { UserProfileType, AuthContextDefault } from "./AppContext";
import appsettings from '../assets/appsettings.json'
import moment from 'moment';
export default class LocalStorageService {
  private static setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify({ value }));
  }
  private static getItem<T>(key: string): T | null {
    const data: string | null | Date = localStorage.getItem(key);
    if (data !== null) {
      return JSON.parse(data).value;
    }
    return null;
  }

  public static getUserProfile(): UserProfileType {
    return this.getItem<UserProfileType>("lmi:userprofile") ?? AuthContextDefault.userProfile
  }

  public static setUserProfile(value: UserProfileType) {
    this.setItem("lmi:userprofile", value)
  }

  public static getExpirationTime() {
    return sessionStorage.getItem("expire_at")
  }

  public static setExpirationTime() {
    let expirationDate = moment().add(appsettings.AUTH_TOKEN_EXPIRATION_TIME,'minutes').format()
    sessionStorage.setItem("expire_at", expirationDate.toString())
  } 

  public static clear() {
    localStorage.clear();
  }

}