import {IAssignedTeachers} from '../model/IAssignedTeachers'
import IAddCourseTeachers from '../model/IAddCourseTeachers'
import { AxiosInstances as AxiosInstance} from './BaseService'
import ITeacherManageSection from '../model/ITeacherManageSection'

export default {
    GetUnassignedTeachersBySchoolDBN: (locationCode : string , courseUniqueId : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/Teachers/${courseUniqueId}`
        }),
        getTeachersBySchoolDBN: (locationCode : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/Teachers`
        }),
        getByCourseId: (courseId : number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/${courseId}/Teachers`
        }),
        getSharedCourseAssignedTeachers: (courseId : number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/SharedCourse/${courseId}/Teachers`
        }),
        GetUnassignedTeacherById: (teacherId : string, courseUniqueId : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Teachers/${teacherId}/${courseUniqueId}`
        }),
        getTeacherById: (teacherId : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Teachers/${teacherId}`
        }),
        post: (requestdata: IAddCourseTeachers) =>
        AxiosInstance({
            'method': 'POST',
            'url': `/Courses/Teachers`,
            'data': requestdata
        }), 
        enableTeacherToManageCourse: (requestdata: ITeacherManageSection) =>
        AxiosInstance({
            'method': 'POST',
            'url': `/ManageSection/Teachers`,
            'data': requestdata
        }), 
        removeTeaccherFromCourse: (requestdata: ITeacherManageSection) =>
        AxiosInstance({
            'method': 'POST',
            'url': `/UnManageSection/Teachers`,
            'data': requestdata
        }), 
        delete: (courseId : number, teacherId : string) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': `/Courses/${courseId}/Teacher/${teacherId}`
        }), 
}