import { AxiosInstances as AxiosInstance } from './BaseService'
import ICourseVendorContent from '../model/ICourseVendorContent';
import ICourses from '../model/ICourses';
import ICourseDetail from '../model/ICourseDetail';
import IMergeCourses from '../model/IMergeCourses';
import IMultipleCoursesVendorContent from '../model/IMultipleCoursesVendorContent';
import internal from 'stream';
import IAddMultipleCoursesVendorContent from '../model/IAddMultipleCoursesVendorContent';
export default {
    getCourses: (schoolDBN: string, schoolYear: number, pageNumber: number, pageSize: number, orderBy: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/${schoolYear}/Courses?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}`,
        }),
    getStarsCourses: (sharedCourseUniqueId: string, schoolDBN: string, schoolYear: number, pageNumber: number, pageSize: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/${schoolYear}/Stars/${sharedCourseUniqueId}/${schoolDBN}/${pageNumber}/${pageSize}`,
        }),

    searchStarsCourseByName: (sharedCourseUniqueId: string, schoolDBN: string, schoolYear: number, pageNumber: number, pageSize: number, searchCourseName: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/${schoolYear}/Stars/${sharedCourseUniqueId}/${schoolDBN}/${pageNumber}/${pageSize}/${searchCourseName}`,
        }),

    filterCourses: (schoolDBN: string, schoolYear: number, pageNumber: number, pageSize: number, orderBy: string, searchCourseName: string , searchCourseStatus: string , searchCourseType: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/${schoolYear}/Courses?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&searchCourseName=${searchCourseName}&searchCourseStatus=${searchCourseStatus}&searchCourseType=${searchCourseType}`,
        }),
    filterCoursesByNameAndStatus: (schoolDBN: string, schoolYear: number, pageNumber: number, pageSize: number, orderBy: string, searchCourseStatus: string, searchCourseName: string,) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/${schoolYear}/Courses/${pageNumber}/${pageSize}/${orderBy}/${searchCourseStatus}/${searchCourseName}`,
        }),
    filterCoursesByStatus: (schoolDBN: string, schoolYear: number, pageNumber: number, pageSize: number, orderBy: string, searchCourseStatus: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/${schoolYear}/Courses/${pageNumber}/${pageSize}/${orderBy}/${searchCourseStatus}`,
        }),
    getCourseDetail: (courseId: number, schoolDBN: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/Courses/${courseId}`,
        }),

    getCourseStatus: () =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/CourseStatus`,
        }),
    getUnassignedVendorContentData: (courseId: number, schoolDBN: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/Courses/${courseId}/UnassignedVendorContent`,
        }),
    getVendorContentUnassignedCourses: (schoolDBN: string,vendorContentId:number,vendorId:number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/${vendorContentId}/${vendorId}/UnassignedCourses`,
        }),       
    getIndividualCoursesBySharedCourseId: (courseId: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/IndividualCourses/${courseId}`,
        }),
    getVendorContentData: (courseId: number, schoolDBN: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${schoolDBN}/Courses/${courseId}/VendorContent`,
        }),
    post: (requestdata: ICourseVendorContent) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Courses/VendorContent',
            'data': requestdata
        }),
    postMultipleCoursesVendorContent: (requestdata: IAddMultipleCoursesVendorContent) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Courses/VendorContent/Add',
            'data': requestdata
        }),
    postVendorContentMultipleCourses: (requestdata: IMultipleCoursesVendorContent) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/MultipleCourses/VendorContent',
            'data': requestdata
        }),
    delete: (requestdata: ICourseVendorContent) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Courses/VendorContent/Delete',
            'data': requestdata
        }),
    edit: (requestdata: ICourses) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/CourseName',
            'data': requestdata
        }),
    merge: (requestdata: IMergeCourses) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/Merge',
            'data': requestdata
        }),
    postCourse: (requestdata: ICourseDetail) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Courses/Course',
            'data': requestdata
        }),

    editCourse: (requestdata: ICourseDetail) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/Course',
            'data': requestdata
        }),

    addIndividualCourses: (requestdata: any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Courses/AddIndividualCourses',
            'data': requestdata
        }),
    deleteIndividualCourses: (requestdata: any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Courses/DeleteIndividualCourses',
            'data': requestdata
        }),

    GetCourseStatusChart: (schoolDBN: string, schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/Chart/${schoolDBN}/Courses/${schoolYear}`,
        }),

    publishCourse: (requestdata: ICourseDetail) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/publish',
            'data': requestdata
        }),

    deleteCourse: (requestdata: ICourseDetail) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/delete',
            'data': requestdata
        }),

    closeCourse: (requestdata: ICourseDetail) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/close',
            'data': requestdata
        }),

    reOpenCourse: (requestdata: ICourseDetail) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Courses/reopen',
            'data': requestdata
        }),

    getLicenseSummary: (courseId: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/${courseId}/LicenseSummary`,
        }),

}