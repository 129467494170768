import React, { useState, useEffect, useReducer, useRef, useContext, useCallback } from 'react';
import { Button, Modal, InputGroup, FormControl, Form } from 'react-bootstrap';
import { Grid, GridColumn,  GridExpandChangeEvent, GridFilterChangeEvent, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { IAssignedTeachers, ManageCourses } from '../../model/IAssignedTeachers';
import TeacherService from '../../services/TeacherService';
import { FormMode, IDropDownValue } from '../../services/Utility';
import schoolService from '../../services/SchoolService';
import IAddCourseTeachers from '../../model/IAddCourseTeachers';
import IconPersonCircle from '../../assets/icons/icon-person-circle';
import LocalStorageService from '../../services/LocalStorage';
import { AuthContextDefault, UserProfileType, Year } from '../../services/AppContext';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import IAssignedAdditionalStaff from '../../model/IAssignedAdditionalStaff';
import ITeacherManageSection from '../../model/ITeacherManageSection';

interface IAssignedTeacherProps {
    courseId: any
    courseStatus: string
    courseSource: string
    isEditable?: boolean | false
    locationCode: string
    onAssignTeachers: any
    onDeleteTeacher: any
    updateCourseStatus: any
    isSharedCourse: boolean
    courseUniqueId : string
    
}
const initialDataState = {
    skip: 0,
    take: 10,
};


export default function AssignedTeachers(props: IAssignedTeacherProps) {
    const mountedRef = useRef(true);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    const { orginalSchoolYear } = useContext(Year);
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [courseTeachers, setCourseTeachers] = useState<IAssignedTeachers[]>([]);
    const [courseAdditionalStaff, setCourseAdditionalStaff] = useState<IAssignedAdditionalStaff[]>([]);
    const [data, setRowData] = useState<IAssignedTeachers[]>([]);
    const [dataCopy, setRowCopyData] = useState<IAssignedTeachers[]>([]);
    const [deleteTeacherId, setDeleteTecherId] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [orginalSchoolList, setOrginalSchoolList] = useState<IDropDownValue[]>([]);
    const [schoolList, setSchoolList] = useState<IDropDownValue[]>([]);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };
    const [filter, setFilter] = useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);

    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const getTeachers =  (locationCode: string) => {
         TeacherService.GetUnassignedTeachersBySchoolDBN(locationCode , props.courseUniqueId).then(response => {
            setRowData(response.data)
            setRowCopyData(response.data)
        })
    }
    
    const getAssignedTeachers = (CourseId: number) => {
        if (props.isSharedCourse) {
            TeacherService.getSharedCourseAssignedTeachers(CourseId).then(response => {
                let teacherData = response.data as IAssignedTeachers[];
                teacherData.forEach(x => x.courseSource = props.courseSource);
                teacherData.forEach(x => x.courseStatus = props.courseStatus);
                setCourseTeachers(teacherData)
            })
        } else {
            TeacherService.getByCourseId(CourseId).then(response => {
                let teacherData = response.data as IAssignedTeachers[];
                teacherData.forEach(x => x.courseSource = props.courseSource);
                teacherData.forEach(x => x.courseStatus = props.courseStatus);
                setCourseTeachers(teacherData)
            })
        }

        return () => { mountedRef.current = false }
    }
    const getSchoolLocations = () => {
        schoolService.getData().then(response => {
            let dropDownData = response.data.map((sl: any) => ({
                id: sl.locationCode,
                text: sl.locationCode + ' -- ' + sl.schoolName
            }));
            setSchoolList(dropDownData)
            setOrginalSchoolList(dropDownData)
        });
    }
    const filterData = (filter: any) => {
        let data = orginalSchoolList.slice();
        return filterBy(data, filter);
    }

    const filterChange = (event: any) => {
        let data = filterData(event.filter)
        setSchoolList(data)
    }

    const handleChange = (event: any) => {
        let selectedSchool = event.target.value;
        getTeachers(selectedSchool.id);
    }

    const getDefaultValue = (schoolList: any) => {
        let school = schoolList.find((s: any) => s.id == props.locationCode)
        if (school) {
            return school
        }
    }

    useEffect(() => {
        getAssignedTeachers(props.courseId);
        setUserProfile(LocalStorageService.getUserProfile())
        return () => { mountedRef.current = false }
    }, [props.locationCode]);


    const [assignedTeachersVisible, setAssignedTeachersVisible] = useState<boolean>(false);
    const toggleAssignedTeachersDialog = () => {
        setAssignedTeachersVisible(!assignedTeachersVisible)
        let data = orginalSchoolList.slice();
        setSchoolList(data)
        setHeaderCheckBox(false);
    }

    const addTeacher = () => {
        setErrorMsg('');
        getSchoolLocations();
        getTeachers(props.locationCode);
        setFormMode(FormMode.New)
        toggleAssignedTeachersDialog()
    }

    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        forceUpdate();
    }

    const [headerCheckBoxValue, setHeaderCheckBox] = useState(false);
    const onHeaderCheckBoxClick = (event: any, products?: any) => {
        let checked = event.target.checked;
        if (checked == true) {
            data.map((t: any) => t.isSelected = true)
        } else {
            data.map((t: any) => t.isSelected = false)
        }
        setHeaderCheckBox(checked)
    }
    const removeTeacher = (teacherId: string) => {
        setDeleteTecherId(teacherId)
        setFormMode(FormMode.Delete)
    }
    const [searchTeacherEin, setSearchTeacherEin] = useState("");
    const searchTeacherChange = (event: any) => {
        const target = event.target
        if (!target.value || target.value == "") {
            setErrorMsg('')
            setRowData(dataCopy)
        }
        setSearchTeacherEin(target.value)
    };
    const getTeacherById = () => {
        setErrorMsg('')
        if (searchTeacherEin == "") {
            setErrorMsg("Please enter teacher EIN")
            return
        }
        setRowData([])
        TeacherService.GetUnassignedTeacherById(searchTeacherEin , props.courseUniqueId).then(response => {
            if (response.data.isValidResponse) {
                let teacher = response.data.result as IAssignedTeachers
                let teachers = []
                teachers.push(teacher)
                setRowData(teachers)
                setPage(initialDataState)
            } 
        }).catch((ex)=>{
            setErrorMsg(ex.response.data.errors.join())
        })
    }
    const onSave = (event: any, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.New:

                let addCourseTeachers: IAddCourseTeachers = {
                    courseId: props.courseId,
                    schoolYear: +orginalSchoolYear.id,
                    teachers: []
                }
                addCourseTeachers.teachers = data.filter(x => x.isSelected);
                if (addCourseTeachers.teachers?.length == 0) {
                    setErrorMsg('Please select atleast one teacher to add.');
                    return;
                }
                let teacherIds = courseTeachers.map(a => a.teacherId);
                let addedTeachers = addCourseTeachers.teachers.filter((x) => !teacherIds.includes(x.teacherId));;
                addCourseTeachers.teachers = addedTeachers;

                if (addedTeachers?.length == 0) {
                    setErrorMsg('Selected teachers are already added.');
                    return;
                }
                addedTeachers.forEach(x => x.role = "Teacher");
                var currentTeacherList = courseTeachers;
                currentTeacherList = currentTeacherList.concat(addedTeachers);
                TeacherService.post(addCourseTeachers).then(response => {
                    setCourseTeachers(currentTeacherList);
                    props.onAssignTeachers(addedTeachers, props.courseId)
                    props.updateCourseStatus(props.courseId)
                    setAssignedTeachersVisible(!assignedTeachersVisible)
                    setFormMode(FormMode.None)
                    setHeaderCheckBox(false);
                });
                break;
            case FormMode.Delete:
                TeacherService.delete(props.courseId, deleteTeacherId).then(response => {
                    let courseteacherList = courseTeachers.filter(x => x.teacherId != deleteTeacherId);
                    props.onDeleteTeacher(courseteacherList, props.courseId)
                    props.updateCourseStatus(props.courseId)
                    setCourseTeachers(courseteacherList);
                    setFormMode(FormMode.None)
                });
                break;
            default: {
                setFormMode(FormMode.None)
                break;

            }
        }
    }

    const changeTeacherCanManage = (e: any, element: ManageCourses, teacherId: string) => {
        let teacher: IAssignedTeachers | undefined = courseTeachers.find(item=>item.teacherId == teacherId)
        let checked = e.target.checked
        let teacherManageSection: ITeacherManageSection = {
            sharedCourseUniqueId : teacher?.courseUniqueId,
            courseId: props.courseId,
            courseStatus: props.courseStatus,
            teacherId: teacherId,
            locationCode: teacher?.schoolDBN,
            sectionUniqueId: element.sectionUniqueId
        }
        let newData = courseTeachers.map((item: IAssignedTeachers) => {
            if (item.teacherId === teacherId) {
                teacher = item
                item.manageCourses?.map((i: ManageCourses) => {
                    if (i.sectionUniqueId === element.sectionUniqueId) {
                        i.canManage = checked
                    }
                })
            }
            return item; 
        })
        setCourseTeachers(newData);
        if (checked) {
            
            TeacherService.enableTeacherToManageCourse(teacherManageSection).then(res => {
                if(res.data.isValidResponse){
                    props.updateCourseStatus(props.courseId)
                }
                
            })
        }else{
            TeacherService.removeTeaccherFromCourse(teacherManageSection).then(res => {
                if(res.data.isValidResponse){
                    props.updateCourseStatus(props.courseId)
                }
            })
        }

    }

    const DetailComponent = (item: any) => {
        const dataItem = item.dataItem;

        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-5">
                            <div className='text-primary small mb-1'>Teaches Course:</div>
                            {dataItem.mainCourses && dataItem.mainCourses.length &&
                                dataItem.mainCourses?.map((element: any, index: number) => {
                                    return <div key={index}>{element.teacherCourse}</div>
                                })}
                        </div>
                        <div className="col-md-7 mt-3 mt-md-0">
                            <div className='text-primary small mb-1'>Allowed to Manage:</div>
                            {dataItem.manageCourses && dataItem.manageCourses.length > 0 &&
                                dataItem.manageCourses?.map((element: any, index: number) => {
                                    return (
                                        <>
                                            <Form>
                                                <Form.Group className='mb-0'>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`canManage${index}`}
                                                        label={element.sectionName}
                                                        name="canManage"
                                                        onChange={(e: any) => changeTeacherCanManage(e, element, dataItem.teacherId)}
                                                        checked={element.canManage}
                                                        key={index}
                                                        disabled={props.courseStatus.toLowerCase() == 'closed'}
                                                    />
                                                </Form.Group>
                                            </Form></>)
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const expandChange = (event: GridExpandChangeEvent) => {
        let newData = courseTeachers.map((item: IAssignedTeachers) => {
            if (item.teacherId === event.dataItem.teacherId) {
                item.expanded = !event.dataItem.expanded;
            } else {
                item.expanded = false
            }
            return item;
        })
        setCourseTeachers(newData);
    }

    return (
        <div>
            {assignedTeachersVisible && <Dialog title={"Add New Teachers"} onClose={toggleAssignedTeachersDialog} width={1000} height={600}>
                <div id="dropDownParent">
                    <div hidden={errorMsg.length == 0}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMsg}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" >
                        <div className="text-sm-left text-left">

                            <InputGroup >
                                <FormControl
                                    placeholder="Teacher EIN"
                                    onChange={searchTeacherChange}
                                />
                                <Button variant="outline-secondary" id="button-addon1" onClick={getTeacherById} >
                                    Search
                                </Button>
                            </InputGroup>
                        </div>
                        <div className="text-sm-right text-right">
                            <DropDownList
                                data={schoolList}
                                defaultValue={getDefaultValue(schoolList)}
                                textField="text"
                                filterable={true}
                                onChange={handleChange}
                                onFilterChange={filterChange}
                                style={{ maxWidth: '350px', width: "100%" }}
                                popupSettings={{ appendTo: document.getElementById("dropDownParent") as HTMLElement }}
                            />
                        </div>
                    </div>
                    <div className="mt-3" style={{ height: '500px' }}>
                        <Grid
                            data={filterBy(data, filter).slice(page.skip, page.take + page.skip)}
                            filter={filter}
                            filterable={true}
                            skip={page.skip}
                            take={page.take}
                            total={data.length}
                            pageable={true}
                            scrollable={"none"}
                            className="mb-3"
                            onPageChange={pageChange}
                            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                        >
                            <GridColumn field="isSelected" width="40px" filterable={false} headerCell={props => (<input type="checkbox" checked={headerCheckBoxValue} onChange={(e) => onHeaderCheckBoxClick(e, props)} />)} cell={props => (
                                <td>
                                    <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                                </td>
                            )} />

                            <GridColumn field="UserIcon" title=" " filterable={false} width="80px" cell={
                                props => (
                                    <td>
                                        <div className="d-flex">
                                            <div className="svg-app-icon text-rust mr-3" style={{ height: "30px" }}>
                                                <IconPersonCircle />
                                            </div>
                                        </div>
                                    </td>
                                )} />
                            <GridColumn field="fullName" title="Name" cell={
                                props => (
                                    <td>
                                        <div>{props.dataItem.fullName}</div>
                                    </td>
                                )} />
                            <GridColumn field="teacherId" title="EIN" />
                            <GridColumn field="userName" title="Username" />
                            <GridColumn field="schoolDBN" title="Associated Location" filterable={false} />
                        </Grid>
                    </div>
                </div>
                <DialogActionsBar>
                    <Button className="m-3" variant="secondary" onClick={toggleAssignedTeachersDialog}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Add Teachers</Button>
                </DialogActionsBar>
            </Dialog>}

            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>

                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Remove Teacher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove teacher ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <div className="card mt-4">
                <div className="card-header font-weight-bold bg-secondary-light">
                    <div className="row align-items-center">
                        <div className="col-8 font-weight-bold">Assigned Teachers</div>
                        {(props.courseSource.toLowerCase() == 'stars' || props.courseStatus.toLowerCase() == 'closed') ? '' :
                            <div className="col-4 text-right">
                                {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && !props.isSharedCourse ? <button type="button" className="btn btn-sm btn-primary" onClick={(e) => { addTeacher() }}>
                                    <i className="fas fa-plus"></i>
                                </button> : props.isSharedCourse && <span className='small'> (auto-synced from the courses above) </span>}

                            </div>}
                    </div>
                </div>
                <div className="card-body">

                    <Grid
                        data={courseTeachers}
                        scrollable={"none"}
                        className={props.isSharedCourse ? "k-grid-no-header hierarchy-grid" : "k-grid-no-header"}
                        detail={props.isSharedCourse ? DetailComponent : null}
                        expandField="expanded"
                        onExpandChange={expandChange}
                    >
                        {/* <GridColumn field="expanded" width="80px" title=' ' /> */}
                        <GridNoRecords>
                            No Assigned Teachers
                        </GridNoRecords>
                        <GridColumn field="UserIcon" title=" " width="80px" cell={
                            props => (
                                <td>
                                    <div className="d-flex">
                                        <div className="svg-app-icon text-rust mr-3" style={{ height: "40px" }}>
                                            <IconPersonCircle />
                                        </div>
                                    </div>
                                </td>
                            )} />
                        <GridColumn field="Name" title="" cell={
                            props => (
                                <td>
                                    <div> {props.dataItem.lastName} {props.dataItem.firstName} </div>
                                    <div className="small">EIN:{props.dataItem.teacherId}</div>
                                </td>
                            )} />
                        <GridColumn field="" title="Title" cell={
                            props => (
                                <td>
                                    <div className="small">Title:</div>
                                    <div>{props.dataItem.role}</div>
                                </td>
                            )} />
                        <GridColumn field="" title="Username" cell={
                            props => (
                                <td>
                                    <div className="small">Username:</div>
                                    <div>{props.dataItem.userName}</div>
                                </td>
                            )} />
                        <GridColumn field="schoolDBN" title="Associated Location" cell={
                            props => (
                                <td>
                                    <div className="small">Associated Location:</div>
                                    <div>{props.dataItem.schoolDBN}</div>
                                </td>
                            )} />
                        <GridColumn field="" title=" " cell={
                            prop => (
                                <td className="text-right menu-btn">
                                    {(userProfile?.role?.roleName == 'Central Staff' || prop.dataItem?.role === "Teacher Of Record" || orginalSchoolYear.isCurrent == 0
                                        || prop.dataItem?.courseStatus?.toLowerCase() === 'closed' || prop.dataItem?.courseSource?.toLowerCase() === 'stars') || props.isSharedCourse
                                        ? '' :
                                        <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { removeTeacher(prop.dataItem.teacherId) }}>
                                            <i className="fas fa-minus"></i>
                                        </button>
                                    }
                                </td>
                            )} />

                    </Grid>
                </div>
            </div>
        </div>
    )
}