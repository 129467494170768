import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';

export default function Footer(/*props*/) {
  return (
       <footer className="footer" id = "footer" >
          <div className="footer-container">
              <div className="container">  
                  <div className="row">
                      <div className="footer-links col-sm-5">
                          <a className="anchor-links" href="https://www.schools.nyc.gov/privacy-policy" target="_blank">Privacy Policy</a>
                        </div>
                      <div className="col-sm-7 footer-copyright">&copy; {new Date().getFullYear()} The New York City Department of Education</div>
                    </div>
                </div>
            </div>
    </footer >
  )
}