import React, { useState, useEffect, useContext } from 'react';
import IDailyReport from '../../model/IDailyReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';


interface ILocation {
    changeExcelRef: any;
    selectedReport: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialSort: Array<SortDescriptor> = [
    { field: "courses", dir: "desc" },
];

export default function DailyReport(props: ILocation) {
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<IDailyReport[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);

    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const GetDailyReport = () => {
        ReportsService.GetDailyReport().then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetDailyReport();
    }, [props.selectedReport]);


    return (
        <ExcelExport data={rowData} ref={_export} fileName={"Daily_Report" + ' ' + new Date().toDateString()}>
            <Grid
                data={orderBy(rowData.slice(page.skip, page.take + page.skip), sort)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                scrollable={"none"}>
                <GridColumn field="courses" title="Courses"></GridColumn>
                <GridColumn field="students" title="Students"></GridColumn>
                <GridColumn field="teachers" title="Teachers"></GridColumn>
            </Grid>
        </ExcelExport>
    )
}