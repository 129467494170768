
import ILicenseAllocation from '../model/ILicenseAllocation'
import { AxiosInstances as AxiosInstance} from './BaseService'

export default {
   
    getAllocatedVendorsDetail: (locationCode: string , schoolYear : number ) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/AllocatedVendorsDetail/${schoolYear}`
        }),
    getAllocatedVendorDetailById: (locationCode: string, vendorId: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/AllocatedVendors/${vendorId}/VendorContents`
        }),
               //get School License chart data- hossni 14/6
    GetSchoolLicenseUsageChart: (locationCode: string, year: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/AllocatedVendors/${year}/SchoolLicenseUsageChart`
        }),

        GetSchoolLicenseUsageReport: (locationCode: string, year: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/AllocatedVendors/${year}/SchoolLicenseUsageReport`
        }),
    getNonAllocatedVendors: (locationCode: string , schoolYear : number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/NonAllocatedVendors/${schoolYear}`
        }),
    getAllocatedVendors: (locationCode: string , schoolYear : number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/AllocatedVendors/${schoolYear}`
        }),
    post: (requestdata: ILicenseAllocation) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/LicenseAllocation',
            'data': requestdata
        }),
    put: (requestdata: ILicenseAllocation) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/LicenseAllocation',
            'data': requestdata
        }),
    postTransfer: (requestdata: ILicenseAllocation) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/LicenseAllocation/Transfer',
            'data': requestdata
        })
}