import React, { useState, useEffect, useRef } from 'react';
import { Button, Form} from 'react-bootstrap';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormMode,IDropDownValue } from '../../services/Utility'
import ILicenseAllocation from '../../model/ILicenseAllocation';
import { RadioButton } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { filterBy, CompositeFilterDescriptor, } from "@progress/kendo-data-query";
import schoolListService from '../../services/SchoolListService'

interface IVendorFormContent {
  id?: number
  location:string
  vendorlist:IDropDownValue[]
  vendorAllocatedlist:IDropDownValue[]
  formmode:FormMode
  datasource: any
  isEditable?: boolean | false
  onSave: (event: React.MouseEvent<HTMLElement,MouseEvent>,actionType: FormMode, data:ILicenseAllocation) => void
}
export default function VendorCrudContent(props:IVendorFormContent) {
  const mountedRef = useRef(true);

  const [originalSchoolList, setOriginalSchoolList] = useState<IDropDownValue[]>([]);
  const [schoolListTo, setSchoolListTo] = useState<IDropDownValue[]>([]);
  const [vendorList, setVendorList] = useState<IDropDownValue[]>([]);
  const [vendorAllocatedList, setVendorAllocatedList] = useState<IDropDownValue[]>([]);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
  const [formValue, setFormValue] = useState<ILicenseAllocation>({
    id:0,
    locationCode:'',
    fromLocationCode: '',
    toLocationCode:'',
    vendorId: 0,
    vendorName: '',
    quantityAllocated: 0,
    quantityAssigned: 0,
    quantityCap: 0
  });

  const getAllSchools = () =>{
    schoolListService.getAllSetupSchool().then(res=>{
      let dropDownData = res.data.map((sl: any) => ({
        id: sl.locationCode,
        text: sl.locationCode + ' -- ' + sl.schoolName
    }));
    setSchoolListTo(dropDownData)
    setOriginalSchoolList(dropDownData)
    })
  }

  useEffect(() => {
    setFormMode(props.formmode)
      getAllSchools()
      setVendorList(props.vendorlist)
      setVendorAllocatedList(props.vendorAllocatedlist)
      setFormValue({ ...formValue, locationCode:props.location,fromLocationCode:props.location});

    return () => { mountedRef.current = false }
  }, []);

  const handleChange = (event: any) => {
    const target = event.target //as HTMLSelectElement

    setFormValue({ ...formValue, [target.name]: target.value });

  };
  const schoolChange = (event: DropDownListChangeEvent) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, toLocationCode: target.value.id });

  };
  const vendorChange = (event: DropDownListChangeEvent) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, vendorName: target.value.text, vendorId: target.value.id });

  };

  const [errors, setErrors] = useState({
    toLocationCodeError: '',
    vendorNameError: '',
    licenseQuantityError: '',
  })
  const validate = () => {
    let error = {
      toLocationCodeError: '',
      vendorNameError: '',
      licenseQuantityError: '',
    };

    if (formMode == FormMode.New) {
      if (!formValue.vendorName) { error.vendorNameError = "Vendor Name is required"; }

      if (!formValue.quantityAllocated) { error.licenseQuantityError = "Enter Quantity Purchased"; }

      setErrors(error);
      if (error.vendorNameError || error.licenseQuantityError) {
        return false;
      }
    }
    else if (formMode == FormMode.Transfer) {
      if (!formValue.toLocationCode) { error.toLocationCodeError = "Receiving school is required"; }
      if (!formValue.vendorName) { error.vendorNameError = "Vendor Name is required"; }

      if (!formValue.quantityAllocated) { error.licenseQuantityError = "Enter Quantity Purchased"; }

      setErrors(error);
      if (error.vendorNameError ||error.licenseQuantityError||error.toLocationCodeError) {
        return false;
      }
    }
    return true;
  };
  const onFormAction = (Id: number, actionType: FormMode) => {
    setErrors({
      toLocationCodeError: '',
      vendorNameError: '',
      licenseQuantityError: '',
    });
    setFormValue({...formValue,
    id:0,
    toLocationCode:'',
    vendorId: 0,
    vendorName: '',
    quantityAllocated: 0,
    quantityAssigned: 0,
    quantityCap: 0
    });
 
    switch (actionType) {

      case FormMode.New:
        setFormMode(FormMode.New)

        //Clear Existing vlues
        break;
      case FormMode.Transfer:
        setFormMode(FormMode.Transfer)
        //Clear Existing vlues
        break;
      default: {
        setFormMode(FormMode.None)
        break;
      }
    }
  }

  const onSave = (event: React.MouseEvent<HTMLElement>, actionType: FormMode) => {
    
    let formData:ILicenseAllocation ={
      id:Number(formValue.id),
      locationCode:formValue.locationCode,
      fromLocationCode: formValue.fromLocationCode,
      toLocationCode:formValue.toLocationCode,
      vendorId: Number(formValue.vendorId),
      vendorName: formValue.vendorName,
      quantityAllocated: Number(formValue.quantityAllocated),
      quantityAssigned: 0,
      quantityCap: Number(formValue.quantityCap)
    };

    switch (actionType) {
      case FormMode.New:
        // messageService.post(message).then(response => {
        //   // Display Success message
        // })
        event.preventDefault();
        if (validate()) {

          props.onSave(event, FormMode.New,formData);
         }
        //Clear Existing vlues
        break;
        case FormMode.Transfer:
        // messageService.post(message).then(response => {
        //   // Display Success message
        // })
        event.preventDefault();
        if (validate()) {

          props.onSave(event, FormMode.Transfer,formData);
         }
        //Clear Existing vlues
        break;
      default: {
        props.onSave(event, FormMode.None,formData);
        break;
      }
    }
  }
  const filterData = (filter: any) => {
    let data = originalSchoolList.slice();
    return filterBy(data, filter);
  }

  const filterChange = (event: any) => {
    let data = filterData(event.filter)
    onSchoolSearch(event, data);
  }

  const onSchoolSearch = (event: any, data: IDropDownValue[]) => {
    setSchoolListTo(data)
}

  return (
    <div>
      {formMode == FormMode.New &&(

          <Dialog title={"Manage Licenses"} onClose={(e: any) => onSave(e, FormMode.None)}>

          <Form.Group>
            <Form.Label className="mr-2"><RadioButton name="transferLicense" checked={true} value='Add Licenses' onClick={(e) => { onFormAction(0, FormMode.New) }}> Add Licenses</RadioButton></Form.Label>
            <Form.Label><RadioButton name="transferLicense" checked={false} value='Transfer Licenses' onClick={(e) => { onFormAction(0, FormMode.Transfer) }}> Transfer Licenses</RadioButton></Form.Label>
          </Form.Group>

          <Form>
            <Form.Group controlId="vendorName">
              <Form.Label>Vendor</Form.Label>
              <DropDownList
                style={{ width: '100%' }}
                data={vendorList}
                textField="text"
                onChange={vendorChange}
              />
              <Form.Text className="text-danger">{errors.vendorNameError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="quantityAllocated">
              <Form.Label>Number of Licenses</Form.Label>
              <Form.Control type="number" name="quantityAllocated" value={formValue.quantityAllocated} onChange={handleChange} />
              <Form.Text className="text-danger">{errors.licenseQuantityError}</Form.Text>
            </Form.Group>
          </Form>
        {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </Modal.Footer>
      </Modal> */}
      <DialogActionsBar>
          <Button className="m-2" variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </DialogActionsBar>
      </Dialog>
    )}
    {formMode== FormMode.Transfer&&(
      <Dialog title={"Manage Licenses"} onClose={(e: any) => onSave(e, FormMode.None)}>
      {/* <Modal backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Transfer} onHide={(e:any) => onSave(e, FormMode.None)}>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Manage Licenses</Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
          <Form.Group>
            <Form.Label className="mr-2"><RadioButton name="transferLicense" checked={false} value='Add Licenses' onClick={(e) => { onFormAction(0, FormMode.New) }}> Add Licenses</RadioButton></Form.Label>
            <Form.Label><RadioButton name="transferLicense" checked={true} value='Transfer Licenses' onClick={(e) => { onFormAction(0, FormMode.Transfer) }}> Transfer Licenses</RadioButton></Form.Label>
          </Form.Group>
          <Form>
            <Form.Group controlId="schoolListTo">
              <Form.Label>Receiving School</Form.Label>
              <DropDownList
                style={{ width: '100%' }}
                data={schoolListTo}
                textField="text"
                filterable={true}
                onFilterChange={filterChange}
                onChange={schoolChange}
              />
              <Form.Text className="text-danger">{errors.toLocationCodeError}</Form.Text>
            </Form.Group>

            <Form.Group controlId="vendorAllocatedList">
              <Form.Label>Vendor</Form.Label>
              <DropDownList
                style={{ width: '100%' }}
                data={vendorAllocatedList}
                textField="text"
                onChange={vendorChange}
              />
              <Form.Text className="text-danger">{errors.vendorNameError}</Form.Text>
            </Form.Group>
            
            <Form.Group controlId="quantityAllocated">
              <Form.Label>Number of Licenses</Form.Label>
              <Form.Control type="number" name="quantityAllocated" value={formValue.quantityAllocated} onChange={handleChange} />
              <Form.Text className="text-danger">{errors.licenseQuantityError}</Form.Text>
            </Form.Group>
          </Form>
        {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </Modal.Footer>
      </Modal> */}
              <DialogActionsBar>
          <Button className="m-2" variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </DialogActionsBar>
      </Dialog>
      )}
    </div>
  )
}

