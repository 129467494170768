
import IconDOELogo from '../assets/icons/icon-doe-logo'

export default function Header(/*props*/) {
  return (
    <header className="header">
            <div className="container app-banner">
                <div className="row">
                    <div className="col-3 doe-logo">
                        <a href="#">
                            <IconDOELogo />
                        </a>
                    </div>
                    <div className="col-9 app-title-sm text-right d-flex justify-content-end align-items-center">
                        { window.location.pathname == '/' || window.location.pathname == '/Home' ? <div className="app-txt-main">LMS Admin</div> : <div className="app-txt-main">iLearn Align</div> }
                    </div>
                </div>
            </div>
        </header>
       
  )
}