import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthContextType, UserProfileType } from '../services/AppContext'
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom'
import LocalStorageService from '../services/LocalStorage';

type ProfileMenuType = {
    userProfile: UserProfileType
    onNavigation: (event: React.MouseEvent<HTMLElement, MouseEvent>, data: string) => void
}

export default function ProfileMenu(props: ProfileMenuType) {
    const [userProfile, setUserProfile] = useState<UserProfileType>();
    let history = useHistory();
    const onMenuClick = (e: any, menuName: string) => {
        history.replace(menuName)
        props.onNavigation(e, menuName)
        e.preventDefault();
    }

    useEffect(() => {
        //const userProf = LocalStorageService.getUserProfile()
        setUserProfile(props.userProfile);
    }, [props.userProfile])

    return (
        <div>
            <Dropdown className="user-sign-in link-mobile-visible link-mobile-user">
                <Dropdown.Toggle variant="link" id="user_dropdown">
                    <svg className="icon-user-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                        <path data-name="Path 27" d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.52,17.52,0,0,0,17.5,0ZM28.944,26.363a15.491,15.491,0,0,0-3.756-5.542,10.356,10.356,0,0,1-2.353,1.964,12.75,12.75,0,0,1,3.77,5.974A14.428,14.428,0,0,1,8.374,28.74a12.32,12.32,0,0,1,3.76-5.974A10.287,10.287,0,0,1,9.78,20.785a14.712,14.712,0,0,0-3.753,5.54,14.493,14.493,0,1,1,22.916.038Z" fill="#fff"></path>
                        <path data-name="Path 28" d="M43.455,23.314a7.518,7.518,0,1,0,7.516,7.518A7.526,7.526,0,0,0,43.455,23.314Zm0,12.028a4.511,4.511,0,1,1,4.51-4.511A4.516,4.516,0,0,1,43.455,35.342Z" transform="translate(-25.955 -16.838)" fill="#fff"></path>
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right">
                    <div className="dropdown-username d-flex">
                        {/* <!-- User icon SVG image --> */}
                        <svg className="icon-user-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                            <path data-name="Path 27" d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.52,17.52,0,0,0,17.5,0ZM28.944,26.363a15.491,15.491,0,0,0-3.756-5.542,10.356,10.356,0,0,1-2.353,1.964,12.75,12.75,0,0,1,3.77,5.974A14.428,14.428,0,0,1,8.374,28.74a12.32,12.32,0,0,1,3.76-5.974A10.287,10.287,0,0,1,9.78,20.785a14.712,14.712,0,0,0-3.753,5.54,14.493,14.493,0,1,1,22.916.038Z" fill="#fff"></path>
                            <path data-name="Path 28" d="M43.455,23.314a7.518,7.518,0,1,0,7.516,7.518A7.526,7.526,0,0,0,43.455,23.314Zm0,12.028a4.511,4.511,0,1,1,4.51-4.511A4.516,4.516,0,0,1,43.455,35.342Z" transform="translate(-25.955 -16.838)" fill="#fff"></path>
                        </svg>
                        <div>
                            <div className="dropdown-user-name text-nowrap">{userProfile?.userName}</div>
                            <div className="dropdown-user-title text-nowrap">{userProfile?.role?.roleName}</div>
                        </div>
                    </div>
                    <Dropdown.Divider />
                    {
                        (history.location.pathname != '/Home') && <div>
                            {
                                (userProfile?.role?.roleName == 'System Administrator') && <div>
                            <Dropdown.Header>Manage</Dropdown.Header>
                                    <Dropdown.Item onClick={(e) => onMenuClick(e, '/ILearn/manage/announcements')}>Announcements</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => onMenuClick(e, '/ILearn/manage/vendors')}>Vendors</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => onMenuClick(e, '/ILearn/manage/schools')}>Schools</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => onMenuClick(e, '/ILearn/manage/years')}>School Year</Dropdown.Item>
                                </div>}
                            {
                                (userProfile?.role?.roleName == 'System Administrator' || userProfile?.role?.roleName == 'Central Staff' ) && <div>
                                    <Dropdown.Item onClick={(e) => onMenuClick(e, '/ILearn/manage/reports')}>Reports</Dropdown.Item>
                                </div>}
                            <Dropdown.Divider />
                        </div>
                    }
                    <Dropdown.Item href="/?logout">Sign Out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}