import { AxiosInstances as AxiosInstance} from './BaseService'

export default {
    getTeachersFromDaap: (locationCode : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Teacher/Daap/${locationCode}`
        }),

        addSchoolLeaders: (req:any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/AddSchoolLeader',
            'data':req
        }),


        getSchoolLeadersDetails: (locationCode : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/GetSchoolLeaders/${locationCode}`
        }),
}