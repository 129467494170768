import React, { useState, useEffect,useRef, useContext } from 'react';
import { Button} from 'react-bootstrap';
import { Grid, GridColumn, GridNoRecords,GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { FormMode } from '../../services/Utility'
import { Year} from '../../services/AppContext';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import IconCourseEnvelop from '../../assets/icons/icon-envelop-course';
import vendorContentService from '../../services/VendorContentService';
import CoursesService from '../../services/CoursesService';
import IAddMultipleCoursesVendorContent from '../../model/IAddMultipleCoursesVendorContent';
import IMultipleCoursesVendorContent from '../../model/IMultipleCoursesVendorContent';
import IVendorContent from '../../model/VendorContent';
import IUnassignedCoursesForVendorContent from '../../model/IUnassignedCoursesForVendorContent';
import ICourses from '../../model/ICourses';

interface Props {
    schoolDBN: string
    closeCourseForVendorContentModal: (error:string, courseForVendorContentModal: IMultipleCoursesVendorContent) => void
}
interface IDropDownVendorContentValue {
    id: number;
    vendorId: number;
    text: string;
  }
const initialDataState = {
    skip: 0,
    take: 10,
};


export default function AddCoursesForVendorContent(courseProps: Props) {
    const mountedRef = useRef(true);
    const { orginalSchoolYear } = useContext(Year);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [vendorContentDefault, setVendorContentDefault] = useState<IDropDownVendorContentValue>();
    const [unassignedCourses, setUnassignedCourses] = useState<IUnassignedCoursesForVendorContent[]>([]);
    const [checkedCourses, setCheckedCourses] = useState<ICourses[]>([]);
    const [selectedVendorContent, setSelectedVendorContent] = useState<IVendorContent>();
    const [vendorContentList, setVendorContentList] = useState<IDropDownVendorContentValue[]>([]);
    const [originalVendorContentList, setOriginalVendorContentList] = useState<IDropDownVendorContentValue[]>([]);
    const [page, setPage] = React.useState(initialDataState);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };
    const [filter, setFilter] = useState(initialFilter);



    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const GetStatusBadgeCssClass = (status: string) => {

        if (status.toLowerCase() === "new") {
            return "text-gray"
        }
        else if (status.toLowerCase() === "edited") {
            return "text-secondary"
        }
        else if (status.toLowerCase() === "published") {
            return "text-success"
        }
        else if (status.toLowerCase() === "closed") {
            return "text-danger"
        }
        
    }

    const getVendorContentData = (school:any) => {
        vendorContentService.getVendorContentByLocationCode(school).then(response => {
            let dropDownData = response.data.map((sl: any) => ({
                id: sl.vendorContentId,
                vendorId: sl.vendorId,
                text: sl.vendorContentName + ' -- ' + sl.vendorName
            }));
            if(dropDownData){
            setVendorContentDefault(dropDownData[0])
            }
            setVendorContentList(dropDownData)
            setOriginalVendorContentList(dropDownData)
        });
    }

    const filterData = (filter: any) => {
        let data = originalVendorContentList.slice();
        return filterBy(data, filter);
    }
    const filterChange = (event: any) => {
        let data = filterData(event.filter)
        setVendorContentList(data)
    }

    const handleChange = (event: any) => {
        let selectedVendorContent = event.target.value;
        let vendorcontent:IVendorContent={
            id: selectedVendorContent.id,
            vendorCourseCode: '',
            vendorCourseContentName: '',
            vendorId: selectedVendorContent.vendorId,
            vendorName: '',
            TotalCourses: 0,
            TotalStudents : 0,
            isActive: false,
            modifiedBy: ''
        }
        setCheckedCourses([]);
        setSelectedVendorContent(vendorcontent)
        getVendorContentUnassignedCourses(courseProps.schoolDBN,selectedVendorContent.id,selectedVendorContent.vendorId);
    }

    const getVendorContentUnassignedCourses = (locationCode:string,vendorContentId:number,vendorId:number) => {
        CoursesService.getVendorContentUnassignedCourses(locationCode,vendorContentId,vendorId).then(response => {
            setUnassignedCourses(response.data)
        });
    }

    useEffect(() => {
        setCheckedCourses([]);
        getVendorContentData(courseProps.schoolDBN);
        addIndividualCourses();
        return () => { mountedRef.current = false }
    }, [courseProps.schoolDBN]);

    useEffect(() => {
        setCheckedCourses([]);
        if(vendorContentDefault){
        getVendorContentUnassignedCourses(courseProps.schoolDBN,vendorContentDefault.id,vendorContentDefault?.vendorId)
        let vendorcontent:IVendorContent={
            id: vendorContentDefault.id,
            vendorCourseCode: '',
            vendorCourseContentName: '',
            vendorId: vendorContentDefault.vendorId,
            vendorName: '',
            TotalCourses: 0,
            TotalStudents : 0,
            isActive: false,
            modifiedBy: ''
        }
        setSelectedVendorContent(vendorcontent)
    }
    }, [vendorContentDefault,courseProps.schoolDBN]);



    const addIndividualCourses = () => {
        setPage(initialDataState)
    }

    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        let selectedCourses = checkedCourses
        if (dataItem.isSelected) {
            selectedCourses.push(dataItem)

            setCheckedCourses(selectedCourses)
        } else {
            selectedCourses = checkedCourses.filter(item => item.courseId !== dataItem.courseId);

            setCheckedCourses(selectedCourses)
        }
    }

    const onSave = (event: any, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.New:
                let addMultipleCoursesVendorContent: IAddMultipleCoursesVendorContent = {
                    //sharedCourseUniqueId: courseProps.courseId,
                    courseId: checkedCourses.map(i => i.courseId
                        ),
                    schoolDBN: courseProps.schoolDBN,
                    vendorContentId: selectedVendorContent?.id,
                    vendorId: selectedVendorContent?.vendorId,
                    schoolYear :+orginalSchoolYear.id,
                }

                let multipleCoursesVendorContent: IMultipleCoursesVendorContent = {
                    //sharedCourseUniqueId: courseProps.courseId,
                    courseId: addMultipleCoursesVendorContent.courseId,
                    schoolDBN: addMultipleCoursesVendorContent.schoolDBN,
                    vendorContentId: addMultipleCoursesVendorContent.vendorContentId,
                    vendorId: addMultipleCoursesVendorContent.vendorId,
                    vendorContentName: '',
                    vendorName: '',
                    availableLicenses: 0,
                    totalLicenses: 0,
                    statusCode : ''
                    
                }

                if (addMultipleCoursesVendorContent.courseId?.length === 0) {
                    setErrorMsg('Please select courses to add.');
                    return;
                }

                CoursesService.postMultipleCoursesVendorContent(addMultipleCoursesVendorContent).then(res => {
                    courseProps.closeCourseForVendorContentModal('',multipleCoursesVendorContent)
                    //setFormMode(FormMode.None);
                   // courseProps.updateCourseInfo(courseProps.courseId, courseTeachers.flat(), result, "add")
                })

                break;
            default: {
                let multipleCoursesVendorContent: IMultipleCoursesVendorContent = {
                    //sharedCourseUniqueId: courseProps.courseId,
                    courseId: [],
                    schoolDBN: '',
                    vendorContentId: 0,
                    vendorId: 0,
                    vendorContentName: '',
                    vendorName: '',
                    availableLicenses: 0,
                    totalLicenses: 0,
                    statusCode : ''
                    
                }
                setCheckedCourses([]);
                courseProps.closeCourseForVendorContentModal('',multipleCoursesVendorContent)
                break;

            }
        }
    }


    return (
        <div>
             <Dialog title={"Add Vendor Content To Courses"} onClose={(e) => onSave(e, FormMode.None)}  style={{ width: "75%", position: "fixed", left: "15%", top: "20%", height: "350px" }} >
                <div id="dropDownParent">
                <div hidden={errorMsg.length === 0}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMsg}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" >
                        <div className="text-sm-right text-left">
                            <DropDownList
                                data={vendorContentList}
                                defaultValue={vendorContentDefault}
                                textField="text"
                                filterable={true}
                                onChange={handleChange}
                                onFilterChange={filterChange}
                                popupSettings={{ appendTo: document.getElementById("dropDownParent") as HTMLElement }}
                                style={{ maxWidth: '500px', width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="mt-3" style={{ height: "400px" }}>
                        <Grid
                            data={filterBy(unassignedCourses,filter).slice(page.skip, page.take + page.skip)}
                            filter={filter}
                            filterable={true}
                            skip={page.skip}
                            take={page.take}
                            total={unassignedCourses.length}
                            pageable={true}
                            scrollable={"none"}
                            onPageChange={pageChange}
                            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                        >
                            <GridColumn field="isSelected" title=" " width="40px" filterable={false} cell={props => (
                                <td>
                                    <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                                </td>
                            )} />

                            <GridColumn field="UserIcon" title=" " filterable={false} width="50px" cell={
                                props => (
                                    <td className="pr-0">
                                        <div className="d-flex">
                                            <div className={`svg-app-icon ${props.dataItem.courseStatus.toLowerCase() === "published" ? " text-success" : " text-blue-gray"} mr-3"`} style={{ height: "50px" }}>
                                                <IconCourseEnvelop courseType={props.dataItem.courseType} courseOrginType={props.dataItem.courseSource} merge={0}></IconCourseEnvelop>
                                            </div>
                                        </div>
                                    </td>
                                )} />
                            <GridColumn field="courseName" title='Course Name' />
                            <GridColumn field="courseStatus" title='Course Status'filterable={true} cell={
                                props => (
                                    <td>
                                        <div ><span className={GetStatusBadgeCssClass(props.dataItem.courseStatus)}> {props.dataItem.courseStatus} </span></div>
                                    </td>
                                )} />
                            <GridNoRecords>
                                         There are no courses available to assign to the selected vendor's content.
                            </GridNoRecords>
                        </Grid>
                    </div>
                </div>
                <DialogActionsBar>
                    <Button className="m-3" variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, FormMode.New)}>Add Vendor Content  </Button>
                </DialogActionsBar>
            </Dialog>
        </div>
    )
}