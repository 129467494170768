import { useCallback, useEffect, useRef, useState } from "react";
import { IStaffInfo } from '../../model/IStudentAndStaffInfo';
import studentAndStaffService from '../../services/StudentAndStaffService'
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import IconPersonCircle from "../../assets/icons/icon-person-circle";
import StaffCoursesDetails from './StaffCoursesDetails';
import SplashScreen from '../../shared/SplashScreen'

interface Props {
    locationCode: string,
    schoolYear: number,
    searchNameOrId: string,
    searchForData: boolean
}

const initialDataState = {
    skip: 0,
    take: 10,
};



export default function Staff(props: Props) {
    const [data, setRowData] = useState<IStaffInfo[]>([]);
    const [page, setPage] = useState(initialDataState);
    const [searchForData, setSearchForData] = useState(false);
    const [search, setSearch] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [pageAlert, setPageAlert] = useState<string>('');
    const mountedRef = useRef(true)



    const pageChange = (event: any) => {
        setPage(event.page);
        let currentPage = (event.page.skip / event.page.take) + 1
        getStaffDataByLocationCode(currentPage, event.page.take, props.searchNameOrId)
    };



    const onGridExpand = (event: any) => {
        let newData = data.map(item => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setRowData(newData);
    }

    const SubGridHost = (courses: any) => {
        return (
            <div>
                <StaffCoursesDetails id={courses.dataItem.id} locationCode={props.locationCode} />
            </div>
        )
    }


    const getStaffDataByLocationCode = async (pageNumber: number, pageSize: number, search: string) => {
        if (props.schoolYear != 0 && props.locationCode != '') {
            setIsLoading(true)
            if (search == "") {
                await studentAndStaffService.getStaffByLocationCode(props.locationCode, props.schoolYear, pageNumber, pageSize).then(response => {
                    setRowData(response.data);
                    setIsLoading(false)
                    setPageAlert('')
                    mountedRef.current = false
                }).catch((ex) => {
                    setRowData([]);
                    setIsLoading(false)
                    setPageAlert('Oops!, Something went wrong. Please try again.')
                    setTimeout(() => { setPageAlert('') }, 15000);
                })

            } else {
                await studentAndStaffService.SearchStaffByLocationCode(props.locationCode, props.schoolYear, pageNumber, pageSize, search.replaceAll(' ', '')).then(response => {
                    setRowData(response.data);
                    setSearch(true)
                    setIsLoading(false)
                    setPageAlert('')
                    mountedRef.current = false
                }).catch((ex) => {
                    setRowData([]);
                    setSearch(true)
                    setIsLoading(false);
                    setPageAlert('Oops!, Something went wrong. Please Try searching by first name last name or search by EIN or OSIS number if available.')
                    setTimeout(() => { setPageAlert('') }, 15000);
                })
            }
        }
    }

    useEffect(() => {
        setPage(initialDataState);
        setSearchForData(false)
        getStaffDataByLocationCode(1, initialDataState.take, '')
        return () => { mountedRef.current = false }
    }, [props.locationCode, props.schoolYear]);

    useEffect(() => {
        
        if(mountedRef.current == false){
            setPage(initialDataState);
            setSearchForData(false)
            getStaffDataByLocationCode(1, initialDataState.take, props.searchNameOrId)
        }   
    }, [props.searchForData]);

    return (
        <div>
            {pageAlert && pageAlert != "" && <div className="alert alert-danger" role="alert">
                {pageAlert}
            </div>}
            {loading && <div className="d-flex justify-content-center"><SplashScreen /></div>}


            <div className="card">
                <div className="card-header font-weight-bold bg-secondary-light">
                    <div className="row align-items-center">
                        <div className="col-10">
                            {"Enrolled staff at" + ' ' + props.locationCode + ' ' + '(' + (data && data.length > 0 ? data[0].totalPages : 0) + ')'}
                        </div>

                    </div>
                </div>
                <div className="card-body">

                    <Grid
                        data={data.slice(0, page.take)}
                        skip={page.skip}
                        take={page.take}
                        total={data && data.length > 0 ? data[0].totalPages : 0}
                        scrollable={"none"}
                        onPageChange={pageChange}
                        expandField="expanded"
                        onExpandChange={onGridExpand}
                        detail={SubGridHost}
                        pageable={{ pageSizes: [10, 25, 50], info: true }}
                        className="k-grid-no-header"
                    >
                        <GridNoRecords>
                            No Records
                            {props.searchNameOrId && props.searchNameOrId != "" && search &&
                                <div style={{ color: "#8D8887", fontFamily: 'Roboto, sans-serif' }}><small>Try searching by first name last name or search by EIN or OSIS number if available</small> </div>}
                        </GridNoRecords>
                        <GridColumn width="80px" cell={
                            props => (
                                <td>
                                    <div className="d-flex">
                                        <div className="svg-app-icon text-rust mr-3" style={{ height: "40px" }}>
                                            <IconPersonCircle />
                                        </div>
                                    </div>
                                </td>
                            )} />

                        <GridColumn field="" cell={
                            props => (
                                <td>
                                    <div>{props.dataItem.fullName}</div>
                                    <div className="small"> EIN:{props.dataItem.id}</div>
                                </td>
                            )} />



                        <GridColumn field="" title="" cell={
                            props => (
                                <td>
                                    <div className="small">Courses</div>
                                    <div>{props.dataItem.courseCount}</div>
                                </td>
                            )} />

                        <GridColumn field="" cell={
                            props => (
                                <td>
                                    <>
                                        <div className="small">Username:</div>
                                        <div>{props.dataItem.emailId}</div>
                                    </>
                                </td>
                            )} />
                    </Grid>
                </div>
            </div>
        </div >
    )
}