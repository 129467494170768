import { AxiosInstances as AxiosInstance, setupInterceptorsTo } from './BaseService'

const axiosInstance = setupInterceptorsTo(AxiosInstance)
export default {
    getData: () =>
        axiosInstance({
            'method': 'GET',
            'url': '/Menu',
        })

}