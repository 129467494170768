export default function GridHierarchyExpand(props: any)
{
  let dataItem = props.dataItem
  return(
      <td className="k-hierarchy-cell">
        {dataItem.expanded 
        ? <a onClick={() => props.expandChange(dataItem)} className="k-icon k-minus"></a> 
        : <a onClick={() => props.expandChange(dataItem)} className="k-icon k-plus"></a>}
      </td>
    )
}
 