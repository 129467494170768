import React, { useState, useEffect, useContext } from 'react';
import ICourseEnrollmentSummaryReport from '../../model/ICourseEnrollmentSummaryReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';


interface ILocation {
    changeExcelRef: any;
    
}

const initialDataState = {
    skip: 0,
    take: 10,
};



const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

const initialSort: Array<SortDescriptor> = [
    { field: "studentCount", dir: "desc" },
];

export default function CourseEnrollmentSummaryReport(props: ILocation) {
    const { orginalSchoolYear } = useContext(Year);
    const [page, setPage] = React.useState(initialDataState);
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<ICourseEnrollmentSummaryReport[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);

    const pageChange = (event: any) => {
        setPage(event.page);
    };


    const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
    }

    const GetPublishedCoursesReport = () => {
        ReportsService.GetCourseEnrollmentSummaryReport(+orginalSchoolYear.id).then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetPublishedCoursesReport();
    }, [orginalSchoolYear.id]);

    const GridColumnDetails = (props: any) => {
        return (
            <td>
                <div>
                    {moment(props.dataItem.createdOn).format('MM/DD/YYYY')}
                </div>
            </td>
        )
    }


    return (
        <ExcelExport data={rowData} ref={_export} fileName={"Course_Enrollment_Summary_Report" + ' ' + new Date().toDateString()}>
            <Grid
                data={filterBy(orderBy(rowData, sort), filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50, 100], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    if (e.sort.length === 0) {
                        if (sort.length != 0 && sort[0].dir == "asc") {
                            setSort([{ field: "studentCount", dir: "desc" }])
                        } else {
                            setSort([{ field: "studentCount", dir: "asc" }])
                        }
                    } else {
                        setSort(e.sort);
                    }
                }}
                filterable={true}
                filter={filter}
                onFilterChange={onFilterChange}
                scrollable={"none"}>
                <GridColumn field="locationCode" filterable={true} title="Location Code"></GridColumn>
                <GridColumn field="courseName" filterable={true} title="Course Name"></GridColumn>
                <GridColumn field="courseStatus" filterable={false} title="Course Status"></GridColumn>
                <GridColumn field="courseType" filterable={false} title="Course Type"></GridColumn>
                <GridColumn field="studentCount" filterable={false} title="Student Count"></GridColumn>
                <GridColumn field="createdOn" cell={GridColumnDetails} filterable={false} title="Created On"></GridColumn>

            </Grid>
        </ExcelExport>
    )
}