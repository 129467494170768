import React, { useState, useEffect, useRef } from 'react';
import {  Button, Form,  Row, Col, InputGroup} from 'react-bootstrap';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormMode, IDropDownValue } from '../../services/Utility'
import { RadioButton} from '@progress/kendo-react-inputs';
import ICodeSet from '../../model/Codeset'
import ICourseDetail from '../../model/ICourseDetail';
import Moment from 'moment';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { filterBy} from "@progress/kendo-data-query";


interface ICourseContent {
  courseDetail: ICourseDetail
  purpose: ICodeSet[],
  location: string,
  schoolYear: Number,
  contentType: IDropDownValue[],
  originalTeacherList: IDropDownValue[],
  defaultTeacher: IDropDownValue,
  courseSaveErrorMessage: string,
  teacher: IDropDownValue[],
  setCourseSaveErrorMessage: any,
  formmode: FormMode,
  teacherById: (event: React.MouseEvent<HTMLElement, MouseEvent>, data: string) => void,
  onSearch: (event: React.MouseEvent<HTMLElement, MouseEvent>, data: IDropDownValue[]) => void,
  onSave: (event: React.MouseEvent<HTMLElement, MouseEvent>, actionType: FormMode, data: ICourseDetail) => void

}

export default function AddNewCourse(props: ICourseContent) {
  const mountedRef = useRef(true);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
  const [formValue, setFormValue] = useState<ICourseDetail>(props.courseDetail);
  const [selectedValue, setSelectedValue] = useState("Demo");
  const [searchTeacherEin, setSearchTeacherEin] = useState("");
  const [courseType, setCourseType] = useState<string>();
  const [courseTypes ] = useState([
    { key: 1, value: 'manual', text: 'Manual Course', isDefaultSelected: props.formmode == FormMode.New || (props.courseDetail.courseType && props.courseDetail.courseType.toLowerCase() == "manual") },
    { key: 2, value: 'shared', text: 'Shared Instruction Course', isDefaultSelected: props.courseDetail.courseType && props.courseDetail.courseType.toLowerCase() == "shared"  },
  ])
  const [purposeList, setPurposeList] = useState<ICodeSet[]>(props.purpose);
  const [contentTypeList, setContentTypeList] = useState<IDropDownValue[]>([]);
 

  const handleRadioChange = React.useCallback(
    (e: any) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const excludeDemoPurpos = () =>{
    return  props.purpose.filter(p=>p.displayText!="Demo")
  }

  useEffect(() => {
    setFormMode(props.formmode)
    if(props.courseDetail.courseType && props.courseDetail.courseType.toLowerCase() == "shared"){
      let purposList = excludeDemoPurpos()
      setPurposeList(purposList)
    }else{
      setPurposeList(props.purpose)
    }
    
    
    if (props.courseDetail.purpose) {
      setSelectedValue(props.courseDetail.purpose)
    }
    setContentTypeList(props.contentType)
    setFormValue(props.courseDetail)
    setFormValue({ ...formValue, purpose: selectedValue });
    let type = props.courseDetail.courseType ? props.courseDetail.courseType.toLowerCase() : "manual"
    
    
    setCourseType(type)
    if (props.formmode == FormMode.New) {
      setFormValue({ ...formValue, schoolDBN: props.location, schoolYear: +props.schoolYear });
    }

    return () => { mountedRef.current = false }
  }, [props.courseDetail.purpose , props.purpose]);

  useEffect(() => {

    if (props.defaultTeacher.id) {
      setFormValue({ ...formValue, teacherOfRecord: props.defaultTeacher.id });
    }
    return () => { mountedRef.current = false }
  }, [props.defaultTeacher]);

  useEffect(() => {
    let error = {
      courseTitleError: '',
      purposeError: '',
      courseContentTypeError: '',
      teacherOfRecordError: '',
      startDateError: '',
      endDateError: '',
      courseNameDublicateError: ''

    };
    error.courseNameDublicateError = props.courseSaveErrorMessage
    setErrors(error);

  }, [props.courseSaveErrorMessage]);

  const handleChange = (event: any) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, [target.name]: target.value });

  };
  const contentTypeChange = (event: DropDownListChangeEvent) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, courseContentType: target.value.text });

  };
  const teacherChange = (event: DropDownListChangeEvent) => {
    const target = event.target //as HTMLSelectElement
    setFormValue({ ...formValue, teacherOfRecord: target.value.id });
  };

  const [errors, setErrors] = useState({
    courseTitleError: '',
    purposeError: '',
    courseContentTypeError: '',
    teacherOfRecordError: '',
    startDateError: '',
    endDateError: '',
    courseNameDublicateError: ''
  })

  const validate = () => {
    let error = {
      courseTitleError: '',
      purposeError: '',
      courseContentTypeError: '',
      teacherOfRecordError: '',
      startDateError: '',
      endDateError: '',
      courseNameDublicateError: ''

    };
    if (!formValue.courseName) { error.courseTitleError = "Course Title is required"; }
    const regex = /[!@#$%^&*~`\\]+/;
    if (formValue.courseName && regex.test(formValue.courseName)) { error.courseTitleError = "Some characters are not allowed in course title for example : !@#$%^&*\\~` "; }
    if (!formValue.courseContentType) { error.courseContentTypeError = "Course Content is required"; }

    if (!formValue.teacherOfRecord) { error.teacherOfRecordError = "Teacher is required"; }
    if (!formValue.endDate){ error.endDateError = "Course End date is required."}
    if (!formValue.startDate){ error.startDateError = "Course Start date is required."}
    if (Moment(formValue.endDate).format('YYYY-MM-DD') < Moment(formValue.startDate).format('YYYY-MM-DD')) { error.endDateError = "End date must not be less than start date." }
    if (Moment(formValue.endDate).format('YYYY-MM-DD') <= Moment(Date.now()).format('YYYY-MM-DD')) { error.endDateError = "End date must be greater than today's date." }


    setErrors(error);
    if (error.courseTitleError || error.purposeError || error.courseContentTypeError || error.teacherOfRecordError || error.startDateError || error.endDateError) {
      return false;
    }

    return true;
  };

  const onSave = (event: React.MouseEvent<HTMLElement>, actionType: FormMode) => {
    let formData: ICourseDetail = {
      courseId: Number(formValue.courseId),
      schoolDBN: formValue.schoolDBN,
      schoolYear: formValue.schoolYear,
      courseName: formValue.courseName,
      courseSource: formValue.courseSource,
      isMerged: Number(formValue.isMerged),
      purpose: selectedValue,
      mergeCourses: formValue.mergeCourses,
      courseContentType: formValue.courseContentType,
      teacherOfRecord: formValue.teacherOfRecord,
      courseType : courseType,
      startDate: formValue.startDate,
      endDate: formValue.endDate,
      createdBy: formValue.createdBy,
      createdOn: formValue.createdOn,
      courseStatus: formValue.courseStatus,
      oldTeacherId: formValue.oldTeacherId,
      courseUniqueId: props.courseDetail.courseUniqueId,
      mergedOn: new Date(),
      mergedBy: ''
    };
    let error = {
      courseTitleError: '',
      purposeError: '',
      courseContentTypeError: '',
      teacherOfRecordError: '',
      startDateError: '',
      endDateError: '',
      courseNameDublicateError: ''

    };
    switch (actionType) {
      case FormMode.New:
        setFormMode(FormMode.New)
        // messageService.edit(message).then(response => {
        //   // Display Success message
        // })
        event.preventDefault();
        if (validate()) {
          props.onSave(event, FormMode.New, formData);
          error.courseNameDublicateError = props.courseSaveErrorMessage
          setErrors(error);
        }
        break;
      case FormMode.Edit:
        setFormMode(FormMode.Edit)
        // messageService.edit(message).then(response => {
        //   // Display Success message
        // })
        event.preventDefault();
        if (validate()) {
          props.onSave(event, FormMode.Edit, formData);
          error.courseNameDublicateError = props.courseSaveErrorMessage
          setErrors(error);

        }
        break;
      default:
        props.onSave(event, FormMode.None, formData);
        error.courseNameDublicateError = ""
        props.setCourseSaveErrorMessage("")
        setErrors(error);
        break;

    }
  }
  const defaultItem = {
    text: props.courseDetail.courseContentType
  };

  const filterData = (filter: any) => {
    let data = props.originalTeacherList.slice();
    return filterBy(data, filter);
  }

  const filterChange = (event: any) => {
    let data = filterData(event.filter)
    props.onSearch(event, data);
  }

  const searchTeacherChange = (event: any) => {
    const target = event.target
    setSearchTeacherEin(target.value)
  };

  const onTeacherSearch = (event: any) => {
    if (searchTeacherEin != '') {
      props.teacherById(event, searchTeacherEin)
    }
  }

  const changeCourseType = (e: any) => {
    
    if(e.value == "shared"){
      let purposList = excludeDemoPurpos()
      setPurposeList(purposList)
    }else{
      setPurposeList(props.purpose)
    }
    setSelectedValue("Credit Bearing")
    setCourseType(e.value)
  }

  return (
    <div>
      <Dialog title={formMode == FormMode.Edit ? "Edit Course" : "Add Course"} onClose={(e: any) => onSave(e, FormMode.None)} height={600}>
        <Form>
          {!(formMode == FormMode.Edit  && props.courseDetail.courseSource.toLowerCase() == 'stars') &&
          <div style={{ backgroundColor: "white", padding: "10px" }}>
            <Form.Group controlId="courseType">
              {courseTypes.map((item) => (
                <Form.Label className="mr-2" key={item.key}>
                  <RadioButton hidden={false} disabled={formMode == FormMode.Edit ? true : false} key={item.key} defaultChecked={item.isDefaultSelected? item.isDefaultSelected :  false} name="courseType" value={item.value} label={item.text.toString()} onChange={changeCourseType} ></RadioButton>
                </Form.Label>
              ))}
              <p style={{ color: "#707782", fontSize: "13px" , margin:"2px"}}>
                {courseType == 'manual' ? "Enroll students manually. Student enrollments will not be auto-synced." : "Enroll students from school courses. Student enrollments will be auto-synced."}
              </p>
            </Form.Group>
          </div> }
          <Form.Group controlId="courseName">
            <Form.Label>Course Title</Form.Label>
            <Form.Control type="text" name="courseName" value={formValue.courseName ?? ''} onChange={handleChange} />
            <Form.Text className="text-danger">{errors.courseTitleError}</Form.Text>
            <Form.Text className="text-danger">{errors.courseNameDublicateError}</Form.Text>
          </Form.Group>
          <Form.Label>Purpose</Form.Label>
          <Form.Group>
            {purposeList.map((purposeOptions) => (
              <Form.Label className="mr-2" key={purposeOptions.id}>
                <RadioButton hidden={false} disabled={(formMode == FormMode.Edit && props.courseDetail.courseSource.toLowerCase() == 'stars') ? true : false} key={purposeOptions.id} defaultChecked={true} name="purpose" checked={selectedValue === purposeOptions.displayText} value={purposeOptions.displayText} label={purposeOptions.displayText.toString()} onChange={handleRadioChange}></RadioButton>
              </Form.Label>
            ))}
          </Form.Group>
          <Form.Group controlId="contentType">
            <Form.Label>Course Content Type</Form.Label>
            <DropDownList
              style={{ width: '100%' }}
              defaultValue={defaultItem}
              disabled={(formMode == FormMode.Edit && props.courseDetail.courseSource.toLowerCase() == 'stars') ? true : false}
              data={props.contentType}
              textField="text"
              onChange={contentTypeChange}
            />
            <Form.Text className="text-danger">{errors.courseContentTypeError}</Form.Text>
          </Form.Group>
          <Form.Group controlId="teacherOfRecord">
            <Form.Label>Teacher Of Record</Form.Label>
            <DropDownList
              style={{ width: '100%' }}
              defaultValue={props.defaultTeacher}
              disabled={(formMode == FormMode.Edit && props.courseDetail.courseSource.toLowerCase() == 'stars') ? true : false}
              data={props.teacher}
              textField="text"
              value={props.teacher.find(x => x.id == formValue.teacherOfRecord)}
              filterable={true}
              onFilterChange={filterChange}
              onChange={teacherChange}
            />
            <Form.Text className="text-danger">{errors.teacherOfRecordError}</Form.Text>
          </Form.Group>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Example text with button addon"
              aria-describedby="basic-addon1"
              disabled={(formMode == FormMode.Edit && props.courseDetail.courseSource.toLowerCase() == 'stars') ? true : false}
              placeholder="Teacher EIN"
              onChange={searchTeacherChange}
            />
            <Button variant="outline-secondary" id="button-addon1" onClick={(e) => onTeacherSearch(e)} >
              Search
            </Button>
          </InputGroup>
          <Row>
            <Col>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" name="startDate" value={Moment(formValue.startDate).format('YYYY-MM-DD')} onChange={handleChange}></Form.Control>
                <Form.Text className="text-danger">{errors.startDateError}</Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" name="endDate" value={Moment(formValue.endDate).format('YYYY-MM-DD')} onChange={handleChange}></Form.Control>
                <Form.Text className="text-danger">{errors.endDateError}</Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <DialogActionsBar>
          <Button className="m-2" variant="secondary" onClick={(e) => onSave(e, FormMode.None)}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </DialogActionsBar>
      </Dialog>

    </div>
  )
}