import React, { useState, useEffect, useContext } from 'react';
import announcementService from '../../services/AnnouncementService';
import IAnnouncement from '../../model/IAnnouncement'
import AdminManageIcon from './AdminManageIcon'
import { ErrorContext, IErrorContext } from '../../services/AppContext';

export default function AnnouncementDisplay() {
  const [announcementData, setAnnouncementData] = useState<IAnnouncement[]>([]);
  const { errorMessages , setErrorMessages } = useContext<IErrorContext>(ErrorContext);

  useEffect(() => {
    announcementService.getRecent().then(response => {
    setAnnouncementData(response.data)  
  }).catch((e) =>{
    setErrorMessages([...errorMessages, 'Data did not load correctly. Try to refresh the page. ' + e])
  })
 }, []);

 let annNo = announcementData.length;
 if (announcementData.length > 0) {
  return (
    <div className="card mb-4 shadow-sm">
        <div className="card-header bg-warning">
            <div className="h3 mb-0 font-weight-normal">
                Announcements
            </div>
            {/* <AdminManageIcon/> */}
        </div>
        <ul className="list-group list-group-flush">
            {
            announcementData.sort().slice((announcementData.length-3) >= 3 
                                    ?(announcementData.length-3) : 0,
                                    announcementData.length).map(data =>
                <li className="list-group-item list-group-item-warning d-flex align-items-top" key={data.id}><i className="far fa-comment-alt fa-2x mr-2"></i> {data.description}</li>
                )
            }
        </ul>
  </div>
  )
 } else {
  return (<></>)
 }
  
}

