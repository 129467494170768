import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Dropdown, Form, Row } from 'react-bootstrap';
import {
    Grid, GridColumn as Column, GridFilterChangeEvent, GridColumnMenuFilter,
    GridColumnMenuProps,
    GridSortChangeEvent,
    GridColumn
} from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, } from "@progress/kendo-data-query";
import schoolService from '../../../services/SchoolService'
import ISchoolSetup from '../../../model/ISchoolSetup'
import IPrincipalInfo from '../../../model/IPrincipalInfo'
import ISchoolDaap from '../../../model/ISchoolDaap'
import { FormMode } from '../../../services/Utility'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { UserProfileType } from '../../../services/AppContext'
import LocalStorageService from '../../../services/LocalStorage';
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import schoolLeaderService from "../../../services/SchoolLeaderService";
import ITeacherFromDaap from "../../../model/ITeachersFromDaap";
import ISchoolLeader from "../../../model/ISchoolLeader"
import { isTemplateSpan } from 'typescript';
import { propTypes } from 'react-bootstrap/esm/Image';
const initialDataState = {
    skip: 0,
    take: 25,
}; 

interface dropDownData {
    id: string
    text: string
}

const initialSort: Array<SortDescriptor> = [
    { field: "locationCode", dir: "asc" },
];



export default function SchoolManage() {

    const mountedRef = useRef(true);
    const [data, setRowData] = useState<ISchoolSetup[]>([]);
    const [principalInfo, setPrincipalInfo] = useState<IPrincipalInfo>({
        id: 0,
    locationName: '',
    locationCode: '',
    principalFullName: '',
    principalEmail: ''
    });
    const [schoolsFromDaap, setSchoolsFromDaap] = useState<dropDownData[]>([]);
    const [schoolsDaap, setSchoolsDaap] = useState<dropDownData[]>([]);
    let [userProfile, setUserProfile] = useState<UserProfileType>();
    const [schoolList, setSchoolList] = useState<ISchoolDaap[]>([]);

    const [checkedTeachers, setCheckedTeachers] = useState<ISchoolLeader[]>([]);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };
    const [filter, setFilter] = useState(initialFilter);

    // school leaders filter
    const [schoolLeadersFilter, setSchoolLeadersFilter] = useState(initialFilter);

    const [sort, setSort] = React.useState(initialSort);
    const [page, setPage] = React.useState(initialDataState);
    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const [locationCode, setLocationCode] = React.useState("");
    const [teacherList, setTeacherList] = useState<ITeacherFromDaap[]>([]);

    const [headerCheckBoxValue, setHeaderCheckBox] = useState(false);
    const onHeaderCheckBoxClick = (event: any, products?: any) => {
        let checked = event.target.checked;
        if(checked == true){
            data.map((t:any)=>t.isSelected = true)
        }else{
            data.map((t:any)=>t.isSelected = false)
        }
        setHeaderCheckBox(checked)
    }
    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        if (dataItem.isSelected) {
            let items: ISchoolLeader = {
                staffID: dataItem.pmsId,
                email: dataItem.doeEmail ? dataItem.doeEmail : "",
                firstName: dataItem.firstName,
                lastName: dataItem.lastName,
                actionCode: "A",
                createdBy: LocalStorageService.getUserProfile().userName,
                loginID: dataItem.doeEmail ? dataItem.doeEmail.substring(0, dataItem.doeEmail.indexOf("@")) : "",
            }
            if (items.email == "") {
                setErrors("Email is required")
                dataItem.isSelected = !dataItem.isSelected
                return
            } else {
                setCheckedTeachers([...checkedTeachers, items])
            }

        } else {
            const items = checkedTeachers.filter(item => item.staffID !== dataItem.pmsId);
            setCheckedTeachers(items);
        }
    }

    const addSchoolLeader = (props: any) => {

        let schoolLeader = {
            schoolLeader: checkedTeachers,
            associatedLocation: locationCode
        }
        schoolLeaderService.addSchoolLeaders(schoolLeader).then(res => {
            if (res.data.isValidResponse == true) {
                setSchoolLeaderVisible(!schoolLeaderVisible);
                setCheckedTeachers([])
                getSchoolData()
                setErrors("")
            } else {
                setErrors(res.data.errors[0])
            }
        }, error => setErrors("Unable to add school leader to D2L"))
    }

    const [visible, setVisible] = React.useState(false);

    const toggleDialog = () => {
        setVisible(!visible);
        let addSchoolDetails: ISchoolDaap = {
            schoolName: '',
            districtNumber: '',
            boroughCode: '',
            locationCode: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            principalName: '',
            principalTitle: '',
        };
        setFormValue(addSchoolDetails)
        setErrors("")
    };

    const [schoolLeaderVisible, setSchoolLeaderVisible] = React.useState(false);

    const toggleSchoolLeaderDialog = (props: any) => {
        if (schoolLeaderVisible == false) {
            setLocationCode(props.dataItem.locationCode)
            schoolLeaderService.getTeachersFromDaap(props.dataItem.locationCode).then(res => {
                setTeacherList(res.data)
            })
        }
        setSchoolLeaderVisible(!schoolLeaderVisible);
        setCheckedTeachers([])
        setErrors("")
    };

    const [principalUpdateVisible, setPrincipalUpdateVisible] = React.useState(false);

    const togglePrincipalUpdateDialog = (props: any) => {
        let data:IPrincipalInfo={
            id: 0,
        locationName: '',
        locationCode: '',
        principalFullName: '',
        principalEmail: ''
        }
        setPrincipalInfo(data)
        if (principalUpdateVisible == false) {
            setLocationCode(props.dataItem.locationCode)
            schoolService.getPrincipalInfoBySchoolDBN(props.dataItem.locationCode).then(res => {
                setPrincipalInfo(res.data)
            })
        }
        setPrincipalUpdateVisible(!principalUpdateVisible);
        setErrors("")
    };


    const [schoolLeaderListVisible, setSchoolLeaderListVisible] = React.useState(false);
    const [schoolLeaderList, setSchoolLeaderList] = useState<ITeacherFromDaap[]>([]);

    const toggleSchoolLeaderListDialog = (props: any) => {
        if (schoolLeaderListVisible == false) {
            setLocationCode(props.dataItem.locationCode)
            schoolLeaderService.getSchoolLeadersDetails(props.dataItem.locationCode).then(res => {
                setSchoolLeaderList(res.data)
            })
        } else {
            setSchoolLeaderList([])
            setErrors("")
        }
        setSchoolLeaderListVisible(!schoolLeaderListVisible);

    };

    const getSchoolData = () => {
        schoolService.getData().then(response => {
          
            let data = response.data.map((s: any) => ({
                schoolDetails: s.locationCode + s.schoolName,
                locationCode: s.locationCode,
                schoolName: s.schoolName,
                id: s.id,
                districtCode: s.districtCode,
                boroughCode: s.boroughCode,
                address: s.address,
                principalFullName: s.principalFullName,
                learningEnvironmentID: s.learningEnvironmentID,
                createdBy: s.createdBy,
                schoolLeadersCount: s.schoolLeadersCount,
            }))
            setRowData(data)
        })
        return () => { mountedRef.current = false }
    }



    const getSchoolDataFromDaap = () => {
        schoolService.getSchoolsFromDaap().then(response => {
            let data = response.data.map((sl: any) => ({
                id: sl.locationCode,
                text: sl.locationCode + ' -- ' + sl.schoolName
            }))
            setSchoolsFromDaap(data)
            setSchoolsDaap(data)
            setSchoolList(response.data)
        })
        return () => { mountedRef.current = false }
    }

    useEffect(() => {
        getSchoolData();
        getSchoolDataFromDaap()
        return () => { mountedRef.current = false }
    }, []);



    const GridColumnButton = (props: any) => {
        return (
            <td style={{ overflow: 'visible' }}>
                <Dropdown>
                    <Dropdown.Toggle className="menu-bars" size="sm" variant="outline-primary" id="dropdown-basic">
                        <i className="fas fa-bars"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { toggleSchoolLeaderDialog(props) }}>Add School Leaders</Dropdown.Item>
                        <Dropdown.Item onClick={() => { togglePrincipalUpdateDialog(props) }}>Update Principal</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>)
    }


    const GridColumnHighLightText = (props: any) => {
        return (
            <td>
                <div className="d-flex">
                    <div>
                        <div className="text-dark mb-0 h4 p-0 font-weight-bold">{props.dataItem.locationCode} - {props.dataItem.schoolName}</div>
                        <div style={{ color: "#707782", margin: "2px" }}>{props.dataItem.address}</div>
                        <div style={{ color: "#707782" }}> Principal: {props.dataItem.principalFullName}</div>
                    </div>
                </div>
            </td>
        )
    }
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [formValue, setFormValue] = useState<ISchoolDaap>({
        schoolName: '',
        districtNumber: '',
        boroughCode: '',
        locationCode: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        principalName: '',
        principalTitle: '',
    });


    const filterData = (filter: any) => {
        let data = schoolsFromDaap.slice();
        return filterBy(data, filter);
    }

    const filterChange = (event: any) => {
        let data = filterData(event.filter)
        setSchoolsDaap(data)
    }

    const handleChange = (event: any) => {
        setErrors("");
        let school = schoolList.find(s => s.locationCode == event.target.value.id)
        if (school) {

            let addSchoolDetails: ISchoolDaap = {
                schoolName: school.schoolName,
                districtNumber: school.districtNumber,
                boroughCode: school.boroughCode,
                locationCode: school.locationCode,
                addressLine1: school.addressLine1,
                addressLine2: school.addressLine2,
                city: school.city,
                principalName: school.principalName,
                principalTitle: school.principalTitle,
            };
            setFormValue(addSchoolDetails)
        }
    };
    const handlePrincipalChange = (event: any) => {
        if (event.target.name == 'principalFullName') {
            let data ={
                "id":principalInfo?.id,
                "locationCode": principalInfo?.locationCode,
                "locationName": principalInfo?.locationName,
                "principalFullName": event.target.value,
                "principalEmail": principalInfo.principalEmail,
            }
            setPrincipalInfo(data)
        }
        if (event.target.name == 'principalEmail') {
            let data ={
                "id":principalInfo?.id,
                "locationCode": principalInfo?.locationCode,
                "locationName": principalInfo?.locationName,
                "principalFullName": principalInfo?.principalFullName,
                "principalEmail": event.target.value,
            }
            setPrincipalInfo(data)
        }
    };
    const onFormAction = (schoolId: number, actionType: FormMode) => {
        setErrors("");
        switch (actionType) {
            case FormMode.New:
                getSchoolDataFromDaap();
                if (schoolList && schoolList.length > 0) {
                    let addSchoolDetails: ISchoolDaap = {
                        schoolName: schoolList[1].schoolName,
                        districtNumber: schoolList[1].districtNumber,
                        boroughCode: schoolList[1].boroughCode,
                        locationCode: schoolList[1].locationCode,
                        addressLine1: schoolList[1].addressLine1,
                        addressLine2: schoolList[1].addressLine2,
                        city: schoolList[1].city,
                        principalName: schoolList[1].principalName,
                        principalTitle: schoolList[1].principalTitle,
                    };
                    setFormValue(addSchoolDetails)
                } else {
                    let addSchoolDetails: ISchoolDaap = {
                        schoolName: '',
                        districtNumber: '',
                        boroughCode: '',
                        locationCode: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        principalName: '',
                        principalTitle: '',
                    };
                    setFormValue(addSchoolDetails)
                }
                setFormMode(FormMode.New);
                setErrors("");
                break;

            default: {
                setFormMode(FormMode.None)
                setFormValue({
                    schoolName: '',
                    districtNumber: '',
                    boroughCode: '',
                    locationCode: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    principalName: '',
                    principalTitle: '',
                })
                setErrors("");

                break;
            }
        }
    }

    const onSave = (event: any, actionType: FormMode) => {
        if (!formValue.locationCode || formValue.locationCode == "") {
            setErrors("Please select school ");
            return
        }

        if (!formValue.schoolName || formValue.schoolName == "") {
            setErrors("Please select school ");
            return
        }

        if (!formValue.districtNumber || formValue.districtNumber == "") {
            setErrors("Please select school ");
            return
        }

        if (!formValue.boroughCode || formValue.boroughCode == "") {
            setErrors("Please select school ");
            return
        }
        let checkSchool = data.find(s => s.locationCode == formValue.locationCode)
        if (checkSchool) {
            setErrors("School already setup")
            return
        }
        userProfile = LocalStorageService.getUserProfile()
        let school = {
            "iLearnNYCUserID": userProfile.userName,
            "locationCode": formValue.locationCode,
            "schoolName": formValue.schoolName,
            "districtCode": formValue.districtNumber,
            "boroughCode": formValue.boroughCode,
            "principalFullName": formValue.principalName,
        }
        schoolService.getSchoolOrgFromEndPoint(school)
            .then(response => {
                if (response.data.isValidResponse == true) {
                    toggleDialog();
                    getSchoolData();
                } else {
                    setErrors(response.data.errors)
                }
            }, error => {
                setErrors(error.message)
            });

    }

    const onPrincipalSave = (event: any, actionType: FormMode) => {
        let principal = {
            "id":principalInfo.id,
            "locationCode": principalInfo.locationCode,
            "locationName": principalInfo.locationName,
            "principalFullName": principalInfo.principalFullName,
            "principalEmail": principalInfo.principalEmail
        }
        if (principalInfo.principalFullName==="" && principalInfo.principalEmail==="")
        {
            setErrors("Principal Name and Email are required.")
        }
        else if(principalInfo.principalFullName==="")
        {
            setErrors("Principal Name is required.")
        }
        else if(principalInfo.principalEmail==="")
        {
            setErrors("Principal Email is required.")
        }
        else{
        schoolService.updatePrincipal(principal)
            .then(response => {
                if (response.data.isValidResponse == true) {
                    setPrincipalUpdateVisible(!principalUpdateVisible);
                    getSchoolData();
                } else {
                    setErrors(response.data.errors)
                }
            }, error => {
                setErrors(error.message)
            });
        }

    }
    const [errors, setErrors] = useState("");
    return (
        <div>
            <div className="text-right mb-2">
                <Button variant="primary" onClick={toggleDialog}><i className="fas fa-plus"></i></Button>

            </div>
            <Grid
                data={filterBy(orderBy(data, sort), filter).slice(page.skip, page.take + page.skip)}
                filter={filter}
                filterable={true}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                pageable={true}
                skip={page.skip}
                take={page.take}
                total={data.length}
                onPageChange={pageChange}
                onFilterChange={(e: GridFilterChangeEvent) =>{
                    setFilter(e.filter)
                } }>
                <Column field="School Leaders" width="100px" filterable={false} title=" " cell={
                    props => (
                        <td>
                            <div className="d-flex">
                                <div className="svg-app-icon text-secondary" style={{ width: "50px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.551 50">
                                        <title>School</title>
                                        <g transform="translate(-797.418 -33.62)">
                                            <path d="M828.852,59.886a5.756,5.756,0,1,0,5.757,5.756A5.762,5.762,0,0,0,828.852,59.886Zm0,9.29a3.534,3.534,0,1,1,3.535-3.534A3.537,3.537,0,0,1,828.852,69.176Z" transform="translate(-6.659 -6.811)"></path>
                                            <rect width="2.592" height="3.824" transform="translate(805.741 70.388)"></rect>
                                            <rect width="2.592" height="3.824" transform="translate(805.741 76.316)"></rect>
                                            <path d="M832.658,86.042h-6.995a1.3,1.3,0,0,0-1.3,1.3c0,.013,0,.025,0,.037s0,.025,0,.038v8.458a1.3,1.3,0,1,0,2.592,0V88.634H831.4v7.237a1.3,1.3,0,0,0,2.592,0V87.413a1.409,1.409,0,0,0-1.338-1.371Z" transform="translate(-6.988 -13.593)"></path>
                                            <rect width="2.592" height="3.824" transform="translate(836.055 70.388)"></rect>
                                            <rect width="2.592" height="3.824" transform="translate(836.055 76.316)"></rect>
                                            <path d="M857.286,65.881H844.8V62.4l-2.592-1.341V89.439a1.3,1.3,0,1,0,2.592,0V73.679h12.489a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,857.286,65.881Zm-1.3,5.2H844.84V68.474h11.15Z" transform="translate(-11.614 -7.116)"></path>
                                            <path d="M801.168,94.575a1.3,1.3,0,0,0,2.592,0V81.043h-2.592Z" transform="translate(-0.972 -12.297)"></path>
                                            <path d="M857.067,94.575a1.3,1.3,0,0,0,2.592,0V81.043h-2.592Z" transform="translate(-15.467 -12.297)"></path>
                                            <path d="M811.2,65.863h-12.49a1.3,1.3,0,0,0-1.3,1.3v5.2a1.3,1.3,0,0,0,1.3,1.3H811.2V89.42a1.3,1.3,0,1,0,2.592,0V60.988L811.2,62.329Zm-.042,5.2H800.01V68.455h11.151Z" transform="translate(0 -7.097)"></path>
                                            <path d="M811.656,54.125a1.283,1.283,0,0,0,.595-.146l13.3-6.881,13.3,6.881a1.283,1.283,0,0,0,.595.146,1.3,1.3,0,0,0,.6-2.448l-13.114-6.786a1.293,1.293,0,0,0-.121-.184,1.263,1.263,0,0,0,.026-.256v-2.03l7.394-2.989a1.284,1.284,0,0,0,.226-.119l0,0a1.294,1.294,0,0,0,.253-.227c.018-.02.033-.041.051-.061a1.394,1.394,0,0,0,.178-.3l0-.006c.01-.025.014-.051.023-.076a1.36,1.36,0,0,0,.047-.161c.007-.039.009-.076.013-.114s.012-.09.013-.137a1.226,1.226,0,0,0-.012-.128c0-.041-.005-.081-.013-.123a1.2,1.2,0,0,0-.045-.153c-.009-.027-.013-.056-.024-.083l0-.008a1.177,1.177,0,0,0-.093-.176c-.01-.016-.016-.035-.027-.05a1.293,1.293,0,0,0-.3-.315c-.018-.013-.032-.028-.05-.04s-.049-.026-.073-.04a1.5,1.5,0,0,0-.152-.081l-.009,0L826.1,33.715a1.4,1.4,0,0,0-1.86,1.273v9.464a1.27,1.27,0,0,0,.035.287,1.323,1.323,0,0,0-.1.152L811.06,51.677a1.3,1.3,0,0,0,.6,2.448Zm15.175-17.312,3.463,1.411-3.463,1.4Z" transform="translate(-3.356)"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </td>
                    )} />
                <Column field="schoolDetails" title="Schools" cell={GridColumnHighLightText} />
                <Column filterable={false} cell={
                    props => (
                        <td>

                            <div style={{ cursor: 'pointer' }} onClick={() => toggleSchoolLeaderListDialog(props)} ><b>School Leaders({props.dataItem.schoolLeadersCount ? props.dataItem.schoolLeadersCount : 0})</b></div>

                        </td>
                    )} />
                <Column field="id" title=" " filterable={false} className="text-right" cell={GridColumnButton} width={90} />
            </Grid>
            {visible &&
                <Dialog title={"Add New School"} onClose={toggleDialog}>

                    {/* <Modal.Header closeButton className="bg-primary text-white">
                     <Modal.Title>{formMode == FormMode.Edit ? "Edit" : "New School Setup"}</Modal.Title>
                 </Modal.Header> */}
                    {/* <Modal.Body> */}
                    {
                        errors != "" ?
                            <div className="alert alert-danger" >
                                {errors}
                            </div> : <div></div>
                    }
                    <Row>
                        <div className="form-group col-12">
                            <DropDownList style={{ width: "350px" }}
                                data={schoolsDaap}
                                textField="text"
                                filterable={true}
                                onChange={handleChange}
                                onFilterChange={filterChange}
                            />
                        </div>
                    </Row>

                    <Form>
                        <Row>
                            <Form.Group controlId="principalName" className="col-12">
                                <Form.Label>Principal Name</Form.Label>
                                <Form.Control readOnly type="text" name="principalName" value={formValue.principalName ?? ''} />
                            </Form.Group>
                            <Form.Group controlId="districtNumber" className="col-sm-6">
                                <Form.Label>District Number</Form.Label>
                                <Form.Control readOnly type="text" name="districtNumber" value={formValue.districtNumber ?? ''} />
                            </Form.Group>
                            <Form.Group controlId="boroughCode" className="col-sm-6">
                                <Form.Label>Borough Code</Form.Label>
                                <Form.Control readOnly type="text" name="boroughCode" value={formValue.boroughCode ?? ''} />
                            </Form.Group>
                        </Row>
                    </Form>
                    {/* </Modal.Body> */}
                    <DialogActionsBar>
                        <Button className="m-2" variant="secondary" onClick={toggleDialog}>Close</Button>
                        <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
                    </DialogActionsBar>
                </Dialog>
            }

{principalUpdateVisible &&
                <Dialog title={"Update Principal"} onClose={togglePrincipalUpdateDialog}>
                    {
                        errors != "" ?
                            <div className="alert alert-danger" >
                                {errors}
                            </div> : <div></div>
                    }
                    <Row>
                        <div className="form-group col-12">
                           {principalInfo?.locationCode} - {principalInfo?.locationName}
                        </div>
                    </Row>

                    <Form>
                        <Row>
                            <Form.Group controlId="principalFullName" className="col-12">
                                <Form.Label>Principal Name</Form.Label>
                                <Form.Control required type="text" name="principalFullName" defaultValue={principalInfo.principalFullName}onBlur={handlePrincipalChange} />
                            </Form.Group>
                            <Form.Group controlId="principalEmail" className="col-sm-6">
                                <Form.Label>Principal Email</Form.Label>
                                <Form.Control required type="text" name="principalEmail" defaultValue={principalInfo.principalEmail} onBlur={handlePrincipalChange} />
                            </Form.Group>                     
                        </Row>
                    </Form>
                    <DialogActionsBar>
                        <Button className="m-2" variant="secondary" onClick={togglePrincipalUpdateDialog}>Close</Button>
                        <Button variant="primary" type="button" onClick={(e) => onPrincipalSave(e, formMode)}>Save</Button>
                    </DialogActionsBar>
                </Dialog>
            }


            {schoolLeaderVisible &&
                <Dialog title={"Add New School Leader"} onClose={toggleSchoolLeaderDialog} style={{ width: "75%", position: "fixed", left: "15%" }} >
                    {
                        errors != "" ?
                            <div className="alert alert-danger" >
                                {errors}
                            </div> : <div></div>
                    }

                    <Grid
                        data={filterBy(teacherList, schoolLeadersFilter)}
                        filter={schoolLeadersFilter}
                        filterable={true}
                        onFilterChange={(e: GridFilterChangeEvent) => setSchoolLeadersFilter(e.filter)}
                        style={{ height: '350px' }}>
                        <GridColumn field="isSelected" filterable={false} width="40px" headerCell={props => (<input type="checkbox" checked={headerCheckBoxValue} onChange={(e) => onHeaderCheckBoxClick(e)} />)} cell={props => (
                            <td>
                                <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                            </td>
                        )} />

                        <GridColumn field="firstName" title="First Name" />
                        <GridColumn field="lastName" title="Last Name" />
                        <GridColumn field="doeEmail" title="Email" />

                    </Grid>


                    <DialogActionsBar>
                        <Button className="m-2" variant="secondary" onClick={toggleSchoolLeaderDialog}>Close</Button>
                        <Button variant="primary" type="button" onClick={(e) => addSchoolLeader(e)}>Save</Button>
                    </DialogActionsBar>
                </Dialog>
            }


            {schoolLeaderListVisible &&
                <Dialog title={"School Leaders"} onClose={toggleSchoolLeaderListDialog} style={{ width: "75%", position: "fixed", left: "15%" }} >
                    {
                        errors != "" ?
                            <div className="alert alert-danger" >
                                {errors}
                            </div> : <div></div>
                    }

                    <Grid
                        data={schoolLeaderList}

                        style={{ height: '350px' }}>

                        <GridColumn field="firstName" title="First Name" />
                        <GridColumn field="lastName" title="Last Name" />
                        <GridColumn field="doeEmail" title="Email" />
                    </Grid>
                    <DialogActionsBar>
                        <Button className="m-2" variant="secondary" onClick={toggleSchoolLeaderListDialog}>Close</Button>
                    </DialogActionsBar>
                </Dialog>
            }

        </div>
    )
}