

import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';

import 'hammerjs';
import { CheckIfNYCDOELicense } from '../../helpers/helper';


interface Data {
  kind: string,
  share: number,
  color: string
}

interface SchoolLicenseUsage {
  id: number,
  quantityAllocated: number,
  quantityAssigned: number,
  vendorId: number,
  vendorName: string,
  vendorModel: string
}



function ChartContainer(data: any) {
  let schoolLicenseUsage: SchoolLicenseUsage[] = [];
  let isDOELicense = data.data &&  CheckIfNYCDOELicense(data.data.vendorModel);

  schoolLicenseUsage.push(data.data);
  let schoolLicenseUsageChartData: Data[] = [];
  schoolLicenseUsageChartData.push(
    { kind: "Used", share: schoolLicenseUsage[0].quantityAssigned, color: "#E87511" },
    { kind: "Unused", share: isDOELicense ? Number.MAX_SAFE_INTEGER : (schoolLicenseUsage[0].quantityAllocated - schoolLicenseUsage[0].quantityAssigned), color: "#d4dae3" }
  );

  const donutCenterRender = () => (<span style={{ fontSize: "15px" }}>{schoolLicenseUsage[0].quantityAssigned}/ {isDOELicense ? 'Unlimited' : schoolLicenseUsage[0].quantityAllocated}</span>);

  if (schoolLicenseUsageChartData.length > 0) {
    return (
      <Chart renderAs='svg' style={{ width: "120px", height: "120px" }} donutCenterRender={donutCenterRender}>
        <ChartSeries>
          <ChartSeriesItem type="donut" data={schoolLicenseUsageChartData} outliers={{ rotation: 5 }} categoryField="kind" field="share" color="color" holeSize={45} size={8} />
        </ChartSeries>
        <ChartLegend visible={false} />
      </Chart>
    );
  } else {
    return (
      <div>No Assigned Licenses</div>
    );
  }
}

export default ChartContainer









