import React, { useState, useEffect, useContext } from 'react';
import ILicenseAllocationReport from '../../model/ILicenseAllocationReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import licenseAllocationService from '../../services/LicenseAllocation'
import { LocationContext, Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

interface ILocation {
  id: string,
  changeExcelRef: any
}

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};
const initialSort: Array<SortDescriptor> = [
  { field: "quantityAssigned", dir: "desc" },
];

export default function LicenseAllocationReport(props: ILocation) {
  const { locationCode } = useContext(LocationContext);
  const { orginalSchoolYear } = useContext(Year);
  const [page, setPage] = React.useState(initialDataState);
  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(initialSort);
  const [rowData, setRowData] = useState<ILicenseAllocationReport[]>([]);
  const _export = React.useRef<ExcelExport | null>(null);

  const pageChange = (event: any) => {
    setPage(event.page);
  };



  const GetSchoolLicenseUsageReportForSelectedSchool = () => {
    licenseAllocationService.GetSchoolLicenseUsageReport(locationCode, orginalSchoolYear.id).then(response => {
      setRowData(response.data)
      props.changeExcelRef(_export)
    })
  }

  const GetSchoolLicenseUsageReportForAllSchools = () => {
    licenseAllocationService.GetSchoolLicenseUsageReport(props.id, orginalSchoolYear.id).then(response => {
      setRowData(response.data)
      props.changeExcelRef(_export)
    })
  }

  const GetSchoolLicenseUsageReport = () => {
    if (props.id != "AllSchools") {
      GetSchoolLicenseUsageReportForSelectedSchool()
    } else {
      GetSchoolLicenseUsageReportForAllSchools();

    }
  }


  const onFilterChange = (e: any) => {
    setPage(initialDataState)
    setFilter(e.filter)
  }

  const getQuantityAllocated = (props: any) => {
    if (props.dataItem.unlimittedQuantityAllocated) {
      return <td>Unlimited</td>
    } else {
      return <td>{props.dataItem.quantityAllocated}</td>
    }
  }

  useEffect(() => {
    GetSchoolLicenseUsageReport();
  }, [locationCode, orginalSchoolYear.id, props.id]);


  return (
    <ExcelExport data={rowData} ref={_export} fileName={"License_Allocation_Report" + ' ' + new Date().toDateString()}>
      <Grid
        data={filterBy(orderBy(rowData, sort), filter).slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={rowData.length}
        pageSize={page.take}
        pageable={{ pageSizes: [10, 25, 50 , 100], info: true }}
        onPageChange={pageChange}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          if (e.sort.length === 0) {
            if (sort.length != 0 && sort[0].dir == "asc") {
              setSort([{ field: "quantityAssigned", dir: "desc" }])
            } else {
              setSort([{ field: "quantityAssigned", dir: "asc" }])
            }
          } else {
            setSort(e.sort);
          }

        }}
        filterable={props.id != "AllSchools" ? false : true}
        filter={filter}
        onFilterChange={onFilterChange}
        scrollable={"none"}>
        <GridColumn field="district" filterable={false} title="District"></GridColumn>
        <GridColumn field="locationCode" filterable={props.id != "AllSchools" ? false : true} title="School Location"></GridColumn>
        <GridColumn field="schoolName" filterable={false} title="School Name"></GridColumn>
        <GridColumn field="vendorName" filterable={false} title="Vendor Name"></GridColumn>
        <GridColumn field="quantityAllocated" cell={getQuantityAllocated} filterable={false} title="Quantity Allocated" ></GridColumn>
        <GridColumn field="quantityAssigned" filterable={false} title="Quantity Assigned"></GridColumn>
      </Grid>
    </ExcelExport>
  )
}