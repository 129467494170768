import React, { Component } from 'react';
import AuthService from './AuthService';
//import { useHistory } from "react-router-dom";

export default class SigninCallback extends Component {
    componentDidMount() {
        let authService = new AuthService();
        let mgr = authService.userManager;
        mgr.signinRedirectCallback()
            .then(() => {         
                window.history.replaceState({}, window.document.title, window.location.origin);
                window.location = "/Home" 
            })
            .catch((error) => this.onRedirectError(error));
    }    

    onRedirectError = (error) => {
        window.location ='/'
    };

    render() {
        return (
            <div>
                <h1>Redirecting...</h1>
            </div>
        );
    }
}
