import React, { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import ILicenseSummary from '../../model/ILicenseummary';
import coursesService from '../../services/CoursesService'
import { CheckIfNYCDOELicense } from '../../helpers/helper';

interface ILicenseCourseSummary {
    courseId: number
}

export default function LicenseSummary(props: ILicenseCourseSummary) {
    const mountedRef = useRef(true);
    const [data, setRowData] = useState<ILicenseSummary[]>([]);

    const getLicenseSummary = (courseId: number) => {
        coursesService.getLicenseSummary(courseId).then((response)=>{
            setRowData(response.data)
        })        
    }


    useEffect(() => {
        getLicenseSummary(props.courseId);
        return () => { mountedRef.current = false }
    }, [props.courseId]);

    const GridCellRender = (props: any) => {
        const licenseModel = props.dataItem.licenseModel;
        const availableLicense = props.dataItem.availableLicense;
      
        // Set the available license text based on the license model
        const cellContent = CheckIfNYCDOELicense(licenseModel) ? "Unlimited" : availableLicense;
      
        return <td>{cellContent}</td>;
      };

    const rowRender = (trElement:any, props:any) => {
    
        const hasNotEnoughLicense  = props.dataItem.hasNotEnoughLicense 
       
        const red = {
          backgroundColor: "rgb(243, 23, 0, 0.32)"
        };

        const white= {
            backgroundColor: "rgb(255,255,255)"
        }

        const gray ={
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
        const trProps = {
          style: hasNotEnoughLicense  ?  red : props.isAltRow ? gray : white
        };
        return React.cloneElement(trElement, { ...trProps
        }, trElement.props.children);
      };


    return (
        <div>
            <div className="mt-3">
                <div className="card mt-4">
                    <div className="card-header font-weight-bold bg-secondary-light">
                        <div className="row align-items-center">
                            <div className="col-10">License Summary</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Grid
                            data={data}
                            rowRender={rowRender}
                        >
                            <GridNoRecords>
                                No License Available
                            </GridNoRecords>
                            <GridColumn field="locationCode" title="Location Code" />
                            <GridColumn field="vendorName" title="Vendor" />
                            <GridColumn field="availableLicense" title="Available Licenses" cell={GridCellRender}/>
                            <GridColumn field="requiredLicense" title="License Required" />
                        </Grid>
                    </div>
                </div>

            </div>
        </div>
    )
}