import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Modal, Button, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { Grid, GridColumn, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, } from "@progress/kendo-data-query";
import vendorService from '../../../services/VendorService';
import codesetService from '../../../services/CodesetService';
import IVendor from '../../../model/Vendor'
import ICodeSet from '../../../model/Codeset'
import IStateCode from '../../../model/StateCode'
import VendorDetail from '../../../shared/VendorDetail'
import VendorContentManagement from './VendorContentManagement'
import GridHierarchyExpand from '../../../shared/GridHierarchyExpand'
import VendorService from '../../../services/VendorService';
import Product from './Product';
import { FormMode } from '../../../services/Utility'
import IconWindowGear from '../../../assets/icons/icon-window-gear';

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};
const initialDataState = {
    skip: 0,
    take: 10,
};
export default function VendorManage() {

    const mountedRef = useRef(true);
    //Page Load event - Get data from Service
    const [data, setRowData] = useState<IVendor[]>([]);
    //Force re-render the child component on Expand
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [filter, setFilter] = useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);

    const pageChange = (event: any) => {
        setChildFormMode(FormMode.None)
        setPage(event.page);
    };
    const getVendorData = () => {
        VendorService.getData().then(response => {
            setRowData(response.data)
        })
        return () => { mountedRef.current = false }
    }

    useEffect(() => {
        getVendorData();
        data.map((item: any) => {
            item.expanded = false;
            item.isSelected = false
            return item;
        })
        return () => { mountedRef.current = false }
    }, []);

    //On click of expand this function will call
    const SubGridHost = (props: any) => {
        // feed data to below components, Call the service Based on the prop.Id
        return (
            <div className="m-0">
                <VendorDetail data={props.dataItem} />
                <Product selectedVendorId={props.dataItem?.id} />
                <VendorContentManagement vendorId={props.dataItem?.id} updateCourseCount={updateCourseCount} childFormMode={childFormMode} />
            </div>
        )
    }

    const GridColumnButton = (props: any) => {
        return (
            <td className="text-right" style={{ overflow: 'visible' }}>
                <DropdownButton
                    menuAlign="right"
                    title={<i className='fas fa-bars'></i>}
                    id="dropdown-menu-align-right"
                    size="sm"
                    variant="outline-primary"
                    className="menu-bars"
                >
                    <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id, FormMode.Edit) }}>Edit Vendor</Dropdown.Item>
                    {
                        props.dataItem?.vendorCourseAssignedCount > 0 ? ' ' :
                            <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id, FormMode.Delete) }}>Delete Vendor</Dropdown.Item>
                    }
                </DropdownButton>
            </td>
        )
    }

    const GridColumnHierarchyExpand = (props: any) => <GridHierarchyExpand {...props} expandChange={onGridExpand} />
    const GridColumnHighLightText = (props: any) => {
        return (
            <td>
                <div className="d-flex">
                    <div className="svg-app-icon text-blue-gray mr-3" style={{ height: "60px" }}>
                        <IconWindowGear />
                    </div>
                    <div>
                        <div className="text-dark mb-0 h4 p-0 font-weight-bold">{props.dataItem.vendorName}</div>
                        <div>{props.dataItem.city}, {props.dataItem.state}</div>
                        <div>{props.dataItem.phoneNumber}</div>
                    </div>
                </div>
            </td>
        )
    }

    const onGridExpand = (dataItem: any) => {
        setChildFormMode(FormMode.None)
        dataItem.expanded = !dataItem.expanded;
        forceUpdate();
    }

    const [stateCodes, setStateCodes] = useState<IStateCode[]>([]);
    const [codesetData, setCodeSetData] = useState<ICodeSet[]>([]);
    const [licenseModel, setLicenseModelList] = useState<ICodeSet[]>([]);
    const [licenseType, setLicenseTypeList] = useState<ICodeSet[]>([]);
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [formValue, setFormValue] = useState<IVendor>({
        id: 0,
        vendorName: '',
        vendorDescription: '',
        vendorShortName: '',
        phoneNumber: '',
        vendorEmail: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        licenseModelId: 0,
        licenseTypeId: 0,
        licenseModel: '',
        licenseType: '',
        assignedContent: 0,
        totalContent: 0,
        courseCount: 0,
        availableLicenses: 0,
        totalLicenses: 0,
        isActive: true,
        modifiedBy: '',
        vendorCourseAssignedCount: 0
    });

    const handleChange = (event: any) => {
        if (event.target.name == 'licenseModelId') {
            if ((event.target.value == licenseModel.find(l => l.displayText == 'Per student per enrollment/course')?.id || event.target.value == licenseModel.find(l => l.displayText == 'Unlimited use of catalog for unlimited users')?.id)) {
                let data = licenseType
                let licenseTypeList = data.filter(x => x.displayText == licenseType.find(t => t.displayText == 'Non-Transferrable')?.displayText);
                setLicenseTypeList(licenseTypeList)
                let NonTransferrableLicense = licenseType.find(t => t.displayText == 'Non-Transferrable')
                if (NonTransferrableLicense) {
                    formValue.licenseTypeId = NonTransferrableLicense.id ? NonTransferrableLicense.id : 0
                    setFormValue(formValue);
                }

            } else {
                let licenseTypeList = codesetData.filter(x => x.codeSetName.toLowerCase().includes('licensetype'));
                setLicenseTypeList(licenseTypeList);
            }
        }
        const target = event.target //as HTMLSelectElement
        const value = target.type === 'checkbox' ? target.checked : target.value
        setFormValue({ ...formValue, [target.name]: value });
    };

    const post = (event: any) => {
        let vendor: IVendor = {
            vendorName: formValue.vendorName,
            vendorDescription: formValue.vendorDescription,
            vendorShortName: formValue.vendorShortName,
            phoneNumber: formValue.phoneNumber,
            vendorEmail: formValue.vendorEmail,
            addressLine1: formValue.addressLine1,
            addressLine2: formValue.addressLine2,
            city: formValue.city,
            state: formValue.state,
            zip: formValue.zip,
            isActive: formValue.isActive,
            licenseModelId: Number(formValue.licenseModelId),
            licenseTypeId: Number(formValue.licenseTypeId),
            assignedContent: 0,
            totalContent: 0,
            courseCount: 0,
            availableLicenses: 0,
            totalLicenses: 0,
            modifiedBy: '',
            vendorCourseAssignedCount: 0
        };
        vendorService.post(vendor).then(response => {
            // Display Success message
        })
        event.preventDefault();
    }

    const frameworkComponents = {
        btnCellRenderer: (props: any) => {
            return (<Button variant="primary" onClick={() => {
                props.onClick(props.value, props.formAction)
            }}>
                {props.buttonText}
            </Button>)
        }
    }


    const onFormAction = (vendorId: number, actionType: FormMode) => {
        setChildFormMode(FormMode.None)
        setErrors({
            vendorNameError: '',
            licenseModelError: '',
            licenseTypeError: '',
        });
        switch (actionType) {
            case FormMode.New:
                let addVendorDetails: IVendor = {
                    id: vendorId,
                    vendorName: '',
                    vendorDescription: '',
                    vendorShortName: '',
                    phoneNumber: '',
                    vendorEmail: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                    licenseModelId: 0,
                    licenseTypeId: 0,
                    licenseModel: '',
                    licenseType: '',
                    assignedContent: 0,
                    totalContent: 0,
                    availableLicenses: 0,
                    totalLicenses: 0,
                    courseCount: 0,
                    isActive: true,
                    modifiedBy: '',
                    vendorCourseAssignedCount: 0
                };
                setFormValue(addVendorDetails)
                vendorService.getStates().then(response => {
                    setStateCodes(response.data)
                });
                codesetService.getData().then(response => {
                    setCodeSetData(response.data);
                    let data = response.data as ICodeSet[];
                    let licenseModelList = data.filter(x => x.codeSetName.toLowerCase().includes('licensemodel'));
                    setLicenseModelList(licenseModelList);
                    let licenseTypeList = data.filter(x => x.codeSetName.toLowerCase().includes('licensetype'));
                    setLicenseTypeList(licenseTypeList);
                });
                setFormMode(FormMode.New);
                //Clear Existing vlues
                break;
            case FormMode.Edit:
                vendorService.getStates().then(response => {
                    setStateCodes(response.data)
                });
                codesetService.getData().then(response => {
                    setCodeSetData(response.data);
                    let data = response.data as ICodeSet[];
                    let licenseModelList = data.filter(x => x.codeSetName.toLowerCase().includes('licensemodel'));
                    setLicenseModelList(licenseModelList);
                    let licenseTypeList = data.filter(x => x.codeSetName.toLowerCase().includes('licensetype'));
                    setLicenseTypeList(licenseTypeList);
                });
                vendorService.getById(vendorId).then(response => {
                    setFormValue(response.data)
                    let vendorDetails = response.data as IVendor;
                    formValue.licenseModelId = vendorDetails.licenseModelId;
                    formValue.licenseTypeId = vendorDetails.licenseTypeId;
                })
                setFormMode(FormMode.Edit)
                break;
            case FormMode.Delete:
                //TODO: why vendorDetails Intilized
                let vendorDetails: IVendor = {
                    id: vendorId,
                    vendorName: '',
                    vendorDescription: '',
                    vendorShortName: '',
                    phoneNumber: '',
                    vendorEmail: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zip: '',
                    licenseModelId: 0,
                    licenseTypeId: 0,
                    licenseModel: '',
                    licenseType: '',
                    assignedContent: 0,
                    totalContent: 0,
                    availableLicenses: 0,
                    totalLicenses: 0,
                    courseCount: 0,
                    isActive: true,
                    modifiedBy: '',
                    vendorCourseAssignedCount: 0
                };
                setFormValue(vendorDetails)
                setFormMode(FormMode.Delete)
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }

    const onSave = (event: any, actionType: FormMode) => {

        let vendor: IVendor = {
            id: formValue.id,
            vendorName: formValue.vendorName,
            vendorDescription: formValue.vendorDescription,
            vendorShortName: formValue.vendorShortName,
            phoneNumber: formValue.phoneNumber,
            vendorEmail: formValue.vendorEmail,
            addressLine1: formValue.addressLine1,
            addressLine2: formValue.addressLine2,
            city: formValue.city,
            state: formValue.state,
            zip: formValue.zip,
            isActive: formValue.isActive,
            licenseModelId: Number(formValue.licenseModelId),
            licenseTypeId: Number(formValue.licenseTypeId),
            assignedContent: 0,
            totalContent: 0,
            availableLicenses: 0,
            totalLicenses: 0,
            courseCount: 0,
            modifiedBy: '',
            vendorCourseAssignedCount: 0
        };
        switch (actionType) {
            case FormMode.New:
                event.preventDefault();
                if (validate()) {
                    vendorService.post(vendor).then(response => {
                        // Display Success message
                        getVendorData();
                        setFormMode(FormMode.None)
                    })
                }
                //Clear Existing vlues

                break;
            case FormMode.Edit:

                event.preventDefault();
                if (validate()) {
                    vendorService.edit(vendor).then(response => {
                        // Display Success message
                        getVendorData(); // TODO : update item in collection instead
                        setFormMode(FormMode.None)
                    })
                }
                break;
            case FormMode.Delete:
                var vendorId = Number(vendor.id);
                vendorService.delete(vendorId).then(response => {
                    // Display Success message
                    getVendorData();   // TODO : remove item from collection instead
                    setFormMode(FormMode.None)
                })
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }
    const [errors, setErrors] = useState({
        vendorNameError: '',
        licenseModelError: '',
        licenseTypeError: '',
    })
    const validate = () => {
        let error = {
            vendorNameError: '',
            licenseModelError: '',
            licenseTypeError: '',
        };

        if (formMode == FormMode.New || formMode == FormMode.Edit) {
            if (!formValue.vendorName) { error.vendorNameError = "Name is required"; }

            if (!formValue.licenseModelId) { error.licenseModelError = "Choose License Usage"; }

            if (!formValue.licenseTypeId) { error.licenseTypeError = "Choose License Type"; }

            setErrors(error);
            if (error.vendorNameError || error.licenseModelError || error.licenseTypeError) {
                return false;
            }
        }
        return true;
    };

    const [childFormMode, setChildFormMode] = useState<FormMode>(FormMode.None);
    const updateCourseCount = (counter: number, vendorId: number, currentFormMode: FormMode) => {
        let vendorArray = data;
        let vendorIndex = vendorArray.findIndex(o => o.id == vendorId);
        vendorArray[vendorIndex].courseCount += counter
        setRowData([...vendorArray.slice(0, vendorIndex), vendorArray[vendorIndex], ...vendorArray.slice(vendorIndex + 1)])
        setChildFormMode(currentFormMode);
    }

    return (
        <div>
            <div className="text-right mb-2">
                <Button variant="primary" onClick={(e) => { onFormAction(0, FormMode.New) }}><i className="fas fa-plus"></i></Button>
            </div>
            <Grid
                data={filterBy(data, filter).slice(page.skip, page.take + page.skip)}
                filter={filter}
                filterable={true}
                skip={page.skip}
                take={page.take}
                total={data.length}
                pageable={true}
                detail={SubGridHost}
                expandField={'expanded'}
                onPageChange={pageChange}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                scrollable={"none"}
            >

                <GridColumn field="expanded" filterable={false} cell={GridColumnHierarchyExpand} title=' ' />
                <GridColumn field="vendorName" filterable={true} title="Vendor" cell={GridColumnHighLightText} />
                <GridColumn field="Courses" filterable={false} title="Courses" cell={
                    props => (
                        <td>
                            <div>{props.dataItem.courseCount}</div>
                        </td>
                    )} />
                <GridColumn field="id" title=" " filterable={false} className="text-right" cell={GridColumnButton} />
            </Grid>

            <Modal size="lg" backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Edit || formMode == FormMode.New} onHide={() => { setFormMode(FormMode.None) }}>
                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>{formMode == FormMode.Edit ? "Edit" : "New Vendor Account"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <input type="hidden" name="id" value={formValue.id ?? ''} onChange={handleChange} />
                        <Row>
                            <div className="form-group col-12">
                                <h4 className="mb-0">Vendor Information</h4>
                            </div>
                            <Form.Group controlId="vendorName" className="col-sm-6">
                                <Form.Label>Vendor Name</Form.Label>
                                <Form.Control type="text" name="vendorName" value={formValue.vendorName ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger">{errors.vendorNameError}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="vendorShortName" className="col-sm-6">
                                <Form.Label>Short Name</Form.Label>
                                <Form.Control type="text" name="vendorShortName" value={formValue.vendorShortName ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="addressLine1" className="col-sm-6">
                                <Form.Label>Address Line</Form.Label>
                                <Form.Control type="text" name="addressLine1" value={formValue.addressLine1 ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="addressLine2" className="col-sm-6">
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control type="text" name="addressLine2" value={formValue.addressLine2 ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="city" className="col-sm-6">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" name="city" value={formValue.city ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="state" className="col-md-3 col-sm-6">
                                <Form.Label>State</Form.Label>
                                <Form.Control as="select" name="state" value={formValue.state} onChange={handleChange}>
                                    <option key={0} defaultValue={0} selected hidden>Choose</option>
                                    {stateCodes.map((stateCodesOptions) => (
                                        <option defaultValue="Choose" key={stateCodesOptions.stateCode} value={stateCodesOptions.stateCode}>{stateCodesOptions.stateCode}</option>
                                    ))}
                                </Form.Control>
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="zip" className="col-md-3 col-sm-6">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control type="text" name="zip" value={formValue.zip ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="phoneNumber" className="col-sm-6">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" name="phoneNumber" value={formValue.phoneNumber ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="vendorEmail" className="col-sm-6">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="vendorEmail" value={formValue.vendorEmail ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <div className="form-group col-12">
                                <h4 className="mb-0">Vendor Pricing</h4>
                            </div>
                            <Form.Group controlId="vendorDescription" className="col-12">
                                <Form.Label>License Description</Form.Label>
                                <Form.Control as="textarea" rows={3} name="vendorDescription" value={formValue.vendorDescription ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger"></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="licenseModelId" className="col-sm-6">
                                <Form.Label>License Usage</Form.Label>
                                <Form.Control as="select" name="licenseModelId" value={formValue.licenseModelId ?? 0} onChange={handleChange}>
                                    <option key={0} defaultValue={0} selected hidden>Choose</option>
                                    {licenseModel.map((licenseModelOptions) => (
                                        <option key={licenseModelOptions.id} value={licenseModelOptions.id}>{licenseModelOptions.displayText}</option>
                                    ))}
                                </Form.Control>
                                <Form.Text className="text-danger">{errors.licenseModelError}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="licenseTypeId" className="col-sm-6">
                                <Form.Label>License Type</Form.Label>
                                <Form.Control as="select" name="licenseTypeId" value={formValue.licenseTypeId ?? 0} onChange={handleChange}>
                                    <option key={0} defaultValue={0} selected hidden>Choose</option>
                                    {licenseType.map((licenseTypeOptions) => (
                                        <option key={licenseTypeOptions.id} value={licenseTypeOptions.id}>{licenseTypeOptions.displayText}</option>
                                    ))}
                                </Form.Control>
                                <Form.Text className="text-danger">{errors.licenseTypeError}</Form.Text>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setFormMode(FormMode.None) }}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>

                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Delete Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the vendor ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}