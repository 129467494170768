import React, { useContext } from 'react';
import { ErrorContext, IErrorContext } from '../services/AppContext'

export default function ErrorDisplay() {
    const errorContext = useContext<IErrorContext>(ErrorContext);
    let isErrorContentVisible = (errorContext.errorMessages)
    return (<div className="main-alert-container">
        { 
        errorContext?.errorMessages?.length != 0 &&
            <div className="main-alert">
                <div className="container pt-5 pb-5">
                    <div className="alert alert-danger mb-0 shadow-sm pt-0 pb-0">
                        {
                            errorContext.errorMessages.map((msg, i) =>
                                <div className="alert-item pt-2 pb-2 ml-n3 mr-n3 pr-3 pl-6" key={i.toString()}>
                                    <i className="fas fa-bomb alert-icon fa-lg"></i> {msg}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        }
    </div>)
}