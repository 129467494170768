import React, { useState, useEffect, useRef } from 'react';

export default function IconSchool() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.551 50">
        <title>School</title>
        <g transform="translate(-797.418 -33.62)">
            <path d="M828.852,59.886a5.756,5.756,0,1,0,5.757,5.756A5.762,5.762,0,0,0,828.852,59.886Zm0,9.29a3.534,3.534,0,1,1,3.535-3.534A3.537,3.537,0,0,1,828.852,69.176Z" transform="translate(-6.659 -6.811)"></path>
            <rect width="2.592" height="3.824" transform="translate(805.741 70.388)"></rect>
            <rect width="2.592" height="3.824" transform="translate(805.741 76.316)"></rect>
            <path d="M832.658,86.042h-6.995a1.3,1.3,0,0,0-1.3,1.3c0,.013,0,.025,0,.037s0,.025,0,.038v8.458a1.3,1.3,0,1,0,2.592,0V88.634H831.4v7.237a1.3,1.3,0,0,0,2.592,0V87.413a1.409,1.409,0,0,0-1.338-1.371Z" transform="translate(-6.988 -13.593)"></path>
            <rect width="2.592" height="3.824" transform="translate(836.055 70.388)"></rect>
            <rect width="2.592" height="3.824" transform="translate(836.055 76.316)"></rect>
            <path d="M857.286,65.881H844.8V62.4l-2.592-1.341V89.439a1.3,1.3,0,1,0,2.592,0V73.679h12.489a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,857.286,65.881Zm-1.3,5.2H844.84V68.474h11.15Z" transform="translate(-11.614 -7.116)"></path>
            <path d="M801.168,94.575a1.3,1.3,0,0,0,2.592,0V81.043h-2.592Z" transform="translate(-0.972 -12.297)"></path>
            <path d="M857.067,94.575a1.3,1.3,0,0,0,2.592,0V81.043h-2.592Z" transform="translate(-15.467 -12.297)"></path>
            <path d="M811.2,65.863h-12.49a1.3,1.3,0,0,0-1.3,1.3v5.2a1.3,1.3,0,0,0,1.3,1.3H811.2V89.42a1.3,1.3,0,1,0,2.592,0V60.988L811.2,62.329Zm-.042,5.2H800.01V68.455h11.151Z" transform="translate(0 -7.097)"></path>
            <path d="M811.656,54.125a1.283,1.283,0,0,0,.595-.146l13.3-6.881,13.3,6.881a1.283,1.283,0,0,0,.595.146,1.3,1.3,0,0,0,.6-2.448l-13.114-6.786a1.293,1.293,0,0,0-.121-.184,1.263,1.263,0,0,0,.026-.256v-2.03l7.394-2.989a1.284,1.284,0,0,0,.226-.119l0,0a1.294,1.294,0,0,0,.253-.227c.018-.02.033-.041.051-.061a1.394,1.394,0,0,0,.178-.3l0-.006c.01-.025.014-.051.023-.076a1.36,1.36,0,0,0,.047-.161c.007-.039.009-.076.013-.114s.012-.09.013-.137a1.226,1.226,0,0,0-.012-.128c0-.041-.005-.081-.013-.123a1.2,1.2,0,0,0-.045-.153c-.009-.027-.013-.056-.024-.083l0-.008a1.177,1.177,0,0,0-.093-.176c-.01-.016-.016-.035-.027-.05a1.293,1.293,0,0,0-.3-.315c-.018-.013-.032-.028-.05-.04s-.049-.026-.073-.04a1.5,1.5,0,0,0-.152-.081l-.009,0L826.1,33.715a1.4,1.4,0,0,0-1.86,1.273v9.464a1.27,1.27,0,0,0,.035.287,1.323,1.323,0,0,0-.1.152L811.06,51.677a1.3,1.3,0,0,0,.6,2.448Zm15.175-17.312,3.463,1.411-3.463,1.4Z" transform="translate(-3.356)"></path>
        </g>
    </svg>
  )
}

