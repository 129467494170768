import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

export default function IconStudentOutline() {

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
      <title>Student</title>
      <path d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M9.3,28.6c1.1-1.8,3.7-2.3,3.7-2.3 c0.4-0.1,0.7-0.4,0.7-0.8v-2c0-0.3-0.2-0.6-0.4-0.7C12,22,11,21,10.4,19.8c-0.1-0.2-0.2-0.3-0.4-0.4c-1-0.5-1.6-1.5-1.6-2.6 c0-0.9,0.4-1.9,1.1-2c0.4,0,0.8-0.4,0.8-0.8c0.2-3.6,2.5-6.1,6.2-7c-0.1,0.4-0.1,0.7,0,1.1c0.2,0.8,0.8,1.2,1.4,1.4
        c2.1,0.6,3.6,2.4,3.7,4.5c0,0.4,0.3,0.8,0.7,0.8c0.8,0.1,1.2,0.8,1.2,2c0,1.2-0.6,2.2-1.6,2.6c-0.2,0.1-0.3,0.2-0.4,0.4 c-0.6,1.1-1.5,2.1-2.7,2.8c-0.3,0.2-0.4,0.4-0.4,0.7v2c0,0.4,0.3,0.8,0.7,0.8c0,0,2.3,0.4,3.6,2.4c-2,1.1-4.3,1.7-6.8,1.7 C13.6,30.3,11.3,29.7,9.3,28.6z M24.2,27.7c-1.2-1.8-3-2.7-4-3v-0.9c1.2-0.8,2.1-1.8,2.8-3c1.4-0.8,2.4-2.3,2.4-4c0-1.8-0.7-3-2-3.5 c-0.4-2.6-2.3-4.8-4.9-5.5c0,0-0.2-0.1-0.2-0.2c-0.1-0.2,0.1-0.6,0.6-1.2c0.2-0.3,0.3-0.6,0.1-1C18.8,5.1,18.5,5,18.1,5 c-5.4,0.6-8.9,3.7-9.4,8.3c-1.2,0.4-2,1.8-2,3.5c0,1.7,0.9,3.2,2.3,4c0.7,1.2,1.7,2.3,3,3.1v0.9c-1.1,0.3-3.1,1.1-4.1,2.9 C4.1,25.2,1.7,20.9,1.7,16C1.7,8.1,8.1,1.7,16,1.7c7.9,0,14.3,6.4,14.3,14.3C30.3,20.8,27.9,25.1,24.2,27.7z"/>
    </svg>
  )
}

