
import { WebStorageStateStore, Log } from "oidc-client";
import appsettings from '../../assets/appsettings.json';
import { UserManager } from "../../../node_modules/oidc-client/src/UserManager.js";
export default class AuthService {
    UserManager;
    constructor() {
        const settings = {
            authority: appsettings.OIDC.REACT_APP_OIDC_AUTHORITY,
            metadataUrl: appsettings.OIDC.REACT_APP_OIDC_METADATA_URL,
            client_id: appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID,
            redirect_uri: appsettings.OIDC.REACT_APP_OIDC_REDIRECT_URI,
            silent_redirect_uri: appsettings.OIDC.REACT_APP_OIDC_SILENT_REDIRECT_URI,
            post_logout_redirect_uri: appsettings.OIDC.REACT_APP_OIDC_REDIRECT_URI,
            response_type: 'code',  
            scope: "openid profile offline_access",
            
            filterProtocolClaims: true,  
            loadUserInfo: false
        };

        this.UserManager = new UserManager({
            ...settings,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),

        });
        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
        });
            //oidc-client js 
        this.UserManager.events.addAccessTokenExpired(() => {
            this.UserManager.signinSilent()
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
        });
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    login() {
        return this.userManager.signinRedirect().then(res => {
           
        });
    }

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        window.location.replace("/Home");
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`))
        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    renewToken() {
      return this.UserManager.signinSilent()
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        sessionStorage.clear()
        localStorage.clear();
        
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            sessionStorage.clear()
            window.location.replace(process.env.REACT_APP_PUBLIC_URL);
        });
        this.UserManager.clearStaleState();
    };
}