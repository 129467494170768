import * as React from 'react';
import * as ReactDOM from 'react-dom';
import courseService from '../../services/CoursesService'
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels
} from '@progress/kendo-react-charts';
import 'hammerjs';


interface CourseStatusList {
  courseStatus: string,
  courseCount: string
}

const labelContent = (e: any) => (e.text + " - " + e.value);

function CourseChartContainer(data: any) {
  let Tdata: CourseStatusList[] = []
  if (data.data.length > 0) {
    Tdata = data.data
  } 
  
  if (Tdata.length > 0) {
    return (
      <Chart renderAs='svg'  style={{fontSize:"20px",height:"210px"}}>
        <ChartSeries >
          <ChartSeriesItem type="donut" data={Tdata} categoryField="courseStatus" field="courseCount"  holeSize={80} size={20}>
          </ChartSeriesItem>
        </ChartSeries>
        <ChartLegend visible={true}  position="right" labels={
          { margin: 5 ,content:labelContent}
        } />
      </Chart>
    )
  } else {
    return (
      <div>No Courses</div>
    ) 
  }
};

export default CourseChartContainer









