import React, { useState, useEffect, useContext } from 'react';
import ILicenseUsageReport from '../../model/ILicenseUsageReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

interface ILocation {
    licenseType:string;
    changeExcelRef: any;
    selectedReport: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

const initialSort: Array<SortDescriptor> = [
    { field: "totalCourses", dir: "desc" },
];

export default function LicenseUsageReport(props: ILocation) {
    const { orginalSchoolYear } = useContext(Year);
    const [filter, setFilter] = React.useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<ILicenseUsageReport[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);
    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const GetLicenseUsageReport = () => {
        ReportsService.GetLicenseUsageReport(+orginalSchoolYear.id,props.licenseType).then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetLicenseUsageReport();
    }, [props.selectedReport, orginalSchoolYear.id]);

    const filterReportData = (data: any, filter: any) => {
        let report = rowData;
        return filterBy(report, filter);
      }
      const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
      }


    return (
        <ExcelExport data={rowData} ref={_export} fileName={props.licenseType+"_License_Usage_Report_" + new Date().toDateString()}>
            <Grid
                data={filterReportData(orderBy(rowData, sort),filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                filterable={true}
                filter={filter}
                onFilterChange={onFilterChange}
                style={{width:"auto"}}
                scrollable={"scrollable"}>
                <GridColumn field="studentLocationCode"  filterable={true} width="180px" title="Student Location"></GridColumn>
                <GridColumn field="studentId" filterable={false} width="130px"  title="Student Id"></GridColumn>
                <GridColumn field="firstName" filterable={false}  title="First Name"></GridColumn>
                <GridColumn field="lastName" filterable={false}  title="Last Name"></GridColumn>
                <GridColumn field="courseLocationCode"  filterable={true}  width="180px" title="Course Location"></GridColumn>
                <GridColumn field="vendorName" filterable={false}  title="Vendor"></GridColumn>
                <GridColumn field="licenseType" filterable={false}  title="License Type"></GridColumn>
                <GridColumn field="courses" filterable={false}  title="Courses" ></GridColumn>
                <GridColumn field="licenseConsumed" filterable={false}  title="License Consumed" ></GridColumn>
            </Grid>
        </ExcelExport>
    )
}