import axios from 'axios'
import { AxiosInstances as AxiosInstance } from './BaseService'

export default {
    getData: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/SetupSchool',
        }),

    getSchoolsFromDaap: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/SetupSchool/Daap',
        }),

        getSchoolOrgFromEndPoint: (req:any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/AddSchool',
            'data':req
        }),

        addNewSchool: (req:any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/AddSchool',
            'data':req
        }),
        updatePrincipal: (req:any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/School/UpdatePrincipal',
            'data':req
        }),
        getPrincipalInfoBySchoolDBN: (locationCode: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/PrincipalInfo`
        })



}