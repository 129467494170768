
import React from "react";
import { IRBACUser } from '../model/IRBACUser' 

export type AuthContextType = { 
    userProfile : UserProfileType
    setUserProfile: Function
}

export type UserProfileType = {
    userName?: string;
    role: IRBACUser;
}

export const AuthContextDefault : AuthContextType = {    
    userProfile : { userName :'', role : { userId: '', roleName: '', roleID: '', locations:[{LocationCode:'',LocationName:''}] }  }  ,
    setUserProfile: (value:UserProfileType) => {}
}
export const AuthContext = React.createContext<AuthContextType>(AuthContextDefault);


export interface IErrorContext { 
    errorMessages: Array<string>
    setErrorMessages: Function
}

export const ErrorContextDefault ={
    errorMessages: [],
    setErrorMessages: (value:Array<string>) => {}
}
export const ErrorContext = React.createContext<IErrorContext>(ErrorContextDefault);

 
type LocationContextType = {
    locationCode: string;
    setLocationCode: Function;   
};

const LocationContextDefault = {
    locationCode: '',
    setLocationCode: (value: string) => {}   
};

//export const LocationContext = React.createContext<LocationContextType | undefined>(LocationContextDefault);
export const LocationContext = React.createContext<LocationContextType>(LocationContextDefault);

type OrginalSchoolYearType = {
    orginalSchoolYear: SchoolYear;
    setOrginalSchoolYear: Function;   
  };
  
  const OrginalSchoolYearDefault = {
    orginalSchoolYear: {id:'', isCurrent:0,schoolYearEndDate:new Date()},
    setOrginalSchoolYear: (value: string) => {}   
  };
  
  interface SchoolYear {
      id:string,
      isCurrent:number,
      schoolYearEndDate:Date
  }
  export const Year = React.createContext<OrginalSchoolYearType>(OrginalSchoolYearDefault);


 