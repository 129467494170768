import { User } from 'oidc-client'
import { useContext } from 'react'
import IAnnouncement from '../model/IAnnouncement'
import { AxiosInstances as AxiosInstance} from './BaseService'


export default {
    getData: () =>    
      AxiosInstance({
            'method': 'GET',
            'url': '/announcement',
        }),
        getRecent: () =>
        AxiosInstance({
              'method': 'GET',
              'url': '/announcement/getrecent',
          }),
    getById: (id: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': '/announcement/getbyid?id=' + id,
        }),
    post: (requestdata: IAnnouncement) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/announcement',
            'data': requestdata
        }),
    edit: (requestdata: IAnnouncement) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/announcement?id=' + requestdata.id,
            'data': requestdata
        }),
    delete: (id: number) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': '/announcement?id=' + id,
        })
}