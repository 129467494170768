export enum FormMode {
    New,
    Edit,
    Transfer,
    Merge,
    Delete,
    Error,
    None
  }

  export interface IDropDownValue {
    id: string;
    text: string;
  }
  
  // export interface IVendorDropDownValue {
  //   id: string;
  //   text: string;
  // }
  // export interface IProductDropDownValue {
  //   id: string;
  //   text: string;
  // }
