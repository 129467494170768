import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import React, { useRef } from 'react';
import appsettings from '../assets/appsettings.json'
import LocalStorageService from './LocalStorage';
import RbacService from '../services/RBACService'
import { IRBACUser } from '../model/IRBACUser';
import jwt_decode from "jwt-decode";
import { UserProfileType } from './AppContext';
import AuthService from './oidc/LMIAuthService';
import moment from 'moment';

export const AxiosInstances = axios.create({
    baseURL: appsettings.REACT_APP_BASE_API_URL,
    timeout: 50000,
    headers: {
        'X-LMI-ClientId': '837E5E65-6754-4B65-9160-113D72936875',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});
const authService = new AuthService()
let refreshingToken = false

const onRequest = async (request: AxiosRequestConfig) => {

    let userProfile = sessionStorage.getItem(`oidc.user:${appsettings.OIDC.REACT_APP_OIDC_AUTHORITY}:${appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID}`) ?? ''
    let claimToken = sessionStorage.getItem(`lmi.user:claim:${appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID}`)
    let claim = claimToken == null ? '' : JSON.parse(claimToken).rbac_token
    let token = JSON.parse(userProfile)
    request.headers = {
        'Authorization': `Bearer ${token.id_token}`,
        'Claim': `${claim}`,
    }
    return request;
};

const onRequestError = (error: AxiosError) => {
    return Promise.reject(error);
};

const onResponse =  (response: AxiosResponse) => {
    if (response.status === 401) {
        window.location.pathname = '/';
        localStorage.clear();
        sessionStorage.clear();
    }
    return response;
};

const onResponseError = (error: AxiosError) => {
    if (error.response?.status === 401) {
        console.error(`[response error] [${JSON.stringify(error)}]`);
        axios.request(error.config)
        window.location.pathname = '/';
        localStorage.clear();
        sessionStorage.clear()
    }
    return Promise.reject(error);
}

export const refreshToken = async () => {
    refreshingToken = true
    await authService.renewToken()
    await authService.getUser().then((user) => {
        RbacService.getUserRole().then((claim) => {
            sessionStorage.setItem(`lmi.user:claim:${appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID}`, JSON.stringify(claim.data))
            let rbacUser = jwt_decode<IRBACUser>(claim.data.rbac_token)
            let userProfileFromClaim: UserProfileType = {
                userName: user?.profile.name, role: rbacUser
            }
            LocalStorageService.setUserProfile(userProfileFromClaim)
            LocalStorageService.setExpirationTime()
            refreshingToken = false
        })
    })
}

export const isTokenExpired = () => {

    if (!LocalStorageService.getExpirationTime()) {
        return false
    }
    let userLoggedIn = sessionStorage.getItem(`lmi.user:claim:${appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID}`)
    if (!userLoggedIn) {
        return false
    }
    var now = moment();
    var expirationDate = moment(LocalStorageService.getExpirationTime());
    var isExpired = now.isSameOrAfter(expirationDate)
    return (isExpired && !refreshingToken)
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {

    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);

    return axiosInstance;
}