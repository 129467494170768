import React, { useState, useEffect } from 'react';
import VendorContentService from '../../../services/VendorContentService';
import IVendorContent from '../../../model/VendorContent';
import { Grid, GridColumn, GridFilterChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, } from "@progress/kendo-data-query";
import { Modal, Button, Dropdown, Form, Row } from 'react-bootstrap';
import { FormMode } from '../../../services/Utility'
interface IContentDisplay {
    vendorId: number,
    updateCourseCount: (counter: number, vendorId: number, childFormMode : FormMode) => void,
    childFormMode: FormMode
}
const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  const initialDataState = {
    skip: 0,
    take: 10,
};
export default function VendorContentManagement(props: IContentDisplay) {
    const [rowData, setCourseContentData] = useState<IVendorContent[]>([]);
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [formValue, setFormValue] = useState<IVendorContent>({
        id: 0,
        vendorCourseCode: '',
        vendorCourseContentName: '',
        vendorId: 0,
        vendorName: '',
        TotalCourses: 0,
        TotalStudents : 0,
        isActive: true,
        modifiedBy: ''
    });
  
    const [filter, setFilter] = useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);
    const [errorMsg, setErrorMsg] = useState<string>('');

    const pageChange = (event: any) => {
        setPage(event.page); 
    };

    const getVendorContentData = () =>{
        VendorContentService.getByVendorId(props.vendorId).then(response => {
            setCourseContentData(response.data)           
        })
    }
    useEffect(() => {
        getVendorContentData();
        setFormMode(props.childFormMode);
    }, []);

    const handleChange = (event: any) => {
        const target = event.target //as HTMLSelectElement
        const value = target.type === 'checkbox' ? target.checked : target.value

        setFormValue({ ...formValue, [target.name]: value });
    };

    const onFormAction = (vendorContentId: number, actionType: FormMode) => {
        setErrors({
            vendorContentCourseNameError: '',
            vendorCourseCodeError: '',
        });
        switch (actionType) {
            case FormMode.New:
                setFormMode(FormMode.New)
                //Clear Existing vlues
                let vendorContentReset: IVendorContent = {
                    id: 0,
                    vendorCourseCode: '',
                    vendorCourseContentName: '',
                    vendorId: 0,
                    vendorName: '',
                    TotalCourses: 0,
                    TotalStudents : 0,
                    isActive: true,
                    modifiedBy: ''
                };
                setFormValue(vendorContentReset)
                break;
            case FormMode.Edit:
                setFormMode(FormMode.Edit)
                VendorContentService.getById(vendorContentId).then(response => {
                    setFormValue(response.data);
                });
                break;
            case FormMode.Delete:
                let vendorContentDetails: IVendorContent = {
                    id: vendorContentId,
                    vendorCourseCode: '',
                    vendorCourseContentName: '',
                    vendorId: 0,
                    vendorName: '',
                    TotalCourses: 0,
                    TotalStudents : 0,
                    isActive: true,
                    modifiedBy: ''
                };
                setFormValue(vendorContentDetails)
                setFormMode(FormMode.Delete)
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }

    const onSave = (event: any, actionType: FormMode) => {
        let vendorContent: IVendorContent = {
            id: formValue.id,
            vendorCourseCode: formValue.vendorCourseCode ,
            vendorCourseContentName: formValue.vendorCourseContentName,
            vendorName: '',
            vendorId: props?.vendorId,
            TotalCourses: 0,
            TotalStudents : 0,
            isActive: formValue.isActive,
            modifiedBy: ''
        };
        switch (actionType) {
            case FormMode.New:
                event.preventDefault();
                if(validate()){
                    VendorContentService.post(vendorContent).then(response => {
                        // Display Success message
                        
                        getVendorContentData();                        
                        const target = event.target 
                        if(target.id == "save-add-another-content"){
                            let vendorContentReset: IVendorContent = {
                                id: 0,
                                vendorCourseCode: '',
                                vendorCourseContentName: '',
                                vendorId: 0,
                                vendorName: '',
                                TotalCourses: 0,
                                TotalStudents : 0,
                                isActive: true,
                                modifiedBy: ''
                            };
                            props.updateCourseCount(1,props.vendorId,FormMode.New);
                            setFormValue(vendorContentReset)
                            setFormMode(FormMode.New)
                        }else{               
                            props.updateCourseCount(1,props.vendorId,FormMode.None);             
                            setFormMode(FormMode.None)
                        }
                        
                    },error =>{
                        setErrorMsg(error.response.data.errors.join())
                    })
                }                              
                //Clear Existing vlues
                break;
            case FormMode.Edit:
                event.preventDefault();
                if(validate()){
                    VendorContentService.edit(vendorContent).then(response => {
                        // Display Success message
                        getVendorContentData();
                        setFormMode(FormMode.None)
                    },error =>{
                        setErrorMsg(error.response.data.errors.join())
                    })
                }
                break;
            case FormMode.Delete:
                var vendorContentId = Number(vendorContent.id);
                VendorContentService.delete(vendorContentId).then(response => {
                    // Display Success message
                    props.updateCourseCount(-1,props.vendorId,FormMode.None);
                    getVendorContentData();
                    setFormMode(FormMode.None)
                })
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }

    const GridColumnButton = (props: any) => {
        return (
            <td className="text-right" style={{ overflow: 'visible' }}>
                <Dropdown>
                    <Dropdown.Toggle className="menu-bars" size="sm" variant="outline-primary">
                        <i className="fas fa-bars"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id,FormMode.Edit )}}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id,FormMode.Delete )}}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>)
    }

    const [errors, setErrors] = useState({
        vendorContentCourseNameError: '',
        vendorCourseCodeError: '',
      })
    const validate = () => {
        let error = {
            vendorContentCourseNameError: '',
            vendorCourseCodeError: '',
        };

        if (formMode == FormMode.New || formMode == FormMode.Edit ) {
          if (!formValue.vendorCourseContentName) { error.vendorContentCourseNameError = "Course Name is required"; }
    
          if (!formValue.vendorCourseCode) { error.vendorCourseCodeError = "Course Code is required" ; }
    
          setErrors(error);
          if (error.vendorContentCourseNameError || error.vendorCourseCodeError ) {
            return false;
          }
        }
        return true;    
      };
    
    return (
        <div>
            <Modal backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Edit || formMode == FormMode.New} onHide={() => { setFormMode(FormMode.None) }}>
                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>{formMode == FormMode.Edit? "Edit" : "Add" } Vendor Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div hidden={errorMsg.length == 0}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMsg}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                </div>
                <Form>
                <input type="hidden" name="id" value={formValue.id ?? ''} onChange={handleChange} />
                    <Row>
                        <Form.Group controlId="vendorCourseContentName" className="col-sm-12">
                            <Form.Label>Vendor Course Name</Form.Label>
                            <Form.Control type="text" name="vendorCourseContentName" value={formValue.vendorCourseContentName ?? ''} onChange={handleChange} />
                            <Form.Text className="text-danger">{errors.vendorContentCourseNameError}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="vendorCourseCode" className="col-sm-12">
                            <Form.Label>Course Code</Form.Label>
                            <Form.Control type="text" name="vendorCourseCode" value={formValue.vendorCourseCode ?? ''} onChange={handleChange} />
                            <Form.Text className="text-danger">{errors.vendorCourseCodeError}</Form.Text>
                        </Form.Group>
                    </Row>                  
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setFormMode(FormMode.None) }}>Close</Button>
                    <Button style={{ display: formMode == FormMode.New ? "flex" : "none" }} id="save-add-another-content" variant="dark" type="button" onClick={(e) => onSave(e, formMode)}>Save and Add Another</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>

                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Delete Vendor Content </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the Vendor Content?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <div className="card mt-4">
            <div className="card-header font-weight-bold bg-secondary-light">
                <div className="row align-items-center">
                    <div className="col-10">Vendor Content</div>
                    <div className="col-2  text-right">
                        <button type="button" className="btn btn-sm btn-primary" onClick={(e) => { onFormAction(0, FormMode.New)}}>
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <Grid 
                    data={filterBy(rowData, filter).slice(page.skip, page.take + page.skip)} 
                    filter={filter}
                    filterable={true} 
                    skip={page.skip}
                    take={page.take}
                    total={rowData.length}
                    pageable={true}
                    sortable={false}
                    scrollable={"none"}
                    onPageChange={pageChange}
                    onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}>

                    <GridColumn field="vendorCourseContentName" title="Vendor Course Name" />
                    <GridColumn field="vendorCourseCode" title="Course Code" />
                    <GridColumn field="id" title=" " filterable={false} cell={GridColumnButton} width={90} />
                </Grid>
            </div>
        </div>
        </div>
    )
}

