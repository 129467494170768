import React, { useState, useEffect , useContext} from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Year } from '../services/AppContext';
import schoolYearService from '../services/SchoolYearService'
interface IDropDownValue {
  id: string;
  text: string; 
  isCurrent:number;
  schoolYearEndDate:Date;
}

  export default function SchoolYearDropDown(props: any)
  {    
    let { orginalSchoolYear, setOrginalSchoolYear } = useContext(Year);
    const [currentYear , setCurrentYear] = useState<IDropDownValue>({id:'',text:'',isCurrent:0,schoolYearEndDate:new Date()})
    let currentDate = new Date();
    const [orginalSchoolYearList, setOrginalSchoolYearList] = useState<IDropDownValue[]>([
  ]);
  
 
  useEffect(() => { 
    schoolYearService.getData().then(response => {
      
      let year = response.data.find((y:any)=>y.isCurrent==1)
      if(year){
         let current = {
           id:year.schoolYear,
           text: year.schoolYearRange,
           isCurrent : year.isCurrent,
           schoolYearEndDate:year.schoolYearEndDate
         }
         setCurrentYear(current)
         setOrginalSchoolYear({
           id:current.id,
           isCurrent:current.isCurrent,
           schoolYearEndDate:current.schoolYearEndDate
         })
      }
      let dropDownData = response.data.map((sl: any) => ({
        id: sl.schoolYear,
        text: sl.schoolYearRange,
        isCurrent:sl.isCurrent,
        schoolYearEndDate:sl.schoolYearEndDate
      }));
      setOrginalSchoolYearList(dropDownData)
    })
  }, []);

  const handleChange = (event: any) => {
    const selectedYear = event.target.value;
    setOrginalSchoolYear(selectedYear);
  }

 
    return (
        <DropDownList
        data={orginalSchoolYearList}
        defaultValue={orginalSchoolYearList.find((y:any)=>y.isCurrent==1)}
        textField="text"
        onChange={handleChange}
        />
      )
  }