import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
//import './styles/scss/lmi.scss' /* Compile lmi.scss file if any style changes have been made. Removed it here because it's slow to compile. Importing CSS styles instead. */
import './styles/css/lmi.css'
import App from './App';
import Login from '../src/shared/Login';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";
 
ReactDOM.render(
  <React.StrictMode>
    
      <BrowserRouter>
<App />     
      </BrowserRouter>
   
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();