import React, { useState, useEffect, useRef, useContext } from 'react';
import ICourseDetail from '../../model/ICourseDetail';
import coursesService from '../../services/CoursesService'
import { FormMode } from '../../services/Utility';
import Moment from 'react-moment';
import LocalStorageService from '../../services/LocalStorage';
import { AuthContextDefault, UserProfileType, Year } from '../../services/AppContext';


interface ICourseContent {
    dataSource: number
    sharedCourses : string []
    location: string
    courseStatus : string
    onFormAction: (e: any, data: ICourseDetail, actionType: FormMode,) => void
}

export default function CourseDetail(props: ICourseContent) {
    const mountedRef = useRef(true);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    const { orginalSchoolYear } = useContext(Year);
    useEffect(() => {
        coursesService.getCourseDetail(props.dataSource, props.location).then(response => {
            setCourseDetailData(response.data)
            setUserProfile(LocalStorageService.getUserProfile())
        })
        return () => { mountedRef.current = false }
    }, [props.dataSource]);

    useEffect(() => {
        
    }, [props.courseStatus]);

    const [courseDetailData, setCourseDetailData] = useState<ICourseDetail>({
        courseId: 0,
        schoolDBN: '',
        schoolYear: 0,
        courseName: '',
        courseSource: '',
        isMerged: 0,
        purpose: '',
        mergeCourses: [],
        courseContentType: '',
        teacherOfRecord: '',
        startDate: new Date(),
        endDate: new Date(),
        createdBy: '',
        createdOn: new Date(),
        courseStatus: '',
        mergedOn: new Date(),
        mergedBy : ''
    });
    const onFormAction = (e: any, course: ICourseDetail, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.Edit:
                let courseinfo: ICourseDetail = {
                    courseId: course.courseId,
                    schoolDBN: course.schoolDBN,
                    schoolYear: course.schoolYear,
                    courseName: course.courseName,
                    courseSource: course.courseSource,
                    isMerged: course.isMerged,
                    purpose: course.purpose,
                    mergeCourses: course.mergeCourses,
                    courseContentType: course.courseContentType,
                    teacherOfRecord: course.teacherOfRecord,
                    startDate: course.startDate,
                    endDate: course.endDate,
                    createdBy: course.createdBy,
                    createdOn: course.createdOn,
                    courseStatus: course.courseStatus,
                    oldTeacherId: course.teacherOfRecord,
                    mergedOn: course.mergedOn,
                    mergedBy : course.mergedBy,
                    courseType : course.courseType
                }
                props.onFormAction(e, courseinfo, FormMode.Edit)
                break;
            default: {
                break;
            }
        }
        e.preventDefault();
    }
    return (
        <div className="card">
            <div className="card-header font-weight-bold bg-secondary-light">
                <div className="row align-items-center">
                    <div className="col-7">Course Detail </div>
                    {((props.courseStatus && props.courseStatus == "Closed") || userProfile?.role?.roleName == 'Central Staff' || orginalSchoolYear.isCurrent == 0) ? " " : <div className="col-5 text-right"><button className="btn btn-sm btn-primary" onClick={(e) => { onFormAction(e, courseDetailData, FormMode.Edit) }}>Edit</button></div>}

                </div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-lg-4 mb-3 mb-lg-0">
                        <div className="small text-primary">Title:</div>
                        <div>{courseDetailData.courseName}</div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="small text-primary">Purpose:</div>
                        <div>{courseDetailData.purpose}</div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="small text-primary">Content Type:</div>
                        <div>{courseDetailData.courseContentType}</div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-4 col-6">
                        <div className="small text-primary">Start Date:</div>
                        <div>
                            <Moment format="MM/DD/YYYY">
                                {courseDetailData.startDate}
                            </Moment>
                        </div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="small text-primary">End Date:</div>
                        <div>
                            <Moment format="MM/DD/YYYY">
                                {courseDetailData.endDate}
                            </Moment>
                        </div>
                    </div>                 
                    <div className="col-lg-4 col-sm-6 mt-3 mt-lg-0">
                        <div className="small text-primary">Added By:</div>
                        <div>{courseDetailData?.createdBy}
                            <span> on </span>
                            <Moment format="MM/DD/YYYY">
                                {courseDetailData.createdOn}
                            </Moment>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-4 col-6">
                        <div className="small text-primary">Course Source:</div>
                        <div>
                            {courseDetailData?.courseSource}
                        </div>
                    </div>
                    { courseDetailData.lastSubmittedOnLMS ? 
                        <div className="col-lg-4 col-6">
                            <div className="small text-primary">Last Published On:</div>
                            <div>
                                {courseDetailData.lastSubmittedOnLMS &&
                                    <Moment format="MM/DD/YYYY">
                                        {courseDetailData.lastSubmittedOnLMS}
                                    </Moment>
                                }
                            </div>
                        </div> 
                        : ''
                    }
                    
                    {(courseDetailData.isMerged===1) ?
                        <div className="col-lg-4 mt-3 mt-lg-0">
                            <div className="small text-primary">Merged By:</div>
                            <div>{courseDetailData?.mergedBy}
                                <span> on </span>
                                <Moment format="MM/DD/YYYY">
                                    {courseDetailData.mergedOn}
                                </Moment>
                            </div>
                        </div>
                        : ""
                    }
                    { courseDetailData?.mergeCourses.length === 0 ? 
                      "" :  <div className="col-12 mt-3">
                                <div className="small text-primary">Merged Courses:</div>
                                <div>
                                    {courseDetailData?.mergeCourses?.map((value: any, index: number) =>
                                        courseDetailData?.mergeCourses?.length > 1 && index != courseDetailData?.mergeCourses?.length - 1 ? value.mergedCourse + ' ' + ',' + ' ' : value.mergedCourse
                                    )}
                                </div>
                            </div>
                    }  
                    { props.sharedCourses.length === 0 ? 
                      "" :  <div className="col-12 mt-3">
                                <div className="small text-primary">In {props.sharedCourses.length} SI Courses:</div>
                                <div>
                                    {props.sharedCourses?.map((value: any, index: number) =>
                                        props.sharedCourses?.length > 1 && index != props.sharedCourses?.length - 1 ? value + ' ' + ',' + ' ' : value
                                    )}
                                </div>
                            </div>
                    }               
                </div>
            </div>
        </div>
    )
}

