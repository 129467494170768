import React, { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import schoolService from '../services/SchoolService';
import { LocationContext, UserProfileType ,AuthContextDefault} from '../services/AppContext'
import { useContext } from 'react';
import { IDropDownValue } from '../services/Utility'
import LocalStorageService from '../services/LocalStorage';



export default function SchoolDropDown(props: any) {
  const [orginalSchoolList, setOrginalSchoolList] = useState<IDropDownValue[]>([]);
  const [schoolList, setSchoolList] = useState<IDropDownValue[]>([]);
  const { locationCode, setLocationCode } = useContext(LocationContext);
  const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
  useEffect(() => { 
    setUserProfile(LocalStorageService.getUserProfile())
    if(LocalStorageService.getUserProfile()?.role?.roleName == 'System Administrator' || LocalStorageService.getUserProfile().role.roleName == "Central Staff"){
      schoolService.getData().then(response => {
        let dropDownData = response.data.map((sl: any) => ({
          id: sl.locationCode, 
          text: sl.locationCode + ' -- ' + sl.schoolName
        }));
        setLocationCode(response.data[0].locationCode)
        setSchoolList(dropDownData)
        setOrginalSchoolList(dropDownData)
      })
    }else{
     let data =  LocalStorageService.getUserProfile()?.role?.locations?.map(s=>({id:s.LocationCode,text:s.LocationCode + ' -- ' + s.LocationName}))
     
      if(data){
        setSchoolList(data)
        setOrginalSchoolList(data)
      }
      
    }
   
  }, []);

  const filterData = (filter: any) => {
    let data = orginalSchoolList.slice();
    return filterBy(data, filter);
  }

  const filterChange = (event: any) => {
    let data = filterData(event.filter)
    setSchoolList(data)
  } 

  const handleChange = (event: any) => {
    const selectedSchool = event.target.value;
    setLocationCode(selectedSchool.id);
  }

  const changeSelectedSchool = (event: any) => {
    const selectedSchool = event.target.value;
    setLocationCode(selectedSchool.id);
  }
  return (

    <DropDownList
      data={schoolList}
      defaultValue={schoolList[0]}
      textField="text"
      filterable={true}
      onChange={handleChange}
      onFilterChange={filterChange}
      onClose={changeSelectedSchool}
      
    />

  )
}
