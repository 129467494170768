import React, { useState, useEffect, useRef } from 'react';
import messageService from '../../../services/AnnouncementService';
import IMessage from '../../../model/IAnnouncement'
import { Grid, GridColumn, GridFilterChangeEvent, GridToolbar, GridColumn as Column, 
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
 } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, } from "@progress/kendo-data-query";
import { Modal, Button, Dropdown, Form, Row, DropdownButton } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FormMode } from '../../../services/Utility'
import axios from "axios";
import Moment from 'moment';

export default function AnnouncementManage() {

  const mountedRef = useRef(true);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState([]);
  const [rowData, setRowData] = useState<IMessage[]>([]);

  const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
  const [formValue, setFormValue] = useState<IMessage>({
    description: '',
    effectiveDate: new Date(),
    expirationDate: new Date(),
    isActive: true
  });

  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  const [filter, setFilter] = useState(initialFilter);

  const handleChange = (event: any) => {
    const target = event.target //as HTMLSelectElement
    const value = target.type === 'checkbox' ? target.checked : target.value
    setFormValue({ ...formValue, [target.name]: value });
  };

  const post = (event: any) => {
    let message: IMessage = {
      description: formValue.description,
      effectiveDate: formValue.effectiveDate,
      expirationDate: formValue.expirationDate,
      isActive: formValue.isActive,
    };
    messageService.post(message).then(response => {
      // Display Success message
    })
    event.preventDefault();
  }

  const onFormAction = (messageId: number, actionType: FormMode) => {
    setErrors({
      descriptionError: '',
      effectiveDateError: '',
      expirationDateError: ''
  });
    switch (actionType) {
      case FormMode.New:
        setFormMode(FormMode.New)
        let resetMessage: IMessage = {
          description: '',
          effectiveDate: new Date(),
          expirationDate: new Date(),
          isActive: false,
        };
      setFormValue(resetMessage)
        //Clear Existing vlues
        break;
      case FormMode.Edit:
        setFormMode(FormMode.Edit)
        messageService.getById(messageId).then(response => {
          setFormValue(response.data)
        })
        break;
      case FormMode.Delete:
        setFormMode(FormMode.Delete)
        messageService.getById(messageId).then(response => {
          setFormValue(response.data)
        })
        break;
      default: {
        setFormMode(FormMode.None)
        break;
      }
    }
  }

  const onSave = (event: any, actionType: FormMode) => {
    let message: IMessage = {
      id: formValue.id,
      description: formValue.description,
      effectiveDate: formValue.effectiveDate,
      expirationDate: formValue.expirationDate,
      isActive: formValue.isActive,
    };
    switch (actionType) {
      case FormMode.New:
        event.preventDefault();
        if (validate()) {
          message.isActive = true
          messageService.post(message).then(response => {
            getAnnouncementData();
            setFormMode(FormMode.None)
          })
        }

        //Clear Existing vlues
        break;
      case FormMode.Edit:
        event.preventDefault();
        if (validate()) {
          messageService.edit(message).then(response => {
            getAnnouncementData();
            setFormMode(FormMode.None)
          })
        }
        break;
      case FormMode.Delete:
        setFormMode(FormMode.Delete)
        messageService.delete(Number(formValue.id)).then(response => {
          getAnnouncementData();
          setFormMode(FormMode.None)
        })
        break;
      default: {
        setFormMode(FormMode.None)
        break;
      }
    }
  }

  const getAnnouncementData = () => {
    messageService.getData().then(response => {
      setRowData(response.data)
    })
  }
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getAnnouncementData();
    //return () => {  source.cancel();    };
     return () => { mountedRef.current = false }
  }, []);

  const [errors, setErrors] = useState({
    descriptionError: '',
    effectiveDateError: '',
    expirationDateError: ''
  })
  const validate = () => {
    let error = {
      descriptionError: '',
      effectiveDateError: '',
      expirationDateError: ''
    };

    if (formMode == FormMode.New || formMode == FormMode.Edit) {
      if (!formValue.description) { error.descriptionError = "Description is required"; }
      if (!formValue.effectiveDate) { error.effectiveDateError = "Effective date is required"; }
      if (!formValue.expirationDate) { error.expirationDateError = "Expiration date is required"; }
      if (Moment(formValue.expirationDate).format('YYYY-MM-DD') < Moment(formValue.effectiveDate).format('YYYY-MM-DD')) { error.expirationDateError = "Expiration date must not be less than effective date." }

      setErrors(error);
      if (error.descriptionError || error.effectiveDateError || error.expirationDateError) {
        return false;
      }
    }
    return true;
  };

  const ColumnMenuFilter = (props: GridColumnMenuProps) => {
    return (
      <div>
        <GridColumnMenuFilter {...props} expanded={true} />
      </div>
    );
  };

 
  
  

  const GridColumnButton = (props: any) => {
    return (
      <td style={{ overflow: 'visible' }}>

        <DropdownButton
            menuAlign="right"
            title={<i className='fas fa-bars'></i>}
            id="dropdown-menu-align-right"
            size="sm"
            variant="outline-primary"
            className="menu-bars"
        >
            <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id, FormMode.Edit) }}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id, FormMode.Delete) }}>Delete</Dropdown.Item>
        </DropdownButton>

      </td>)
  }

  const GridDescriptionCell = (props: any) => {
    return (
      <td>
        <div className="d-flex">
          <i className="far fa-comment-alt mr-3 text-blue-gray" style={{"fontSize" : "30px"}}></i>
          <div>
            {props.dataItem.description}
          </div>
        </div>
      </td>
    )
  }

  const GridDateCell = (props: any) => {
    {Moment.locale('en')}
    return (
      <td>
        
        {Moment(props.dataItem.effectiveDate).format('MMM D')} to {Moment(props.dataItem.expirationDate).format('MMM D, YYYY')}
      </td>
    )
  }
  const GridActiveCell = (props: any) => {
    
    return (
      <td>
        {props.dataItem.isActive}
      </td>
    )

    // if (props.dataItem.IsActive == "True") {
    //   return (
    //     <td className="text-success">
    //       Active
    //     </td>
    //   )
    // } else {
    //   return (
      
    //     <td>
    //       Inactive
    //     </td>
    //   )
    // }
    
  }

  return (
    <div className="announcement-page">
      <Modal size="lg" animation={false} show={formMode == FormMode.Edit || formMode == FormMode.New} onHide={() => { setFormMode(FormMode.None) }}>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>{formMode == FormMode.Edit ? "Edit" : "Add"} Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <input type="hidden" name="id" value={formValue.id ?? ''} onChange={handleChange} />
            <Row>
              <Form.Group controlId="description" className="col-sm-12">
                <Form.Label>Announcement</Form.Label>
                <Form.Control as="textarea" rows={5} name="description" value={formValue.description ?? ''} onChange={handleChange} />
                <Form.Text className="text-danger">{errors.descriptionError}</Form.Text>
              </Form.Group>
              <Form.Group controlId="effectiveDate" className="col-sm-4">
                <Form.Label>Effective</Form.Label>
                <Form.Control type="date" name="effectiveDate"  value={Moment(formValue.effectiveDate).format('YYYY-MM-DD') ?? ''} onChange={handleChange} />
                <Form.Text className="text-danger">{errors.effectiveDateError}</Form.Text>
              </Form.Group>
              <Form.Group controlId="expirationDate" className="col-sm-4">
                <Form.Label>Expires</Form.Label>
                <Form.Control type="date" name="expirationDate" value={Moment(formValue.expirationDate).format('YYYY-MM-DD') ?? ''} onChange={handleChange} />
                <Form.Text className="text-danger">{errors.expirationDateError}</Form.Text>
              </Form.Group>
              <Form.Group hidden={formMode == FormMode.New} controlId="isActive" className="col-sm-4 mt-sm-5 mt-0 text-left text-sm-right">
                <Form.Check type="checkbox" name="isActive" label="Active" checked={formValue?.isActive} onChange={handleChange} />
                {/* <Form.Text className="text-danger">{errors.expirationDateError}</Form.Text> */}
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setFormMode(FormMode.None) }}>Close</Button>
          <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={formMode == FormMode.Delete}
        onHide={() => { setFormMode(FormMode.None) }}
        animation={false}
        backdrop="static"
        keyboard={false}>

        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Delete Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the message?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
          <Button variant="primary" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
        </Modal.Footer>
      </Modal>
      <div className="text-right mb-2 mt-2">
        <Button type="button" variant="primary" onClick={(e) => { onFormAction(0, FormMode.New) }}>
          <i className="fas fa-plus"></i>
        </Button>
      </div>
      <Grid data={filterBy(rowData, filter)}
        filter={filter}
        filterable={false}
        pageable={true}
        sortable={true}
        scrollable={"none"}
        onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
      >

        <GridColumn field="description" title="Announcements" cell={GridDescriptionCell} columnMenu={ColumnMenuFilter} />
        <GridColumn field="effectiveDate" title="Effective" cell={GridDateCell} columnMenu={ColumnMenuFilter} width={225} />
        <GridColumn field="isActive" title="Active" columnMenu={ColumnMenuFilter} />
        <GridColumn field="AnnouncementId" filterable={false} title=" " className="text-right" cell={GridColumnButton} width={60} />
      </Grid>
    </div>
  )
}

