import React, { useState, useEffect, useReducer, useRef, useContext, useCallback } from 'react';
import {
    Grid, GridColumn
} from '@progress/kendo-react-grid';
import GridHierarchyExpand from '../../shared/GridHierarchyExpand'
import { Modal, Button, Dropdown, Card, Tooltip, DropdownButton, OverlayTrigger, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FormMode, IDropDownValue } from '../../services/Utility'
import IndividualCourses from './IndividualCourses'
import AssignedTeachers from './AssingedTeachers'
import AssignedAdditionalStaff from './AssignedAdditionalStaff'
import AddVendorContent from './CourseVendorContent'
import EnrolledStudent from './EnrolledStudent'
import AddNewCourse from './AddNewCourse'
import MergeCourses from './MergeCourses'
import MergeError from './MergeError'
import LicenseSummary from './LicenseSummary'
import codesetService from '../../services/CodesetService';
import CourseService from '../../services/CoursesService'
import TeacherService from '../../services/TeacherService'
import ICodeSet from '../../model/Codeset'
import ICourses from '../../model/ICourses';
import ICourseVendorContent from '../../model/ICourseVendorContent';
import { IAssignedTeachers } from '../../model/IAssignedTeachers';
import CourseDetail from './CourseDetail';
import ICourseDetail from '../../model/ICourseDetail';
import IconCourseEnvelop from '../../assets/icons/icon-envelop-course';
import IMergeCourses from '../../model/IMergeCourses';
import IMergeCourseSpec from "../../model/IMergeCourseSpec";
import LocalStorageService from '../../services/LocalStorage';
import { AuthContextDefault, UserProfileType, Year } from '../../services/AppContext';
import SplashScreen from '../../shared/SplashScreen'
import SharedCoursesList from '../../shared/SharedCoursesList';
import IMultipleCoursesVendorContent from '../../model/IMultipleCoursesVendorContent';
import AddCoursesForVendorContent from './AddCoursesForVendorContent';

interface ILocationCode {
    id: string,
}

const initialDataState = {
    skip: 0,
    take: 10,
};

interface CourseFilters {
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    searchCourseStatus: string,
    searchCourseType: string,
    searchCourseName: string
}

interface DataSort {
    sort: string,
    value: string,
}

interface CourseTypes {
    courseType: string,
    value: string,
}

interface CourseStatus {
    courseStatus: string,
    value: string,
}

const initialDataSort: DataSort[] = [
    { sort: 'Sort by Date (Newest)', value: 'DateDesc' },
    { sort: 'Sort by Date (Oldest)', value: 'DateAsc' },
    { sort: 'Sort by Name (A-Z)', value: 'NameAsc' },
    { sort: 'Sort by Name (Z-A)', value: 'NameDesc' },
]

const initialCourseTypes: CourseTypes[] = [
    { courseType: 'Filter By Course Type', value: "" },
    { courseType: 'Manual Courses', value: 'Manual' },
    { courseType: 'Shared Instruction Courses', value: 'Shared' },
    { courseType: 'STARS Courses', value: 'STARS' },
]

const initialCourseFilters: CourseFilters =
{
    pageNumber: 1,
    pageSize: initialDataState.take,
    orderBy: "DateDesc",
    searchCourseStatus: "",
    searchCourseType: "",
    searchCourseName: ""
}


export default function Courses(locationCode: ILocationCode) {
    const mountedRef = useRef(true);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    //Page Load event - Get data from Service
    const [data, setDefaultExpand] = useState<ICourses[]>([]);
    const [sortValue] = useState<DataSort[]>(initialDataSort);
    const [filterCourseTypes] = useState<CourseTypes[]>(initialCourseTypes);
    const [filterCourseStatus, setFilterCourseStatus] = useState<CourseStatus[]>([]);

    const [defaultSortValue] = useState<DataSort>({ sort: 'Sort by Date (Newest)', value: 'DateDesc' });
    const [page, setPage] = React.useState(initialDataState);
    const [courseFilters, setCourseFilters] = useState<CourseFilters>(initialCourseFilters);
    const [isLoading, setIsLoading] = useState(true);
    //Force re-render the child component on Expand
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const { orginalSchoolYear } = useContext(Year);
    const [courseIdSelected, setCourseId] = useState<number>(0);
    const [vendorContentIdSelected, setVendorContentId] = useState<number>(0);
    const [checkedCourses, setCheckedCourses] = useState<ICourses[]>([]);
    const [mergeCourseData, setMergeCourseData] = useState<IMergeCourseSpec[]>([]);
    const today = new Date()
    const [refreshSubGrid, setRefreshSubGrid] = useState<boolean>(false)
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [courseDetail, setCourseDetail] = useState<ICourseDetail>({
        courseId: 0,
        schoolDBN: '',
        schoolYear: 0,
        courseName: '',
        courseSource: '',
        isMerged: 0,
        courseStatus: '',
        purpose: '',
        mergeCourses: [],
        courseContentType: '',
        teacherOfRecord: '',
        startDate: new Date(),
        endDate: orginalSchoolYear.schoolYearEndDate,
        createdBy: '',
        createdOn: new Date(),
        mergedOn: new Date(),
        mergedBy: ''
    });

    const [pageAlert, setPageAlert] = useState<string>('');
    const [courseSaveErrorMessage, setCourseSaveErrorMessage] = useState<string>('');
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [purposeList, setPurposeList] = useState<ICodeSet[]>([]);
    const [contentTypeList, setContentTypeList] = useState<IDropDownValue[]>([]);
    const [originalTeacherOfRecordList, setOriginalTeacherOfRecordList] = useState<IDropDownValue[]>([]);
    const [teacherOfRecordList, setTeacherOfRecordList] = useState<IDropDownValue[]>([]);
    const [defaultTeacher, setDefaultTeacher] = useState<IDropDownValue>({
        id: '',
        text: ''
    });

    const getCourses = () => {
        setIsLoading(true);
        CourseService.getCourses(locationCode.id, +orginalSchoolYear.id, 1, page.take, defaultSortValue.value).then(response => {
            setDefaultExpand(response.data)
            setRefreshSubGrid(!refreshSubGrid)
            setCheckedCourses([])
            setMergeCourseData([])
            setPageAlert('')
        }).catch((ex) => {
            setIsLoading(false);
            setPageAlert('Oops!, Something went wrong. Please try again.')
        }).finally(() => {
            setIsLoading(false);
        })
        return () => { mountedRef.current = false }
    }

    const deleteCourseSaveErrorMessage = () => {
        setCourseSaveErrorMessage("")
    }
    const [courseNameSearch, setCourseNameSearch] = useState<string>("");


    const onSortValueChange = (e: any) => {
        let filters = courseFilters
        filters.orderBy = e.target.value.value
        setCourseFilters(filters)
        filterCourses()

    }
    const pageChange = (event: any) => {
        setPage(event.page);
        let currentPage = (event.page.skip / event.page.take) + 1
        let filters = courseFilters
        filters.pageNumber = currentPage
        filters.pageSize = event.page.take
        setCourseFilters(filters)
        filterCourses()
    }

    const onChangeCourseNameSearch = (e: any) => {
        if (e.target.value && e.target.value != "") {
            let filters = courseFilters
            filters.searchCourseName = e.target.value
            setCourseFilters(filters)
            setCourseNameSearch(e.target.value)
        } else {
            clearCourseNameSearch()
        }
    }
    const clearCourseNameSearch = () => {
        let filters = courseFilters
        filters.searchCourseName = ""
        filters.pageNumber = 1
        setPage({ skip: 0, take: page.take })
        setCourseFilters(filters)
        setCourseNameSearch("")
        filterCourses();
    }

    const searchByCourseName = () => {
        if (courseFilters.searchCourseName && courseFilters.searchCourseName != "") {
            filterCourses();
        }
    }

    const onSearchClickEnter = (e: any) => {
        if (courseNameSearch && courseNameSearch != "" && e.charCode == 13) {
            searchByCourseName()
        }
    }

    const getCourseStatus = () => {
        if (filterCourseStatus.length == 0) {
            CourseService.getCourseStatus().then((res: any) => {
                let status: string[] = res.data
                let courseStatusFilter = status.map((element) => ({
                    courseStatus: element + ' ' + "Courses",
                    value: element,

                }));

                courseStatusFilter.unshift({
                    courseStatus: "Filter By Course Status",
                    value: "",

                });
                setFilterCourseStatus(courseStatusFilter)
            })
        }
    }

    const onStatusFilterChange = (e: any) => {
        let filters = courseFilters
        filters.searchCourseStatus = e.target.value.value
        filters.pageNumber = 1
        setPage({ skip: 0, take: page.take })
        setCourseFilters(filters)
        filterCourses();

    }
    const onCourseTypeFilterChange = (e: any) => {
        let filters = courseFilters
        filters.searchCourseType = e.target.value.value
        filters.pageNumber = 1
        setPage({ skip: 0, take: page.take })
        setCourseFilters(filters)
        filterCourses();

    }

    const filterCourses = () => {
        setIsLoading(true);
        CourseService.filterCourses(locationCode.id, +orginalSchoolYear.id, courseFilters.pageNumber, courseFilters.pageSize, courseFilters.orderBy, courseFilters.searchCourseName, courseFilters.searchCourseStatus, courseFilters.searchCourseType).then(response => {
            setDefaultExpand(response.data)
            setRefreshSubGrid(!refreshSubGrid)
            setPageAlert('')
        }).catch((ex) => {
            setIsLoading(false);
            setPageAlert('Oops!, Something went wrong. Please try again.')
        }).finally(() => {
            setIsLoading(false);
        })


        return () => { mountedRef.current = false }
    }


    useEffect(() => {
        setDefaultExpand([])
        if (locationCode.id != "" && orginalSchoolYear.id != "") {
            getCourses();
        }
        getCourseStatus()
        setUserProfile(LocalStorageService.getUserProfile())
        setCourseNameSearch("")
        setCourseFilters({
            pageNumber: 1,
            pageSize: initialDataState.take,
            orderBy: "DateDesc",
            searchCourseStatus: "",
            searchCourseType: "",
            searchCourseName: ""
        })
        setPage(initialDataState)


    }, [locationCode.id, orginalSchoolYear]);





    const onChangeStudentCount = (count: number, courseId: number) => {
        let courseArray = data;
        let courseIndex = courseArray.findIndex(o => o.courseId == courseId);
        courseArray[courseIndex].totalStudentEnrolled = courseArray[courseIndex].totalStudentEnrolled + count
        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])
        setRefreshSubGrid(!refreshSubGrid)

    }

    const onAssignTeachers = (teachers: IAssignedTeachers[], courseId: number) => {
        let courseArray = data;
        let courseIndex = courseArray.findIndex(o => o.courseId == courseId);
        courseArray[courseIndex].courseTeachers = courseArray[courseIndex].courseTeachers.concat(teachers)
        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])

    }

    const onDeleteTeacher = (teacher: IAssignedTeachers[], courseId: number) => {
        let courseArray = data;
        let courseIndex = courseArray.findIndex(o => o.courseId == courseId);
        courseArray[courseIndex].courseTeachers = teacher
        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])

    }
    const updateCourseStatus = (courseId: number) => {
        let courseArray = data;
        let courseIndex = courseArray.findIndex(o => o.courseId == courseId);
        if (courseArray[courseIndex].courseStatus != "New") {
            courseArray[courseIndex].courseStatus = "Edited"
        }

        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])
    }

    const getNewCourseTeachers = (courseTeachers: any[], newCourseTeachers: any[], action: string) => {
        if (action == "add") {
            let newTeachers = [...courseTeachers, ...newCourseTeachers].reduce((courseTeachers, teacher) => {
                const duplicateTeacher = courseTeachers.find((courseTeacher: any) => courseTeacher.teacherId === teacher.teacherId);
                if (!duplicateTeacher) {
                    courseTeachers.push(teacher);
                }
                return courseTeachers;
            }, []);
            return newTeachers
        } else {
            let newTeachers = courseTeachers.filter(oldTeacher =>
                newCourseTeachers.some(newTeacher => newTeacher === oldTeacher.teacherId)
            );
            return newTeachers;
        }
    }

    const updateCourseInfo = (courseId: number, courseTeachers: any[], studentCount: number, action: string) => {
        let courseArray = data;
        let courseIndex = courseArray.findIndex(o => o.courseId == courseId);
        if (courseArray[courseIndex].courseStatus != "New") {
            courseArray[courseIndex].courseStatus = "Edited"
        }

        let newCourseTeachers = getNewCourseTeachers(courseArray[courseIndex].courseTeachers, courseTeachers, action)
        courseArray[courseIndex].courseTeachers = newCourseTeachers;
        courseArray[courseIndex].totalStudentEnrolled = action == "add" ? studentCount + courseArray[courseIndex].totalStudentEnrolled : studentCount
        setRefreshSubGrid(!refreshSubGrid)
        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])
    }


    //On click of expand this function will call
    const SubGridHost =
        //useMemo(() =>
        useCallback((props: any) => {

            return (
                <div>
                    <CourseDetail sharedCourses={props.dataItem.starsSharedCourses} courseStatus={props.dataItem.courseStatus} dataSource={props.dataItem.courseId} location={locationCode.id} onFormAction={onFormAction} />
                    {props.dataItem.courseType && props.dataItem.courseType.toLowerCase() == "shared" && <IndividualCourses courseUniqueId={props.dataItem.courseUniqueId} courseStatus={props.dataItem.courseStatus} updateCourseInfo={updateCourseInfo} courseId={props.dataItem.courseId} locationCode={locationCode.id} />}
                    <AssignedTeachers courseUniqueId={props.dataItem.courseUniqueId} isSharedCourse={props.dataItem.courseType && props.dataItem.courseType.toLowerCase() == "shared" ? true : false} courseStatus={props.dataItem.courseStatus} courseId={props.dataItem.courseId} courseSource={props.dataItem.courseOrginType} isEditable={true}
                        locationCode={locationCode.id} onAssignTeachers={onAssignTeachers} onDeleteTeacher={onDeleteTeacher} updateCourseStatus={updateCourseStatus} />
                    {(!props.dataItem.courseType || (props.dataItem.courseType && props.dataItem.courseType.toLowerCase() != "shared")) &&
                        <AssignedAdditionalStaff courseUniqueId={props.dataItem.courseUniqueId} courseStatus={props.dataItem.courseStatus} courseId={props.dataItem.courseId}
                            courseSource={props.dataItem.courseOrginType} isEditable={true} locationCode={locationCode.id} updateCourseStatus={updateCourseStatus} />}
                    <EnrolledStudent isSharedCourse={props.dataItem.courseType && props.dataItem.courseType.toLowerCase() == "shared" ? true : false} coursePurpose={props.dataItem.coursePurpose} courseStatus={props.dataItem.courseStatus} courseId={props.dataItem.courseId} courseSource={props.dataItem.courseOrginType}
                        isEditable={true} locationCode={locationCode.id}
                        onChangeStudentCount={onChangeStudentCount}

                        updateCourseStatus={updateCourseStatus}
                    />
                    <LicenseSummary courseId={props.dataItem.courseId} />
                </div>
            )
        }
            , [refreshSubGrid]
        )

    //const onEnrolledStudentSave = () => { }
    const GridColumnHierarchyExpand = (props: any) => <GridHierarchyExpand {...props} />
    const GridColumnCourses = (props: any) => {
        const [editable, setIsEditable] = useState({ 'isEditable': false, 'buttonText': 'Edit' });
        const [name, setOnChangeName] = useState(props.dataItem.courseName);
        const onChangeName = (event: any) => {
            const target = event.target
            const regex = /[!@#$%^&*~`\\]+/;
            if (target.value != '' && !regex.test(target.value)) {
                courseNameChangeError.courseNameError = ''
                setOnChangeName(target.value);
            } else if (regex.test(target.value)) {
                setOnChangeName(target.value)
                courseNameChangeError.courseNameError = "Some characters are not allowed in course title for example : !@#$%^&*\\~` "
            }
            else {
                courseNameChangeError.courseNameError = "Course name is required."
                setOnChangeName(target.value);
            }
        }
        const [courseNameChangeError, setCourseNameChangeError] = useState({
            courseNameError: ''
        })
        const courseNameValidate = () => {

            if (courseNameChangeError.courseNameError) {
                return false;
            }

            return true;
        };

        const onEditClose = () => {
            courseNameChangeError.courseNameError = '';
            setOnChangeName(props.dataItem.courseName)
            setIsEditable({ 'isEditable': false, 'buttonText': 'Edit' })
        }

        const onEditClick = () => {
            if (editable.isEditable) {
                let editCourseName: ICourses = {
                    courseId: props.dataItem.courseId,
                    schoolDBN: locationCode.id,
                    schoolYear: +orginalSchoolYear.id,
                    courseName: name,
                    coursePurpose: '',
                    courseCode: '',
                    isMerged: 0,
                    courseOrginType: '',
                    courseContentType: '',
                    courseStatus: '',
                    totalStudentEnrolled: 0,
                    courseTeachers: [],
                    vendorContents: []
                }
                if (courseNameValidate()) {

                    CourseService.edit(editCourseName).then(response => {
                        if (!response.data.isValidResponse) {
                            //TODO: Display error message in proper format
                            courseNameChangeError.courseNameError = response.data.errors.join();
                            // setOnChangeName(props.dataItem.courseName)
                            // setTimeout(() => { setPageAlert('') }, 8000);
                            setIsEditable({ 'isEditable': true, 'buttonText': 'Save' })
                        }
                        else {
                            // Display Success message 
                            setOnChangeName(editCourseName.courseName);
                            let courseArray = data;
                            let courseIndex = courseArray.findIndex(o => o.courseId == editCourseName.courseId);
                            courseArray[courseIndex].courseName = editCourseName.courseName
                            if (courseArray[courseIndex].courseStatus != "New") {
                                courseArray[courseIndex].courseStatus = "Edited"
                            }
                            setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])
                            setRefreshSubGrid(!refreshSubGrid)
                            setIsEditable({ 'isEditable': false, 'buttonText': 'Edit' })
                        }
                    })
                }

            }
            else {
                setIsEditable({ 'isEditable': true, 'buttonText': 'Save' })
            }
        }

        function CourseNameBadge(props: ICourses) {

            return (

                <>
                    {
                        <OverlayTrigger
                            key={props.courseId}
                            placement='top'
                            overlay={
                                <Tooltip id={`tooltip-${props.courseId}`}>
                                    {props.courseName}
                                </Tooltip>
                            }
                        >
                            {(userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && props.courseStatus != "Closed") ? <Button key={props.courseId} className="text-dark mb-0 h4 p-0 font-weight-bold btn-lg title-edit text-left" variant="link" style={{ display: editable.isEditable ? "none" : "block" }} onClick={() => onEditClick()}>{props.courseName}</Button> :
                                <Button key={props.courseId} className="text-dark mb-0 h4 p-0 font-weight-bold btn-lg  text-left" variant="link" >{props.courseName}</Button>}
                        </OverlayTrigger>
                    }
                </>
            );
        }

        function TeacherBadge() {

            return (

                <>
                    {props.dataItem.courseTeachers.map((item: IAssignedTeachers) => (
                        <OverlayTrigger
                            key={item.teacherId}
                            placement='top'
                            overlay={
                                <Tooltip id={`tooltip-${item.teacherId}`}>
                                    {item.firstName + item.lastName + '(' + item.role + ')'}
                                </Tooltip>
                            }
                        >
                            <Button key={item.teacherId} className={item.role.toLowerCase() == "teacher of record" ? "btn badge badge-warning badge-teacher-record border-0 mr-1" : "btn badge badge-warning border-0 mr-1"}>{item.teacherInitial ? item.teacherInitial : item.firstName?.charAt(0) + item.lastName?.charAt(0)}</Button>
                        </OverlayTrigger>
                    ))}
                </>
            );
        }



        return (
            <td className='td-course-title'>
                <div>
                    <div className="input-group" style={{ display: editable.isEditable ? "flex" : "none" }}>
                        <input type="text" name="description" className="form form-control form-control-sm text-ellipse" value={name} onChange={onChangeName} maxLength={128} />
                        <button type="button" onClick={(e) => onEditClose()} className="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="input-group-append">
                            <Button className="btn btn-primary btn-sm" onClick={(e) => onEditClick()}>{editable.buttonText}</Button>
                        </div>
                    </div>
                    <div className="text-danger small">{courseNameChangeError.courseNameError}</div>
                    <CourseNameBadge {...props.dataItem} />
                    <div className="text-secondary">
                        {props.dataItem.courseOrginType.toLowerCase() === 'lmi' ? '' :
                            <div>Course Code: {props.dataItem.courseCode}</div>}
                        <div>Assigned Teachers: <TeacherBadge /> </div>
                        {/* <div className="d-flex flex-row"> */}
                        <div className='mr-1'><span >Students: {props.dataItem.totalStudentEnrolled}</span>
                            {props.dataItem.starsSharedCourses.length != 0 &&
                                <SharedCoursesList
                                    sharedCourses={props.dataItem.starsSharedCourses}
                                    width='70%'

                                />
                            }</div>

                        {/* </div> */}

                    </div>
                </div>
            </td>
        )
    }

    const GridColumnButton = (props: any) => {
        let propsData = props.dataItem as ICourseDetail
        const overlayTarget = useRef(null);
        const [showConfirmation, setShowConfirmation] = useState({
            canShow: false,
            title: '',
            action: ''
        });

        const onConfirmation = (e: any, title: string, action: string) => {
            setShowConfirmation({ canShow: true, title: title, action: action })
            e.preventDefault();
        }

        const onCourseAction = (e: any, action: string) => {
            setIsLoading(true);
            let courseDetail = props.dataItem as ICourseDetail
            courseDetail.schoolDBN = locationCode.id
            switch (action) {
                case 'Publish':
                    CourseService.publishCourse(courseDetail).then(response => {

                        if (!response.data.isValidResponse) {
                            //TODO: Display error message in proper format
                            setPageAlert(response.data.errors.join())
                            // setTimeout(() => { setPageAlert('') }, 8000);

                        }
                        else {

                            filterCourses()
                            setPageAlert('')
                        }
                    }).finally(() => {
                        setIsLoading(false);
                        setTimeout(() => { setPageAlert('') }, 8000);
                    })
                    break;
                case 'Delete':
                    CourseService.deleteCourse(courseDetail).then(response => {

                        filterCourses()
                    }).finally(() => {
                        setIsLoading(false);
                        setPageAlert('')
                    })
                    break;
                case 'Close':
                    setIsLoading(true)
                    CourseService.closeCourse(courseDetail).then(response => {
                        let courseArray = data;
                        let courseIndex = courseArray.findIndex(o => o.courseId == courseDetail.courseId);
                        courseArray[courseIndex].courseStatus = 'Closed'
                        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])
                    }).finally(() => {
                        setIsLoading(false);
                        setPageAlert('')
                    })
                    break;
                case 'Reopen':
                    CourseService.reOpenCourse(courseDetail).then(response => {
                        let courseArray = data;
                        let courseIndex = courseArray.findIndex(o => o.courseId == courseDetail.courseId);
                        courseArray[courseIndex].courseStatus = 'Published'
                        setDefaultExpand([...courseArray.slice(0, courseIndex), courseArray[courseIndex], ...courseArray.slice(courseIndex + 1)])
                    }).finally(() => {
                        setIsLoading(false)
                        setPageAlert('')
                    })

                    break;
                default:
                    break;
            }
            e.preventDefault();
        }

        const getVendorContent = (props: any) => (
            props.dataItem.vendorContents?.map((value: any, i: number) => (
                <span>test</span>
            ))
        )

        const hasIlearnNYCContent = (props: any) => {
            return (showConfirmation.action == "Publish" && (props.dataItem.totalStudentEnrolled > 0   && !(props.dataItem as ICourses).vendorContents.some(x => x.vendorContentName ==='iLearnNYC Student License')))
        }
        return (
            <>
                {/* <Confirmation showConfirmation={showConfirmation}  ref={overlayTarget}></Confirmation>  */}
                <Modal size="lg"
                    show={showConfirmation.canShow}
                    onHide={() => { setShowConfirmation({ canShow: false, title: '', action: '' }) }}
                    animation={false}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton className="bg-primary text-white">
                        <Modal.Title>
                            {showConfirmation.action == 'Close' && "Close course"}
                            {showConfirmation.action == 'Delete' && "Delete course"}
                            {showConfirmation.action == 'Reopen' && "Reopen course"}
                            {(showConfirmation.action != 'Close' && showConfirmation.action != 'Delete' && showConfirmation.action != 'Reopen') && "Publish to iLearn"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <b> {showConfirmation.action != 'Publish' && showConfirmation.title} </b>
                        {(showConfirmation.action == 'Publish' && props.dataItem.vendorContents.length == 0 && props.dataItem.starsSharedCourses.length == 0) ?
                            <div>
                                You are publishing <b> {showConfirmation.title} </b> course. Are you sure you want to proceed?

                            </div> : (showConfirmation.action == 'Publish' && props.dataItem.vendorContents.length != 0 && props.dataItem.starsSharedCourses.length == 0) ?
                                <div>
                                    You are publishing <b> {showConfirmation.title} </b> course with the following vendor content.
                                    <Card className="mt-3 mb-3">
                                        <Card.Body>
                                            {props.dataItem.vendorContents.length != 0 &&
                                                <div>
                                                    <div className="text-muted small">Vendor Content:</div>
                                                    <div className="font-weight-bold">
                                                        {props.dataItem.vendorContents?.map((value: any, i: number) => (
                                                            <span>{value.vendorContentName}  {i != props.dataItem.vendorContents.length - 1 ? ',' : ' '} </span>
                                                        ))}
                                                    </div></div>}

                                        </Card.Body>
                                    </Card>
                                    <div className="font-weight-normal">Are you sure you want to proceed? You will not be able to change this.</div>
                                </div> : (showConfirmation.action == 'Publish' && props.dataItem.vendorContents.length == 0 && props.dataItem.starsSharedCourses.length != 0) ?
                                    <div>
                                        You are publishing <b> {showConfirmation.title} </b> course that is part of these shared instruction courses
                                        <Card className="mt-3 mb-3">
                                            <Card.Body>
                                                {props.dataItem.starsSharedCourses.length != 0 &&
                                                    <div>
                                                        <div className="text-muted small">Shared Instruction Courses:</div>
                                                        <div className="font-weight-bold">
                                                            {props.dataItem.starsSharedCourses?.map((value: any, i: number) => (
                                                                <span>{value}  {i != props.dataItem.starsSharedCourses.length - 1 ? ',' : ' '} </span>
                                                            ))}
                                                        </div></div>}

                                            </Card.Body>
                                        </Card>
                                        <div className="font-weight-normal">Are you sure you want to proceed?</div>
                                    </div> : (showConfirmation.action == 'Publish' && props.dataItem.vendorContents.length != 0 && props.dataItem.starsSharedCourses.length != 0) &&
                                    <div>
                                        You are publishing <b> {showConfirmation.title} </b> course with the following vendor content.
                                        <Card className="mt-3 mb-3">
                                            <Card.Body>
                                                {props.dataItem.vendorContents.length != 0 &&
                                                    <div>
                                                        <div className="text-muted small">Vendor Content:</div>
                                                        <div className="font-weight-bold">
                                                            {props.dataItem.vendorContents?.map((value: any, i: number) => (
                                                                <span>{value.vendorContentName}  {i != props.dataItem.vendorContents.length - 1 ? ',' : ' '} </span>
                                                            ))}
                                                        </div></div>}

                                            </Card.Body>
                                        </Card>
                                        And part of these shared instruction courses
                                        <Card className="mt-3 mb-3">
                                            <Card.Body>
                                                {props.dataItem.starsSharedCourses.length != 0 &&
                                                    <div>
                                                        <div className="text-muted small">Shared Instruction Courses:</div>
                                                        <div className="font-weight-bold">
                                                            {props.dataItem.starsSharedCourses?.map((value: any, i: number) => (
                                                                <span>{value}  {i != props.dataItem.starsSharedCourses.length - 1 ? ',' : ' '} </span>
                                                            ))}
                                                        </div></div>}

                                            </Card.Body>
                                        </Card>
                                        <div className="font-weight-normal">Are you sure you want to proceed? You will not be able to change this.</div>
                                    </div>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        {
                            hasIlearnNYCContent(props)
                                ? <div className="alert alert-danger" role="alert">iLearnNYC Student License is needed to publish course with students.</div>
                                : ''
                        }
                        <Button variant="secondary" onClick={(e) => { setShowConfirmation({ canShow: false, title: '', action: '' }) }}>Cancel</Button>
                        {
                            ((props.dataItem.courseStatus == "Edited" && (showConfirmation.action == "Delete" || showConfirmation.action == "Close")) || hasIlearnNYCContent(props))
                                ? ''
                                : <Button variant="primary" onClick={(e) => { onCourseAction(e, showConfirmation.action) }}>{showConfirmation.action}</Button>
                        }
 
                        {/* {(props.dataItem.courseStatus == "Edited" && (showConfirmation.action == "Delete" || showConfirmation.action == "Close")) ? '' : <Button variant="primary" onClick={(e) => { onCourseAction(e, showConfirmation.action) }}>{showConfirmation.action}</Button>} */}
                    </Modal.Footer>
                </Modal>


                <td className="text-right td-course-btn" style={{ overflow: 'visible' }}>
                    {(isLoading && overlayTarget?.current) ? <SplashScreen /> :
                        <DropdownButton
                            menuAlign="right"
                            title={<i className='fas fa-bars'></i>}
                            id="dropdown-menu-align-right"
                            size="sm"
                            variant="outline-primary"
                            className="menu-bars"
                        >
                            {(props.dataItem.courseStatus != "Closed" && props.dataItem.courseStatus != "Published") &&
                                <Dropdown.Item onClick={(e) => {
                                    onConfirmation(e, "" + propsData.courseName + ""
                                        , 'Publish')
                                }}>Publish</Dropdown.Item>}
                            {(props.dataItem.courseStatus == "Closed" || (props.dataItem.courseStatus == "New" || (props.dataItem.courseOrginType == "STARS" && props.dataItem.courseStatus != "Published" && props.dataItem.courseStatus != "Edited"))) ? '' : <Dropdown.Item onClick={(e) => { onConfirmation(e, props.dataItem.courseStatus == "Edited" ? ` Course has been modified. Please publish to iLearn, before the course ${propsData.courseName} can be closed.` : `Are you sure  you want to Close the course ${propsData.courseName} ?`, 'Close') }}>Close</Dropdown.Item>}
                            {/* {(props.dataItem.courseStatus == "New" && props.dataItem.courseOrginType == "STARS") && <Dropdown.Item onClick={(e) => { mergeCourses() }}>Merge {checkedCourses.length === 0 ? '' : checkedCourses.length} Courses </Dropdown.Item>} */}
                            {(props.dataItem.courseOrginType == "STARS" || (props.dataItem.courseType && props.dataItem.courseType.toLowerCase() == "shared" && props.dataItem.courseStatus != "Closed")) ? '' : <Dropdown.Item onClick={(e) => { onConfirmation(e, props.dataItem.courseStatus == "Edited" ? ` Course has been modified. Please publish to iLearn, before the course ${propsData.courseName} can be deleted. ` : `Are you sure  you want to Delete the course ${propsData.courseName} ?`, 'Delete') }}>Delete</Dropdown.Item>}
                            {props.dataItem.courseStatus == "Closed" && <Dropdown.Item onClick={(e) => { onConfirmation(e, `Are you sure  you want to Reopen the course ${propsData.courseName} ?`, 'Reopen') }}>Reopen</Dropdown.Item>}
                        </DropdownButton>

                    }
                </td >
            </>
        )
    }

    const [showVendorContent, setShowVendorContent] = useState(FormMode.None);
    const [showCoursesForSelectedVendorContent, setShowCoursesForSelectedVendorContent] = useState(FormMode.None);


    const GetStatusBadgeCssClass = (status: string) => {

        if (status.toLowerCase() === "new") {
            return "badge badge-blue-gray"
        }
        else if (status.toLowerCase() === "edited") {
            return "badge badge-secondary"
        }
        else if (status.toLowerCase() === "published") {
            return "badge badge-success"
        }
        else if (status.toLowerCase() === "closed") {
            return "badge badge-danger"
        }
        else if (status.toLowerCase() === "reopened") {
            return "badge badge-info"
        }
    }

    const GridColumnVendorContent = (props: any) => {
        let vendorContents: Array<ICourseVendorContent> = props.dataItem.vendorContents;
        let onDeleteVendorContent = (VendorContentId: number) => {
            setCourseId(props.dataItem.courseId)
            setVendorContentId(VendorContentId)
            setShowVendorContent(FormMode.Delete)
        }
        let onAddVendorContent = () => {
            setCourseId(props.dataItem.courseId)
            setShowVendorContent(FormMode.New)
        }

        function VendorContentBadge(props: ICourseVendorContent) {

            return (

                <>
                    <OverlayTrigger
                        key={props.vendorContentId}
                        placement='top'
                        overlay={
                            <Tooltip id={`tooltip-${props.vendorContentId}`}>
                                {props.vendorContentName}
                            </Tooltip>
                        }
                    >
                        <div className="form form-control form-control-sm" key={props.vendorContentId}>{props.vendorContentName}</div>
                    </OverlayTrigger>

                </>
            );
        }

        let vendorList = vendorContents.map((value, i) =>

            <div className="input-group" key={i}>
                <VendorContentBadge {...value} />
                {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && props.dataItem.courseContentType != 'Content to be Teacher-Created' &&
                    <div className="input-group-append">
                        {value?.statusCode?.toLowerCase() == 's' || props.dataItem.courseStatus.toLowerCase() === 'closed' || props.dataItem.courseStatus.toLowerCase() === 'published' || props.dataItem.courseStatus.toLowerCase() === 'reopened' ? ' ' :
                            <Button size="sm" variant="outline-secondary" onClick={(e) => { onDeleteVendorContent(value.vendorContentId) }}><i className="fas fa-trash-alt"></i></Button>
                        }
                        {(vendorContents.length - 1 == i && userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && props.dataItem.courseStatus.toLowerCase() != 'closed') && (
                            <Button size="sm" variant="outline-primary" onClick={(e) => { onAddVendorContent() }}><i className="fas fa-plus"></i></Button>
                        )}
                    </div>
                }
            </div>
        );
        return (
            <td className="td-vendor-content">
                <div className="input-group-space">
                    {vendorList}
                    <div style={{ display: vendorContents.length == 0 ? "flex" : "none" }} >
                        {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && props.dataItem.courseStatus.toLowerCase() != 'closed' && props.dataItem.courseContentType != 'Content to be Teacher-Created' && <Button size="sm" variant="outline-primary" onClick={(e) => { onAddVendorContent() }}><i className="fas fa-plus"></i></Button>}
                    </div>
                </div>
            </td>)
    }

    const GridStatusCell = (props: any) => {
        return (
            <td className='td-course-status'>
                <div className={GetStatusBadgeCssClass(props.dataItem.courseStatus)}>{props.dataItem.courseStatus}</div>
            </td>
        )
    }

    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        if (dataItem.isSelected) {
            let items: ICourses = {
                courseId: dataItem.courseId,
                schoolDBN: dataItem.schoolDBN,
                schoolYear: +orginalSchoolYear.id,
                courseName: dataItem.courseName,
                coursePurpose: dataItem.coursePurpose,
                courseCode: dataItem.courseCode,
                isMerged: dataItem.isMerged,
                courseOrginType: dataItem.courseOrginType,
                courseContentType: dataItem.courseContentType,
                courseStatus: dataItem.courseStatus,
                totalStudentEnrolled: dataItem.totalStudentEnrolled,
                courseTeachers: dataItem.courseTeachers,
                vendorContents: dataItem.vendorContents
            }
            let mergeData: IMergeCourseSpec = {
                courseId: dataItem.courseId,
                isPrimary: false
            }

            setCheckedCourses([...checkedCourses, items])
            setMergeCourseData([...mergeCourseData, mergeData]
            )
        }
        else {
            const items = checkedCourses.filter(item => item.courseId !== dataItem.courseId);
            const mergeItems = mergeCourseData.filter(item => item.courseId !== dataItem.courseId);
            setCheckedCourses(items)
            setMergeCourseData(mergeItems)
        }
        forceUpdate();
    }

    const [courseNameChangeError, setCourseNameChangeError] = useState({
        courseNameError: ''
    })

    const [errors, setErrors] = useState({
        mergeError: ''
    })
    const validate = () => {
        let vendorCheck: any = checkedCourses.filter(item => { return item.vendorContents.length > 0 });
        let newCourseCheck: any = checkedCourses.filter(item => item.courseStatus.toLowerCase() !== 'new');
        let mergeCourseCheck: any = checkedCourses.filter(item => item.isMerged === 1);
        let coursesOriginCheck: any = checkedCourses.filter(item => item.courseOrginType.toLowerCase() !== 'stars');
        let error = {
            mergeError: '',
        };
        if (vendorCheck.length > 0) { error.mergeError = "Please remove all vendor contents before merging the course."; }
        if (coursesOriginCheck != '') { error.mergeError = "Only Stars course can be merged."; }
        if (newCourseCheck != '') { error.mergeError = "Only New courses can be merged. Deselect Published courses."; }
        if (checkedCourses.length < 2) { error.mergeError = "Please choose 2 or more courses in order to merge"; }
        if (checkedCourses.length > 10) { error.mergeError = "Cannot merge more than 10 courses. Please deselect."; }
        if (mergeCourseCheck != '') { error.mergeError = "Merged courses can't be merged again"; }
        setErrors(error);
        if (error.mergeError) {
            setFormMode(FormMode.Error)
            return false;
        }

        return true;
    };
    // Header Checkbox Template.. Binds the CheckBox in the Header
    const [headerCheckBoxValue, setHeaderCheckBox] = useState(false);
    const onHeaderCheckBoxClick = (event: any, products?: any) => {
        let checked = event.target.checked;
        setHeaderCheckBox(checked)
    }

    const closeCourseVendorContentModal = (error: string) => {
        if (error != "") {
            //TODO: Display error message in proper format
            setPageAlert(error)
            setTimeout(() => { setPageAlert('') }, 8000);

        }
        CourseService.getVendorContentData(courseIdSelected, locationCode.id).then(response => {
            let vendorContents = response.data as ICourseVendorContent[];
            let courseArray = data;
            let courseIndex = courseArray.findIndex(o => o.courseId == courseIdSelected);
            courseArray[courseIndex].vendorContents = vendorContents;
            setDefaultExpand(courseArray);
            let courses = checkedCourses
            if (courses.length > 0) {
                let index = courses.findIndex(o => o.courseId == courseIdSelected);
                courses[index].vendorContents = vendorContents;
                setCheckedCourses(courses);
            }
            setRefreshSubGrid(!refreshSubGrid)
            setShowVendorContent(FormMode.None)

        });
    }


    const closeCourseForVendorContentModal = (error: string, vendorInfo: IMultipleCoursesVendorContent) => {
        if (error != "") {
            //TODO: Display error message in proper format
            setPageAlert(error)
            setTimeout(() => { setPageAlert('') }, 8000);

        };
        if (vendorInfo.courseId.length !== 0) {
            getCourses();
        }
        setShowCoursesForSelectedVendorContent(FormMode.None);
    }
    const deleteVendorContent = () => {
        let deleteCourseVendorContent: ICourseVendorContent = {
            courseId: courseIdSelected,
            schoolDBN: locationCode.id,
            vendorContentId: vendorContentIdSelected,
            vendorId: 0,
            vendorContentName: '',
            vendorName: '',
            availableLicenses: 0,
            totalLicenses: 0,
            statusCode: ''
        }
        CourseService.delete(deleteCourseVendorContent).then(response => {
            // Display Success message          
            let courseArray = data;
            let courseIndex = courseArray.findIndex(o => o.courseId == courseIdSelected);
            var vendorContentArray = courseArray[courseIndex].vendorContents
            let vendorContentIndex = vendorContentArray.findIndex(o => o.vendorContentId == vendorContentIdSelected);
            vendorContentArray.splice(vendorContentIndex, 1);
            courseArray[courseIndex].vendorContents = vendorContentArray
            if (courseArray[courseIndex].courseStatus != "New") {
                courseArray[courseIndex].courseStatus = "Edited"
            }
            setDefaultExpand(courseArray);

            let courses = checkedCourses
            if (courses.length > 0) {
                let index = courses.findIndex(o => o.courseId == courseIdSelected);
                var ContentArray = courses[index].vendorContents
                let ContentIndex = ContentArray.findIndex(o => o.vendorContentId == vendorContentIdSelected);
                ContentArray.splice(ContentIndex, 1);
                courses[index].vendorContents = ContentArray
                setCheckedCourses(courses);
            }
            setRefreshSubGrid(!refreshSubGrid)
            setShowVendorContent(FormMode.None)
        })

    }
    const onGridExpand = (event: any) => {
        //  dataItem.expanded = !dataItem.expanded;
        let courseArray = data;
        courseArray.map((item: any) => {
            if (event.dataItem.courseId === item.courseId) {
                item.expanded = !event.dataItem.expanded;
            } else {
                item.expanded = false;
            }
        })
        setDefaultExpand(courseArray);
        forceUpdate();
    }

    const onLicensesClick = () => {
        setShowCoursesForSelectedVendorContent(FormMode.New)
    }

    const onFormAction = (e: any, course: ICourseDetail, actionType: FormMode) => {

        switch (actionType) {
            case FormMode.New:
                setFormMode(FormMode.New)
                setDefaultTeacher({ id: '', text: '' })
                setCourseDetail({
                    courseId: 0,
                    schoolDBN: '',
                    schoolYear: 0,
                    courseName: '',
                    courseSource: '',
                    isMerged: 0,
                    courseStatus: '',
                    purpose: '',
                    mergeCourses: [],
                    courseContentType: '',
                    teacherOfRecord: '',
                    startDate: new Date(),
                    endDate: orginalSchoolYear.schoolYearEndDate,
                    createdBy: '',
                    createdOn: new Date(),
                    mergedOn: new Date(),
                    mergedBy: ''
                });
                TeacherService.getTeachersBySchoolDBN(locationCode.id).then(response => {
                    let data = response.data as IAssignedTeachers[]
                    let teacherList = data.map((tl: any) => ({
                        id: tl.teacherId,
                        text: tl.teacherId + ' | ' + tl.firstName + '  ' + tl.lastName
                    }));
                    setTeacherOfRecordList(teacherList)
                    setOriginalTeacherOfRecordList(teacherList)

                });
                codesetService.getData().then(response => {
                    let data = response.data as ICodeSet[];
                    let purposeList = data.filter(x => x.codeSetName.toLowerCase().includes('coursepurpose'));
                    let contentTypeList = data.filter(x => x.codeSetName.toLowerCase().includes('coursecontenttype')).map((cl: any) => ({
                        id: cl.id,
                        text: cl.displayText
                    }));
                    setPurposeList(purposeList);
                    setContentTypeList(contentTypeList);
                });
                break;
            case FormMode.Edit:
                setDefaultTeacher({ id: '', text: '' })
                let courseUniqueId = data.find(c => c.courseId == course.courseId);
                if (courseUniqueId) {
                    course.courseUniqueId = courseUniqueId.courseUniqueId
                }

                setCourseDetail(course)
                TeacherService.getTeachersBySchoolDBN(locationCode.id).then(response => {
                    let data = response.data as IAssignedTeachers[]
                    let teacherList = data.map((tl: any) => ({
                        id: tl.teacherId,
                        text: tl.teacherId + ' | ' + tl.firstName + '  ' + tl.lastName
                    }));
                    setTeacherOfRecordList(teacherList)
                    setOriginalTeacherOfRecordList(teacherList)
                    let teacher = teacherList.find(x => (x.id) === course.teacherOfRecord) as IDropDownValue
                    if (teacher != null) {
                        setDefaultTeacher(teacher)
                    }
                    else {
                        TeacherService.getTeacherById(course.teacherOfRecord).then(response => {
                            let teacher = {
                                id: response.data.teacherId,
                                text: response.data.teacherId + ' | ' + response.data.firstName + '  ' + response.data.lastName
                            }
                            setDefaultTeacher(teacher)
                        })
                    }
                });

                codesetService.getData().then(response => {
                    let data = response.data as ICodeSet[];
                    let purposeList = data.filter(x => x.codeSetName.toLowerCase().includes('coursepurpose'));

                    let contentTypeList = data.filter(x => x.codeSetName.toLowerCase().includes('coursecontenttype')).map((cl: any) => ({
                        id: cl.id,
                        text: cl.displayText
                    }));
                    setPurposeList(purposeList);
                    setContentTypeList(contentTypeList);
                });
                setFormMode(FormMode.Edit)

                break;
            default: {

                break;
            }
        }
        e.preventDefault();
    }
    const onCourseSave = (event: any, actionType: FormMode, data: ICourseDetail) => {
        //  let courseUniqueId = data.find(c=>c.)
        switch (actionType) {
            case FormMode.New:
                event.preventDefault();
                CourseService.postCourse(data).then(response => {
                    // Display Success message
                    if (!response.data.isValidResponse) {
                        //TODO: Display error message in proper format
                        // setPageAlert(response.data.errors.join())
                        setCourseSaveErrorMessage(response.data.errors.join())
                        // setTimeout(() => { setPageAlert('') }, 8000);

                    } else {
                        getCourses();
                        setCourseSaveErrorMessage("")
                        setFormMode(FormMode.None)
                    }
                }
                );
                //Clear Existing vlues
                break;
            case FormMode.Edit:
                event.preventDefault();
                CourseService.editCourse(data).then(response => {
                    // Display Success message
                    if (!response.data.isValidResponse) {
                        //TODO: Display error message in proper format
                        //   setPageAlert(response.data.errors.join())
                        setCourseSaveErrorMessage(response.data.errors.join())
                        // setTimeout(() => { setPageAlert('') }, 8000);

                    }
                    else {
                        getCourses();
                        setCourseSaveErrorMessage("")
                        setFormMode(FormMode.None)
                    }
                }
                );
                //Clear Existing vlues
                break;
            default: {
                setCourseSaveErrorMessage("")
                setFormMode(FormMode.None)
                break;
            }
        }
    }
    const onTeacherSearch = (event: any, data: IDropDownValue[]) => {
        setTeacherOfRecordList(data)
    }

    const onTeacherById = (event: any, teacherEin: string) => {
        TeacherService.getTeacherById(teacherEin).then(response => {
            let teacher = {
                id: response.data.teacherId,
                text: response.data.teacherId + ' | ' + response.data.firstName + '  ' + response.data.lastName
            }
            if (teacher.id != null) {
                let teacherExist = originalTeacherOfRecordList.find(x => x.id === teacher.id)
                if (!teacherExist) {
                    setTeacherOfRecordList(
                        teacherOfRecordList.concat(teacher))
                    setOriginalTeacherOfRecordList(
                        originalTeacherOfRecordList.concat(teacher))
                }
                setDefaultTeacher(teacher)
            }
        })
    }

    const onMergeClose = (data: FormMode) => {
        setFormMode(data)

    }

    const onMergeSave = (event: any, actionType: FormMode, data: IMergeCourseSpec[]) => {
        let mergeCourses: IMergeCourses = { mergeCourses: data };
        switch (actionType) {
            case FormMode.Merge:
                event.preventDefault();
                CourseService.merge(mergeCourses).then(response => {
                    // Display Success message
                    getCourses();
                    setFormMode(FormMode.None)
                }
                );
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }
    const mergeCourses = () => {
        if (validate()) {
            setFormMode(FormMode.Merge)
        }
        else {
            setFormMode(FormMode.Error)
        }
    }

    const el = document.querySelector(".loader-container");

    return (
        <div>
            <div style={{ display: 'inline', color: "red" }} ></div>
            <Modal
                size="lg"
                show={showVendorContent == FormMode.New}
                onHide={() => { setShowVendorContent(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Vendor Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddVendorContent updateCourseStatus={updateCourseStatus} courseId={courseIdSelected} schoolDBN={locationCode.id} closeCourseVendorContentModal={closeCourseVendorContentModal} />
                </Modal.Body>
            </Modal>
            <Modal
                show={showVendorContent == FormMode.Delete}
                onHide={() => { setShowVendorContent(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Delete Vendor Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the Vendor Content ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setShowVendorContent(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { deleteVendorContent() }}>Delete</Button>
                </Modal.Footer>
            </Modal>
            {showCoursesForSelectedVendorContent == FormMode.New && <AddCoursesForVendorContent schoolDBN={locationCode.id} closeCourseForVendorContentModal={closeCourseForVendorContentModal} />}
            {formMode == FormMode.New && <AddNewCourse courseDetail={courseDetail} schoolYear={+orginalSchoolYear.id} location={locationCode.id} formmode={formMode} purpose={purposeList} contentType={contentTypeList} originalTeacherList={originalTeacherOfRecordList} defaultTeacher={defaultTeacher} teacher={teacherOfRecordList} teacherById={onTeacherById} onSearch={onTeacherSearch} onSave={onCourseSave} courseSaveErrorMessage={courseSaveErrorMessage} setCourseSaveErrorMessage={deleteCourseSaveErrorMessage} />}
            {formMode == FormMode.Edit && <AddNewCourse courseDetail={courseDetail} schoolYear={+orginalSchoolYear.id} location={locationCode.id} formmode={formMode} purpose={purposeList} contentType={contentTypeList} originalTeacherList={originalTeacherOfRecordList} defaultTeacher={defaultTeacher} teacher={teacherOfRecordList} teacherById={onTeacherById} onSearch={onTeacherSearch} onSave={onCourseSave} courseSaveErrorMessage={courseSaveErrorMessage} setCourseSaveErrorMessage={deleteCourseSaveErrorMessage} />}
            {formMode == FormMode.Merge && <MergeCourses mergeData={mergeCourseData} courses={checkedCourses} courseCount={checkedCourses.length} formmode={formMode} onMergeClose={onMergeClose} onMerge={onMergeSave} />}
            {formMode == FormMode.Error && <MergeError mergeMessage={errors.mergeError} formmode={formMode} onMergeClose={onMergeClose} onMerge={onMergeSave} />}
            {pageAlert && pageAlert != "" && <div className="alert alert-danger" role="alert">
                {pageAlert}
            </div>}
            <Row className="mb-3">
                <Col></Col>
                <Col sm="12" xl="4" xs="12" className='text-right order-first order-xl-last mb-3 mb-xl-0 mt-n5 mt-xl-0'>
                    {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 &&
                        <button type="button" className="btn btn-primary ml-2" onClick={(e) => { onFormAction(e, courseDetail, FormMode.New) }}>
                            <i className="fas fa-plus">  </i>
                            <a> Courses</a>
                        </button>
                    }

                    {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 &&
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id={`tooltip}`}>
                                    Add the same vendor content to multiple courses at once.
                                </Tooltip>
                            }
                        >
                            <button type="button" className="btn btn-primary ml-2" onClick={(e) => { onLicensesClick() }}>
                                <i className="fas fa-plus">  </i>
                                <a> Vendor Content</a>
                            </button>
                        </OverlayTrigger>
                    }</Col>
            </Row>
            <Row className="mb-3">
                <Col xl="3" xs="10" style={{ zIndex: 1 }}>
                    <InputGroup className="input-group">
                        <FormControl
                            placeholder="Course name"
                            onChange={onChangeCourseNameSearch}
                            className="form form-control form-control-md text-ellipse"
                            maxLength={128}
                            value={courseNameSearch}
                            onKeyPress={onSearchClickEnter}
                        />
                        {courseFilters.searchCourseName && courseFilters.searchCourseName != "" &&
                            <button type="button" className="close mr-3" onClick={clearCourseNameSearch} aria-label="Close">
                                <span aria-hidden="false">&times;</span>
                            </button>
                        }
                        <div className="input-group-append">
                            <Button className="btn btn-primary btn-md " onClick={searchByCourseName} >
                                Search
                            </Button>
                        </div>
                    </InputGroup>
                </Col>
                <Col xl="9" className='text-right'>
                    <Row>
                        <Col md="4" xl="4" className='text-right mb-1 mb-md-0'>
                            <DropDownList
                                data={sortValue}
                                value={sortValue.find(i => i.value == courseFilters.orderBy)}
                                textField="sort"
                                onChange={onSortValueChange}
                                style={{ width: "100%" }}
                            /></Col>
                        <Col md="4" xl="4" className='text-right'>
                            <DropDownList
                                data={filterCourseStatus}
                                textField="courseStatus"
                                value={filterCourseStatus.find(i => i.value == courseFilters.searchCourseStatus)}
                                onChange={onStatusFilterChange}
                                style={{ width: "100%" }}
                            /></Col>
                        <Col md="4" xl="4" className='text-right'>
                            <DropDownList
                                data={filterCourseTypes}
                                textField="courseType"
                                onChange={onCourseTypeFilterChange}
                                value={filterCourseTypes.find(i => i.value == courseFilters.searchCourseType)}
                                style={{ width: "100%" }}
                            /></Col>

                    </Row>
                </Col>
            </Row>
            {isLoading && <div className="d-flex justify-content-center"><SplashScreen /></div>}
            <Grid
                data={data.slice(0, page.take)}
                detail={SubGridHost}
                expandField={'expanded'}
                scrollable={"none"}
                onExpandChange={onGridExpand}
                skip={page.skip}
                take={page.take}
                total={(data.length > 0 && data[0].totalPages) ? data[0].totalPages : 0}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                className="courses-grid"
            >

                {/* <GridColumn field="isSelected" filterable={false} width="40px" title=" " cell={props => (
                    <td className="pl-0 pr-lg-0 text-center">
                        {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 &&
                            <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                        }</td>
                )} /> */}

                <GridColumn filterable={false} field="expanded" title=' ' cell={props => (
                    <td className="pr-0 td-course-icon">
                        <div className={`svg-app-icon ${props.dataItem.courseStatus.toLowerCase() === "published" ? " text-success" : " text-blue-gray"} mr-3"`}>
                            <IconCourseEnvelop courseType={props.dataItem.courseType} courseOrginType={props.dataItem.courseOrginType} merge={props.dataItem.isMerged}></IconCourseEnvelop>
                        </div>
                    </td>
                )} />
                <GridColumn field="courseName" title="Courses" cell={GridColumnCourses} className="align-top" />
                <GridColumn field="vendorContent" title="Vendor Content" cell={GridColumnVendorContent} filterable={false} />
                <GridColumn field="courseStatus" title="Status" cell={GridStatusCell} />
                {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && <GridColumn field="buttons" title=" " filterable={false} className="text-right pl-0" cell={GridColumnButton} />}
            </Grid>
        </div>
    )
}