import { Log, UserManager } from 'oidc-client';
import appsettings from '../../assets/appsettings.json';

export default class AuthService {
    constructor() {
        const settings = {
            authority: appsettings.OIDC.REACT_APP_OIDC_AUTHORITY,
            metadataUrl: appsettings.OIDC.REACT_APP_OIDC_METADATA_URL,
            client_id: appsettings.OIDC.REACT_APP_OIDC_CLIENT_ID,
            redirect_uri: appsettings.OIDC.REACT_APP_OIDC_REDIRECT_URI,
            silent_redirect_uri: appsettings.OIDC.REACT_APP_OIDC_SILENT_REDIRECT_URI,
            post_logout_redirect_uri: appsettings.OIDC.REACT_APP_OIDC_REDIRECT_URI,
            response_type: 'code',
            scope: "openid profile offline_access",
            filterProtocolClaims: true,
            loadUserInfo: false
        };
        this.userManager = new UserManager(settings);

        Log.logger = console;
        Log.level = Log.INFO;

    }

    isAuthenticated() {
        this.authService.getUser().then(user => {
            return (user)
        });
    }

    getUser() {
        return this.userManager.getUser();
    }

    login() {
        return this.userManager.signinRedirect().then(res => {
            //they use implicit flow => 
        });
    }



    logout() {
        this.userManager.removeUser().catch((reason) => {
            console.error(reason)
        })
        this.userManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });

        this.userManager.clearStaleState();
        sessionStorage.clear();
        localStorage.clear();
        clearInterval()
        return this.userManager.signoutRedirectCallback();
    }
}