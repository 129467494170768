
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import React from 'react';

interface StringDropdownProps {
    sharedCourses: string[];
    width : string ;
}
export default function SharedCoursesList(props: StringDropdownProps) {
    const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const index = itemProps.index;
        const itemChildren =
            <div>
                {index === 0 &&<small className="text-secondary mb-1">This course is in {props.sharedCourses.length} Shared Instruction courses</small>}
                <div style={{ color: "black" }}>{li.props.children}</div>
            </div>

        return React.cloneElement(li, li.props, itemChildren);
    }
    return (
        <DropDownList
            data={props.sharedCourses}
            itemRender={itemRender}
            value={<div style={{ color: 'blue' , fontWeight :'bold' }}>In {props.sharedCourses.length} SI Courses <i className=" fas fa-solid fa-caret-down ml-1"></i></div>}
            style={{ width: props.width , padding : 0 }}
            className='shared-courses'
        
        />
    );
}
