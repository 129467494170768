import React, { useState, useEffect, useRef } from 'react';

export default function IconHome() {

  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 29 32">
      <title>Home</title>
      <path d="M28.6,10.6L15.2,0.2c-0.4-0.3-1-0.3-1.5,0L0.5,10.6C0.2,10.8,0,11.2,0,11.6v16.3 C0,30.1,1.9,32,4.1,32h20.7c2.3,0,4.1-1.9,4.1-4.1V11.6C29,11.2,28.9,10.8,28.6,10.6z M17.8,29.6h-6.5V17.2h6.5V29.6z M26.7,27.9 c0,1-0.8,1.8-1.8,1.8h-4.7V16c0-0.7-0.5-1.2-1.2-1.2h-8.9c-0.7,0-1.2,0.5-1.2,1.2v13.6H4.1c-1,0-1.8-0.8-1.8-1.8V12.1l12.1-9.4 l12.1,9.4V27.9z" />
    </svg>
  )
}

