import React, { useState, useEffect, useContext } from 'react';
import IMissingTORReport from '../../model/IMissingTORReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';

interface ILocation {
    changeExcelRef: any;
    selectedReport: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

const initialSort: Array<SortDescriptor> = [
    { field: "totalCourses", dir: "desc" },
];

export default function MissingTORReport(props: ILocation) {
    const { orginalSchoolYear } = useContext(Year);
    const [filter, setFilter] = React.useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<IMissingTORReport[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);
    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const GetMissingTORReport = () => {
        ReportsService.GetMissingTORReport(+orginalSchoolYear.id).then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetMissingTORReport();
    }, [props.selectedReport, orginalSchoolYear.id]);

    const filterReportData = (data: any, filter: any) => {
        let report = rowData;
        return filterBy(report, filter);
      }
      const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
      }


    return (
        <ExcelExport data={rowData} ref={_export} fileName={"Missing_TOR_Report_" + new Date().toDateString()}>
            <Grid
                data={filterReportData(orderBy(rowData, sort),filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                filterable={true}
                filter={filter}
                onFilterChange={onFilterChange}
                style={{width:"auto"}}
                scrollable={"scrollable"}>
                <GridColumn field="locationCode"  filterable={true} width="180px" title="Course Location"></GridColumn>
                <GridColumn field="courseName" filterable={true}  title="Courses Missing TOR" ></GridColumn>
                <GridColumn field="courseStatus" filterable={false}  title="Course Status" ></GridColumn>
                <GridColumn field="courseType" filterable={false}  title="Course Type" ></GridColumn>
                <GridColumn field="createdOn" filterable={false}  title="Created On" ></GridColumn>
            </Grid>
        </ExcelExport>
    )
}