import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import SchoolYearService from "../../../services/SchoolYearService";
import Moment from 'moment';

interface ISchoolYear {
    schoolYear: number,
    schoolYearName: string,
    schoolYearRange?: string,
    schoolYearStartDate: Date,
    schoolYearEndDate: Date,
    canAddNewSchoolYear?: boolean
}





export default function SchoolYearManage() {

    const [visible, setVisible] = useState(false);
    const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>();
    const [isSchoolYearChanging, setIsSchoolYearChanging] = useState(false);
    const [pageAlert, setPageAlert] = useState<string>('');
    const [pageError, setPageErrors] = useState<string>('');
    const [errors, setErrors] = useState({
        startDateError: '',
        endDateError: '',
    })
    const [formValue, setFormValue] = useState<ISchoolYear>({
        schoolYear: 0,
        schoolYearName: '',
        schoolYearStartDate: new Date(),
        schoolYearEndDate: new Date()
    });


    const addNewSchoolYear = () => {
        setVisible(!visible);
    }
    const handleChange = (event: any) => {
        const target = event.target //as HTMLSelectElement
        setFormValue({ ...formValue, [target.name]: target.value });

    };

    const GridColumnStartDate = (props: any) => {
        return (
            <td>
                <div>
                    {Moment(props.dataItem.schoolYearStartDate).format('MM/DD/YYYY')}
                </div>
            </td>
        )
    }

    const GridColumnEndDate = (props: any) => {
        return (
            <td>
                <div>
                    {Moment(props.dataItem.schoolYearEndDate).format('MM/DD/YYYY')}
                </div>
            </td>
        )
    }

    const validateFormValues = () => {
        let error = {
            startDateError: '',
            endDateError: '',
        }
        if (!formValue.schoolYearEndDate) { error.endDateError = "School Year End date is required." }
        if (!formValue.schoolYearStartDate) { error.startDateError = "School Year  Start date is required." }
        if (Moment(formValue.schoolYearEndDate).format('YYYY-MM-DD') < Moment(formValue.schoolYearStartDate).format('YYYY-MM-DD')) { error.endDateError = "End date must not be less than start date." }
        if (Moment(formValue.schoolYearEndDate).format('YYYY-MM-DD') <= Moment(Date.now()).format('YYYY-MM-DD')) { error.endDateError = "End date must be greater than today's date." }
        setErrors(error);
        if (error.endDateError || error.startDateError) {
            return false
        } else {
            return true
        }
    }

    const onSave = () => {
        if (validateFormValues()) {
            SchoolYearService.AddNewSchoolYear(formValue).then(response => {
                if (!response.data.isValidResponse) {
                    setPageErrors(response.data.errors.join())

                } else {
                    getCurrentSchoolYear();
                    setVisible(!visible);
                    setPageAlert('Success: The new school year has been created. Please refresh the page to see the changes.')
                }
            }).catch((ex) => {
                setPageErrors('Oops!, Something went wrong. Please try again.')
            })
        }
    }

    const getCurrentSchoolYear = () => {
        SchoolYearService.getCurrentSchoolYear().then(res => {
            let schoolYears = []
            schoolYears.push(res.data)
            setSchoolYears(schoolYears)
            if (res.data.canAddNewSchoolYear) {
                let newYear: ISchoolYear = res.data
                setFormValue({
                    schoolYear: +newYear.schoolYear + 1,
                    schoolYearName: `${+newYear.schoolYear + 1}Term`,
                    schoolYearStartDate: new Date(+newYear.schoolYear + 1, 7, 20),
                    schoolYearEndDate: new Date(+newYear.schoolYear + 2, 7, 20)
                })
            }
        })
    }

    useEffect(() => {
        getCurrentSchoolYear()
        setPageAlert("")
        setPageErrors("")

    }, []);

    return (

        <div>
            <div className="text-right mb-2">
                <Button variant="primary" onClick={addNewSchoolYear}><i className="fas fa-plus"></i></Button>

            </div>
            {pageAlert && pageAlert != "" && <div className="alert alert-success" role="alert">
                {pageAlert}
            </div>}
            <Grid
                data={schoolYears}
            >

                <GridColumn field="schoolYear" title="School Year"></GridColumn>
                <GridColumn field="schoolYearName" title="School Year Name"></GridColumn>
                <GridColumn field="schoolYearRange" title="School Year Range" ></GridColumn>
                <GridColumn field="schoolYearStartDate" title="School Year Start Date" cell={GridColumnStartDate}></GridColumn>
                <GridColumn field="schoolYearEndDate" cell={GridColumnEndDate} title="School Year End Date" ></GridColumn>
            </Grid>
            {visible &&
                <Dialog title={"Add New Year"} onClose={addNewSchoolYear} width={500}>
                    {pageError && pageError != "" && <div className="alert alert-danger" role="alert">
                        {pageError}
                    </div>}
                    {schoolYears && schoolYears[0].canAddNewSchoolYear ?
                        <Form>
                            <Row>
                                <Form.Group controlId="schoolYear" className="col-6" >
                                    <Form.Label>School Year</Form.Label>
                                    <Form.Control readOnly type="text" name="schoolYear" value={formValue ? formValue.schoolYear : 0} disabled />
                                </Form.Group>
                                <Form.Group controlId="schoolYearName" className="col-sm-6" >
                                    <Form.Label>School Year Name</Form.Label>
                                    <Form.Control readOnly type="text" name="schoolYearName" value={formValue ? formValue.schoolYearName : ''} disabled />
                                </Form.Group>

                                <Form.Group controlId="startDate" className="col-sm-6">
                                    <Form.Label>School Start Date</Form.Label>
                                    <Form.Control
                                        value={
                                            formValue
                                                ? Moment(formValue.schoolYearStartDate).format('YYYY-MM-DD')
                                                : Moment(new Date()).format('YYYY-MM-DD')
                                        }
                                        type="date" name="schoolYearStartDate" onChange={handleChange} ></Form.Control>
                                    <Form.Text className="text-danger">{errors.startDateError}</Form.Text>
                                </Form.Group>
                                <Form.Group controlId="endDate" className="col-sm-6">
                                    <Form.Label>School End Date</Form.Label>
                                    <Form.Control
                                        value={
                                            formValue
                                                ? Moment(formValue.schoolYearEndDate).format('YYYY-MM-DD')
                                                : Moment(new Date()).format('YYYY-MM-DD')
                                        }
                                        type="date" name="schoolYearEndDate" onChange={handleChange} ></Form.Control>
                                    <Form.Text className="text-danger">{errors.endDateError}</Form.Text>
                                </Form.Group>

                                <Form.Group controlId="changeSchoolYear" className="col-sm-12">
                                    <Form.Check
                                        type="checkbox"
                                        label="I agree to create a new school year"
                                        checked={isSchoolYearChanging}
                                        onChange={(e) => setIsSchoolYearChanging(e.target.checked)}
                                    />
                                </Form.Group>
                                <div className="alert alert-warning" style={{ marginLeft: "10px", marginRight: "10px" }}>
                                    <strong>Note:</strong> Once the new school year is created, all NYCDOE vendors will be moved to the new year.
                                </div>
                            </Row>
                        </Form>

                        : <div>
                            You've already added the school year for {schoolYears ? schoolYears[0].schoolYear : 0}. You can add the next year after 8/1/{schoolYears ? +schoolYears[0].schoolYear + 1 : 0}.
                        </div>}
                    {/* </Modal.Body> */}
                    <DialogActionsBar>
                        <Button className="m-2" variant="secondary" onClick={addNewSchoolYear}>Close</Button>
                        {schoolYears && schoolYears[0].canAddNewSchoolYear ? <Button variant="primary" type="button" onClick={onSave} disabled={!isSchoolYearChanging}>Save</Button> : ''}
                    </DialogActionsBar>
                </Dialog>
            }
        </div>
    )
}