import React, { useState, useEffect, useContext } from 'react';
import ITeacherCreatedCourse from '../../model/ITeacherCreatedCourseReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import reportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SplashScreen from '../../shared/SplashScreen';
import { IAssignedTeachers } from '../../model/IAssignedTeachers';


interface IProps {
    changeExcelRef: any
    getReportData: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

export default function SharedInstructionCourseReport(props: IProps) {
    const { orginalSchoolYear } = useContext(Year);
    const [page, setPage] = React.useState(initialDataState);
    const [filter, setFilter] = React.useState(initialFilter);
    const [isLoading, setIsLoading] = useState(true);
    const [rowData, setRowData] = useState<any[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);

    const pageChange = (event: any) => {
        setPage(event.page);
    };


    const GetSharedInstructionCourseReport = () => {
        reportsService.GetSharedInstructionCoursesReport(+orginalSchoolYear.id).then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
            props.getReportData(response.data)
            setIsLoading(false);
        }).catch((ex) => {
            setIsLoading(false);

        }).finally(() => {
            setIsLoading(false);
        })
    }

    const GridColumnDetails = (props: any) => {
        return (
            <td>
                <div>
                    {moment(props.dataItem.sharedCourseCreationDate).format('MM/DD/YYYY')}
                </div>
            </td>
        )
    }

   

    const GridStarsCoursesCell = (props: any) => {

        return (
            <td style={{ minWidth: "300px" }}>
                <div>
                    <ListGroup variant="flush">
                        {props.dataItem.assignedStarsCourses.length ? (
                            props.dataItem.assignedStarsCourses.map((item: any) => (
                                (item.starsCourseName && item.starsCourseName !== "") ? (
                                    <ListGroup.Item
                                        style={{ backgroundColor: "transparent", border: "none" }}
                                        key={item.starsCourseName}
                                    >
                                        {item.starsCourseName} {
                                            item.assignedStarsCoursesTeachers && item.assignedStarsCoursesTeachers.length && item.assignedStarsCoursesTeachers.map((i: any) => (
                                                i.teacherName && i.teacherName != "" &&
                                                <OverlayTrigger
                                                    key={i.teacherId}
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id={`tooltip-${item.teacherId}`}>
                                                            {i.teacherName + '-' + i.teacherEIN + '(' + i.teacherDBN + ')'}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button key={item.teacherEIN} className={"btn badge badge-warning border-0 mr-1"}>{i.teacherName.split(' ').map((word: string) => word[0]).join('')}</Button>
                                                </OverlayTrigger>
                                            ))
                                        } | {item.starsCourseLocationCode} | {item.studentsCount} students
                                    </ListGroup.Item>
                                ) : (<ListGroup.Item style={{ backgroundColor: "transparent", border: "none" }}>No sections</ListGroup.Item>)
                            ))
                        ) : (
                            <p>No sections</p>
                        )}
                    </ListGroup>
                </div>
            </td>
        );

    }



    const GetStatusBadgeCssClass = (status: string) => {

        if (status.toLowerCase() === "new") {
            return "badge badge-blue-gray"
        }
        else if (status.toLowerCase() === "edited") {
            return "badge badge-secondary"
        }
        else if (status.toLowerCase() === "published") {
            return "badge badge-success"
        }
        else if (status.toLowerCase() === "closed") {
            return "badge badge-danger"
        }
        else if (status.toLowerCase() === "reopened") {
            return "badge badge-info"
        }
    }

    const GridStatusCell = (props: any) => {
        return (
            <td >
                <div className={GetStatusBadgeCssClass(props.dataItem.sharedCourseStatus)}>{props.dataItem.sharedCourseStatus}</div>
            </td>
        )
    }

    const filterReportData = (data: any, filter: any) => {
        let report = rowData;
        return filterBy(report, filter);
    }
    const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
    }

    useEffect(() => {
        GetSharedInstructionCourseReport();
    }, [orginalSchoolYear.id]);


    return (
        <div>
            {isLoading && <div className="d-flex justify-content-center"><SplashScreen /></div>}
            <ExcelExport data={rowData} ref={_export} fileName={"Shared_Instruction_Courses_Report" + ' ' + new Date().toDateString()}>
                <ExcelExportColumn field="sharedCourseName" title="Shared Course Name"></ExcelExportColumn>
                <ExcelExportColumn field="sharedCourseLocationCode" title="Shared Course Location Code"></ExcelExportColumn>
                <ExcelExportColumn field="sharedCourseStatus" title="Shared Course Status"></ExcelExportColumn>
                <ExcelExportColumn field="teacherofRecordEIN" title="Teacher of Record EIN"></ExcelExportColumn>
                <ExcelExportColumn field="teacherofRecordName" title="Teacher of Record Name"></ExcelExportColumn>
                <ExcelExportColumn field="sharedCourseCreationDate" title="Shared Course Creation Date" ></ExcelExportColumn>
                <ExcelExportColumn field="starsCourseName" title="Stars Course Name" ></ExcelExportColumn>
                <ExcelExportColumn field="starsCourseLocationCode" title="Stars Course Location Code" ></ExcelExportColumn>
                <ExcelExportColumn field="starsCourseCode" title="STARS Course code" ></ExcelExportColumn>
                <ExcelExportColumn field="starsMappingId" title="STARS Course Mapping ID" ></ExcelExportColumn>
                <ExcelExportColumn field="termId" title="STARS Course Term ID" ></ExcelExportColumn>
                <ExcelExportColumn field="assignedStarsCoursesTeachers" title="STARS Course Teachers" ></ExcelExportColumn>
                <ExcelExportColumn field="studentsCount"  title="Number of Students" ></ExcelExportColumn>
            </ExcelExport>
            <Grid
                data={filterReportData(rowData, filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                filter={filter}
                filterable={true}
                onFilterChange={onFilterChange}
                style={{ overflowX: "auto" }}
                scrollable={"none"}>
                <GridColumn field="sharedCourseLocationCode" filterable={true} title="SI Course DBN"></GridColumn>
                <GridColumn field="sharedCourseName" filterable={true} title="SI Course Name"></GridColumn>
                <GridColumn field="sharedCourseCreationDate" cell={GridColumnDetails} filterable={false} title="SI Creation Date" ></GridColumn>
                <GridColumn field="courseStatus" cell={GridStatusCell} filterable={false} title="Course Status"></GridColumn>
                <GridColumn field="teacherofRecordEIN" filterable={false} title="TOR EIN"></GridColumn>
                <GridColumn field="teacherofRecordName" filterable={false} title="TOR Name"></GridColumn>
                <GridColumn field="teachers" cell={GridStarsCoursesCell} filterable={false} title="Stars Courses"></GridColumn>
            </Grid>


        </div>
    )
}