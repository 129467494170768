import axios from 'axios'
import { AxiosInstances as AxiosInstance } from './BaseService'

export default {
    GetStatusWiseReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/StatusWiseReport`,
        }),

    GetLicenseUsageReport: (schoolYear: number, licenseType: string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/LicenseUsageReport/${licenseType}`,
        }),


    GetPublishedCoursesReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/PublishedCoursesReport`,
        }),
    GetCourseEnrollmentSummaryReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/StudentsCountReport`,
        }),

    GetSharedInstructionCoursesReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/SharedInstructionCoursesReport`,
        }),

    GetLicenseTransferHistoryReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/LicenseTransferReport`,
        }),

    GetTotalCountsReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/TotalCountsReport`,
        }),

    GetTeacherCreatedCourseReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/TeacherCreatedCourse`,
        }),

    GetDailyReport: () =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/DailyReport`,
        }),

    GetAllReports: () =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports`,
        }),

    GetMissingTORReport: (schoolYear: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Reports/${schoolYear}/MissingTORReport`,
        }),
}