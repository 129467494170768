import React, { useState, useEffect, useContext } from 'react';
import AnnouncementDisplay from '../components/announcement/AnnouncementDisplay';
import ChartContainer from './charts/licenseChart';
import CourseChartContainer from './charts/CourseChart'
import courseService from '../services/CoursesService'
import licenseAllocationService from '../services/LicenseAllocation'
import { LocationContext, Year } from '../services/AppContext'

interface ILocationCode {
  id: string,
}


interface CourseStatusList {
  courseStatus: string,
  courseCount: string
}

interface SchoolLicenseUsage {
  id: number,
  quantityAllocated: number,
  quantityAssigned: number, 
  vendorId: number,
  vendorName: string
}
export default function Dashboard(location: ILocationCode) {

    // get locationCode and schoolYear from dropDown - hossni 14/6
  const { locationCode } = useContext(LocationContext);
  const { orginalSchoolYear } = useContext(Year);
 
  const [courseStatusList, setcourseStatusList] = useState<CourseStatusList[]>([]);
  const [schoolLicenseUsageList, setSchoolLicenseUsage] = useState<SchoolLicenseUsage[]>([]);
  useEffect(() => {
    if(locationCode != "" && orginalSchoolYear.id != ""){
      courseService.GetCourseStatusChart(locationCode, +orginalSchoolYear.id).then(response => {
        setcourseStatusList(response.data);
      })
      licenseAllocationService.GetSchoolLicenseUsageChart(locationCode, orginalSchoolYear.id).then(response => {
        setSchoolLicenseUsage(response.data)
      }) 
    }
  
  }, [locationCode, orginalSchoolYear.id]);

  return (
    <div>
      <AnnouncementDisplay />
      <div className="row mb-4" >
        <div className="col-md-6">
          <div className="card">
            <div className="card-header bg-blue-gray d-flex justify-content-between align-items-center">
              <div className="h3 mb-0 font-weight-normal">
                Courses ({courseStatusList.reduce((accumulator, currentValue) => accumulator + +currentValue.courseCount,0)})
                </div>
            </div>
            <div className="card-body text-center">
            {/* // get locationCode and schoolYear from dropDown - hossni 14/6 */} 
              <CourseChartContainer data={courseStatusList} />
            </div>
          </div>   
        </div>
        <div className="col-md-6 mt-4 mt-md-0">
          <div className="card">
            <div className="card-header bg-blue-gray d-flex justify-content-between align-items-center">
              <div className="h3 mb-0 font-weight-normal">
                Assigned Licenses
              </div>
            </div>
            <div className="card-body text-center">
              <div className="row">
                {
                  schoolLicenseUsageList.length > 0 ? schoolLicenseUsageList.map(vendor => <div className="col-lg-4 col-sm-6 d-flex flex-column justify-content-between align-items-center" key={vendor.id} ><div> <ChartContainer data={vendor} /> </div> <div className="small">{vendor.vendorName}</div> </div>) : <div className="col-sm-12"> <div>No Assigned Licenses</div> </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

