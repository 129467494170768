import React, { useState, useEffect, useRef } from 'react';
import menuService from '../services/MenuService';
import IMenu from '../model/Menu'
import { NavLink } from 'react-router-dom';

export default function Menu() {
  const mountedRef = useRef(true);
  const [menuData, setMenuData] = useState<IMenu[]>([]); 
  useEffect(() => {
    menuService.getData().then(response => {
      setMenuData(response.data)
    })
    return () => { mountedRef.current = false }
  }, []); 

  return (
    <div className='main-tabs-overflow'>
      <ul className="nav nav-tabs main-tabs mt-5">
        {
          menuData.map((link, i) =>
            <li className="nav-item" key={i.toString()}>
              <NavLink exact to={link.navigationURL} className="nav-link" activeClassName="active" key={link.sortIndex}>{link.displayText}</NavLink>
            </li>
          )
        }
      </ul>
    </div>
  )
}

