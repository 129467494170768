import React, { useState, useEffect, useRef } from 'react';

export default function IconGear() {

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 90.3 64.9">
      <title>Browser Window with Gear</title>
      <g transform="translate(-31.68 -738.224)">
          <path d="M31.7,795.9v-50.5c0.2-0.8,0.4-1.6,0.6-2.4c1.2-3,4.2-5,7.5-4.8c7,0,14,0,21,0c17.8,0,35.6,0,53.4,0c4.2,0.1,7.6,3.5,7.7,7.7c0,16.5,0,33,0,49.5c0,4.3-3.5,7.7-7.8,7.7c-24.9,0-49.9,0-74.8,0 c-3.1,0.1-5.9-1.8-7-4.6C32.1,797.6,31.8,796.8,31.7,795.9z M105.1,797.8L105.1,797.8c0.1,0.1,0.2,0.1,0.3,0.1c2.9,0,5.9,0,8.8,0 c0.5,0,1-0.2,1.4-0.5c0.8-0.6,1.3-1.5,1.2-2.5c0-12.2,0-24.3,0-36.5c0-1.1,0-1.1-1-1.1H37.9c-0.1,0-0.3,0-0.4,0 c-0.4,0-0.6,0.1-0.6,0.6c0,0.1,0,0.2,0,0.4v36.3c0,0.2,0,0.3,0,0.5c-0.1,1.5,1.1,2.8,2.6,2.8c0.1,0,0.2,0,0.3,0c3.4,0,6.8,0,10.2,0
              h0.5c-0.2-0.2-0.5-0.4-0.7-0.5c-1.1-0.5-2.2-1-3.3-1.5c-0.9-0.4-1.5-1.3-1.4-2.3c0-0.5,0.1-1,0.3-1.4c0.9-2.3,1.9-4.6,2.8-6.9 c0.4-1.4,2-2.3,3.4-1.8c0.2,0,0.3,0.1,0.5,0.2c1.3,0.5,2.5,1,3.8,1.6c0.3,0.2,0.7,0.1,0.8-0.2c0,0,0,0,0,0c1.3-1.6,2.7-3.1,4.3-4.4 c0.6-0.5,0.6-0.5,0.3-1.2c-0.5-1.3-1.1-2.5-1.5-3.8c-0.6-1.3,0-2.9,1.4-3.5c0.1,0,0.1,0,0.2-0.1c2.4-1,4.7-2,7.1-2.9 c1.3-0.7,2.9-0.2,3.6,1.1c0.1,0.1,0.1,0.3,0.2,0.5c0.5,1.3,1,2.5,1.6,3.8c0.1,0.3,0.4,0.5,0.7,0.4c2.2-0.2,4.3-0.2,6.5,0 c0.3,0.1,0.7-0.1,0.7-0.4c0.5-1.3,1.1-2.5,1.6-3.8c0.4-1.4,2-2.2,3.4-1.8c0.2,0,0.3,0.1,0.4,0.2c2.4,1,4.7,2,7.1,2.9
              c1.4,0.5,2.2,2,1.7,3.4c0,0.1-0.1,0.2-0.1,0.3c-0.5,1.2-1,2.5-1.5,3.7c-0.3,0.7-0.3,0.7,0.3,1.2c1.6,1.3,3.1,2.8,4.4,4.4 c0.2,0.3,0.5,0.4,0.8,0.2c0,0,0,0,0,0c1.3-0.6,2.7-1.1,4-1.7c1.4-0.6,2.9,0.1,3.5,1.4c0,0,0,0,0,0c1,2.5,2.1,4.9,3.1,7.4 c0.2,0.4,0.2,0.9,0.2,1.4c-0.1,1-0.7,1.8-1.6,2.2c-1,0.5-2,0.9-3,1.3C105.7,797.5,105.4,797.6,105.1,797.8z M77.8,797.9 c4.1,0,8.2,0,12.2,0c0.6,0,0.7-0.1,0.4-0.6c-2.5-6.7-9.8-10.4-16.6-8.3c-4,1.1-7.3,4.1-8.6,8.1c-0.3,0.8-0.3,0.8,0.6,0.8
              L77.8,797.9z M90.8,749.1c0-2.4-1.9-4.4-4.3-4.4c-2.4,0-4.4,1.9-4.4,4.3l0,0c0,2.4,1.9,4.4,4.3,4.4 C88.8,753.5,90.7,751.5,90.8,749.1z M103.7,749.1c0,2.4,2,4.3,4.4,4.3c2.4,0,4.3-2,4.3-4.4c0-2.4-1.9-4.3-4.3-4.3 C105.7,744.7,103.7,746.6,103.7,749.1C103.7,749.1,103.7,749.1,103.7,749.1L103.7,749.1z M101.9,749.1c0-2.4-1.9-4.4-4.3-4.4 c-2.3,0-4.3,1.8-4.4,4.2c-0.1,2.4,1.7,4.5,4.1,4.6c2.4,0.1,4.5-1.7,4.6-4.1C101.9,749.3,101.9,749.2,101.9,749.1z"/>
      </g>
  </svg>
  )
}

