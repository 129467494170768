import React, { useState, useEffect } from 'react';
import IVendor from '../model/Vendor';

export default function VendorDetail(props: any) {
    const [vendorDetailData, setvendorDetailData] = useState<IVendor>();
    useEffect(() => {
            setvendorDetailData(props.data)
    }, []);

    return (
        <div className="card mb-4">
            <div className="card-header font-weight-bold bg-secondary-light">
                Vendor Details
            </div>
            <div className="card-body">
                <div>
                    <div className="small text-primary">Description:</div>
                    <div>{vendorDetailData?.vendorDescription}</div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="small text-primary">Type:</div>
                        <div>{vendorDetailData?.licenseType}</div>
                    </div>
                    <div className="col">
                        <div className="small text-primary">Usage:</div>
                        <div>{vendorDetailData?.licenseModel}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

