import axios from 'axios'
import IVendor from '../model/Vendor';
import { AxiosInstances as AxiosInstance} from './BaseService'

export default {
    getData: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/Vendor',
        }),
    getAllByLocationCode: function (locationCode: string) {
        return AxiosInstance({
            'method': 'GET',
            'url': '/getallbylocationcode/' + locationCode,
        });
    },
    getStates: function () {
        return AxiosInstance({
            'method': 'GET',
            'url': '/States',
        });
    },
    getById: (id: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': '/vendor/' + id,
        }),
    post: (requestdata: IVendor) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/Vendor',
            'data': requestdata
        }),
    edit: (requestdata: IVendor) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/Vendor?id=' + requestdata.id,
            'data': requestdata
        }),
    delete: (id: number) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': '/Vendor?id=' + id,
        })
}
