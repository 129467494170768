import IProduct from '../model/Product';
import { AxiosInstances as AxiosInstance} from './BaseService'

export default {
    getData: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/product',
        }),
    getById: (id: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': '/product/' + id,
        }),
    getByVendorId: (vendorId: number) =>
        AxiosInstance({
            'method': 'GET',
            'url': '/productByVendorId/' + vendorId,
        }),
    post: (requestdata: IProduct) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/product',
            'data': requestdata
        }),
    edit: (requestdata: IProduct) =>
        AxiosInstance({
            'method': 'PUT',
            'url': '/product?id=' + requestdata.id,
            'data': requestdata
        }),
    delete: (id: number) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': '/product?id=' + id,
        })
}
