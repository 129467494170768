import axios from 'axios'
import { AxiosInstances as AxiosInstance } from './BaseService'

export default {
    getData: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/SchoolYear',
        }),
    getCurrentSchoolYear: () =>
        AxiosInstance({
            'method': 'GET',
            'url': '/CurrentSchoolYear',
        }),

    AddNewSchoolYear: (req: any) =>
        AxiosInstance({
            'method': 'POST',
            'url': '/AddNewSchoolYear',
            'data': req
        }),

}