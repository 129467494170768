type CourseIconType = {
  courseOrginType: string;
  merge: Number;
  courseType: string;
};

export default function IconCourseEnvelop(props: CourseIconType) {
  if (props.merge === 1) {
    return (
      <svg version="1.1" id="iconmonstr" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 23">
        <path d="M29.1,0H2.8C1.2,0,0,1.2,0,2.8v17.4c0,1.5,1.2,2.8,2.8,2.8h26.3c1.5,0,2.8-1.2,2.8-2.8V2.8 C31.9,1.2,30.6,0,29.1,0z M30,20.1c0,0.5-0.4,0.9-0.9,0.9h-2.3v-1.8c0-0.5-0.4-0.9-0.9-0.9H14.5c-0.5,0-0.9,0.4-0.9,0.9v1.8H2.8 c-0.5,0-0.9-0.4-0.9-0.9V2.8c0-0.5,0.4-0.9,0.9-0.9h26.3c0.5,0,0.9,0.4,0.9,0.9V20.1z" />
        <path d="M13.4,5.2H5.5C5,5.3,4.7,5.6,4.7,6.1c0,0.5,0.4,0.8,0.8,0.8h7.9c0.5,0,0.8-0.4,0.8-0.9 C14.2,5.6,13.9,5.3,13.4,5.2z" />
        <path d="M13.4,8.6H5.5C5,8.6,4.7,9,4.7,9.5c0,0.5,0.4,0.8,0.8,0.8h7.9c0.5,0,0.8-0.4,0.8-0.9C14.2,9,13.9,8.6,13.4,8.6 z" />
        <path d="M13.4,12H5.5c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h7.9c0.5,0,0.8-0.4,0.8-0.8S13.9,12,13.4,12z" />
        <g>
          <path d="M19.3,8V6.9h6V8h1.5V6.9c0-0.8-0.7-1.5-1.5-1.5h-6c-0.8,0-1.5,0.7-1.5,1.5V8H19.3z" />
          <path d="M25.3,11v1.1h-6V11h-1.5v1.1c0,0.8,0.7,1.5,1.5,1.5h6c0.8,0,1.5-0.7,1.5-1.5V11H25.3z" />
          <path d="M17.8,9.9V9.1c0-0.1,0.1-0.2,0.2-0.2h1.7V8c0-0.2,0.2-0.3,0.4-0.1l1.5,1.5c0.1,0.1,0.1,0.2,0,0.3L20,11.1 c-0.1,0.1-0.4,0-0.4-0.1v-0.9H18C17.9,10.1,17.8,10,17.8,9.9z" />
          <path d="M26.8,9.1v0.8c0,0.1-0.1,0.2-0.2,0.2h-1.7V11c0,0.2-0.2,0.3-0.4,0.1L23,9.6c-0.1-0.1-0.1-0.2,0-0.3l1.5-1.5 c0.1-0.1,0.4,0,0.4,0.1v0.9h1.7C26.7,8.9,26.8,9,26.8,9.1z" />
        </g>
      </svg>
    )
  }
  else {

    if (props.courseType && props.courseType.toLowerCase() === "shared") {
      return (
        <svg role="img" aria-label="class board with s" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 23">
          <path d="M29.1,0H2.8C1.2,0,0,1.2,0,2.8v17.4C0,21.7,1.2,23,2.8,23h26.3c1.5,0,2.8-1.2,2.8-2.8V2.8C31.9,1.2,30.6,0,29.1,0z M30,20.1 c0,0.5-0.4,0.9-0.9,0.9h-2.3v-1.8c0-0.5-0.4-0.9-0.9-0.9H14.5c-0.5,0-0.9,0.4-0.9,0.9V21H2.8c-0.5,0-0.9-0.4-0.9-0.9V2.8 c0-0.5,0.4-0.9,0.9-0.9h26.3c0.5,0,0.9,0.4,0.9,0.9C30,2.8,30,20.1,30,20.1z" />
          <path d="M15.9,5.2H5.5C5,5.3,4.7,5.6,4.7,6.1s0.4,0.8,0.8,0.8h10.4c0.5,0,0.8-0.4,0.8-0.9C16.7,5.6,16.4,5.3,15.9,5.2z" />
          <path d="M15.9,8.6H5.5C5,8.6,4.7,9,4.7,9.5s0.4,0.8,0.8,0.8h10.4c0.5,0,0.8-0.4,0.8-0.9C16.7,9,16.4,8.6,15.9,8.6z" />
          <path d="M15.9,12H5.5c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h10.4c0.5,0,0.8-0.4,0.8-0.8S16.4,12,15.9,12z" />
          <path d="M22.3,9.2c0,0.3,0.1,0.4,0.2,0.6c0.2,0.2,0.5,0.3,1,0.3c0.3,0,0.5,0,0.7-0.1c0.3-0.1,0.5-0.3,0.5-0.6 c0-0.2-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.2-0.8-0.3l-0.6-0.1c-0.6-0.1-1-0.3-1.2-0.4c-0.4-0.3-0.6-0.6-0.6-1.2c0-0.5,0.2-0.9,0.5-1.2 c0.4-0.3,0.9-0.5,1.6-0.5c0.6,0,1.1,0.2,1.5,0.5C25.4,6,25.6,6.4,25.6,7h-1.1c0-0.3-0.2-0.6-0.4-0.7c-0.2-0.1-0.4-0.1-0.7-0.1 c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.4,0.1,0.7,0.2l1,0.2c0.4,0.1,0.7,0.2,1,0.4 c0.3,0.3,0.5,0.6,0.5,1.1c0,0.5-0.2,0.9-0.6,1.3c-0.4,0.3-0.9,0.5-1.6,0.5c-0.7,0-1.3-0.2-1.7-0.5c-0.4-0.3-0.6-0.8-0.6-1.3H22.3z" />
        </svg>

      )
    }

    // Icon - when Course Created Manually
    else if (props.courseOrginType && props.courseOrginType.toLowerCase() === "lmi") {
      return (
        <svg version="1.1" id="iconmonstr" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 23">
          <title>Blackboard With M</title>
          <path d="M29.1,0H2.8C1.2,0,0,1.2,0,2.8v17.4c0,1.5,1.2,2.8,2.8,2.8h26.3c1.5,0,2.8-1.2,2.8-2.8 V2.8C31.9,1.2,30.6,0,29.1,0z M30,20.1c0,0.5-0.4,0.9-0.9,0.9h-2.3v-1.8c0-0.5-0.4-0.9-0.9-0.9H14.5c-0.5,0-0.9,0.4-0.9,0.9v1.8H2.8 c-0.5,0-0.9-0.4-0.9-0.9V2.8c0-0.5,0.4-0.9,0.9-0.9h26.3c0.5,0,0.9,0.4,0.9,0.9V20.1z" />
          <path d="M15.9,5.2H5.5C5,5.3,4.7,5.6,4.7,6.1c0,0.5,0.4,0.8,0.8,0.8h10.4c0.5,0,0.8-0.4,0.8-0.9 C16.7,5.6,16.4,5.3,15.9,5.2z" />
          <path d="M15.9,8.6H5.5C5,8.6,4.7,9,4.7,9.5c0,0.5,0.4,0.8,0.8,0.8h10.4c0.5,0,0.8-0.4,0.8-0.9 C16.7,9,16.4,8.6,15.9,8.6z" />
          <path d="M15.9,12H5.5c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h10.4c0.5,0,0.8-0.4,0.8-0.8 S16.4,12,15.9,12z" />

          <path d="M20.3,5.2h2.3l0.9,3.5l0.9-3.5h2.3V11h-1.4V6.6L24.2,11h-1.3l-1.1-4.4V11h-1.4V5.2z" />
        </svg>
      )
    }



    else if (props.courseOrginType && props.courseOrginType.toLowerCase() === "stars") {
      return (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 32 23">
          <path d="M29.2,0H2.7C1.2,0-0.1,1.3-0.1,2.8v17.5c0,1.5,1.3,2.8,2.8,2.8h26.5 c1.5,0,2.8-1.3,2.8-2.8V2.8C32,1.3,30.7,0,29.2,0z M30.1,20.3c0,0.5-0.4,0.9-0.9,0.9h-2.3v-1.8c0-0.5-0.4-0.9-0.9-0.9H14.5 c-0.5,0-0.9,0.4-0.9,0.9v1.8H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.8c0-0.5,0.4-0.9,0.9-0.9h26.5c0.5,0,0.9,0.4,0.9,0.9V20.3z" />
          <path d="M15.9,5.3H5.5C5,5.3,4.6,5.7,4.6,6.1C4.6,6.6,5,7,5.5,7h10.5c0.5,0,0.8-0.4,0.8-0.9 C16.7,5.7,16.4,5.3,15.9,5.3z" />
          <path d="M15.9,8.7H5.5C5,8.7,4.6,9.1,4.6,9.5c0,0.5,0.4,0.8,0.8,0.8h10.5c0.5,0,0.8-0.4,0.8-0.9 C16.7,9.1,16.4,8.7,15.9,8.7z" />
          <path d="M15.9,12.1H5.5c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h10.5c0.5,0,0.9-0.4,0.9-0.9 S16.4,12.1,15.9,12.1z" />
          <path d="M22.7,5.5l-0.9,1.9l-2,0.3c-0.2,0-0.4,0.3-0.4,0.5c0,0.1,0.1,0.2,0.1,0.3 L21,9.9l-0.3,2c0,0.2,0.1,0.5,0.4,0.5c0.1,0,0.2,0,0.3,0l1.8-1l1.8,1c0.2,0.1,0.5,0,0.6-0.2c0-0.1,0.1-0.2,0-0.3l-0.3-2l1.5-1.4 c0.2-0.2,0.2-0.5,0-0.6c-0.1-0.1-0.2-0.1-0.3-0.1l-2-0.3l-0.9-1.9c-0.1-0.2-0.4-0.3-0.6-0.2C22.8,5.4,22.7,5.4,22.7,5.5z" />
        </svg>
      )
    }
    // Icon - Same as above
    else {
      return (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 32 23">
          <path d="M29.2,0H2.7C1.2,0-0.1,1.3-0.1,2.8v17.5c0,1.5,1.3,2.8,2.8,2.8h26.5 c1.5,0,2.8-1.3,2.8-2.8V2.8C32,1.3,30.7,0,29.2,0z M30.1,20.3c0,0.5-0.4,0.9-0.9,0.9h-2.3v-1.8c0-0.5-0.4-0.9-0.9-0.9H14.5 c-0.5,0-0.9,0.4-0.9,0.9v1.8H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.8c0-0.5,0.4-0.9,0.9-0.9h26.5c0.5,0,0.9,0.4,0.9,0.9V20.3z" />
          <path d="M15.9,5.3H5.5C5,5.3,4.6,5.7,4.6,6.1C4.6,6.6,5,7,5.5,7h10.5c0.5,0,0.8-0.4,0.8-0.9 C16.7,5.7,16.4,5.3,15.9,5.3z" />
          <path d="M15.9,8.7H5.5C5,8.7,4.6,9.1,4.6,9.5c0,0.5,0.4,0.8,0.8,0.8h10.5c0.5,0,0.8-0.4,0.8-0.9 C16.7,9.1,16.4,8.7,15.9,8.7z" />
          <path d="M15.9,12.1H5.5c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h10.5c0.5,0,0.9-0.4,0.9-0.9 S16.4,12.1,15.9,12.1z" />
          <path d="M22.7,5.5l-0.9,1.9l-2,0.3c-0.2,0-0.4,0.3-0.4,0.5c0,0.1,0.1,0.2,0.1,0.3 L21,9.9l-0.3,2c0,0.2,0.1,0.5,0.4,0.5c0.1,0,0.2,0,0.3,0l1.8-1l1.8,1c0.2,0.1,0.5,0,0.6-0.2c0-0.1,0.1-0.2,0-0.3l-0.3-2l1.5-1.4 c0.2-0.2,0.2-0.5,0-0.6c-0.1-0.1-0.2-0.1-0.3-0.1l-2-0.3l-0.9-1.9c-0.1-0.2-0.4-0.3-0.6-0.2C22.8,5.4,22.7,5.4,22.7,5.5z" />
        </svg>

      )
    }
  }
}