import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

export default function IconDOELogo() {

  return (
    <svg id="doe_logo_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.39 40">
      <path d="M360.981,175.713h4.436c3.164,0,5.057,1.923,5.057,5.115,0,3.38-1.922,5.2-5.057,5.2h-4.436Zm2.688,8h1.271c2.038-.042,2.846-.837,2.846-2.9a2.508,2.508,0,0,0-2.846-2.788h-1.271Z" transform="translate(-235.318 -174.408)" fill="#036" />
      <path d="M378.755,184.188a1.6,1.6,0,0,0,1.676,1.7,1.464,1.464,0,0,0,1.4-.823h2.3a3.549,3.549,0,0,1-3.728,2.485,3.771,3.771,0,0,1-4.1-3.987,3.935,3.935,0,0,1,4.045-3.943c2.687,0,4.031,2.022,3.915,4.566Zm3.049-1.373a1.519,1.519,0,0,0-1.46-1.531,1.618,1.618,0,0,0-1.589,1.531Z" transform="translate(-240.44 -175.715)" fill="#036" />
      <path d="M389.665,179.838h2.341v.867h.029a2.4,2.4,0,0,1,2.066-1.083c2.354,0,3.351,2.022,3.351,4.132,0,1.95-1.04,3.8-3.164,3.8a2.511,2.511,0,0,1-2.139-1.024h-.029v3.323h-2.455V179.838Zm2.369,3.67c0,1.315.375,2.167,1.488,2.167S395,184.533,395,183.508c0-.954-.375-2.008-1.46-2.008C392.872,181.5,392.034,181.962,392.034,183.508Z" transform="translate(-244.909 -175.715)" fill="#036" />
      <path d="M402.557,182.15a2.308,2.308,0,0,1,1.17-2.009,4.953,4.953,0,0,1,2.384-.52c1.777,0,3.5.389,3.5,2.513v3.28a4.346,4.346,0,0,0,.29,1.922h-2.472a2.972,2.972,0,0,1-.144-.708,3.384,3.384,0,0,1-2.47.924c-1.43,0-2.557-.721-2.557-2.269,0-2.441,2.659-2.253,4.363-2.6.419-.085.65-.23.65-.693,0-.563-.679-.781-1.184-.781a1.1,1.1,0,0,0-1.229.941h-2.3Zm3.178,3.887c1.17,0,1.56-.666,1.5-2.211a6.928,6.928,0,0,1-1.517.4c-.549.13-1.012.362-1.012.954C404.71,185.79,405.187,186.036,405.735,186.036Z" transform="translate(-249.119 -175.714)" fill="#036" />
      <path d="M415.41,179.875h2.383v1.315h.029a2.262,2.262,0,0,1,2.255-1.458,2.632,2.632,0,0,1,.578.057v2.24a3.431,3.431,0,0,0-.94-.144,1.724,1.724,0,0,0-1.849,1.777v3.712h-2.456v-7.5Z" transform="translate(-253.518 -175.751)" fill="#036" />
      <path d="M428.806,180.4h-1.517v3.409c0,.593.347.722.881.722.2,0,.419-.029.636-.029V186.3c-.449.015-.9.072-1.345.072-2.094,0-2.629-.607-2.629-2.644V180.4H423.59v-1.59h1.243v-2.268h2.456v2.268h1.517v1.59Z" transform="translate(-256.253 -174.684)" fill="#036" />
      <path d="M432.852,179.838h2.383v1.026h.03a2.5,2.5,0,0,1,2.151-1.243,2.166,2.166,0,0,1,2.181,1.212,2.749,2.749,0,0,1,2.342-1.212c2.383,0,2.643,1.806,2.643,3.236v4.478h-2.456V182.93c0-.809-.39-1.286-1.026-1.286-1.054,0-1.156.809-1.156,2.022v3.67H437.49v-4.262c0-.882-.26-1.43-.925-1.43-.881,0-1.257.506-1.257,2.037v3.655h-2.456v-7.5Z" transform="translate(-259.35 -175.715)" fill="#036" />
      <path d="M454.4,184.188a1.6,1.6,0,0,0,1.676,1.7,1.462,1.462,0,0,0,1.4-.823h2.3a3.548,3.548,0,0,1-3.728,2.485,3.771,3.771,0,0,1-4.1-3.987,3.934,3.934,0,0,1,4.044-3.943c2.687,0,4.032,2.022,3.916,4.566Zm3.049-1.373a1.519,1.519,0,0,0-1.46-1.531,1.619,1.619,0,0,0-1.589,1.531Z" transform="translate(-265.734 -175.715)" fill="#036" />
      <path d="M465.314,179.838H467.7v1.026h.029a2.834,2.834,0,0,1,2.356-1.243c2.5,0,2.7,1.819,2.7,2.9v4.811h-2.456V183.71c0-1.026.116-2.065-1.229-2.065-.925,0-1.329.779-1.329,1.6v4.089h-2.456v-7.5Z" transform="translate(-270.204 -175.715)" fill="#036" />
      <path d="M482.6,180.4h-1.516v3.409c0,.593.346.722.881.722.2,0,.419-.029.634-.029V186.3c-.447.015-.9.072-1.343.072-2.094,0-2.629-.607-2.629-2.644V180.4h-1.243v-1.59h1.243v-2.268h2.456v2.268H482.6v1.59Z" transform="translate(-274.239 -174.684)" fill="#036" />
      <path d="M496.242,179.622a3.965,3.965,0,1,1-3.945,3.972A3.844,3.844,0,0,1,496.242,179.622Zm0,6.053c1.142,0,1.5-1.127,1.5-2.081s-.361-2.094-1.5-2.094-1.488,1.142-1.488,2.094S495.1,185.675,496.242,185.675Z" transform="translate(-279.226 -175.715)" fill="#036" />
      <path d="M505.657,180.121h-1.186v-1.59h1.186c0-2.153.75-2.818,2.846-2.818.39,0,.794.03,1.184.043v1.733a5.348,5.348,0,0,0-.664-.042c-.564,0-.91.072-.91.751v.332h1.473v1.59h-1.473v5.908h-2.456Z" transform="translate(-283.296 -174.408)" fill="#036" />
      <path d="M360.981,199.56H369.2v2.153h-5.533v1.807h5.057V205.6h-5.057v1.965h5.678v2.312h-8.365Z" transform="translate(-235.318 -182.381)" fill="#036" />
      <path d="M382.479,209.876h-2.34v-.867h-.029a2.4,2.4,0,0,1-2.066,1.083c-2.355,0-3.352-2.022-3.352-4.132,0-1.95,1.04-3.8,3.164-3.8a2.508,2.508,0,0,1,2.138,1.024h.029v-3.626h2.456Zm-5.331-3.827c0,1.025.375,2.166,1.5,2.166,1.2,0,1.459-1.142,1.459-2.094,0-1.084-.39-2.081-1.459-2.081C377.524,204.039,377.148,205.094,377.148,206.048Z" transform="translate(-239.903 -182.381)" fill="#036" />
      <path d="M395.916,211.292h-2.384v-1.026H393.5a2.762,2.762,0,0,1-2.283,1.243c-2.153,0-2.774-1.243-2.774-3.207v-4.507H390.9v4.349c0,1,.433,1.343,1.213,1.343.535,0,1.344-.347,1.344-1.677v-4.016h2.456Z" transform="translate(-244.502 -183.797)" fill="#036" />
      <path d="M406.431,206.43a1.155,1.155,0,0,0-1.2-1.083c-1.373,0-1.59,1.17-1.59,2.21,0,.969.42,1.965,1.46,1.965a1.316,1.316,0,0,0,1.4-1.286h2.383a3.514,3.514,0,0,1-3.77,3.164,3.732,3.732,0,0,1-3.93-3.843,3.8,3.8,0,0,1,3.93-4.088c1.863,0,3.525.982,3.7,2.961Z" transform="translate(-248.762 -183.688)" fill="#036" />
      <path d="M413.844,206a2.306,2.306,0,0,1,1.17-2.008,4.954,4.954,0,0,1,2.384-.52c1.777,0,3.5.389,3.5,2.513v3.28a4.342,4.342,0,0,0,.29,1.921h-2.471a2.969,2.969,0,0,1-.144-.708,3.384,3.384,0,0,1-2.47.924c-1.43,0-2.557-.721-2.557-2.268,0-2.441,2.659-2.254,4.363-2.6.419-.086.65-.23.65-.693,0-.564-.679-.781-1.184-.781a1.1,1.1,0,0,0-1.229.941h-2.3Zm3.178,3.887c1.17,0,1.56-.666,1.5-2.211a6.928,6.928,0,0,1-1.517.4c-.549.13-1.012.362-1.012.954C416,209.637,416.474,209.884,417.022,209.884Z" transform="translate(-252.893 -183.688)" fill="#036" />
      <path d="M430.661,204.244h-1.517v3.409c0,.594.347.722.881.722.2,0,.419-.029.636-.029v1.806c-.447.015-.9.072-1.343.072-2.1,0-2.63-.607-2.63-2.644v-3.337h-1.243v-1.59h1.243v-2.268h2.456v2.268h1.517v1.59Z" transform="translate(-256.873 -182.657)" fill="#036" />
      <path d="M437.247,201.439h-2.456V199.56h2.456Zm-2.456.939h2.456v7.5h-2.456Z" transform="translate(-259.998 -182.381)" fill="#036" />
      <path d="M443.964,203.469a3.965,3.965,0,1,1-3.945,3.972A3.844,3.844,0,0,1,443.964,203.469Zm0,6.053c1.142,0,1.5-1.126,1.5-2.08s-.361-2.094-1.5-2.094-1.488,1.142-1.488,2.094S442.822,209.522,443.964,209.522Z" transform="translate(-261.746 -183.688)" fill="#036" />
      <path d="M453.377,203.686h2.384v1.026h.029a2.831,2.831,0,0,1,2.354-1.242c2.5,0,2.7,1.819,2.7,2.9v4.811h-2.457v-3.626c0-1.026.116-2.066-1.227-2.066-.925,0-1.33.779-1.33,1.6v4.089h-2.456v-7.5Z" transform="translate(-266.212 -183.689)" fill="#036" />
      <path d="M324.887,173.752h-25l-7.5,7.5v25l7.5,7.5h25l7.5-7.5v-7.5l-2.5-2.5h-12.5v2.5h-10v-10h10v2.469h12.5l2.5-2.469v-7.5Z" transform="translate(-212.383 -173.752)" fill="#69c" />
      <path d="M209.7,173.752h-10l-2.5,2.5v10l-12.5-12.5h-10l-2.5,2.5v35l2.5,2.5h10l2.5-2.5v-10l12.5,12.5h10l2.5-2.5v-35Z" transform="translate(-172.195 -173.752)" fill="#80b34d" />
      <path d="M247.291,213.752h10l2.5-2.5v-7.5l12.5-12.5v-15l-2.5-2.5h-10l-2.5,2.5v5l-5,5-5-5h0v-5l-2.5-2.5h-10l-2.5,2.5v15l12.5,12.5v7.5Z" transform="translate(-192.289 -173.752)" fill="#f93" />
      <g transform="translate(115.524 0.001)">
        <g transform="translate(0)">
          <path d="M345.75,174.11v-.356h1.814v.356h-.693v1.773h-.424V174.11Zm2.782-.356.544,1.525.541-1.525h.611v2.129h-.392v-1.716l-.586,1.716h-.341l-.594-1.716v1.716h-.391v-2.129Z" transform="translate(-345.75 -173.754)" fill="#036" />
        </g>
      </g>
    </svg>
  )
}

