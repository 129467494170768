
import IAddCourseAdditionalStaff from '../model/IAddCourseAdditionalStaff'
import { AxiosInstances as AxiosInstance} from './BaseService'

export default {
    GetUnassignedStaffBySchoolDBN: (locationCode : string , courseUniqueId : string) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Schools/${locationCode}/AdditionalStaff/${courseUniqueId}`
        }),
        getByCourseId: (courseId : number) =>
        AxiosInstance({
            'method': 'GET',
            'url': `/Courses/${courseId}/AdditionalStaff`
        }),
        post: (requestdata: IAddCourseAdditionalStaff) =>
        AxiosInstance({
            'method': 'POST',
            'url': `/Courses/AdditionalStaff`,
            'data': requestdata
        }), 
        delete: (courseId : number, additionalStaffId : string) =>
        AxiosInstance({
            'method': 'DELETE',
            'url': `/Courses/${courseId}/AdditionalStaff/${additionalStaffId}`
        }), 
}