import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Button, Nav, NavItem, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Grid, GridColumn, GridFilterChangeEvent, GridNoRecords, GridDataStateChangeEvent, GridExpandChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import IEnrolledStudent from '../../model/IEnrolledStudent';
import StudentService from '../../services/StudentService';
import { FormMode, IDropDownValue } from '../../services/Utility'
import schoolService from '../../services/SchoolService';
import IAddCourseStudent from '../../model/IAddCourseStudent';
import IconStudnetOutline from '../../assets/icons/icon-student-outline';
import LocalStorageService from '../../services/LocalStorage';
import { AuthContextDefault, UserProfileType, Year } from '../../services/AppContext';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DataResult, process, State } from '@progress/kendo-data-query';
import { setGroupIds, getGroupIds, setExpandedState } from '@progress/kendo-react-data-tools';
interface IAssignedStudent {
    courseId: any
    courseStatus: string
    courseSource: string
    coursePurpose: string
    isEditable?: boolean | false
    locationCode: string
    onChangeStudentCount: any
    updateCourseStatus: any
    isSharedCourse: boolean
}
const initialDataState = {
    skip: 0,
    take: 10,
};

const initialState: State = {
    group: [{ field: 'sectionId' }]
};

const processWithGroups = (data: IEnrolledStudent[], dataState: State) => {
    let newDataState = process(data, dataState);
    setGroupIds({ data: newDataState.data, group: dataState.group });
    newDataState.data = newDataState.data.map((group) => {
        if (group.value && group.items && group.items.length > 0) {
            group.value = (

                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}> <span>{group.items[0].sectionName}</span> <span style={{
                    display: 'inline-block',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    padding: '2px 8px',
                    borderRadius: '4px',
                }}>{group.items.length}</span></div>

            );
        }
        return group;
    });
    return newDataState;
};


export default function EnrolledStudent(studentProps: IAssignedStudent) {
    const mountedRef = useRef(true);
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [courseStudent, setCourseStudent] = useState<IEnrolledStudent[]>([]);
    const [userProfile, setUserProfile] = useState<UserProfileType>(AuthContextDefault.userProfile);
    const { orginalSchoolYear } = useContext(Year);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [data, setRowData] = useState<IEnrolledStudent[]>([]);
    const [dataCopy, setRowCopyData] = useState<IEnrolledStudent[]>([]);
    const [deleteStudentId, setDeleteStudent] = useState<number>(0);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [orginalSchoolList, setOrginalSchoolList] = useState<IDropDownValue[]>([]);
    const [schoolList, setSchoolList] = useState<IDropDownValue[]>([]);
    const [resultState, setResultState] = React.useState<DataResult>();
    const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
    const [gridData, setGridData] = React.useState<any>();
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };
    const [filter, setFilter] = useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);

    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const getStudent = (locationCode: string) => {
        StudentService.getBySchoolDBN(locationCode).then(response => {
            let students = response.data.map((s: any) => ({
                studentId: s.studentId,
                firstName: s.firstName,
                lastName: s.lastName,
                fullName: s.lastName + ' ' + s.firstName,
                userName: s.userName,
                locationName: s.locationName,
                schoolDBN: s.schoolDBN,
                role: s.role,
                gradeLevelCode: s.gradeLevelCode,
            }))
            students = students.filter((el: any) => !courseStudent.find((e: any) => e.studentId == el.studentId))
            setRowData(students)
            setRowCopyData(students)
        })
    }
    const getAssignedStudent = (CourseId: number) => {
        StudentService.getByCourseId(CourseId).then(response => {
            let studentData = response.data as IEnrolledStudent[];
            studentData.forEach(x => x.courseSource = studentProps.courseSource);
            studentData.forEach(x => x.courseStatus = studentProps.courseStatus);
            setCourseStudent(studentData)
            var dataResult = processWithGroups(studentData, initialState)
            setResultState(dataResult)
            setCollapsedState(collapsedState.length ? [] : getGroupIds({ data: dataResult.data }));
            const newData = setExpandedState({
                data: dataResult.data,
                collapsedIds: getGroupIds({ data: dataResult.data })
            });
            setGridData(newData)
        })

    }
    const getSchoolLocations = () => {
        schoolService.getData().then(response => {
            let dropDownData = response.data.map((sl: any) => ({
                id: sl.locationCode,
                text: sl.locationCode + ' -- ' + sl.schoolName
            }));
            setSchoolList(dropDownData)
            setOrginalSchoolList(dropDownData)
        });
    }
    const filterData = (filter: any) => {
        let data = orginalSchoolList.slice();
        return filterBy(data, filter);
    }

    const filterChange = (event: any) => {
        let data = filterData(event.filter)
        setSchoolList(data)
    }

    const handleChange = (event: any) => {
        let selectedSchool = event.target.value;
        getStudent(selectedSchool.id);
    }

    const getDefaultValue = (schoolList: any) => {
        let school = schoolList.find((s: any) => s.id == studentProps.locationCode)
        if (school) {
            return school
        }
    }

    useEffect(() => {

        getAssignedStudent(studentProps.courseId);
        setUserProfile(LocalStorageService.getUserProfile())
        return () => { mountedRef.current = false }
    }, [studentProps.locationCode]);

    const [studentVisible, setStudentVisible] = useState<boolean>(false);
    const toggleStudentDialog = () => {
        setStudentVisible(!studentVisible)
        let data = orginalSchoolList.slice();
        setSchoolList(data)
        setHeaderCheckBox(false);
    }
    const addStudent = () => {
        setErrorMsg('');
        getSchoolLocations();
        getStudent(studentProps.locationCode);
        setFormMode(FormMode.New)
        toggleStudentDialog()
    }

    const onGridCheckBoxClick = (dataItem: any) => {
        dataItem.isSelected = !dataItem.isSelected;
        forceUpdate();
    }

    const [headerCheckBoxValue, setHeaderCheckBox] = useState(false);
    const onHeaderCheckBoxClick = (event: any, products?: any) => {
        let checked = event.target.checked;
        if (checked == true) {
            data.map((t: any) => t.isSelected = true)
        } else {
            data.map((t: any) => t.isSelected = false)
        }
        setHeaderCheckBox(checked)
    }
    const removeStudent = (studentId: number) => {
        setDeleteStudent(studentId)
        setFormMode(FormMode.Delete)
    }


    const [searchStudentId, setSearchStudentId] = useState(0);
    const searchStudentChange = (event: any) => {
        const target = event.target
        if (!target.value || target.value == 0) {
            setErrorMsg('')
            setRowData(dataCopy)
        }
        setSearchStudentId(target.value)
    };
    const getStudentById = () => {
        setErrorMsg('')
        if (searchStudentId == 0) {
            setErrorMsg("Please enter student ID ")
            return
        }
        setRowData([])
        StudentService.getStudentById(searchStudentId).then(response => {
            if (response.data) {
                let student = response.data as IEnrolledStudent
                student.fullName = student.lastName + ' ' + student.firstName
                let students = []
                students.push(student)
                setRowData(students)
                setPage(initialDataState)
            } else {
                setErrorMsg("Student is not exist")
            }
        })
    }

    const onSave = (event: any, actionType: FormMode) => {
        switch (actionType) {
            case FormMode.New:
                let addCourseStudent: IAddCourseStudent = {
                    courseId: studentProps.courseId,
                    courseSchoolDBN: studentProps.locationCode,
                    schoolYear: +orginalSchoolYear.id,
                    student: []
                }
                addCourseStudent.student = data.filter(x => x.isSelected);

                if (addCourseStudent.student.length == 0) {
                    setErrorMsg('Please select atleast one student to add.');
                    return;
                }
                let studentIds = courseStudent.map(a => a.studentId);
                let addedStudent = addCourseStudent.student.filter((x) => !studentIds.includes(x.studentId));;
                addCourseStudent.student = addedStudent;

                if (addedStudent?.length == 0) {
                    setErrorMsg('Selected students are already added.');
                    return;
                }
                var currentStudentList = courseStudent;
                currentStudentList = currentStudentList.concat(addedStudent);
                StudentService.post(addCourseStudent).then(response => {
                    studentProps.onChangeStudentCount(addedStudent.length, studentProps.courseId)
                    studentProps.updateCourseStatus(studentProps.courseId)
                    setCourseStudent(currentStudentList);
                    setStudentVisible(!studentVisible)
                    setFormMode(FormMode.None)
                    setHeaderCheckBox(false);
                });
                break;
            case FormMode.Delete:
                StudentService.delete(studentProps.courseId, deleteStudentId).then(response => {
                    let courseStudentList = courseStudent.filter(x => x.studentId != deleteStudentId);
                    studentProps.onChangeStudentCount(-1, studentProps.courseId)
                    studentProps.updateCourseStatus(studentProps.courseId)
                    setCourseStudent(courseStudentList);
                    setFormMode(FormMode.None)
                });
                break;
            default: {
                setFormMode(FormMode.None)
                break;

            }
        }
    }

    const studentNameCell = (props: any) => {
        if (props.rowType === "groupHeader") {
            return null;
        } else {
            return (
                <td>
                    <div> {props.dataItem.lastName} {props.dataItem.firstName}</div>
                    <div className="small">OSIS:{props.dataItem.studentId}</div>
                </td>
            );
        }
    }

    const locationCell = (props: any) => {
        if (props.rowType === "groupHeader") {
            return null;
        } else {
            return (
                <td>
                    <div className="small">Associated Location:</div>
                    <div>{props.dataItem.schoolDBN}</div>
                </td>
            );
        }
    }
    const enrolledInCourseCell = (props: any) => {
        if (props.rowType === "groupHeader") {
            return null;
        } else {
            return (
                <td>
                    <div className="small">Enrolled in Course:</div>
                    <div>{props.dataItem.sectionName}</div>
                </td>
            );
        }
    }



    const studentIconCell = (props: any) => {
        if (props.rowType === "groupHeader") {
            return null;
        } else {
            return (
                <td>
                    <div className="d-flex">
                        <div className="svg-app-icon text-secondary mr-3" style={{ height: "40px" }}>
                            <IconStudnetOutline />
                        </div>
                    </div>
                </td>
            );
        }


    };
    const onExpandChange = React.useCallback(
        (event: GridExpandChangeEvent) => {

            const item = event.dataItem;

            if (item.groupId) {
                const collapsedIds = !event.value ?
                    [...collapsedState, item.groupId] :
                    collapsedState.filter(groupId => groupId !== item.groupId);
                setCollapsedState(collapsedIds);
                var dataResult = processWithGroups(courseStudent, initialState)

                const newData = setExpandedState({
                    data: dataResult?.data,
                    collapsedIds: collapsedIds
                });
                setGridData(newData)
            }
        },
        [collapsedState]
    );

    return (
        <div>
            {studentVisible && <Dialog title={"Add Students"} onClose={toggleStudentDialog} style={{ width: "75%", position: "fixed", left: "15%", top: "20%", height: "350px" }} >
                <div id="dropDownParent">
                    <div hidden={errorMsg.length == 0}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMsg}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" >
                        <div className="text-sm-left text-left">
                            <InputGroup >
                                <FormControl
                                    placeholder="Student Id"
                                    onChange={searchStudentChange}
                                />
                                <Button variant="outline-secondary" id="button-addon1" onClick={getStudentById} >
                                    Search
                                </Button>
                            </InputGroup>
                        </div>
                        <div className="text-sm-right text-left"  >
                            <DropDownList
                                data={schoolList}
                                defaultValue={getDefaultValue(schoolList)}
                                textField="text"
                                filterable={true}
                                onChange={handleChange}
                                onFilterChange={filterChange}
                                popupSettings={{ appendTo: document.getElementById("dropDownParent") as HTMLElement }}
                                style={{ maxWidth: '400px', width: '100%' }}
                            />
                        </div>
                    </div>
                    <div className="mt-3" style={{ height: "400px" }}>
                        <Grid
                            data={filterBy(data, filter).slice(page.skip, page.take + page.skip)}
                            filter={filter}
                            filterable={true}
                            skip={page.skip}
                            take={page.take}
                            total={data.length}
                            pageable={true}
                            scrollable={"none"}
                            onPageChange={pageChange}
                            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}>
                            <GridColumn field="isSelected" width="40px" filterable={false} headerCell={props => (<input type="checkbox" checked={headerCheckBoxValue} onChange={(e) => onHeaderCheckBoxClick(e)} />)} cell={props => (
                                <td>
                                    <input type="checkbox" checked={props.dataItem.isSelected} onChange={() => onGridCheckBoxClick(props.dataItem)} />
                                </td>
                            )} />

                            <GridColumn field="UserIcon" title=" " filterable={false} width="50px" cell={
                                props => (
                                    <td>
                                        <div className="d-flex">
                                            <div className="svg-app-icon text-secondary mr-3" style={{ height: "30px" }}>
                                                <IconStudnetOutline />
                                            </div>
                                        </div>
                                    </td>
                                )} />
                            <GridColumn field="fullName" title="Name" cell={
                                props => (
                                    <td>
                                        <div>{props.dataItem.fullName}</div>
                                    </td>
                                )} />
                            <GridColumn field="studentId" title="OSIS" width="200px" />
                            <GridColumn field="userName" title="Username" width="200px" />
                            <GridColumn field="gradeLevelCode" title="Grade" width="200px" />
                            <GridColumn field="schoolDBN" title="Associated Location" filterable={false} />
                        </Grid>

                    </div>

                </div>
                <DialogActionsBar>
                    <Button className="m-3" variant="secondary" onClick={toggleStudentDialog}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Add Students</Button>
                </DialogActionsBar>
            </Dialog>}
            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}>

                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Remove Student</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove student ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <div className="card mt-4">
                <div className="card-header font-weight-bold bg-secondary-light">
                    <div className="row align-items-center">
                        <div className="col-9">Enrolled Students ({courseStudent.length})</div>
                        {studentProps.courseSource.toLowerCase() == 'stars' || studentProps.coursePurpose == 'Demo' || studentProps.courseStatus.toLowerCase() == 'closed' ? '' :
                            <div className="col-3  text-right">
                                {userProfile?.role?.roleName != 'Central Staff' && orginalSchoolYear.isCurrent != 0 && !studentProps.isSharedCourse ?
                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => { addStudent() }}>
                                        <i className="fas fa-plus"></i>
                                    </button> : studentProps.isSharedCourse && <span className='small'> (auto-synced from the courses above) </span>}
                            </div>
                        }

                    </div>
                </div>
                <div className="card-body">
                    <Grid
                        data={studentProps.isSharedCourse ? gridData : courseStudent}
                        // scrollable={"none"}
                        className="k-grid-no-header group-section"
                        groupable={{ enabled: studentProps.isSharedCourse, footer: 'none' }}
                        group={initialState.group}
                        onExpandChange={onExpandChange}
                        expandField="expanded"

                    >
                        {/* <GridColumn field="expanded" width="80px" title=' ' /> */}
                        <GridNoRecords>
                            No Enrolled Students
                        </GridNoRecords>
                        <GridColumn field="UserIcon" title=" " width="80px" cell={
                            studentIconCell} />
                        <GridColumn field="Name" title="tiltle" cell={
                            studentNameCell} />
                        {!studentProps.isSharedCourse &&
                            <GridColumn field="" title="Title" cell={
                                props => (
                                    <td>
                                        <div className="small">Title:</div>
                                        <div>Student</div>
                                    </td>
                                )} />}
                        {!studentProps.isSharedCourse &&
                            <GridColumn field="" title="Username" cell={
                                props => (
                                    <td>
                                        <div className="small">Username:</div>
                                        <div>{props.dataItem.userName}</div>
                                    </td>
                                )} />}
                        {studentProps.isSharedCourse && <GridColumn field="EnrolledInCourse" title="Enrolled in Course" cell={enrolledInCourseCell} />}
                        <GridColumn field="schoolDBN" title="Associated Location" cell={locationCell} />
                        {!studentProps.isSharedCourse &&
                            <GridColumn field="" title=" " cell={
                                props => (
                                    <td className="text-right menu-btn">
                                        {(userProfile?.role?.roleName == 'Central Staff'
                                            || props.dataItem?.courseStatus?.toLowerCase() === 'closed'
                                            || props.dataItem?.courseSource?.toLowerCase() === 'stars') || studentProps.isSharedCourse
                                            ? '' :
                                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={(e) => { removeStudent(props.dataItem.studentId) }}>
                                                <i className="fas fa-minus"></i>
                                            </button>
                                        }
                                    </td>
                                )} />
                        }
                    </Grid>
                </div>
            </div>
        </div>
    )
}