import React, { useState, useEffect, useReducer, useRef } from 'react';
import coursesService from '../../services/CoursesService';
import { Grid, GridColumn, GridFilterChangeEvent, } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import ICourseVendorContent from '../../model/ICourseVendorContent';
import { CheckIfNYCDOELicense } from '../../helpers/helper';

interface IAddVendorContent {
    courseId: number,
    schoolDBN: string,
    closeCourseVendorContentModal: (error: string) => void
    updateCourseStatus: any
}
const initialDataState = {
    skip: 0,
    take: 10,
};
export default function AddVendorContent(props: IAddVendorContent) {
    const mountedRef = useRef(true);
    const [page, setPage] = React.useState(initialDataState);

    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const [data, setRowData] = useState<ICourseVendorContent[]>([]);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
    };
    const [filter, setFilter] = useState(initialFilter);

    const getUnassignedVendorContentData = () => {
        coursesService.getUnassignedVendorContentData(props.courseId, props.schoolDBN).then(response => {
            setRowData(response.data)
        })
        return () => { mountedRef.current = false }
    }

    useEffect(() => {
        getUnassignedVendorContentData();
        return () => { mountedRef.current = false }
    }, []);

    const addVendorCourseContentRecords = (vendorConentId: number, vendorId: number) => {
        let courseVendorContent: ICourseVendorContent = {
            courseId: props.courseId,
            schoolDBN: props.schoolDBN,
            vendorContentId: vendorConentId,
            vendorId: vendorId,
            vendorContentName: '',
            vendorName: '',
            availableLicenses: 0,
            totalLicenses: 0,
            statusCode: ''
        };
        coursesService.post(courseVendorContent).then(response => {


            props.updateCourseStatus(props.courseId)
            props.closeCourseVendorContentModal(!response.data.isValidResponse ? response.data.errors.join() : "");
        });
    }
    return (
        <div>
            <Grid
                data={filterBy(data, filter).slice(page.skip, page.take + page.skip)}
                filter={filter}
                filterable={true}
                skip={page.skip}
                take={page.take}
                total={data.length}
                scrollable={"none"}
                pageable={true}
                onPageChange={pageChange}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            >
                <GridColumn field="vendorContentName" title="Vendor Course Name" />
                <GridColumn field="vendorName" title="Vendor" />
                <GridColumn field="AvailableLicense" title="Available Licenses" filterable={false}
                    // Grid Header - center text
                    headerCell={
                        props => (
                            <div className="text-center">
                                {props.title}
                            </div>
                        )}
                    // Grid Cell - center text
                    cell={
                        props => (
                            <td className="text-center">
                                {CheckIfNYCDOELicense(props.dataItem.vendorModel) ?
                                    <div>Unlimited of Unlimited</div>
                                    : <div>{props.dataItem.availableLicenses} of {props.dataItem.totalLicenses}</div>}

                            </td>
                        )}
                />
                <GridColumn field="Add" title=" " filterable={false}
                    cell={
                        props => (
                            <td className="text-right">
                                <div>
                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => { addVendorCourseContentRecords(props.dataItem.vendorContentId, props.dataItem.vendorId) }}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </td>
                        )}
                />
            </Grid>
        </div>
    )
}