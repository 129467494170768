import React, { useState, useEffect } from 'react';
import productService from '../../../services/ProductService';
import vendorService from '../../../services/VendorService';
import codesetService from '../../../services/CodesetService';
import IProduct from '../../../model/Product'
import { Modal, Button, Dropdown, DropdownButton, Form, Row, Col, Card } from 'react-bootstrap';
import { Grid, GridColumn, GridFilterChangeEvent,GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import IVendor from '../../../model/Vendor';
import ICodeSet from '../../../model/Codeset';
import { FormMode } from '../../../services/Utility'
import { CaseOrDefaultClause } from 'typescript';

export default function Product(props: any) {
    const [rowData, setRowData] = useState<IProduct[]>([]);
    const [vendors, setVendorList] = useState<IVendor[]>([]);
    const options = vendors.map(v => ({
        label: v.vendorName,
        value: v.id
    }));
    const [codesetData, setCodeSetData] = useState<ICodeSet[]>([]);
    const [priceTypeList, setpriceTypeList] = useState<ICodeSet[]>([]);
    const [formMode, setFormMode] = useState<FormMode>(FormMode.None);
    const [formValue, setFormValue] = useState<IProduct>({
        id: 0,
        productSKU: '',
        productName: '',
        productDescription: '',
        price: 0,
        vendorId: 0,
        vendorName: '',
        priceTypeId: 0,
        //priceType: '',
        isActive: true,
        modifiedBy: ''
    });
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
      };
    const [filter, setFilter] = useState(initialFilter);

    const initialSort: Array<SortDescriptor> = [
    ];
    const [sort, setSort] = React.useState(initialSort);
    const handleChange = (event: any) => {
        const target = event.target //as HTMLSelectElement
        const value = target.type === 'checkbox' ? target.checked : target.value

        setFormValue({ ...formValue, [target.name]: value });
    };

    const post = (event: any) => {
        let product: IProduct = {
            productSKU: formValue.productSKU,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            price: Number(formValue.price),
            vendorId: Number(formValue.vendorId),
            priceTypeId: Number(formValue.priceTypeId),
            //priceType: '',
            isActive: formValue.isActive,
            modifiedBy: ''
        };
        productService.post(product).then(response => {
            // Display Success message
        })
        event.preventDefault();
    }

    const onFormAction = (productId: number, actionType: FormMode) => {
        setErrors({
            productNameError: '',
            productSKUError: '',
            priceError: '',
            priceTypeError: '',
        });
        switch (actionType) {
            case FormMode.New:
                setFormMode(FormMode.New)
                //Clear Existing vlues
                let productReset: IProduct = {
                    id: 0,
                    productSKU: '',
                    productName: '',
                    productDescription: '',
                    price: 0,
                    vendorId: props?.selectedVendorId,
                    priceTypeId: 0,
                    //priceType: '',
                    isActive: true,
                    modifiedBy: ''
                };
                setFormValue(productReset)
                vendorService.getData().then(response => {
                    setVendorList(response.data)
                });
                codesetService.getData().then(response => {
                    setCodeSetData(response.data);
                    let data = response.data as ICodeSet[]
                    let priceTypeList = data.filter(x => x.codeSetName.toLowerCase().includes('pricetype'));
                    setpriceTypeList(priceTypeList);
                });

                break;
            case FormMode.Edit:
                setFormMode(FormMode.Edit)

                vendorService.getData().then(response => {
                    setVendorList(response.data);
                });

                productService.getById(productId).then(productResponse => {
                    setFormValue(productResponse.data);
                });

                codesetService.getData().then(response => {
                    setCodeSetData(response.data);
                    let data = response.data as ICodeSet[]
                    let priceTypeList = data.filter(x => x.codeSetName.toLowerCase().includes('pricetype'));
                    setpriceTypeList(priceTypeList);
                });

                break;
            case FormMode.Delete:
                let productDetails: IProduct = {
                    id: productId,
                    productSKU: '',
                    productName: '',
                    productDescription: '',
                    price: 0,
                    vendorId: 0,
                    priceTypeId: 0,
                    //priceType: '',
                    isActive: true,
                    modifiedBy: ''
                };
                setFormValue(productDetails)
                setFormMode(FormMode.Delete)
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }

    const onSave = (event: any, actionType: FormMode) => {
        let product: IProduct = {
            id: formValue.id,
            productSKU: formValue.productSKU,
            productName: formValue.productName,
            productDescription: formValue.productDescription,
            price: Number(formValue.price),
            vendorId: Number(formValue.vendorId),
            priceTypeId: Number(formValue.priceTypeId),
            //priceType: '',
            isActive: formValue.isActive,
            modifiedBy: ''
        };
        switch (actionType) {
            case FormMode.New:
                event.preventDefault();
                if (validate()) {
                    productService.post(product).then(response => {
                        // Display Success message
                        getProductData();
                        const target = event.target 
                        if(target.id == "save-add-another-product"){
                            let productReset: IProduct = {
                                id: 0,
                                productSKU: '',
                                productName: '',
                                productDescription: '',
                                price: 0,
                                vendorId: props?.selectedVendorId,
                                priceTypeId: 0,
                                //priceType: '',
                                isActive: true,
                                modifiedBy: ''
                            };
                            setFormValue(productReset)
                            setFormMode(FormMode.New)
                        }else{
                            setFormMode(FormMode.None)
                        }
                        
                    })
                }
                //Clear Existing vlues
                break;
            case FormMode.Edit:
                setFormMode(FormMode.Edit)
                event.preventDefault();
                if (validate()) {
                    productService.edit(product).then(response => {
                        // Display Success message
                        getProductData();
                        setFormMode(FormMode.None)
                    })
                }
                break;
            case FormMode.Delete:
                setFormMode(FormMode.Delete)
                var productId = Number(product.id);
                productService.delete(productId).then(response => {
                    // Display Success message
                    getProductData();
                    setFormMode(FormMode.None)
                })
                break;
            default: {
                setFormMode(FormMode.None)
                break;
            }
        }
    }
    const getProductData = () => {
        productService.getByVendorId(props?.selectedVendorId).then(response => {
            setRowData(response.data)
        })
    }
    useEffect(() => {
        getProductData();
    }, []);

    const [errors, setErrors] = useState({
        productNameError: '',
        productSKUError: '',
        priceError: '',
        priceTypeError: '',
    })
    const validate = () => {
        let error = {
            productNameError: '',
            productSKUError: '',
            priceError: '',
            priceTypeError: '',
        };

        if (formMode == FormMode.New || formMode == FormMode.Edit) {
            if (!formValue.productName) { error.productNameError = "Name is required"; }
            if (!formValue.productSKU) { error.productSKUError = "SKU is required"; }
            if (!formValue.priceTypeId) { error.priceTypeError = "Choose Price Type"; }
            if (!formValue.price) { error.priceError = "Cost is required"; }

            setErrors(error);
            if (error.productNameError || error.productSKUError || error.priceTypeError || error.priceError) {
                return false;
            }
        }
        return true;
    };

    const GridColumnButton = (props: any) => {
        return (
            <td className="text-right" style={{ overflow: 'visible' }}>
                <DropdownButton
                    menuAlign="right"
                    title={<i className='fas fa-bars'></i>}
                    id="dropdown-menu-align-right"
                    size="sm"
                    variant="outline-primary"
                    className="menu-bars"
                >
                    <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id, FormMode.Edit) }}>Edit Pricing</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onFormAction(props.dataItem?.id, FormMode.Delete) }}>Delete Pricing</Dropdown.Item>
                </DropdownButton>
            </td>
        )
    }

    return (
        <div>
            <Modal backdrop="static" keyboard={false} animation={false} show={formMode == FormMode.Edit || formMode == FormMode.New} onHide={() => { setFormMode(FormMode.None) }}>
                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>{formMode == FormMode.Edit ? "Edit" : "Add"} Vendor Pricing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <input type="hidden" name="id" value={formValue.id ?? ''} onChange={handleChange} />
                        <Row>
                            <Form.Group controlId="productName" className="col-sm-12">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="productName" value={formValue.productName ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger">{errors.productNameError}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="productSKU" className="col-sm-12">
                                <Form.Label>famis # (Sku)</Form.Label>
                                <Form.Control type="text" name="productSKU" value={formValue.productSKU ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger">{errors.productSKUError}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="price" className="col-sm-6">
                                <Form.Label>Cost</Form.Label>
                                <Form.Control type="text" name="price" value={formValue.price ?? ''} onChange={handleChange} />
                                <Form.Text className="text-danger">{errors.priceError}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="state" className="col-md-6 col-sm-6">
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select" name="priceTypeId" value={formValue.priceTypeId} onChange={handleChange}>
                                    <option key={0} defaultValue={0} selected hidden>Choose</option>
                                    {priceTypeList.map((priceTypeOptions) => (
                                        <option key={priceTypeOptions.id} value={priceTypeOptions.id}>{priceTypeOptions.displayText}</option>
                                    ))}
                                </Form.Control>
                                <Form.Text className="text-danger">{errors.priceTypeError}</Form.Text>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setFormMode(FormMode.None) }}>Close</Button>
                    <Button variant="primary" type="button" onClick={(e) => onSave(e, formMode)}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={formMode == FormMode.Delete}
                onHide={() => { setFormMode(FormMode.None) }}
                animation={false}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the Product ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { setFormMode(FormMode.None) }}>Cancel</Button>
                    <Button variant="primary" type="button" onClick={(e) => { onSave(e, formMode) }}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Card className="mt-4">
                <Card.Header className="card-header font-weight-bold bg-secondary-light">
                    <Row className="align-items-center">
                        <Col xs={10}>Vendor Pricing</Col>
                        <Col xs={2} className="text-right">
                            <Button variant="primary" size="sm" onClick={(e) => { onFormAction(0, FormMode.New)}}>
                                <i className="fas fa-plus"></i>
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Grid data={filterBy(rowData, filter)} 
                        filter={filter}
                        filterable={false} 
                        pageable={false} 
                        sortable={true}
                        scrollable={"none"}                            
                        sort={sort}
                        onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                        onSortChange={(e: GridSortChangeEvent) => {setSort(e.sort);}}
                        className="k-grid-no-header"
                    >
                        <GridColumn field="productName" title="Name" />
                        <GridColumn field="productSKU" title="FAMIS # (SKU)" />
                        <GridColumn field="Pricing" filterable={false} sortable={false} title="Pricing" cell={
                            props => (
                                <td>
                                    <div>{props.dataItem.price} {props.dataItem.priceType}</div>
                                </td>
                            )} />
                        <GridColumn field="ProductId" filterable={false} title=" " cell={GridColumnButton} />
                    </Grid>
                </Card.Body>
            </Card>            
        </div>
    )
}

