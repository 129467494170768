import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

export default function IconPersonCircle() {

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
      <title>Person</title>
      <path d="M22.5,23.5c-0.5-0.2-1.1-0.4-1.6-0.6c-0.7-0.3-0.8-0.4-0.8-0.5c0,0,0-0.2,0-0.3v-2.4c1.6-2,2.6-5,2.6-8.2 c0-3.3-3-5.9-6.7-5.9c-3.7,0-6.7,2.6-6.7,5.9c0,3,1,6.1,2.6,8.1v2.6c0,0.1,0,0.3,0,0.3c0,0-0.1,0.2-0.8,0.5c-0.5,0.2-1,0.4-1.6,0.6 c-1.2,0.4-1.8,0.6-2.1,1c-0.3,0.3-0.3,0.8,0,1.1c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,1-0.4,1.5-0.6 c0.5-0.2,1.1-0.4,1.6-0.6c1.6-0.6,1.8-1.2,1.8-2.3v-1.1c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.9,0.1,1.1-0.3s0.1-0.9-0.3-1.1
        c-2.1-1.2-3.7-4.8-3.7-8.4c0-2.4,2.3-4.3,5.2-4.3c2.8,0,5.1,1.9,5.2,4.3c-0.9-0.3-1.8-1.3-2.1-1.7c-0.2-0.2-0.4-0.4-0.7-0.3 c-0.2,0-0.5,0.2-0.6,0.4c0,0.1-0.3,0.4-1.3,1.2c-0.9,0.8-2.8,0.7-3.5,0.7c-0.4-0.1-0.8,0.3-0.9,0.7s0.3,0.8,0.7,0.9 c0.1,0,0.4,0,0.9,0c1,0,2.7-0.2,3.8-1.1c0.4-0.3,0.6-0.6,0.8-0.8c0.6,0.6,1.6,1.5,2.7,1.7c-0.4,3.1-1.7,5.8-3.6,6.9 c-0.4,0.2-0.5,0.7-0.3,1.1c0.1,0.2,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1c0.1,0,0.1-0.1,0.2-0.1v1c0,1,0.3,1.6,1.8,2.3 c0.5,0.2,1.1,0.4,1.6,0.6c0.6,0.2,1.3,0.5,1.5,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1 C24.3,24.2,23.7,23.9,22.5,23.5z"/>
      <path d="M16,0C7.2,0,0,7.2,0,16c0,8.8,7.2,16,16,16s16-7.2,16-16C32,7.2,24.8,0,16,0z M16,30.4C8,30.4,1.6,24,1.6,16 S8,1.6,16,1.6S30.4,8,30.4,16S24,30.4,16,30.4z"/>
    </svg>
  )
}

