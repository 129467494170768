import React, { useState, useEffect, useContext } from 'react';
import ILicenseTransferHistoryReport from '../../model/ILicenseTransferHistoryReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import ReportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import Moment from 'moment';

interface ILocation {
    changeExcelRef: any;
    selectedReport: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

const initialSort: Array<SortDescriptor> = [
    { field: "license", dir: "desc" },
];

export default function LicenseTransferHistoryReport(props: ILocation) {
    const { orginalSchoolYear } = useContext(Year);
    const [filter, setFilter] = React.useState(initialFilter);
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = React.useState(initialSort);
    const [rowData, setRowData] = useState<ILicenseTransferHistoryReport[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);
    const pageChange = (event: any) => {
        setPage(event.page);
    };

    const GetLicenseTransferHistoryReport = () => {
        ReportsService.GetLicenseTransferHistoryReport(+orginalSchoolYear.id).then(response => {
            response.data.map((s:any)=>{
              s.date = Moment(s.date).format('YYYY-MM-DD')
            })
            setRowData(response.data)
            props.changeExcelRef(_export)
        })
    }
    useEffect(() => {
        GetLicenseTransferHistoryReport();
    }, [props.selectedReport, orginalSchoolYear.id]);

    const filterReportData = (data: any, filter: any) => {
        let report = rowData;
        return filterBy(report, filter);
      }
      const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
      }


    return (
        <ExcelExport data={rowData} ref={_export} fileName={"License_Transfer_History" + ' ' + new Date().toDateString()}>
            <Grid
                data={filterReportData(orderBy(rowData, sort),filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                filterable={true}
                filter={filter}
                onFilterChange={onFilterChange}
                style={{width:"auto"}}
                scrollable={"scrollable"}>
                <GridColumn field="locationFrom" width="180px" filterable={true} title="From Location"></GridColumn>
                <GridColumn field="locationTo" filterable={true} title="To Location"></GridColumn>
                <GridColumn field="vendor" filterable={true}  title="Vendor"></GridColumn>
                <GridColumn field="license" filterable={false}  title="License"></GridColumn>
                <GridColumn field="transferBy" filterable={false}  title="Transfer By" ></GridColumn>
                <GridColumn field="date" filterable={false}  title="Date"></GridColumn>
            </Grid>
        </ExcelExport>
    )
}