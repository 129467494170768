import React, { useState, useEffect, useContext } from 'react';
import ITeacherCreatedCourse from '../../model/ITeacherCreatedCourseReport'
import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid'
import reportsService from '../../services/ReportsService'
import { Year } from '../../services/AppContext'
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { ListGroup } from 'react-bootstrap';
import SplashScreen from '../../shared/SplashScreen';


interface IProps {
    changeExcelRef: any
}

const initialDataState = {
    skip: 0,
    take: 10,
};

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};
const initialSort: Array<SortDescriptor> = [
    { field: "courseName", dir: "asc" },
];

export default function TeacherCreatedCourseReport(props: IProps) {
    const { orginalSchoolYear } = useContext(Year);
    const [page, setPage] = React.useState(initialDataState);
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState(initialSort);
    const [isLoading, setIsLoading] = useState(true);
    const [rowData, setRowData] = useState<ITeacherCreatedCourse[]>([]);
    const _export = React.useRef<ExcelExport | null>(null);

    const pageChange = (event: any) => {
        setPage(event.page);
    };





    const GetTeacherCreatedCourseReport = () => {
        reportsService.GetTeacherCreatedCourseReport(+orginalSchoolYear.id).then(response => {
            setRowData(response.data)
            props.changeExcelRef(_export)
            setIsLoading(false);
        }).catch((ex) => {
            setIsLoading(false);

        }).finally(() => {
            setIsLoading(false);
        })
    }

    const GridColumnDetails = (props: any) => {
        return (
            <td>
                <div>
                    {moment(props.dataItem.createdOn).format('MM/DD/YYYY')}
                </div>
            </td>
        )
    }

    const GetILearnBadgeCssClass = (status: boolean) => {

        if (status === true) {
            return "badge badge-success"
        }
        else if (status === false) {
            return "badge badge-danger"
        }
    }

    const GridILearnCell = (props: any) => {
        return (
            <td >
                <div className={GetILearnBadgeCssClass(props.dataItem.iLearnFlag)}>{props.dataItem.iLearnFlag ? 'Yes' : 'No'}</div>
            </td>
        )
    }
    const GridTeacherCell = (props: any) => {

        return (
            <td style={{ minWidth: "300px" }}>
                <div>
                    <ListGroup variant="flush" >
                        {props.dataItem.teachers.length && props.dataItem.teachers.map((item: any) => {

                            return (<ListGroup.Item style={{ backgroundColor: "transparent", border: "none" }} key={item.ein}>{item.ein} | {item.lastName + ' ' + item.firstName + ' ' + '(' + item.teacherEmail + ')'} </ListGroup.Item>)
                        })}
                    </ListGroup>
                </div>
            </td>
        )
    }

    const Custom = (props: any) => {

        return (
            <td style={{ minWidth: "300px" }}>
                <div>
                    <ListGroup variant="flush" >
                        {props.dataItem.teachers.length && props.dataItem.teachers.map((item: any) => {

                            return (<ListGroup.Item style={{ backgroundColor: "transparent", border: "none" }} key={item.ein}>{item.ein} | {item.lastName + ' ' + item.firstName + ' ' + '(' + item.teacherEmail + ')'} </ListGroup.Item>)
                        })}
                    </ListGroup>
                </div>
            </td>
        )
    }


    const GetStatusBadgeCssClass = (status: string) => {

        if (status.toLowerCase() === "new") {
            return "badge badge-blue-gray"
        }
        else if (status.toLowerCase() === "edited") {
            return "badge badge-secondary"
        }
        else if (status.toLowerCase() === "published") {
            return "badge badge-success"
        }
        else if (status.toLowerCase() === "closed") {
            return "badge badge-danger"
        }
        else if (status.toLowerCase() === "reopened") {
            return "badge badge-info"
        }
    }

    const GridStatusCell = (props: any) => {
        return (
            <td >
                <div className={GetStatusBadgeCssClass(props.dataItem.courseStatus)}>{props.dataItem.courseStatus}</div>
            </td>
        )
    }

    const filterReportData = (data: any, filter: any) => {
        let report = rowData;
        return filterBy(report, filter);
    }
    const onFilterChange = (e: any) => {
        setPage(initialDataState)
        setFilter(e.filter)
    }

    useEffect(() => {
        GetTeacherCreatedCourseReport();
    }, [orginalSchoolYear.id]);


    return (
        <div>
            {isLoading && <div className="d-flex justify-content-center"><SplashScreen /></div>}
            <ExcelExport data={rowData} ref={_export} fileName={"Teacher_Created_Course_Report" + ' ' + new Date().toDateString()}>
                <ExcelExportColumn field="locationCode" title="School Location"></ExcelExportColumn>
                <ExcelExportColumn field="courseName"  title="Course Name"></ExcelExportColumn>
                <ExcelExportColumn field="courseStatus"  title="Course Status"></ExcelExportColumn>
                <ExcelExportColumn field="iLearnFlag"  title="isILearn?"></ExcelExportColumn>
                <ExcelExportColumn field="createdOn"  title="Created On" ></ExcelExportColumn>
            </ExcelExport>
            <Grid
                data={filterReportData(orderBy(rowData, sort), filter).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={rowData.length}
                pageSize={page.take}
                pageable={{ pageSizes: [10, 25, 50], info: true }}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                filter={filter}
                onFilterChange={onFilterChange}
                scrollable={"none"}>
                <GridColumn field="locationCode" filterable={true} title="School Location"></GridColumn>
                <GridColumn field="courseName" filterable={true} title="Course Name"></GridColumn>
                <GridColumn field="courseStatus" cell={GridStatusCell} filterable={false} title="Course Status"></GridColumn>
                <GridColumn field="teachers" cell={GridTeacherCell} filterable={false} title="Teachers"></GridColumn>
                <GridColumn field="iLearnFlag" cell={GridILearnCell} filterable={false} title="isILearn?"></GridColumn>
                <GridColumn field="createdOn" cell={GridColumnDetails} filterable={false} title="Created On" ></GridColumn>

            </Grid>


        </div>
    )
}